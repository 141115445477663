import { FC } from 'react'
import { ReactComponent as CrossSmallIcon } from '../../assets/icons/icon-cross-small.svg';
import { Btn } from '../index';
import { ITag } from './Tag.d'
import './Tag.scss'

const Tag: FC<ITag> = ({
  text,
  onClick
}) => {
  return (
    <div className="tag">
      <p>{ text }</p>
      <Btn
        isIconBtn={true}
        isReset={true}
        icon={{
          Component: CrossSmallIcon,
          width: 7,
          height: 7,
        }}
        onClick={onClick}
      />
    </div>
  );
};

export default Tag;
