export interface IStretchSettingItem {
    index: number,
    el: Element | null,
    x: number,
    y: number,
    elements: Array<Element> | null,
    head: Element | null,
    body: Element | null,
    total: Element | null,
    stretch?: Element | null,
    stretch_indexes?: Array<Element>
}

export const onStretchBlock = ({
    e,
    parent='.table-pagination',
    selectorStretches='.table-pagination__stretch-cell',
    selectorTitles='.table-pagination__th',
    selectorParentElements='tr',
    selectorElements='td',
    selectorHeadBlock='.table-pagination__head-block',
    selectorBodyBlock='.table-pagination__scroll',
    selectorTotalBlock='.table-pagination__total',
}:{
    e: any,
    parent?: string,
    selectorStretches?: string,
    selectorTitles?: string,
    selectorParentElements?: string,
    selectorElements?: string,
    selectorHeadBlock?: string,
    selectorBodyBlock?: string,
    selectorTotalBlock?: string,
})=> {
    e = e || window.event;
    const el = (e.srcElement || e.target).parentNode.parentNode;
    const block = el.closest(parent);
    const stretches = block.querySelectorAll(`${selectorStretches}`);
    const titles = block.querySelectorAll(`${selectorTitles}`);
    const index = Array.from(titles).indexOf(el);
    const getIndexByWeight = (arr: Array<any>, weight: number) => {
        let sum = 0;
        for (let i = 0; i < arr.length; i++) {
            sum += arr[i].colSpan;
            if (sum >= weight) {
                return i;
            }
        }
        return arr.length - 1;
    }
    let stretch;
    let indexStretch;
    if (stretches.length > 0) {
        indexStretch = getIndexByWeight(stretches, index + 1);
        stretch = stretches[indexStretch];
    }
    const elements = block.querySelectorAll(`${selectorElements}:nth-child(${index + 1})`);
    const headBlock = block.querySelector(`${selectorHeadBlock}`);
    const bodyBlock = block.querySelector(`${selectorBodyBlock}`);
    const totalBlock = block.querySelector(`${selectorTotalBlock}`);
    const result: IStretchSettingItem = {
        'index': index,
        'el': el,
        'x': e.clientX - el.offsetWidth,
        'y': e.clientY - el.offsetHeight,
        'elements': elements,
        'head': headBlock,
        'body': bodyBlock,
        'total': totalBlock
    };
    if (stretch && typeof indexStretch === 'number') {
        const selectorElementsFirstRow = `${selectorParentElements}:first-child > ${selectorElements}`
        const elementsFirstRow = block.querySelectorAll(selectorElementsFirstRow);
        result['stretch'] = stretch;
        const elems: IStretchSettingItem['stretch_indexes'] = [];
        for (let i = indexStretch; i < stretch.colSpan; i++) {
            elems.push(elementsFirstRow[i])
        }
        result['stretch_indexes'] = elems;
    }
    return result;
}