import {useCallback, useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";

import {stepsData, thirdDone} from "../../../../redux/slices/projects";
import {projectIntegrations,listIntegrations} from "../../../../redux/slices/project";
import {closeModal, ModalName, openModal} from "../../../../redux/slices/modals";
import {openPopup} from "../../../../redux/slices/popup";
import {
  authorizeGetIntent,
  authorizeHybrid,
  downloadIntegrations,
  downloadProjectIntegration,
  removeProjectIntegration,
  selectClientForHybrid,
  updateIntegrationName
} from "../../../../actions/project";

import {Input} from "../../../../ui";
import {PopupType} from "../../../../data";
import IntegrationCard, {CardProps} from "../../../../components/IntegrationCard";
import Empty from "../../../../components/Empty";

import cls from "./style.module.scss";
import './style.scss';
import {trans} from "../../../../_locales";
import {currentUser, currentUserLang, userId} from "../../../../redux/slices/user";
import {sendSdk} from "../../../../utils";


export const ThirdStep = () => {
  const reduxStepsData = useAppSelector(stepsData);
  const currentProject = useAppSelector(projectIntegrations);
  const generalIntegrations = useAppSelector(listIntegrations);
  const language = useAppSelector(currentUserLang);
  const user = useAppSelector(currentUser);
  const reduxUserId = useAppSelector(userId);
  const currentListIntegrations = currentProject || [];
  const [generalListIntegrations, setGeneralListIntegrations] = useState(generalIntegrations ? generalIntegrations.integrations : []);
  const [generalListCategories, setGeneralListCategories] = useState(generalIntegrations ? generalIntegrations.categories : []);
  const [showIntegrations, setShowIntegrations] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  if(Object.values(reduxStepsData).length === 0) {
    navigate('/projects/create/1/');
  }
  const projectId =  reduxStepsData && reduxStepsData.dataProject && Number(reduxStepsData.dataProject.id);
  useEffect(()=> {
    if (reduxUserId && projectId) {
      sendSdk('page_view', {
        'user': {
          'uid': reduxUserId
        },
        'event_params': {
          'project_id': projectId,
          'user_id': reduxUserId,
          'page': 'project_create_integrations'
        }
      })
    }
  }, [projectId, reduxUserId])
  useEffect(() => {
    dispatch(downloadProjectIntegration({ projectId: projectId}))
  }, [dispatch, projectId])
  useEffect(() => {
    dispatch(downloadIntegrations()).then((r)=> {
      if(r.payload) {
        setGeneralListIntegrations(r.payload.integrations);
        setGeneralListCategories(r.payload.categories);
      }
    })
  }, [dispatch])

  const nextStep = () => {
    dispatch(thirdDone())
    navigate('/projects/create/4')
  }

  const [inputCategory, setInputCategory] = useState<string>('')

  const COMPLETE_FILTER = {
    AdsSystem: "Рекламные системы",
    Calltracking: "Коллтрекинг",
    Mediatracker: "Медиатрекеры",
    Incompleted: "CRM",
    All: "Все"
  };
  const [filter, setFilter] = useState(COMPLETE_FILTER.All);
  const [emptyFilterList, setEmptyFilterList] = useState(false);
  const getfilteredTodos = (list:any, curFilter = COMPLETE_FILTER.All, search='') => {
    setEmptyFilterList(false);
    if(list.length === 0) return [];

    if (curFilter === COMPLETE_FILTER.All) {
      const temp = list.filter((item:any)=>item.title.includes(search));
      if(temp.length === 0) setEmptyFilterList(true);
      return temp;
    }
    const temp = list.filter((item:any) => item.category === curFilter && item.title.includes(search));
    if(temp.length === 0) setEmptyFilterList(true);
    return temp;
  };
  const filteredTodos = useMemo(() => getfilteredTodos(generalListIntegrations, filter,inputCategory), [filter,inputCategory]);
  const handleFilterUpdate = useCallback((newFilter:any) => {
    setFilter(newFilter);
  }, []);


  const onFiltersChange = (category:string) => {
    handleFilterUpdate(category);
  };

  const onClickAddedIntegration = (event:any, item:any) => {
    if (event.target.closest('.edit')) {
      dispatch(openModal({
          name:ModalName.UPDATE_INTEGRATION,
          data: {
            projectId: projectId,
            fields: [
              {
                tag: 'input',
                type: 'text',
                name: 'name',
                tooltip: trans('Maximum text length: 20 characters', language),
                placeholder: '',
                label: trans('Naming', language),
                errorLabel: trans('The field is required', language),
                isRequired: true,
                limitSymbols: 20
              }
            ],
            initialValues: {
              'name': item.name
            },
            data: item,
            cb: (data:any) => {
              if(projectId) {
                dispatch(updateIntegrationName({
                  projectId: +projectId,
                  piid: item.id,
                  name:data['name']
                }))
              }
            }
          }
      }))
    }
    if (event.target.closest('.remove')) {
      dispatch(openModal({
        name:ModalName.CONFIRM_DELETE,
        data: {
          text: trans('Confirm the deletion of the integration from the project', language),
          onConfirm: () => dispatch(removeProjectIntegration({projectId: Number(projectId), integrationId: item.id}))
        }
      }))
    }
  }

  let integration_window:any = null;
  const onRedirect = (url:string) => {
    integration_window = window.open(`/#/confirm/integrations/yandex-direct/?url=${url}&state="project_id":${projectId}`, "_blank");
  }
  window.addEventListener('yandex_direct', function(event:any) {
    if(event.detail.error) {
      dispatch(openPopup({ type: PopupType.ERROR, description: event.detail.error }))
    }
    integration_window && integration_window.close();
  })

  const onClickCard = (card:any) => {
      if (card.title === 'GetIntent') {
        dispatch(openModal({
          name: ModalName.ADD_INTEGRATION,
          data: {
            projectId: projectId,
            data: card,
            fields: [
              {
                tag: 'input',
                type: 'text',
                name: 'name',
                tooltip: trans('Maximum text length: 20 characters', language),
                placeholder: '',
                label: trans('Naming', language),
                errorLabel: trans('The field is required', language),
                isRequired: false,
                limitSymbols: 20
              },
              {
                tag: 'input',
                type: 'text',
                name: 'token',
                placeholder: '',
                label: trans('Token', language),
                errorLabel: trans('The field is required', language),
                isRequired: true
              }
            ],
            cb: (data: any) => {
              if (projectId && data['token'])
                dispatch(authorizeGetIntent({projectId: +projectId, token: data['token'], userId: +user.id, name: data['name']})).then((r)=> {
                  dispatch(closeModal(ModalName.ADD_INTEGRATION))
                })
            }
          }
        }))
      } else if (card.title === 'Hybrid') {
        dispatch(openModal({
          name: ModalName.ADD_INTEGRATION,
          data: {
            projectId: projectId,
            fields: [
              {
                tag: 'input',
                type: 'text',
                name: 'name',
                tooltip: trans('Maximum text length: 20 characters', language),
                placeholder: '',
                label: trans('Naming', language),
                errorLabel: trans('The field is required', language),
                isRequired: false,
                limitSymbols: 20
              },
              {
                tag: 'input',
                type: 'text',
                name: 'client_id',
                placeholder: '',
                label: 'ClientID',
                errorLabel: trans('The field is required', language),
                isRequired: true
              },
              {
                tag: 'input',
                type: 'text',
                name: 'client_secret',
                placeholder: '',
                label: trans('ClientSecret', language),
                errorLabel: trans('The field is required', language),
                isRequired: true
              },
              {
                tag: 'input',
                type: 'text',
                name: 'advertiser_id',
                placeholder: '',
                label: trans('Advertiser Id', language),
                errorLabel: '',
                isRequired: false
              }
            ],
            cb: (data: any) => {
              const temp = {
                name: data['name'],
                client_id: data['client_id'],
                client_secret: data['client_secret'],
                advertiser_id: data['advertiser_id'] ? data['advertiser_id'] : ''
              }
              if (projectId) {
                dispatch(authorizeHybrid({projectId: +projectId, data: temp, userId: +user.id})).then((r)=> {
                  dispatch(closeModal(ModalName.ADD_INTEGRATION))
                  if(r.payload) {
                     if(r.payload === 'Ok') {
                       dispatch(openPopup({ type: PopupType.SUCCESS, title: 'Интеграция успешно добавлена', icon: 'setting' }))
                     }
                     if(r.payload.advertisers) {
                        const temp:any[] = [];
                        r.payload.advertisers.map((item:any) => {
                          temp.push({
                            label: item.Name,
                            value: item.Id
                          })
                        })
                        dispatch(openModal({
                          name: ModalName.ADD_INTEGRATION,
                          data: {
                            projectId: projectId,
                            fields: [
                              {
                                tag: 'select',
                                type: 'text',
                                name: 'advertiser_id',
                                placeholder: '',
                                label: trans('Select the Advertiser', language),
                                errorLabel: trans('Select the Advertiser', language),
                                isRequired: true,
                                isOpenUp: false,
                                options: temp
                              },
                            ],
                            cb: (data: any) => {
                              if (projectId) {
                                dispatch(selectClientForHybrid({
                                  projectId: +projectId,
                                  clientId: data['advertiser_id'],
                                  integrationId: r.payload.integration_id,
                                  projectIntegrationId: r.payload.project_integration_id
                                })).then((r)=> {
                                  dispatch(closeModal(ModalName.ADD_INTEGRATION))
                                  if(r.payload === 'Ok') {
                                    dispatch(openPopup({ type: PopupType.SUCCESS, title: 'Интеграция успешно добавлена', icon: 'setting' }))
                                  }
                                })
                              }
                            },
                            data: card,
                          }
                        }))
                     }
                   }
                })
              }
            },
            data: card,

          }
        }))
      } else if (card.extra_data && card.extra_data.auth_uri) {
        onRedirect(card.extra_data.auth_uri)
      }
  }

  return (
    <div className={cls.wrapper}>
      <div className={showIntegrations ?  cls.form : cls.integratons_hidden}>
        <h3 className={cls.onboard_step}>{trans('Connected integrations', language)}</h3>
        <h4 className={cls.onboard_desc}>{trans("You can add data from third-party systems to your project. For example, CRM systems, for importing sales data or Advertising systems, for importing expense data. You can connect the integration after, in the project settings. If you already know what you can connect, then feel free to click on the 'Add' button", language)}</h4>
        <div className={cls.added_integrations}>
          <button
            className={cls.add_btn}
            type='button'
            onClick={() => {
              setGeneralListIntegrations(generalIntegrations.integrations);
              setShowIntegrations(!showIntegrations)
            }}
          ><br/>
            {trans('Add', language)}
          </button>
          {
              currentListIntegrations.length > 0
                ?
                currentListIntegrations.map((card:CardProps) => <IntegrationCard
                  className={cls.integration_card}
                  key={card.id}
                  id={card.id}
                  img={card.img}
                  title={card.title}
                  name={card.name ? card.name : user.name}
                  width={70} height={70}
                  onClick={(e:any) => {
                    onClickAddedIntegration(e, card)
                  }}
                  edit={true}
                  remove={true}
                />)
                :
                ''
            }
        </div>
        <div className={cls.footer}>
          <button className={cls.btn} type='button' onClick={nextStep}>{trans('Next step', language)}</button>
          <button className={cls.skipper} onClick={() => navigate('/projects/create/4/')}>{trans('Skip', language)}</button>
        </div>
      </div>
      {showIntegrations &&
        <div className={showIntegrations ? cls.integrations : cls.hidden}>
        <p className={cls.modal_title}>{trans('Search integration', language)}</p>
        <div className={cls.options}>
          <div className={cls.search_block}>
            <Input
              className={`custom-input--search ${cls.search_input}`}
              type={'text'}
              name={'field-search'}
              placeholder={trans('Enter the name of the service...', language)}
              isSearch
              value={inputCategory}
              onChange={setInputCategory}
              isDirectCbInput={true}
            />
          </div>

          <div className={cls.nails}>
            {generalListCategories.map((category:string, index:number) => (
                <button
                  key={index}
                  className={`${cls.nail} ${filter === category ? cls.active : ''}`}
                  onClick={()=>onFiltersChange(category)}
                >
                  {trans(category, language)}
                </button>
            ))}
          </div>
        </div>
          <div className={cls.grid}>
           {
              filteredTodos.length > 0
                ?
                filteredTodos.map((card:CardProps) => <IntegrationCard
                  key={card.id}
                  id={card.id}
                  img={card.img}
                  title={card.title}
                  width={70} height={70}
                  onClick={()=> onClickCard(card)}
                />)
                : emptyFilterList ? <Empty title={trans('Nothing found', language)} /> :
                generalListIntegrations.map((card:CardProps) => <IntegrationCard
                  key={card.id}
                  id={card.id}
                  img={card.img}
                  title={card.title}
                  width={70} height={70}
                  onClick={()=> onClickCard(card)}
                />)
            }
          </div>
      </div>}
    </div>
  );
};
