import React from 'react'
import { createRoot } from 'react-dom/client'
import { HashRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import App from './App'
import reportWebVitals from './reportWebVitals'
import { store } from './redux'
// import './styles/index.css'
import './styles/normalize.css'
import './styles/fonts.scss'
import './styles/reboot.scss'
import './styles/utils.scss'
import './styles/container.scss'
import './styles/title.scss'
import './styles/page-content.scss'
import './styles/create.scss'

Sentry.init({
  dsn: "https://6aaa4bea054944e4a3476159742e7932@o4504196889182208.ingest.sentry.io/4504196891475970",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});


const container = document.getElementById('root') as HTMLDivElement
const root = createRoot(container)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
