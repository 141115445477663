import {trans} from "../../../_locales";
import {Accordion, Btn, Input, Toggle} from "../../../ui";
import Select from "../../ui/Select";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {currentUserLang} from "../../../redux/slices/user";
import {FocusEvent, FormEvent, Fragment, useCallback, useEffect, useState} from "react";
import {getInitialState, validateAll, validateOne, validateUrlValue} from "../../../utils";
import {DefaultObject, IField} from "../../../interfaces/common.d";
import {IAccordion} from "../../ModalForm/ModalForm.d";
import {getDashboardsFilterValues} from "../../../actions/dashboards";
import {newPlatformForm, PopupType} from "../../../data";

import './style.scss';
import {currentCampaign} from "../../../redux/slices/breadcrumbs";
import {openPopup} from "../../../redux/slices/popup";

interface INewPlacement {
    projectId:number,
    cb:(data: JSON) => any,
    initialValues?: DefaultObject<string>,
    type: 'create' | 'edit'
}

const NewPlacement = ({
  projectId,
  cb = (data) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      for (const [name, value] of data) {
          console.log(`${name}: ${value}`);
      }
  },
  initialValues,
  type = 'create'
}:INewPlacement)=> {
    const dispatch = useAppDispatch();
    const language = useAppSelector(currentUserLang);
    const reduxCurrentCampaign = useAppSelector(currentCampaign);

    const [targeting, setTargeting] = useState<any>({})
    const [geo, setGeo] = useState<any>({})
    const [creative, setCreative] = useState<any>({})
    const scrollBlockProps = { 'data-name': 'scroll-block' };

    useEffect(()=> {
        dispatch(getDashboardsFilterValues({projectId: projectId, name: 'targeting'})).then((r=> setTargeting(r.payload)));
        dispatch(getDashboardsFilterValues({projectId: projectId, name: 'geo'})).then((r=> setGeo(r.payload)));
        dispatch(getDashboardsFilterValues({projectId: projectId, name: 'creative'})).then((r=> setCreative(r.payload)));
        if(!initialValues) {
            if(reduxCurrentCampaign?.onboarding_url) {
                setCampaignUrlDisabled(false);
                setCampaignUrlChecked(true);
                handleFieldChange({'onboarding_url': reduxCurrentCampaign.onboarding_url})
            } else setCampaignUrlDisabled(true)
        }
    }, [dispatch, projectId])

    const [errors, setErrors] = useState(getInitialState<(IField | IAccordion)[], boolean>(
      newPlatformForm() as (IField | IAccordion)[],
      false
    ));
    const [form, setForm] = useState(initialValues ? initialValues :
      getInitialState<(IField | IAccordion)[], string>(
        newPlatformForm() as (IField | IAccordion)[],
        ''
      ));

    const handleFieldChange = useCallback((data: {[index: string]: string }) => {
      const name = Object.keys(data)[0];
      const currentForm = {...form, ...data};
      setForm(currentForm)

      if (errors[name]) {
        const {errorsUrl} = validateUrlValue(newPlatformForm() as (IField | IAccordion)[], currentForm);
        setErrors({...errors, ...validateOne(name, data[name]), ...errorsUrl})
      }
    }, [form, errors]);
    const handleUrlFieldBlur = useCallback((
      evt: FocusEvent<HTMLInputElement>
    ) => {
      const name = evt.target.name;
      const value = evt.target.value;

      if (!errors[name] && Boolean(value)) {
        const {errorsUrl} = validateUrlValue(
            newPlatformForm() as (IField | IAccordion)[],
            {[name]: value}
        );
        setErrors({...errors, ...errorsUrl})
      }

      const currentField: IField = newPlatformForm()?.filter((field) =>
          (field.tag === 'input' && field.name === name))[0] as IField;

      if (!currentField.isRequired && !value) {
        setErrors({...errors, ...{[name]: false}})
      }
    }, [errors])

    const handleFormReset = (evt: any) => {
        evt.preventDefault();
        setForm(getInitialState<(IField | IAccordion)[], string>(
            newPlatformForm() as (IField | IAccordion)[],
            ''
        ));
        setErrors(getInitialState<(IField | IAccordion)[], boolean>(
            newPlatformForm() as (IField | IAccordion)[],
            false
        ))
    }
    const handleFormSubmit = useCallback((evt: FormEvent<HTMLFormElement>) => {
        evt.preventDefault();

        const isValid = validateAll(form, errors, newPlatformForm() as (IField | IAccordion)[], setErrors);

        if (!isValid) return;
        let currentData = {};
        Object.entries(form).forEach(([name, value]) =>
            currentData = value ?
                Object.assign({}, currentData, {[name]: value}) :
                currentData
        );

        cb(JSON.stringify(currentData) as unknown as JSON);
        return;
    }, [form, errors, cb]);

    const changeSelect = (response:any, name:string) => {
        for (const key in response) {
            const data:any = {};
            data[name] = key;
            handleFieldChange(data);
        }
    }

    const formatDataset = {
        'JPEG': trans('JPEG', language),
        'GIF': trans('GIF', language),
        'HTML': trans('HTML', language),
        'Video': trans('Video', language),
        'Other': trans('Other', language),
    }

    const [campaignUrlChecked, setCampaignUrlChecked] = useState(false);
    const [campaignUrlDisabled, setCampaignUrlDisabled] = useState(false);
    const changeCheckbox = (checked:boolean) => {
        if(campaignUrlDisabled) {
            dispatch(openPopup({
                type: PopupType.WARNING,
                description: trans('В данной кампании не указана посадочная страница', language),
                icon: 'setting'
            }))
            return;
        }
        setCampaignUrlChecked(checked);
        if(checked && reduxCurrentCampaign?.onboarding_url) {
            handleFieldChange({'onboarding_url': reduxCurrentCampaign.onboarding_url})
            return;
        }
        handleFieldChange({'onboarding_url': ''})
    }

    return(
        <div className='new-placement'>
            <div className="new-placement__wrapper" {...scrollBlockProps}>
                <Input
                    type={'text'}
                    name={'name'}
                    value={form['name']}
                    placeholder={''}
                    label={trans('Placement name', language)}
                    errorLabel={trans('The field is required', language)}
                    isInvalid={errors['name']}
                    onChange={handleFieldChange}
                    required={true}
                />
                <Input
                    type={'text'}
                    className={`new-placement__url ${campaignUrlDisabled ? 'disabled':''}`}
                    name={'onboarding_url'}
                    value={form['onboarding_url']}
                    placeholder={''}
                    label={trans('Onboarding url', language)}
                    errorLabel={trans('Enter the correct url', language)}
                    isInvalid={errors['onboarding_url']}
                    onChange={handleFieldChange}
                    onBlur={handleUrlFieldBlur}
                    required={true}
                    tooltip={trans('This page will be redirected after clicking on the clickable pixel', language)}
                />
                {!campaignUrlDisabled && <Toggle
                    className='new-placement__checkbox custom-toggle--no-padding'
                    name='row-choice'
                    borderMod={true}
                    isChecked={campaignUrlChecked}
                    onChange={(evt)=>changeCheckbox(evt.currentTarget.checked)}
                    label={'Cкопировать страницу из кампании'}
                    // disabled={campaignUrlDisabled}
                    size={'small'}
                    color={'gray'}
                />}

                <Select
                    selectActive={(formatDataset as any)[form['format']]}
                    dataset={formatDataset}
                    onChangeActive={(r:any)=>changeSelect(r, 'format')}
                    label={trans('Format', language)}
                    isInvalid={errors['format']}
                    errorLabel={trans('Select the format', language)}
                    tooltip={trans('The field is used to filter and group the results. It will help you better look for insights in the data.', language)}
                    colorText={'dark'}
                />
                <Accordion title={trans('Advanced Settings', language)}>
                    <Fragment>
                        <Input
                            type={'text'}
                            name={'adv_id'}
                            value={form['adv_id']}
                            placeholder={''}
                            label={trans('ID in the advertising system', language)}
                            errorLabel={''}
                            onChange={handleFieldChange}
                            tooltip={trans('If your advertising system is present in the list of integrations, and you have connected it, then add the creative ID to this field, and expenses will be raised automatically.', language)}
                        />
                    </Fragment>
                    <Fragment>
                        <Input
                            type={'text'}
                            name={'geo'}
                            value={form['geo']}
                            placeholder={''}
                            label={trans('Geo', language)}
                            errorLabel={trans('The field is required', language)}
                            isInvalid={errors['geo']}
                            onChange={handleFieldChange}
                            tip={typeof geo === 'object' ? geo : {}}
                            tooltip={trans(`A free text field. It is needed to filter and group the results. It will help you better look for insights in the data. For example, "Moscow", "UFA", "Moscow and St. Petersburg", "regions"`, language)}
                        />
                    </Fragment>
                    <Fragment>
                        <Input
                            type={'text'}
                            name={'targeting'}
                            value={form['targeting']}
                            placeholder={''}
                            label={trans('Targeting', language)}
                            errorLabel={trans('The field is required', language)}
                            isInvalid={errors['targeting']}
                            onChange={handleFieldChange}
                            tip={typeof targeting === 'object' ? targeting : {}}
                            tooltip={trans(`A free text field. It is needed to filter and group the results. It will help you better look for insights in the data. For example: "wealthy audience", "audience of TV series fans".`, language)}
                        />
                    </Fragment>
                    <Fragment>
                        <Input
                            type={'text'}
                            name={'size'}
                            value={form['size']}
                            placeholder={''}
                            label={trans('Creative size', language)}
                            errorLabel={trans('The field is required', language)}
                            isInvalid={errors['size']}
                            onChange={handleFieldChange}
                            tooltip={trans('A free text field. It is needed to filter and group the results. It will help you better look for insights in the data.', language)}
                            tip={{
                              '240x400px': '240 x 400 px',
                              '300x250px': '300 x 250 px',
                              '300x500px': '300 x 500 px',
                              '300x600px': '300 x 600 px',
                              '336x280px': '336 x 280 px',
                              '640x100px': '640 x 100 px',
                              '640x200px': '640 x 200 px',
                              '640x960px': '640 x 960 px',
                              '728x90px': '728 x 90 px',
                              '960x640px': '960 x 640 px',
                              '970x250px': '970 x 250 px',
                              '160x600px': '160 x 600 px',
                              '1000x120px': '1000 x 120 px',
                              '200x200px': '200 x 200 px',
                              '250x250px': '250 x 250 px',
                              '250x360px': '250 x 360 px',
                              '580x400px': '580 x 400 px',
                              '930x180px': '930 x 180 px',
                              '970x90px': '970 x 90 px',
                              '980x120px': '980 x 120 px',
                              '300x1050px': '300 x 1050 px',
                              '300x50px': '300 x 50 px',
                              '1200x628px': '1200 x 628 px',
                              '600x600px': '600 x 600 px',
                              '628x1120px': '628 х 1120 px',
                              '1920x1080px': '1920 x 1080 px',
                              '1080х1920рх': '1080 х 1920 рх',
                              '1280х720': '1280 х 720',
                              '504х283': '504 х 283',
                              '1080x1080px': '1080  x 1080 рх',
                              '700x500': '700 x 500',
                              '1200x630': '1200 x 630',
                            }}
                        />
                    </Fragment>
                    <Fragment>
                        <Input
                            type={'text'}
                            name={'creative'}
                            value={form['creative']}
                            placeholder={''}
                            label={trans('Description', language)}
                            errorLabel={trans('The field is required', language)}
                            isInvalid={errors['creative']}
                            onChange={handleFieldChange}
                            tip={typeof creative === 'object' ? creative : {}}
                            tooltip={trans(`Describe the creative. The field is used to filter and group the results. It will help you better look for insights in the data. For example: "Creative with gold" or "Creative with UTP".`, language)}
                            beta={true}
                        />
                    </Fragment>
                    <Fragment>
                        {form['format'] === 'Video' && <Input
                            type={'number'}
                            name={'video_duration'}
                            value={form['video_duration']}
                            placeholder={''}
                            label={trans('Video duration', language)}
                            errorLabel={trans('The field is required', language)}
                            isInvalid={errors['video_duration']}
                            onChange={handleFieldChange}
                            limitNumber={10000}
                        />}
                    </Fragment>
                    <Fragment>
                        {form['format'] === 'Video' && <Select
                            selectActive={form['video_orientation']}
                            dataset={{
                              'vertical': 'vertical',
                              'horizontal': 'horizontal',
                            }}
                            onChangeActive={(r:any)=>changeSelect(r, 'video_orientation')}
                            label={trans('Video orientation', language)}
                            isInvalid={errors['video_orientation']}
                            errorLabel={trans('Select the orientation of the video', language)}
                            colorText={'dark'}
                        />}
                    </Fragment>
                </Accordion>
            </div>
            <div className="new-placement__btns">
              <Btn
                type='reset'
                text={trans('Clear', language)}
                size='lg'
                widthAuto={false}
                onClick={handleFormReset}
              />
              <Btn
                type='submit'
                text={(initialValues && type === 'edit') ? trans('Update', language) : trans('Create', language)}
                color='blue'
                widthAuto={false}
                size='lg'
                onClick={handleFormSubmit}
              />
            </div>
        </div>
    )
}

export default NewPlacement;