import Select from "../Select";
import {FC, useRef, useState} from "react";

import iconDate from '../../../assets/icons/calendar.svg'
import {DateRange, DayPicker} from "react-day-picker";
import ru from "date-fns/locale/ru";
import {useOnClickOutside} from "../../../helpers";

import {getDateAgo, getMonday, onDaysAgo} from "./functions";
import {formatDate, isNumber} from "../../../utils";

import './style.scss';
import {trans} from "../../../_locales";
import {useAppSelector} from "../../../redux/hooks";
import {currentUserLang} from "../../../redux/slices/user";


interface ITimeFilter {
    selectActive:string
    onChange: any
    setFlagOpenPicker?:any
}

const TimeFilter:FC<ITimeFilter> = ({selectActive, onChange, setFlagOpenPicker}) => {
    const language = useAppSelector(currentUserLang);
    const dayPickerRef = useRef(null);
    const datasetSelectDate = {
      'month': trans('This month', language),
      'previous_month': trans('Previous month', language),
      'week': trans('This week', language),
      'previous_week': trans('Previous week', language),
      'days_ago': trans('Last N days', language),
      'choice': trans('Select a range', language)
    }
    const [range, setRange] = useState<DateRange>();
    const [isPopperOpen, setIsPopperOpen] = useState<boolean>(false);
    const selectDate = selectActive;

    useOnClickOutside(() => {
        if (isPopperOpen) {
            setIsPopperOpen(false);
            if(typeof setFlagOpenPicker === 'function')
                setFlagOpenPicker(false);
        }
    }, [dayPickerRef]);
    const handleDateBtnClick = () => {
        setIsPopperOpen(true);
    };
    const handleRangeSelect = (range: DateRange) => {
        setRange(range);
        if (range.from && range.to) {
            const data = {
                start: formatDate(range.from),
                end: formatDate(range.to)
            }
          onChange(data);
          setIsPopperOpen(false);
          setIsInput(false);
          setRange(undefined);
        }
    }

    const onChangeInput = (response:any) => {
        onChange(onDaysAgo(response))
    }

    const [isInput, setIsInput] = useState<boolean>(false);

    const onChangeSelectDate = (response:object) => {
        const date = new Date();
        const data = {
            start: formatDate(new Date(date.getDate() - 1)),
            end: formatDate(new Date(date.getFullYear(),date.getMonth(),date.getDate() - 1))
        }
        for(const key in response)
            switch (key) {
              case 'month': {
                let count_month_ago = 0;
                if(date.getDate() === 1)
                    count_month_ago = 1;
                data.start = formatDate(new Date(date.getFullYear(),date.getMonth() - count_month_ago));
                break;
              }
              case 'previous_month': {
                const year = date.getFullYear(),
                  month = date.getMonth();
                let count_month_ago = 1;
                if(date.getDate() === 1)
                    count_month_ago = 2;
                data.start = formatDate(new Date(year,month - count_month_ago));
                data.end = formatDate(new Date(year,month - count_month_ago, 33 - new Date(year, month - count_month_ago, 33).getDate()));
                break;
              }
              case 'week': {
                data.start = date.getDay() === 1 ?
                    formatDate(getDateAgo(getMonday(), 7)) :
                    formatDate(getMonday());
                data.end = formatDate(getDateAgo(date, 1));
                break;
              }
              case 'previous_week': {
                let start_day_ago = 7,
                    end_day_ago = 1;
                if(date.getDay() === 1) {
                    start_day_ago = 14;
                    end_day_ago = 8;
                }
                data.start = formatDate(getDateAgo(getMonday(), start_day_ago));
                data.end = formatDate(getDateAgo(getMonday(), end_day_ago));
                break;
              }
              case 'days_ago': {
                setIsInput(true);
                return;
              }
              case 'choice':
                handleDateBtnClick();
                return;
            }
        setIsInput(false);
        onChange(data);
    }

    return(
        <div ref={dayPickerRef} className='time-filter'>
        <Select
            input={isInput}
            onInput={onChangeInput}
            inputConfig={{
                before: trans('Last', language),
                after: trans('days', language),
                limit: 365
            }}
            selectActive={selectDate}
            icon={iconDate}
            dataset={datasetSelectDate}
            onChangeActive={onChangeSelectDate}
        />
        {
            isPopperOpen &&
            <div className="custom-input__rdp">
              <div className="custom-input__rdp-inner">
                <DayPicker
                  mode="range"
                  defaultMonth={range?.from}
                  selected={range}
                  locale={ru}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  onSelect={handleRangeSelect}
                  fromMonth={new Date(new Date().setFullYear(new Date().getFullYear()-1))}
                  toDate={new Date()}
                />
              </div>
            </div>
        }
        </div>
    )
}

export default TimeFilter;