import {FC, useRef} from "react";
import './style.scss';
import {useOnClickOutside} from "../../../../helpers";
import cx from "classnames";
import Input from "../../../../ui/Input";
import Btn from "../../../../ui/Btn";
import {Link} from "react-router-dom";
import {Toggle} from "../../../../ui";
import { ReactComponent as CloseIcon } from '../../../../assets/icons/icon-close.svg';

interface ISelectOptions {
    className?:string,
    onChange:any
    dataWrapper:any
    activeFlag?:boolean
    activeKey?:string
    setFlagSort?:any,
    isLeft?:boolean,

    isSearch?:boolean,
    searchValue?: string,
    onSearchValue?: (evt: any) => void,
    isResetCheckbox?:boolean,
    onResetCheckbox?: () => void,

    isCheckbox?:boolean,
    listActive?: string[],
    onChangeCheckbox?: (checked:boolean, key:string) => void,

    isLink?:boolean
}

const SelectOptions:FC<ISelectOptions> = ({
    className,
    onChange,
    dataWrapper,
    activeFlag=false,
    activeKey,
    setFlagSort,
    isLeft,
    isSearch,
    searchValue,
    onSearchValue=()=>null,
    isResetCheckbox,
    onResetCheckbox=()=>null,
    isCheckbox,
    listActive=[],
    onChangeCheckbox=(checked:boolean, key:string) => console.log(key, checked),
    isLink
}) => {
    const selectRef = useRef<HTMLDivElement>(null);
    useOnClickOutside(() => {
        setFlagSort(false)
    }, [selectRef]);

    const contentClassName = cx('select-options',
        className,
        activeFlag ? 'active' : '',
        isLeft ? 'left' : ''
    );

    return(
        <div className={contentClassName} ref={selectRef}>
            {isSearch &&
                <div className={'select-options__search'}>
                    <Input
                        className='select-options__search_input'
                        name={'search'}
                        isSearch={true}
                        value={searchValue}
                        onChange={onSearchValue}
                    />
                    {isResetCheckbox &&
                        <div className='select-options__reset_icon'>
                            <Btn
                                color={'dark'}
                                isIconBtn={true}
                                icon={{
                                    Component: CloseIcon,
                                    width: 16,
                                    height: 16
                                }}
                                onClick={()=> {
                                    onResetCheckbox();
                                }}
                            />
                        </div>

                    }
                </div>
            }
            {(()=> {
                if (Object.keys(dataWrapper).length > 0) {
                    return Object.keys(dataWrapper).map((key:string, index:number)=>{
                        if(key) {
                            if(isCheckbox) {
                                return (<div key={index} className={'select-options__checkbox'}>
                                    <Toggle
                                        name='row-choice'
                                        borderMod={true}
                                        isChecked={listActive.includes(key)}
                                        onChange={(evt) => onChangeCheckbox(evt.currentTarget.checked, key)}
                                        label={(dataWrapper as any)[`${key}`]}
                                    />
                                </div>)
                            }
                            if(isLink) {
                                return(
                                    <Link key={index}
                                          to={dataWrapper[key].href}
                                          className={`select-options__link ${activeKey === key ? 'active' : ''}`}
                                          onClick={() => {
                                            onChange(key)
                                          }}
                                    >
                                        {dataWrapper[key].title}
                                    </Link>
                                )
                            }
                            return (
                                <button key={index}
                                        className={`select-options__btn ${activeKey === key ? 'active' : ''}`}
                                        onClick={() => onChange(key)}>
                                    {(dataWrapper as any)[`${key}`]}
                                </button>
                            )
                        }
                    })
                }
                return (<span style={{textAlign: 'center'}}>Не найдено</span>)
            })()}
        </div>
    )
}

export default SelectOptions;