import {FC} from "react";
import "./style.scss";

interface ITableLabelStretchItem {
    title: string,
    index:number,
    colSpan: number,
    isEmptyReturn: boolean
}
const TableLabelStretchItem:FC<ITableLabelStretchItem> = ({
    title,
    index,
    colSpan=1,
    isEmptyReturn=false
}) => {
    if(isEmptyReturn) {
        return <></>;
    }
    if(title) {
        return (
            <div className='table-label-stretch-item' data-col={colSpan}>
                <div className='table-label-stretch-item__block'>
                    <div className={`table-label-stretch-item__title ${index === 0 ? 'left' : ''}`}>
                        {title}
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className='table-label-stretch-item'></div>
    )
}

export default TableLabelStretchItem;