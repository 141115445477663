import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../../redux/hooks";
import {uploadCsv} from "../../../../../actions/upload";
import UploadContent from "../../../../../components/UploadContent";
import './style.scss';
import {useEffect, useState} from "react";
import {Loader} from "../../../../../components";
import {trans} from "../../../../../_locales";
import {currentUserLang, userId} from "../../../../../redux/slices/user";
import {updateUploadedFiles} from "../functions";
import {sendSdk} from "../../../../../utils";

const UploadPerformance = () => {
    const dispatch = useAppDispatch();
    const {projectId} = useParams();
    const language = useAppSelector(currentUserLang);
    const reduxUserId = useAppSelector(userId);

    useEffect(()=> {
        if (projectId && reduxUserId) {
            sendSdk('page_view', {
                'user': {
                    'uid': reduxUserId
                },
                'event_params': {
                    'project_id': projectId,
                    'user_id': reduxUserId,
                    'page': 'upload_campaign_expenses'
                }
            })
        }
    }, [projectId, reduxUserId])

    const [isLoading , setIsLoading] = useState(false);
    const data = {
        title: trans('Upload campaign performance expenses', language),
        subtitle: trans('This is a type of expenses for utm tags not from advertising systems, for which the expense is not automatically transferred, for example, CPA, paid mailings, in general, all paid placements that users can click on, except for media placements, they have their own section', language),
        info: [
            {
                title: trans('The file must contain the following fields', language),
                dataset: [
                    trans('Date (we upload expenses by day)', language),
                    'UTM Source',
                    'UTM Medium',
                    trans('Cost with VAT', language)
                ]
            },
            {
                title: trans('Optional fields', language),
                dataset: [
                    'UTM Campaign',
                    'UTM Content',
                    'UTM Term'
                ]
            }
        ],
    }
    const handleUploadedFiles = (files:any, scale:string) => {
        if(projectId) {
            updateUploadedFiles(files, scale, 'campaign_expenses', +projectId, setIsLoading, dispatch, reduxUserId, language);
        }
    }
        // setNewUserInfo({ ...newUserInfo, profileImages: files });
    return(
        <div className='upload__wrapper'>
            <UploadContent
                title={data.title}
                subtitle={data.subtitle}
                info={data.info}
                updateFilesCb={handleUploadedFiles}
                multiple={false}
                example='https://cdn.targetads.io/files/cost_perform.xlsx'
            />
            {(isLoading) &&
                <Loader inBlock={true}/>}
        </div>
    )
}

export default UploadPerformance;