import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'

interface SidebarState {
  data: ISidebarParams[]
  isLoading: boolean
  error: string | null
}
interface ISidebarParams {
  btn: {
    ariaLabel?: string,
    href?: string,
    icon: {
      Component: React.FunctionComponent<React.SVGProps<SVGSVGElement> & {title?: string | undefined}>,
      width: number,
      height: number
    }
  },
  isActive?: boolean
}

const initialState: SidebarState = {
  data: [],
  isLoading: false,
  error: null,
}

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setSidebar: (state, action: PayloadAction<ISidebarParams[]>) => {
      state.data = action.payload;
    },
    clearSidebar: (state) => {
      state.data = []
    },
  }
});

export const { setSidebar, clearSidebar } = sidebarSlice.actions

export const currentSidebar = createSelector(
  (state: RootState) => state.sidebar,
  (sidebar) => sidebar.data
)

export default sidebarSlice.reducer