import React, {FC, useEffect, useState} from 'react'
import { Loader } from '../../index'
import {Btn} from '../../../ui'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import {
  getIsLoading,
  errorValue,
  removeError
} from '../../../redux/slices/access'
import { useShowError } from '../../../helpers'
import './style.scss';

import {ILastActivityModal} from "./interfaces.d";

import { ReactComponent as Exit } from '../../../assets/icons/icon-exit-red.svg'
import {getUserInfo, logoutUsersDevices} from "../../../actions/users";
import {closeModal, ModalName} from "../../../redux/slices/modals";
import {currentUserLang, currentUserProfile} from "../../../redux/slices/user";
import {trans} from "../../../_locales";

const LastActivityModal: FC<ILastActivityModal> = ({
  userId,
  projectId,
    userActivity
}) => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(getIsLoading);
  const errorMessage = useAppSelector(errorValue);
  const language = useAppSelector(currentUserLang);

  useShowError(errorMessage as string, removeError);

  const [activity, setActivity] = useState(userActivity)
  const onSubmit = ()=> {
    dispatch(logoutUsersDevices())
    dispatch(closeModal(ModalName.LAST_ACTIVITY));
  }
  return (
    <>
      <ul className="last-activity__list">
        {
          activity.length > 0 ? activity.map((item:any, index:number)=> (
              <li key={index}>
                <div className='last-activity__location'>{item.location} {item.browser}</div>
                <div className='last-activity__info'>
                  <span>{item.last_visit}</span>
                  <span>IP: {item.ip}</span>
                </div>
              </li>
          )) : ''
        }
      </ul>
      <div className="last-activity__action">
        <Btn
          type='submit'
          text={trans('Terminate all other session',language)}
          color='red'
          widthAuto={false}
          size='lg'
          disabled={false}
          icon={{
              Component: Exit,
              width: 16,
              height: 13}}
          onClick={onSubmit}
        />
      </div>
      {isLoading && <Loader />}
    </>
  );
};

export default LastActivityModal;
