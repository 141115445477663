import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import {
  createReport,
  getReports
} from '../../actions/reports'
import {ICustomReport} from "../../components/ReportsUpdate/interfaces.d";

export interface ICurrentReports extends ICustomReport {
  id: number,
  status: 'completed'|'draft'|'active'|'pause'|'notsend',
  author: string,
  created_at: string,
  dataset: Array<Array<any>> | []
}
interface ReportsState {
  data: ICurrentReports[] | []
  loaded: boolean
  isLoading: boolean
  error: string | null
}

const initialState: ReportsState = {
  data: [],
  loaded: false,
  isLoading: false,
  error: null
}

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setReports: (state, action) => {
      state.data = action.payload;
    },
    clearReports: (state) => {
      state.data = [];
    },
    setReportsLoaded: (state, action) => {
      state.loaded = action.payload
    },
    removeError: (state) => {
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      getReports.fulfilled,
      (state, action: PayloadAction<ICurrentReports[]>) => {
      state.data = action.payload ? action.payload : [];
      state.loaded = true;
      state.isLoading = false;
    });
    builder.addCase(
      getReports.pending,
      (state) => {
      state.isLoading = true;
      state.loaded = false;
    });
    builder.addCase(
      getReports.rejected,
      (state,  action: PayloadAction<any>) => {
      state.isLoading = false;
      if (action.payload === 'Somehow settings were not found')
        state.data = [];
      state.error = action.payload
    });
    builder.addCase(
      createReport.fulfilled,
      (state, action: PayloadAction<ICurrentReports>) => {
      // state.data = action.payload ? action.payload : [];
      state.loaded = true;
      state.isLoading = false;
    });
    builder.addCase(
      createReport.pending,
      (state) => {
      state.isLoading = true;
      state.loaded = false;
    });
    builder.addCase(
      createReport.rejected,
      (state,  action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload
    });
  }
})

export const {
  setReports,
  removeError,
  clearReports,
  setReportsLoaded
} = reportsSlice.actions

export const reportsData = createSelector(
  (state: RootState) => state.reports,
  (reports) => reports.data
)

export default reportsSlice.reducer

