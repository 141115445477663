import {useNavigate} from "react-router-dom";
import {Loader} from "../../../components";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {useAppDispatch} from "../../../redux/hooks";

const ConfirmRegistration = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const postAuth = createAsyncThunk(
        'user/postAuth',
        async (
          _,
          { rejectWithValue, extra: API}: any
        ) => {
          try {
            const loc:string = window.location.href;
            const regexp = new RegExp(`token=([^&]*)`);
            const searchParams = loc.match(regexp);
            const data = {"code": searchParams ? searchParams[1] : ''};
            const response = await API.post(`/api/auth/auth_by_code/`, data);
            localStorage.setItem('token', response.data.access_token);
            localStorage.setItem('refresh_token', response.data.refresh_token);
            return response.data;
          } catch (e: any) {
            return rejectWithValue(e.response.data.detail);
          }
        }
    );
    dispatch(postAuth()).then(() => {
        navigate('/projects/create/1/');
    });
    return(
        <div>
            <Loader  />
        </div>
    )
}

export default ConfirmRegistration;