import {Btn} from "../../../ui";
import {ReactComponent as CloseIcon} from '../../../assets/icons/icon-close.svg'
import './style.scss';
import {useAppSelector} from "../../../redux/hooks";
import {currentUserLang} from "../../../redux/slices/user";
import {trans} from "../../../_locales";
import Code from "../../Code";
import React from "react";

const DocsCounter = ({title, onClose}: { title: string, onClose: any }) => {
    const language = useAppSelector(currentUserLang);

    const firstScript = `<script src="https://cdn.targetads.io/websdk/index.js"></script>
<script>
window.targetAdsDataLayer = window.targetAdsDataLayer || {}
function _targetadsTag(){window.targetAdsDataLayer.data = arguments;}
_targetadsTag('init', "<id проекта>")
_targetadsTag('page_view');
</script>`

    const secondScript = `<script async src="https://cdn.targetads.io/websdk/index.js"></script>
<script>
window.targetAdsDataLayer = window.targetAdsDataLayer || {}
function _targetadsTag(){window.targetAdsDataLayer.data = arguments;}
_targetadsTag('init', '<id проекта>')
_targetadsTag('page_view', {'event_params': {'uid':'<user_id>', '<параметр события>': '<значение параметра>'}});
</script>`

    const thirdScript = `_targetadsTag('event', {'event_type': '<тип события>',
'event_category': '<категория события>',
'event_name': '<название события>',
'event_value': ‘<ценность события>, 'event_params': {'uid':'<user_id>', '<параметр события>': '<значение>'}});`;

    const fourScript = `_targetadsTag('add_to_cart', {
'id': <id товара>,
'title': '<название продукта>',
'category1': '<категория 1 продукта>',
'category2': '<категория 2 продукта>',
'brand': '<бренд товара>',
'variant': '<вариант товара>',
'price': <стоимость товара>})`;

    const fiveScript = `_targetadsTag('purchase', {'id' : '<id покупки>', 'quantity': <количество товаров в корзине>, 'amount': <сумма покупки>,
'items': [{
'id': <id товара>,
'title': '<название продукта>',
'category1': '<категория 1 продукта>',
'category2': '<категория 2 продукта>',
'brand': '<бренд товара>',
'variant': '<вариант товара>',
'quantity': <количество товара>,
'price': <стоимость товара>}]`;

    const copyText = (step: number) => {
        let text = '';
        if (step === 1) text = firstScript;
        if (step === 2) text = secondScript;
        if (step === 3) text = thirdScript;
        if (step === 4) text = fourScript;
        if (step === 5) text = fiveScript;
        navigator.clipboard.writeText(text).then(r => document.execCommand('Copy'));
    }

    return (
        <div className={'docs-counter'}>
            <div className="docs-counter__title">
                <h2 className="title title--uppercase">{title}</h2>
                <Btn
                    className={'modal__close-btn'}
                    isIconBtn={true}
                    size='sm'
                    color='transparent'
                    onClick={onClose}
                    icon={{
                        Component: CloseIcon,
                        width: 16,
                        height: 16
                    }}
                />
            </div>
            <div className="docs-counter__body">
                <h3>{trans('Documentation on connecting the tracking counter on the website', language)}</h3>
                <div className="docs-counter__step">
                    <div className="docs-counter__head">
                        <span>1</span>&nbsp;{trans('Basic counter initialization', language)}</div>
                    <p className="docs-counter__text">{trans('Paste the following code as close to the top of the page as possible, preferably placed in the tag', language)} &lt;HEAD&gt;</p>
                    <div>
                        <div className="docs-counter__copy">
                            &lt;script src=&apos;https://cdn.targetads.io/websdk/index.js&apos;&gt;&lt;/script&gt;<br/>
                            &lt;script&gt;<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;window.targetAdsDataLayer = window.targetAdsDataLayer || {}<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;function
                            _targetadsTag(){'{window.targetAdsDataLayer.data = arguments;}'}<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;_targetadsTag(&apos;init&apos;,
                            “&lt;{trans('project id', language)}&gt;”)<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;_targetadsTag(&apos;page_view&apos;);<br/>
                            &lt;/script&gt;
                        </div>
                        <Btn
                            text={trans('Copy', language)}
                            color='light-blue'
                            onClick={() => copyText(1)}
                        />
                    </div>
                    <p className="docs-counter__text">
                        <b>{trans('project id', language)}</b> - {trans('your project id in the TargetADS system', language)}
                    </p>
                    <p className="docs-counter__text">{trans('With this code, you send a page view event to the server. After installing the code on the site, you will already be able to collect basic metrics from the site, such as page views, unique users and the number of sessions by traffic sources.', language)}</p>
                    <div className="docs-counter__substep">
                        <div className="docs-counter__subtitle">{trans('Дополнительные настройки', language)}</div>
                        <Code className='docs-counter__code'
                              code={`_targetadsTag('init', “<id проекта>”, <settings>)`}/>
                        <p className="docs-counter__text">В инициализацию можно передать опциональные
                            настройки <strong>settings</strong> в виде объекта.</p>
                        <p className="docs-counter__text">Например установим дебаг режим:</p>
                        <Code className='docs-counter__code'
                              code={`_targetadsTag('init', “<id проекта>”, {"debug": true})`}/>
                        <p className="docs-counter__text">Список параметров в settings:</p>
                        <div className='docs-counter__table'>
                            <table>
                                <thead>
                                <tr>
                                    <th>Параметр</th>
                                    <th>Тип</th>
                                    <th>Описание</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>debug</td>
                                    <td>boolean</td>
                                    <td>Выводит дополнительную информацию в console. Значение по умолчанию: false</td>
                                </tr>
                                <tr>
                                    <td>isOnlyOneInDay</td>
                                    <td>boolean</td>
                                    <td>Отправляет событие просмотра страницы только при первом посещении сайта
                                        пользователем в день. Значение по умолчанию: false
                                    </td>
                                </tr>
                                <tr>
                                    <td>isCheckLocation</td>
                                    <td>boolean</td>
                                    <td>Проверяет смену url страницы. В случае перехода на другую страницу сайта будет
                                        отправляться событие просмотра. Значение по умолчанию: false
                                    </td>
                                </tr>
                                <tr>
                                    <td>isCheckLocationAnchor</td>
                                    <td>boolean</td>
                                    <td>Включает закладки(якоря) в проверку смены url страницы. Работает совместно с
                                        параметром isCheckLocation. Значение по умолчанию: false
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="docs-counter__step">
                    <div className="docs-counter__head">
                        <span>2</span>&nbsp;{trans('Transmitting custom parameters', language)}</div>
                    <p className="docs-counter__text">{trans('The sending function accepts two parameters: the event type (string) and the object describing this event. For the page_view event, you can pass user parameters (user) and event parameters (event_params) to the object describing the event.', language)}</p>
                    <p className="docs-counter__text">{trans('Entity parameters are also objects describing this entity, where the key is the name of the parameter, and the value available by it is the value of the parameter. The exception is the \'uid\' key of the user object, the userId value is passed to it', language)}</p>
                    <p className="docs-counter__text">{trans('For example, in user parameters you can pass the user segment, user loyalty, the date of the previous order and much more, it all depends on the type of your business and the parameters by which you want to group and filter users. Up to 5 parameters of each type can be transmitted as much as possible.', language)}</p>
                    <div>
                        <div className="docs-counter__copy">
                            &lt;script async src=&apos;https://cdn.targetads.io/websdk/index.js&apos;&gt;&lt;/script&gt;
                            <br/>
                            &lt;script&gt;<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;window.targetAdsDataLayer = window.targetAdsDataLayer || {}<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;function
                            _targetadsTag(){'{window.targetAdsDataLayer.data = arguments;}'}<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;_targetadsTag(&apos;init&apos;, &apos;&lt;{trans('project id', language)}&gt;&apos;)<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;_targetadsTag(&apos;page_view&apos;, {'{'}&apos;event_params&apos;: {'{'}&apos;uid&apos;: &apos;&lt;user_id&gt;&apos;, &apos;&lt;{trans('event parameter', language)}&gt;&apos;: &apos;&lt;{trans('parameter value', language)}&gt;&apos;{'}'}{'}'});<br/>
                            &lt;/script&gt;
                        </div>
                        <Btn
                            text={trans('Copy', language)}
                            color='light-blue'
                            onClick={() => copyText(2)}
                        />
                    </div>
                    <p className="docs-counter__text">
                        <b>{trans('project id', language)}</b> - {trans('your project id in the TargetADS system', language)}
                    </p>
                    {/*<p className="docs-counter__text"><b>user</b> - {trans('the name of the object describing the user\'s parameters.', language)}</p>*/}
                    <p className="docs-counter__text">
                        <b>user_id</b> - {trans('your internal user ID. If this parameter is not passed, then the other user parameters passed may not work correctly.', language)}
                    </p>
                    {/*<p className="docs-counter__text"><b>{trans('user parameter', language)}</b> - {trans('the name of the user parameter.', language)}</p>*/}
                    <p className="docs-counter__text">
                        <b>{trans('event parameter', language)}</b> - {trans('the name of the event parameter.', language)}
                    </p>
                    <p className="docs-counter__text">
                        <b>{trans('parameter value', language)}</b> - {trans('the parameter value corresponding to the type and name of the parameter by which it is available.', language)}
                    </p>
                </div>
                <div className="docs-counter__step">
                    <div className="docs-counter__head">
                        <span>3</span>&nbsp;{trans('Transmitting target events', language)}</div>
                    <p className="docs-counter__text">{trans('In order to pass the target event, when the target action is triggered, you need to call the following method:', language)}</p>
                    <div>
                        <div className="docs-counter__copy">
                            &nbsp;&nbsp;&nbsp;&nbsp;_targetadsTag(&apos;event&apos;, {'{'}&apos;event_type&apos;: &apos;&lt;{trans('event type', language)}&gt;&apos;,<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&apos;event_category&apos;: &apos;&lt;{trans('event category', language)}&gt;&apos;,<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&apos;event_name&apos;: &apos;&lt;{trans('event name', language)}&gt;&apos;,<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&apos;event_value&apos;:
                            ‘&lt;{trans('event value', language)}&gt;, &apos;event_params&apos;: {'{'}&apos;uid&apos;: &apos;&lt;user_id&gt;&apos;, &apos;&lt;{trans('event parameter', language)}&gt;&apos;: &apos;&lt;{trans('value', language)}&gt;&apos;{'}'}{'}'});
                        </div>
                        <Btn
                            text={trans('Copy', language)}
                            color='light-blue'
                            onClick={() => copyText(3)}
                        />
                    </div>

                    <p className="docs-counter__text">{trans('The method accepts an object with the following values:', language)}</p>
                    <p className="docs-counter__text">
                        <b>{trans('event type', language)}</b> - {trans('event type values, can be any value, preferably in Latin letters', language)}
                    </p>
                    <p className="docs-counter__text">
                        <b>{trans('event category', language)}</b> - {trans('the value of the event category can be an arbitrary value, preferably in Latin letters. Required parameter.', language)}
                    </p>
                    <p className="docs-counter__text">
                        <b>{trans('event name', language)}</b> - {trans('the value of the event name can be any value, preferably in Latin letters. Required', language)}
                    </p>
                    <p className="docs-counter__text">
                        <b>{trans('event value', language)}</b> - {trans('the value of the event value, takes floating-point values. Displayed in the interface as the value of the action.', language)}
                    </p>
                    <p className="docs-counter__text">
                        <b>user_id</b> - {trans('your internal user ID. If this parameter is not passed, then the other user parameters passed may not work correctly.', language)}
                    </p>
                    <p className="docs-counter__text">{trans('A unique combination of the type, category and name of the event will identify the target action and calculate attribution models based on it.', language)}</p>
                    <p className="docs-counter__text">{trans('Up to 5 event parameters can be passed.', language)}</p>
                </div>
                <div className="docs-counter__step">
                    <div className="docs-counter__head"><span>4</span>&nbsp;{trans('E-commerce', language)}</div>
                    <p className="docs-counter__text">{trans('The e-commerce module can collect data about the addition of a specific product to the cart and collect data about the purchase and the goods in this purchase', language)}</p>
                    <div className="docs-counter__substep">
                        <div className="docs-counter__subtitle">{trans('Adding an item to the cart', language)}</div>
                        <div>
                            <p className="docs-counter__text">{trans("To call the 'added to cart' event, use the following method:", language)}</p>
                            <div className="docs-counter__copy">
                                _targetadsTag(&apos;add_to_cart&apos;, {'{'}<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&apos;id&apos;: &lt;{trans('product id', language)}&gt;,<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&apos;title&apos;: &apos;&lt;{trans('product name', language)}&gt;&apos;,<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&apos;category1&apos;: &apos;&lt;{trans('product category 1', language)}&gt;&apos;,<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&apos;category2&apos;: &apos;&lt;{trans('product category 2', language)}&gt;&apos;,<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&apos;brand&apos;: &apos;&lt;{trans('product brand', language)}&gt;&apos;,<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&apos;variant&apos;: &apos;&lt;{trans('product variant', language)}&gt;&apos;,<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&apos;price&apos;: &lt;{trans('the cost of product', language)}&gt;{'}'})
                            </div>
                            <Btn
                                text={trans('Copy', language)}
                                color='light-blue'
                                onClick={() => copyText(4)}
                            />
                        </div>
                        <p className="docs-counter__text">{trans('Either the ID or the product name must be present, all other parameters are optional.', language)}</p>
                    </div>
                    <div className="docs-counter__substep">
                        <div className="docs-counter__subtitle">{trans('Purchase', language)}</div>
                        <div>
                            <p className="docs-counter__text">{trans("To call an event, use a function with the 'purchase' event type and pass an object describing the purchase to it:", language)}</p>
                            <div className="docs-counter__copy">
                                _targetadsTag(&apos;purchase&apos;, {'{'}&apos;id&apos; : &apos;&lt;{trans('purchase id', language)}&gt;&apos;, &apos;quantity&apos;: &lt;{trans('the number of products in the basket', language)}&gt;, &apos;amount&apos;: &lt;{trans('purchase amount', language)}&gt;,<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&apos;items&apos;: [{'{'}<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&apos;id&apos;: &lt;{trans('product id', language)}&gt;,<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&apos;title&apos;: &apos;&lt;{trans('product name', language)}&gt;&apos;,<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&apos;category1&apos;: &apos;&lt;{trans('product category 1', language)}&gt;&apos;,<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&apos;category2&apos;: &apos;&lt;{trans('product category 2', language)}&gt;&apos;,<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&apos;brand&apos;: &apos;&lt;{trans('product brand', language)}&gt;&apos;,<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&apos;variant&apos;: &apos;&lt;{trans('product variant', language)}&gt;&apos;,<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&apos;quantity&apos;: &lt;{trans('amount of product', language)}&gt;,<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&apos;price&apos;: &lt;{trans('the cost of product', language)}&gt;{'}'}]{'}'})
                            </div>
                            <Btn
                                text={trans('Copy', language)}
                                color='light-blue'
                                onClick={() => copyText(5)}
                            />
                        </div>
                        <p className="docs-counter__text">
                            <b>{trans('purchase ID', language)}</b> - {trans('required parameter. Unique identifier of the sale.', language)}
                        </p>
                        <p className="docs-counter__text">
                            <b>quantity</b> - {trans('the total number of items in the basket.', language)}</p>
                        <p className="docs-counter__text">
                            <b>amount</b> - {trans('the total amount of the purchase.', language)}</p>
                        <p className="docs-counter__text">
                            <b>items</b> - {trans('information about the purchased goods. The array cannot be empty. And also, each product must have either an ID or its name.', language)}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DocsCounter;