import { createAsyncThunk } from '@reduxjs/toolkit'
import {updateToken} from "../utils";

interface campaignParams {
  campaignId: number
}

export const downloadCurrentCampaignData = createAsyncThunk(
  'breadcrumbs/currentCampaign',
  async ({campaignId}: campaignParams , { rejectWithValue, extra: API}: any) => {
    try {
      // const response = await API.get(`/api/user/${currentUserId}/project/${projectId}/media/campaign/${campaignId}`);
      const response = await API.get(`/api/campaigns/${campaignId}/`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data.message);
    }
  }
);