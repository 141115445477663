import {useEffect, useRef, useState} from "react";
import {NavLink, Outlet, useNavigate, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";

import {projectGeneral} from "../../../../redux/slices/project";
import {currentUser, currentUserLang, getIsLoading} from "../../../../redux/slices/user";
import {setBreadcrumbs} from "../../../../redux/slices/breadcrumbs";
import {setSidebar} from "../../../../redux/slices/sidebar";

import cls from './ProjectSettings.module.scss';
import {checkUnSave} from "../../../../redux/slices/warnings";
import {Loader} from "../../../../components";
import {trans} from "../../../../_locales";
import {clearInstructions} from "../../../../redux/slices/instructions";
import {getPages, getSettingsPages} from "../../../../components/SideMenu/Role";
import {DefaultObject} from "../../../../interfaces/common.d";

interface Path {
  title: string;
  path: string
}

const ProjectSettings = () => {
  const reduxCurrentProject = useAppSelector(projectGeneral);
  const user = useAppSelector(currentUser);
  const language = useAppSelector(currentUserLang);
  const userDataLoading = useAppSelector(getIsLoading);
  const warnings = useAppSelector(checkUnSave) || false;
  const dispatch = useAppDispatch()
  const navigate = useNavigate();
  const { projectId } = useParams();

  const permissions = user && user.permission ? user.permission[`${projectId}`] : undefined;
  const [roleLoading, setRoleLoading] = useState(true);
  const [settingsPath, setSettingsPath] = useState<Array<DefaultObject<any>>>([])

  const SettingsPath: Record<string, Path> = {
    'GENERAL': {
      title: trans('General', language),
      path: 'settings',
    },
    'ATTRIBUTIONS': {
      title: trans('Attributions', language),
      path: 'attributions',
    },
    'COUNTER': {
      title: trans('Counter', language),
      path: 'counter',
    },
    'USERS_MANAGEMENT': {
      title: trans('Users management', language),
      path: 'user_control',
    },
    'INTEGRATIONS': {
      title: trans('Integrations', language),
      path: 'integrations',
    },
    'API': {
      title: trans('API', language),
      path: 'api',
    },
  }

  const SettingsPathView: Record<string, Path> = {
    'GENERAL': {
      title: trans('General', language),
      path: 'settings',
    },
    'ATTRIBUTIONS': {
      title: trans('Attributions', language),
      path: 'attributions',
    },
    'COUNTER': {
      title: trans('Counter', language),
      path: 'counter',
    },
    'USERS_MANAGEMENT': {
      title: trans('Users management', language),
      path: 'user_control',
    },
    'INTEGRATIONS': {
      title: trans('Integrations', language),
      path: 'integrations',
    }
  }

  useEffect(() => {
    dispatch(clearInstructions());
    dispatch(setBreadcrumbs([
        {
          href: '/projects',
          title: 'Проект',
          subtitle: reduxCurrentProject?.name
        },
        {
          href: '#',
          title: trans('Settings', language),
        },
      ]))
      dispatch(setSidebar(getPages({
          projectId: Number(projectId),
          access: permissions && permissions['can_view'],
          language,
          active: 'settings'
      })))
      setSettingsPath(getSettingsPages({
        access: permissions && permissions['can_view'],
        language
      }))
      setRoleLoading(false);
  }, [dispatch, projectId, reduxCurrentProject?.name, permissions]);

  useEffect(() => {
    const header = document.querySelector('header');
    const body = document.querySelector('body');
    const content = document.querySelector('.page-content');
    header && header.classList.remove('hide');
    content && content.classList.remove('stretch');
    body && body.classList.remove('no-scroll');
  }, []);

  const Output = ({settingsPath=[]}:{settingsPath: Array<DefaultObject<any>>}) => {
    return (
        <div className={cls.ProjectSettings}>
          {
              typeof settingsPath === 'object' && settingsPath.map((link) => (
                  <div className={cls.link_item} key={link.title}>
                    <NavLink
                        className={({isActive}) =>
                            [
                              cls.link,
                              isActive ? cls.link_selected : null,
                            ]
                                .filter(Boolean)
                                .join(" ")
                        }
                        to={`/projects/${projectId}/${link.path}`}
                    >{link.title}
                    </NavLink>
                  </div>
              ))
          }
        </div>
    )
  }

  return (
      <>
        <Output settingsPath={settingsPath}/>

      <div className={`${cls.foo} page-content__content_scroll`}>
        <Outlet />
      </div>
      {(roleLoading) &&
        <Loader inBlock={true} isNotTransparent={true}/>}
    </>
  );
};


export default ProjectSettings;