import {FC, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/hooks";

import {dashboardsFilterValues} from "../../../../../../redux/slices/dashboards";
import {getDashboardsFilterValues, getEcomCustomDashboardsFilterValues} from "../../../../../../actions/dashboards";

import Select from "../../../../../../components/ui/Select";
import Input from "../../../../../../ui/Input";
import Loader from "../../../../../../components/Loader";

import iconCart from "../../../../../../assets/icons/icon-cart.svg";
import {currentUserLang} from "../../../../../../redux/slices/user";
import {trans} from "../../../../../../_locales";
import {openPopup} from "../../../../../../redux/slices/popup";
import {PopupType} from "../../../../../../data";
import {ICustomReport} from "../../../../../../components/ReportsUpdate/interfaces.d";

interface IEcomFilterItem {
    id:number
    projectId:number
    onChange:any,
    onRemove:any,
    filterName:any
    filterValue:any
    datasetName:any
    datasetNameBase:any
    datasetValue?:any
    isCheckbox?:boolean
    isDelay?:boolean
    interval?: {interval_from:string, interval_to: string}
    trgInterval?: {interval_from:string, interval_to: string}
    categorize?: ICustomReport["grouping"],
    model: ICustomReport["attribution_model"],
    window: ICustomReport["attribution_window"],
    interaction_type: Array<ICustomReport["interaction_type"]>,
    event_dt: Array<ICustomReport["event_dt"]>,
    selectFields: Array<any>
}

const EcomFilterItem:FC<IEcomFilterItem> = ({
        id,
        projectId,
        onChange,
        onRemove,
        filterName,
        filterValue,
        datasetName,
        datasetNameBase,
        datasetValue,
        interval,
        trgInterval,
        categorize,
        model,
        window,
        interaction_type,
        event_dt,
        isCheckbox=true,
        isDelay=false
    }) => {
    const dispatch = useAppDispatch();
    const reduxFilterValues = useAppSelector(dashboardsFilterValues);
    const language = useAppSelector(currentUserLang);

    const [showLoader, setShowLoader] = useState(false);
    const [readyValue, setReadyValue] = useState(false);

    const [name, setName] = useState<string>(filterName);
    const onChangeName = (response: object) => {
        for (const value in response) {
            setName(value)
            const temp:any = {name: value, value: '', type: 'object'};
            onChange(temp, id);
            setValue('')
            setShowLoader(true);
            if (interval && interval.interval_from && interval.interval_to) {
                projectId && dispatch(getEcomCustomDashboardsFilterValues({
                    projectId: projectId,
                    name: 'custom_table_ecommerce_unique',
                    periodStart: interval.interval_from,
                    periodEnd: interval.interval_to,
                    trgPeriodStart: trgInterval ? trgInterval.interval_from : '',
                    trgPeriodEnd: trgInterval ? trgInterval.interval_to : '',
                    aggregation: [value],
                    categorize,
                    model,
                    window,
                    interaction_type: (interaction_type as any),
                    event_dt: (event_dt as any)
                })).then((r) => {
                    if (r.payload && r.payload['custom_table_ecommerce_unique']) {
                        const temp: any = {}
                        r.payload['custom_table_ecommerce_unique'].map((item: any) => {
                            if (item.length > 0)
                                temp[item[0]] = item[0];
                        })
                        setDatasetValues(temp)
                        setReadyValue(true);
                    } else {
                        setReadyValue(false);
                    }
                    setShowLoader(false);
                }).catch((e)=> {
                   setShowLoader(false);
               })
            }
        }
    }

    useEffect(()=> {
       if(typeof filterValue === 'object' && filterValue.length > 0) {
           setShowLoader(true);
           if (interval && interval.interval_from && interval.interval_to) {
               projectId && dispatch(getEcomCustomDashboardsFilterValues({
                   projectId: projectId,
                   name: 'custom_table_ecommerce_unique',
                   periodStart: interval.interval_from,
                   periodEnd: interval.interval_to,
                   trgPeriodStart: trgInterval ? trgInterval.interval_from : '',
                   trgPeriodEnd: trgInterval ? trgInterval.interval_to : '',
                   aggregation: [name],
                   categorize,
                   model,
                   window,
                   interaction_type: (interaction_type as any),
                   event_dt: (event_dt as any)
               })).then((r) => {
                   if (r.payload && r.payload['custom_table_ecommerce_unique']) {
                       const temp:any = {}
                       r.payload['custom_table_ecommerce_unique'].map((item:any)=> {
                           if(item.length > 0)
                            temp[item[0]] = item[0];
                       })
                       setDatasetValues(temp)
                       setReadyValue(true);
                   } else {
                       setReadyValue(false);
                   }
                   setShowLoader(false);
               }).catch((e)=> {
                   setShowLoader(false);
               })
           }

       }
       if(typeof filterValue === 'string' || typeof filterValue === 'number') setReadyValue(true);
    },[])
    const prepareValue = typeof filterValue === 'object' && typeof datasetValue==='object' && filterName ?
        filterValue.map((item:string) => datasetValue[filterName] && datasetValue[filterName][item] && isCheckbox ? datasetValue[filterName][item] : item).join(', ') :
        (Array.isArray(filterValue)) ? filterValue.join(', ') :
        (typeof filterValue === 'string' || typeof filterValue === 'number') ? filterValue : '';
    // if(typeof filterValue === 'object' && typeof datasetValue==='object' && filterName) {
    //     filterValue.map((item:string) => {
    //       if(datasetValue[filterName] && datasetValue[filterName][item] && isCheckbox) {
    //           return datasetValue[filterName][item]
    //       } else {
    //           return item
    //       }
    //     }).join(", ")
    // } else if(typeof filterValue === 'string' || typeof filterValue === 'number') {
    //     return filterValue;
    // } else return '';
    const [value, setValue] = useState<string>(prepareValue);

    const [datasetValues, setDatasetValues] = useState<any>({});

    const onChangeValue = (value: any) => {
        setValue(value);
        if(!isDelay) {
            onChange({value: [value]}, id, 'object');
        }
    }

    useEffect(()=> {
        if (isDelay) {
            const delay = setTimeout(() => {
                if (!isCheckbox) {
                    onChange({value: [value]}, id, 'object');
                }

            }, 1000);
            return () => clearTimeout(delay);
        }
    }, [value])

    const onChangeValueCheckbox = (response: any) => {
        const temp: Array<any> = [];
        const total: Array<any> = [];
        for (const key in response) {
            if (response[key] && response[key].checked) {
                temp.push(response[key].name);
                total.push(key);
            }
        }
        const result = temp.join(', ');
        setValue(result ? result : trans('Value', language));
        onChange({value: total}, id);
    }
    const onChangeValueOne = (response: object) => {
        for (const value in response) {
            onChangeValue(value)
        }
    }

    const remove = () => {
        onRemove(id);
    }

    return (
        <div className="filter-dashboards-item">
            <div className="filter-dashboards-item__choice">
                <Select
                    color='dark'
                    widthAuto={true}
                    selectActive={name && datasetNameBase && (datasetNameBase as any)[name] ? (datasetNameBase as any)[name] : trans('Filter', language)}
                    dataset={datasetName}
                    onChangeActive={onChangeName}
                />

            </div>
            {showLoader && <div className="filter-dashboards-item__choice">
                <div className='filter-dashboards-item__choice_loader'>
                    <Loader inBlock={true} size={'xs'}/>
                </div>
            </div>}
            {
                readyValue && name && datasetValues &&
                <div className="filter-dashboards-item__choice">
                    {/*{*/}
                    {/*    (typeof (datasetValue as any)[name] === 'string') &&*/}
                    {/*    <Input*/}
                    {/*        className='filter-dashboards-item__choice_input'*/}
                    {/*        name={'value'}*/}
                    {/*        value={value}*/}
                    {/*        color={'dark'}*/}
                    {/*        placeholder={trans('Value', language)}*/}
                    {/*        onChange={(e) => onChangeValue(e.value)}*/}
                    {/*    />*/}
                    {/*}*/}
                    {
                        isCheckbox &&
                        <Select
                            color='dark'
                            widthAuto={true}
                            checkbox={true}
                            selectActive={value ? value : trans('Value', language)}
                            dataset={datasetValues ? datasetValues : {}}
                            onChangeActive={onChangeValueCheckbox}
                            listActive={filterValue}
                            search={true}
                            className={'custom-input--search filter-dashboards-item__content_long'}
                            // position={'right'}
                            maxChecked={10}
                        />
                    }
                    {
                        !isCheckbox &&
                        <Select
                            color='dark'
                            widthAuto={true}
                            selectActive={datasetValues[value] ? datasetValues[value] : trans('Value', language)}
                            dataset={datasetValues ? datasetValues : {}}
                            onChangeActive={onChangeValueOne}
                            listActive={filterValue}
                            search={true}
                            className={'custom-input--search'}
                            // position={'right'}
                        />
                    }
                </div>
            }
            <button onClick={remove} className='filter-dashboards-item__remove'>
                <img src={iconCart} alt=""/>
            </button>
        </div>
    )
}

export default EcomFilterItem;