import { FC } from 'react'
import cx from 'classnames'
import { Btn } from '../../ui'
import { IForm } from './Form.d'
import './Form.scss'
import {useNavigate} from "react-router-dom";

const Form: FC<IForm> = ({
  className,
  children,
  resetText,
  submitText,
  isSubmitDisabled = false,
  onSubmit,
  onReset,
  hasScrollBlock = false,
  isRecover
}) => {
  const navigate = useNavigate();
  const resetBtn = resetText && onReset;
  const scrollBlockProps = hasScrollBlock ? { 'data-name': 'scroll-block' } : {};
  const onClickRecover = () => {
    navigate('/recover/');
    return;
  }
  return (
    <div className={cx('form', {[className as string]: className})}>
      <form action="#" onSubmit={onSubmit} >
        <div className="form__wrapper" {...scrollBlockProps}>
          {children}
        </div>
        {
          (resetBtn || submitText) &&
          <div className={cx(
            'form__buttons',
            {['form__buttons--one-col']: !resetBtn})
          }>
            {
              resetBtn &&
              <Btn
                type='reset'
                text={resetText}
                size='lg'
                widthAuto={false}
                onClick={onReset}
              />
            }
            {
              submitText &&
              <Btn
                type='submit'
                text={submitText}
                color='blue'
                widthAuto={false}
                size='lg'
                disabled={isSubmitDisabled}
              />
            }
          </div>
        }
      </form>
      {isRecover ?
          <button onClick={onClickRecover} className='form__recover'>Забыли пароль?</button>
      : ''}
    </div>
  );
};

export default Form;