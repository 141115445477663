export const en = {
    //Header
    'Go to the main page': 'Go to the main page',
    //Breadcrumbs
    'Create project': 'Create project',
    'All projects': 'All projects',
    'Campaigns': 'Campaigns',

    //Side menu
    home: 'Home',
    'Media campaigns': 'Media campaigns',
    Placements: 'Placements',
    Reports: 'Reports',
    'Data upload': 'Data upload',
    Events: 'Events',
    Settings: 'Settings',
    Profile: 'Profile',
    Projects: 'Projects',

    //Profile
    'General information':'General information',
    'Name': 'Name',
    'Change name': 'Change name',

    'Change E-mail': 'Change E-mail',
    'New E-mail': 'New E-mail',

    'Timezone': 'Timezone',
    'Change timezone': 'Change timezone',

    'Language': 'Language',
    'Change language': 'Change language',

    'Security': 'Security',
    'Password': 'Password',
    'Change password': 'Change password',
    'Current password': 'Current password',
    'Your password': 'Your password',
    'New password': 'New password',
    'Repeat password': 'Repeat password',
    'Forgot password?': 'Forgot password?',

    'Last activity': 'Last activity',
    'Terminate all other session': 'Terminate all other session',

    'Timezone has been updated': 'Timezone has been updated',
    'Language has been updated': 'Language has been updated',
    //Buttons
    cancel: 'Cancel',
    Clear: 'Clear',
    Reset: 'Reset',
    Apply: 'Apply',
    Save: 'Save',
    Invite: 'Invite',
    Add: 'Add',
    Delete: 'Delete',
    Leave: 'Leave',
    Exit: 'Exit',
    Copy: 'Copy',
    Copied: 'Copied',
    Create: 'Create',
    Update: 'Update',
    'Next step': 'Next step',
    Next: 'Next',
    Check: 'Check',
    Skip: 'Skip',
    Done: 'Done',
    Change: 'Change',
    Ok: 'Ok',
    'Save changes': 'Save changes',
    'Upload your file': 'Upload your file',
    Retry: 'Retry',
    'Upload Excel': 'Upload Excel',

    //Errors
    'Empty password': 'Empty password',
    'Enter the password from 6 to 50 characters': 'Enter the password from 6 to 50 characters',
    'Enter the correct password': 'Enter the correct password',
    'Enter the correct E-mail': 'Enter the correct E-mail',
    'Enter the correct url': 'Enter the correct url',
    'Enter the correct date': 'Enter the correct date',
    'The field is required': 'The field is required',

    //Naming
    'Naming': 'Name',

    //Source modal
    'Domain': 'Domain',
    'Parameters per click': 'Parameters per click',
    'Parameters per impression': 'Parameters per impression',
    'Dynamic parameters, in the format param={value}&param2={value2}, which will be inserted into all click links from this source': 'Dynamic parameters, in the format param={value}&param2={value2}, which will be inserted into all click links from this source',
    'Dynamic parameters, in the format param={value}&param2={value2}, which will be substituted in all pixels for impression at this source': 'Dynamic parameters, in the format param={value}&param2={value2}, which will be substituted in all pixels for impression at this source',

    //Project
    'Website address': 'Website address',
    'Product Name': 'Product Name',

    //Campaign
    'Campaign name': 'Campaign name',
    Source: 'Source',
    'Your advertising source, for example, Yandex, myTarget or Hybrid': 'Your advertising source, for example, Yandex, myTarget or Hybrid',
    'Select the source': 'Select the source',
    'Campaign type': 'Campaign type',
    'Select the type': 'Select the type',
    'Start date': 'Start date',
    'It is necessary exclusively for reports, does not affect the functionality': 'It is necessary exclusively for reports, does not affect the functionality',
    'End date': 'End date',
    'Purchase type': 'Purchase type',
    'Purchase tooltip': 'CPA is payment for actions. \n' + 'CPC - payment for clicks. \n' + 'CPV - pay per views. \n' + 'FIX - fixed payment.',
    Description: 'Description',
    'Planned indicators': 'Planned indicators',
    Impressions: 'Impressions',
    Clicks: 'Clicks',
    Budget: 'Budget',
    // 'PV lead plan': 'PV lead plan',
    'Number of target events': 'Number of target events',

    //Placement
    'Placement name': 'Placement name',
    'Onboarding url': 'Onboarding url',
    Format: 'Format',
    'Select the format': 'Select the format',
    Banner: 'Banner',
    Video: 'Video',
    Other: 'Other',
    Geo: 'Geo',
    Targeting: 'Targeting',
    Creative: 'Creative',
    'Creative size': 'Creative size',
    'Video duration': 'Video duration in seconds',
    'Video orientation': 'Video orientation',
    'Select the orientation of the video': 'Select the orientation of the video',

    //Modals titles
    'New project': 'New project',
    'New campaign': 'New campaign',
    'New placement': 'New placement',
    'New source': 'New source',
    'Edit campaign': 'Edit campaign',
    'Edit name': 'Edit name',
    'Edit placement': 'Edit placement',
    'Add users': 'Add users',
    'New event': 'New event',
    'Edit user': 'Edit user',
    'Add integration': 'Add integration',
    'Update integration': 'Update integration',
    Upload: 'Upload',
    'Connecting ADRIVER': 'Connecting ADRIVER',
    'Confirmation of deletion': 'Confirmation of deletion',
    Edit: 'Edit',
    //AddEmailModal
    'View': 'View',
    'Admin': 'Admin',
    'Specialist': 'Specialist',
    'Creator': 'Creator',

    'Include': 'Include',
    'Exclude': 'Exclude',

    "Enter the user's email": "Enter the user's email",
    'The user is not registered': 'The user is not registered',
    'The invitation will be sent by email': 'The invitation will be sent by email',
    Role: 'Role',
    'Traffic limit': 'Traffic limit',
    'No limit': 'No limit',
    'Access expiration date': 'Access expiration date',
    Unlimited: 'Unlimited',
    'Adding a user to the project': 'Adding a user to the project',

    //LimitTrafficModal
    'Channel': 'Channel',
    'Campaign': 'Campaign',

    //AddIntegrationModal
    'Folder name': 'Folder name',
    'User': 'User',

    //ConfirmExitModal
    'You have unsaved changes.': 'You have unsaved changes.',
    'Leave the page?': 'Leave the page?',
    'Revoke access': 'Revoke access',

    //DeleteAccessModal
    "Confirmation of the user's removal from the project": "Confirmation of the user's removal from the project",

    //DocsCounter
    'Counter documentation': 'Counter documentation',
    'Documentation on connecting the tracking counter on the website': 'Documentation on connecting the tracking counter on the website',
    'Basic counter initialization': 'Basic counter initialization',
    'Paste the following code as close to the top of the page as possible, preferably placed in the tag': 'Paste the following code as close to the top of the page as possible, preferably placed in the tag',
    'project id': 'project id',
    'your project id in the TargetADS system': 'your project id in the TargetADS system',
    'With this code, you send a page view event to the server. After installing the code on the site, you will already be able to collect basic metrics from the site, such as page views, unique users and the number of sessions by traffic sources.': 'With this code, you send a page view event to the server. After installing the code on the site, you will already be able to collect basic metrics from the site, such as page views, unique users and the number of sessions by traffic sources.',
    'Transmitting custom parameters': 'Transmitting custom parameters',
    'The sending function accepts two parameters: the event type (string) and the object describing this event. For the page_view event, you can pass user parameters (user) and event parameters (event_params) to the object describing the event.': 'The sending function accepts two parameters: the event type (string) and the object describing this event. For the page_view event, you can pass user parameters (user) and event parameters (event_params) to the object describing the event.',
    "Entity parameters are also objects describing this entity, where the key is the name of the parameter, and the value available by it is the value of the parameter. The exception is the 'uid' key of the user object, the userId value is passed to it": "Entity parameters are also objects describing this entity, where the key is the name of the parameter, and the value available by it is the value of the parameter. The exception is the 'uid' key of the user object, the userId value is passed to it",
    'For example, in user parameters you can pass the user segment, user loyalty, the date of the previous order and much more, it all depends on the type of your business and the parameters by which you want to group and filter users. Up to 5 parameters of each type can be transmitted as much as possible.': 'For example, in user parameters you can pass the user segment, user loyalty, the date of the previous order and much more, it all depends on the type of your business and the parameters by which you want to group and filter users. Up to 5 parameters of each type can be transmitted as much as possible.',
    'user parameter': 'user parameter',
    'parameter value': 'parameter value',
    'event parameter': 'event parameter',
    "the name of the object describing the user's parameters.": "the name of the object describing the user's parameters.",
    'your internal user ID. If this parameter is not passed, then the other user parameters passed may not work correctly.': 'your internal user ID. If this parameter is not passed, then the other user parameters passed may not work correctly.',
    'the name of the event parameter.': 'the name of the event parameter.',
    'the name of the user parameter.': 'the name of the user parameter.',
    'the parameter value corresponding to the type and name of the parameter by which it is available.': 'the parameter value corresponding to the type and name of the parameter by which it is available.',
    'Transmitting target events': 'Transmitting target events',
    'In order to pass the target event, when the target action is triggered, you need to call the following method:': 'In order to pass the target event, when the target action is triggered, you need to call the following method:',
    'event type': 'event type',
    'event category': 'event category',
    'event name': 'event name',
    'event value': 'event value',
    value: 'value',
    'The method accepts an object with the following values:': 'The method accepts an object with the following values:',
    'event type values, can be any value, preferably in Latin letters': 'event type values, can be any value, preferably in Latin letters',
    'the value of the event category can be an arbitrary value, preferably in Latin letters. Required parameter.': 'the value of the event category can be an arbitrary value, preferably in Latin letters. Required parameter.',
    'the value of the event name can be any value, preferably in Latin letters. Required': 'the value of the event name can be any value, preferably in Latin letters. Required',
    'the value of the event value, takes floating-point values. Displayed in the interface as the value of the action.': 'the value of the event value, takes floating-point values. Displayed in the interface as the value of the action.',
    'A unique combination of the type, category and name of the event will identify the target action and calculate attribution models based on it.': 'A unique combination of the type, category and name of the event will identify the target action and calculate attribution models based on it.',
    'Up to 5 event parameters can be passed.': 'Up to 5 event parameters can be passed.',
    'E-commerce': 'E-commerce',
    'The e-commerce module can collect data about the addition of a specific product to the cart and collect data about the purchase and thegoods in this purchase': 'The e-commerce module can collect data about the addition of a specific product to the cart and collect data about the purchase and the goods in this purchase',
    'Adding an item to the cart': 'Adding an item to the cart',
    "To call the 'added to cart' event, use the following method:": "To call the 'added to cart' event, use the following method:",
    'product id': 'product id',
    'product name': 'product name',
    'product category 1': 'product category 1',
    'product category 2': 'product category 2',
    'product brand': 'product brand',
    'product variant': 'product variant',
    'the cost of product': 'the cost of product',
    'Either the ID or the product name must be present, all other parameters are optional.': 'Either the ID or the product name must be present, all other parameters are optional.',
    Purchase: 'Purchase',
    "To call an event, use a function with the 'purchase' event type and pass an object describing the purchase to it:": "To call an event, use a function with the 'purchase' event type and pass an object describing the purchase to it:",
    'purchase id': 'purchase id',
    'the number of products in the basket': 'the number of products in the basket',
    'purchase amount': 'purchase amount',
    'amount of product': 'amount of product',
    'purchase ID': 'purchase ID',
    'required parameter. Unique identifier of the sale.': 'required parameter. Unique identifier of the sale.',
    'the total number of items in the basket.': 'the total number of items in the basket.',
    'the total amount of the purchase.': 'the total amount of the purchase.',
    'information about the purchased goods. The array cannot be empty. And also, each product must have either an ID or its name.': 'information about the purchased goods. The array cannot be empty. And also, each product must have either an ID or its name.',

    //MessageModal
    'Loaded': 'Loaded',
    'campaigns': 'campaigns',
    'placements': 'placements',
    'sources': 'sources',
    'Updated': 'Updated',
    'Errors': 'Errors',

    //FilterDashboards
    'Filter': 'Filter',
    'Value': 'Value',
    'Condition': 'Condition',

    //DropdownList
    'Add source': 'Add source',

    //Projects
    'the first project': 'the first project',

    //SearchForm
    'Search': 'Search',

    //Filter
    'Actives': 'Actives',
    'Hold': 'Hold',
    'Archived': 'Archived',
    'Date range': 'Date range',
    'Undefined': 'Undefined',

    //HeaderTable
    'Choice': 'Choice',
    'Cost': 'Cost',
    'Target events': 'Target events',
    'Cost of target events': 'Cost of target events',
    'Creation date': 'Creation date',
    'Status': 'Status',
    'Reach': 'Reach',

    //TableRow
    'Confirm the deletion of the project': 'Confirm the deletion of the project',
    'Activate': 'Activate',
    'Archive': 'Archive',
    'Confirm the deletion of the campaign': 'Confirm the deletion of the campaign',
    'Confirm the deletion of the placement': 'Confirm the deletion of the placement',

    //Create project
    'Create new project': 'Create',
    'Counter code': 'Counter code',
    'Configuring Integrations': 'Configuring Integrations',
    'Configuring Attribution': 'Configuring Attribution',
    'Complete acquaintance': 'Complete acquaintance',
    'You start the process of creating a project in the TargetADS system, it will take 4 simple steps. To begin with, fill in the basic parameters of the project': 'You start the process of creating a project in the TargetADS system, it will take 4 simple steps. To begin with, fill in the basic parameters of the project',
    'Project name': 'Project name',
    "The name of the project will be displayed in the menu, in the reports sent and in the settings. If you have only one project, you don't have to think about the name for a long time": "The name of the project will be displayed in the menu, in the reports sent and in the settings. If you have only one project, you don't have to think about the name for a long time",
    'The site address is needed to receive data only from your site, so that no one could distort your data. The address can be entered in the format https://site.com': 'The site address is needed to receive data only from your site, so that no one could distort your data. The address can be entered in the format https://site.com',
    'Project currency': 'Project currency',
    'The currency is needed for the visual display of monetary indicators in reports, we do not convert currencies': 'The currency is needed for the visual display of monetary indicators in reports, we do not convert currencies',
    //step2
    'Installing a counter': 'Installing a counter',
    'Copy the pixel code': 'Copy the pixel code',
    'Set the pixel on the site': 'Set the pixel on the site',
    'Add the counter code to the HTML code of all the pages of the site. The code must be placed within the <head> </head> or <body> </body> tags as close to the top of the page as possible: this way it will load earlier and will be able to send browsing data to TargetAds, even if the visitor closes the page almost immediately.': 'Add the counter code to the HTML code of all the pages of the site. The code must be placed within the <head> </head> or <body> </body> tags as close to the top of the page as possible: this way it will load earlier and will be able to send browsing data to TargetAds, even if the visitor closes the page almost immediately.',
    'Check the installation of the counter': 'Check the installation of the counter',
    'Checking the counter': 'Checking the counter',
    'The result of the check will appear here': 'The result of the check will appear here',
    'The counter is successfully connected!': 'The counter is successfully connected!',
    'The counter is not installed': 'The counter is not installed',
    //step3
    'Connected integrations': 'Connected integrations',
    "You can add data from third-party systems to your project. For example, CRM systems, for importing sales data or Advertising systems, for importing expense data. You can connect the integration after, in the project settings. If you already know what you can connect, then feel free to click on the 'Add' button": "You can add data from third-party systems to your project. For example, CRM systems, for importing sales data or Advertising systems, for importing expense data. You can connect the integration after, in the project settings. If you already know what you can connect, then feel free to click on the 'Add' button",
    'Search integration': 'Search integration',
    'Enter the name of the service...': 'Enter the name of the service...',
    'Nothing found': 'Nothing found',
    //TODO: modal
    //step4
    'Default attribution window': 'Default attribution window',
    'Default attribution model': 'Default attribution model',
    'Target event KPI': 'Target event KPI',

    //Currency
    'USD': 'Dollar',
    'EUR': 'Euro',
    'RUB': 'Ruble',

    //model
    'fl': 'Linear (all touches)',
    'fli': 'Last touch (all touches)',
    'ffi': 'First touch (all touches)',
    'ftd': 'Time Decay (all touches)',
    'ftdm': 'Time Decay MAX',
    'ml': 'Linear (media touches)',
    'mli': 'Last Touch (media touches)',
    'mfi': 'First touch (media touch)',
    'mtd': 'TimeDecay (media touches)',

    //Campaigns
    'Campaignies': 'Campaignies',
    'not found': 'not found',
    'Download selected': 'Download selected',
    'Download active': 'Download active',
    'Download all': 'Download all',

    //Placements
    'Placementies': 'Placementies',

    //Pagination
    'Lines per page': 'Lines per page',

    //Setting
    'General': 'General',
    'Attributions': 'Attributions',
    'Counter': 'Counter',
    'Users management': 'Users management',
    'Integrations': 'Integrations',

    //Status
    'Active': 'Active',
    'Inactive': 'Inactive',
    'Archival': 'Archival',
    'Deleted': 'Deleted',
    //Timezones
    "UTC": "Western European Time: United Kingdom (UTC)",
    "UTC1": "Central European time: Austria, Germany (UTC+1)",
    "UTC2": "Eastern European time: Ukraine, Greece, Cyprus (UTC+2)",
    "UTC3": "East African time: Russia, Turkey (UTC+3)",
    "UTC4": "Russia, Georgia, Armenia (UTC+4)",
    "UTC5": "Russia, Kazakhstan, Uzbekistan (UTC+5)",
    "UTC6": "Russia, Kazakhstan, Kyrgyzstan, Bhutan, Bangladesh (UTC+6)",
    "UTC7": "Russia, Mongolia, Thailand, Laos, Cambodia (UTC+7)",
    "UTC8": "Russia, Mongolia, China (UTC+8)",
    "UTC9": "Russia, Republic of Korea, Japan(UTC+9)",
    "UTC10": "Russia, Australia (UTC+10)",
    "UTC11": "Russia, Solomon Islands, New Caledonia (UTC+11)",
    "UTC12": "Russia, Fiji, New Zealand (UTC+12)",
    "UTC-1": "Portugal (Azores), Cape Verde (UTC-1)",
    "UTC-2": "Mid-Atlantic Time (UTC-2)",
    "UTC-3": "South American Eastern Time: Denmark(Greenland), Brazil, Argentina (UTC-3)",
    "UTC-4": "Atlantic Time: Canada, Puerto Rico, Venezuela, Brazil (UTC-4)",
    "UTC-5": "North American Eastern Time: Canada, USA, Mexico, Cuba (UTC-5)",
    "UTC-6": "Central American Time: Canada, USA, Mexico, Guatemala (UTC-6)",
    "UTC-7": "Mountain Time: Canada, USA, Mexico \t(UTC-7)",
    "UTC-8": "North American Pacific Time: Canada, USA, Mexico (UTC-8)",
    "UTC-9": "USA (Alaska) (UTC-9)",
    "UTC-10": "USA (Hawaii, Aleutian Islands) (UTC-10)",
    "UTC-11": "American Samoa, Niue (UTC-11)",
    "UTC-12": "Date change line (UTC-12)",

    //PlainSettings
    'Input new name': 'Input new name',
    'Input new email': 'Input new email',
    'Project status': 'Project status',
    'Accept data only from this domain and subdomain': 'Accept data only from this domain and subdomain',
    'Mail for administrative documents': 'Mail for administrative documents',
    'Currency': 'Currency',
    'Project timezone': 'Project timezone',

    //Attributions
    'days': 'days',
    'This month': 'This month',
    'Previous month': 'Previous month',
    'This week': 'This week',
    'Previous week': 'Previous week',
    'Attribution': 'Attribution',
    'Select the attribution window': 'Select the attribution window',
    'Select the attribution model': 'Select the attribution model',
    'Select the attribution period': 'Select the attribution period',
    'Default relative time interval': 'Default relative time interval',
    'Plan fact': 'Plan fact',
    'Show progress in reports': 'Show progress in reports',

    //UserControl
    'Access expires': 'Access expires',
    'Last visit': 'Last visit',

    //Integrations
    'All': 'All',
    'advertising_platforms': 'Advertising platforms',
    'Рекламные площадки': 'Advertising platforms',

    //Events list
    'Event targets': 'Event targets',
    'not founds': 'not founds',
    'Event target name': 'Event target name',
    'Target cost': 'Target cost',
    'Event type': 'Event type',
    'Event category': 'Event category',
    'Event name': 'Event name',
    'Event value': 'Event value',
    'Number of events': 'Number of events',
    //EventsCreate
    'Event editing': 'Event editing',
    'Event creation': 'Event creation',
    "The name will be displayed only in the interface. We advise you to give simple names, for example, 'Application for consultation'": "The name will be displayed only in the interface. We advise you to give simple names, for example, 'Application for consultation'",
    'Add the target cost of your event (KPI) and in some reports we will show you ineffective campaigns.': 'Add the target cost of your event (KPI) and in some reports we will show you ineffective campaigns.',
    'Calculate attribution models': 'Calculate attribution models',
    'Whether to calculate attribution models for this event.': 'Whether to calculate attribution models for this event.',
    'Input event type': 'Input event type',
    'Input event category': 'Input event category',
    'Input event name': 'Input event name',
    'Input event value': 'Input event value',
    'Update event': 'Update event',
    'Create event': 'Create event',
    'Add the pixel code to the HTML code in all the places where the event is triggered when interacting with.': 'Add the pixel code to the HTML code in all the places where the event is triggered when interacting with.',
    'All events': 'All events',

    //Upload
    'Data loading is not available in view mode': 'Data loading is not available in view mode',
    'Media campaign expenses': 'Media campaign expenses',
    'Campaign performance expenses': 'Campaign performance expenses',
    'Planned values for media campaigns': 'Planned values for media campaigns',
    'Planned values for performance campaigns': 'Planned values for performance campaigns',
    'Upload data into a project': 'Upload data into a project',
    //MediaCampaignExpenxes
    'Upload expenses for media campaigns': 'Upload expenses for media campaigns',
    'If you import expenses from advertising systems that are in the list of integrations, we recommend setting up automatic import. For everything else there is a CSV :)': 'If you import expenses from advertising systems that are in the list of integrations, we recommend setting up automatic import. For everything else there is a CSV :)',
    'The file must contain the following fields': 'The file must contain the following fields',
    'Date (we upload expenses by day)': 'Date (we upload expenses by day)',
    'Campaign name (must match the name of the created or imported campaigns)': 'Campaign name (must match the name of the created or imported campaigns)',
    'The name of the creative (must match the name of the created or imported creatives)': 'The name of the creative (must match the name of the created or imported creatives)',
    'Cost with VAT': 'Cost with VAT',
    'Upload campaign performance expenses': 'Upload campaign performance expenses',
    'Optional fields': 'Optional fields',
    'This is a type of expenses for utm tags not from advertising systems, for which the expense is not automatically transferred, for example, CPA, paid mailings, in general, all paid placements that users can click on, except for media placements, they have their own section': 'This is a type of expenses for utm tags not from advertising systems, for which the expense is not automatically transferred, for example, CPA, paid mailings, in general, all paid placements that users can click on, except for media placements, they have their own section',
    'Upload planned values for media campaigns': 'Upload planned values for media campaigns',
    "After downloading the planned indicators, the dashboard plan/fact and the indicators '% of plan completion' and 'Run Rate' will be available to you. It is possible to upload retrospectively, the data is automatically pulled up for past periods": "After downloading the planned indicators, the dashboard plan/fact and the indicators '% of plan completion' and 'Run Rate' will be available to you. It is possible to upload retrospectively, the data is automatically pulled up for past periods",
    'Month (in the format YYYY-MM)': 'Month (in the format YYYY-MM)',
    'The plan for cost': 'The plan for cost',
    'The plan for impressions': 'The plan for impressions',
    'The plan for reach': 'The plan for reach',
    'The plan for clicks': 'The plan for clicks',
    'Planned CPM': 'Planned CPM',
    'Planned CPUU': 'Planned CPUU',
    'Planned CPC': 'Planned CPC',
    'Planned CPA': 'Planned CPA',
    'The plan for event target': 'The plan for event target',
    'Upload planned values for performance campaigns': 'Upload planned values for performance campaigns',

    //UploadContent
    'Download an example': 'Download an example',
    'Upload daily expense': 'Upload daily expense',
    'Upload monthly expense': 'Upload monthly expense',
    'Or drag it to this area': 'Or drag it to this area',

    //Dashboards
    'Project reporting': 'Project reporting',
    'Effectiveness of media campaigns': 'Effectiveness of media campaigns',
    'E-Commerce Reports': 'E-Commerce Reports',
    'User behavior analysis': 'User behavior analysis',
    'Comparison of attribution models': 'Comparison of attribution models',
    'Evaluation of performance channels': 'Evaluation of performance channels',
    'Media plan fact': 'Media plan fact',
    'Soon': 'Soon',

    //upload.ts
    'Upload timeout limit exceeded': 'Upload timeout limit exceeded',
    'Error': 'Error',
    'Great': 'Great',

    //Project
    'Select the period': 'Select the period',
    'Day': 'Day',
    'Week': 'Week',
    'Month': 'Month',
    'Quarter': 'Quarter',
    'Year': 'Year',
    'Attribution window': 'Attribution window',
    'Scale': 'Scale',

    //CampaignEfficiency
    'All event targets': 'All event targets',

    //Names charts, tables
    'main_index_uniq_user': 'uniq user',
    'main_index_cr_user_to_lead': 'cr user to lead',
    'main_index_lead': 'lead',
    'main_index_cost': 'cost',
    'main_index_cpa': 'cpa',
    'main_index_media_reach': 'reach',
    'main_chart_lead_cpa': 'Dynamics of target actions and cost',
    'main_chart_cost': 'Flow dynamics',
    'main_chart_uniq_user': 'Graph of unique users',
    'main_chart_cr_user_to_lead': 'Conversion',
    'main_chart_lead': 'Dynamics of target actions',
    'main_chart_distr_lead_source': 'Effectiveness of advertising sources',
    'main_table_full_stat': 'Performance table',
    'main_table_model_attr_compare': 'Performance comparison by different attribution models',
    'main_table_lead_ptd': 'Comparison of dynamics of target actions, for relative past periods',
    'media_index_cost': 'cost',
    'media_index_impression': 'impression',
    'media_index_reach': 'reach',
    'media_index_frequency': 'frequency',
    'media_index_click': 'click',
    'media_index_lead': 'lead',
    'media_index_consumed_lead': 'consumed lead',
    'media_index_cpm': 'cpm',
    'media_index_cpr': 'cpr',
    'media_index_ctr': 'ctr',
    'media_index_cpc': 'cpc',
    'media_index_cpa': 'cpa',
    'media_chart_lead_cpa': 'Dynamics of the target action cost',
    'media_chart_impression': 'View dynamics',
    'media_chart_cost': 'Schedule of expenses for media campaigns',
    'media_chart_cr_impression_click': 'CTR dynamics',
    'media_chart_click': 'Graph of clicks on display campaigns',
    'media_table_adv_cost_plan_fact': 'Plan-fact table',
    'media_table_full_stat': 'Media economy',
    'media_chart_adv_cohort_efficiency': 'Cohort effectiveness of media campaigns',
    'media_table_reach_crossing': 'Cross coverage intersection',
    'media_chart_distr_impression_user': 'Distribution of the number of impressions per user',
    'media_chart_flight_duration': 'Duration of the display ad effect',
    'media_table_domain': 'Media domains',
    'media_table_adv_impact_to_product': 'The impact of campaigns on products',
    'media_table_not_see_adv': 'The impact of campaigns on products',
    'media_table_video_action': 'Video campaign screenings',
    'ecom_index_income': 'Amount of purchases',
    'ecom_index_purchase': 'Number of purchases',
    'ecom_index_avg_check': 'Average check',
    'ecom_index_uniq_user': 'Unique users',
    'ecom_index_cr_user_to_purchase': 'Conversion to purchase',
    'ecom_index_model_drr': 'drr',
    'ecom_chart_purchase_avg_check': 'Dynamics of the average receipt and number of purchases',
    'ecom_chart_uniq_user': 'Dynamics of unique users on the site',
    'ecom_chart_cr_user_to_purchase': 'Purchase conversion dynamics',
    'ecom_chart_income': 'Dynamics of the amount of purchases',
    'ecom_chart_distr_purchase_weekday': 'Sales schedule by day of the week',
    'ecom_chart_distr_item_purchase_amount': 'Chart of popular products',
    'ecom_table_item_purchase_popular': 'Popular products',
    'ecom_table_page_popular': 'ecom_table_page_popular',
    'ecom_table_model_adsource_purchase': 'Effectiveness of advertising sources',
    'ecom_table_model_adsource_item': 'ecom_table_model_adsource_item',
    'ecom_table_model_drr_cost_income': 'ecom_table_model_drr_cost_income',
    'ecom_table_model_campaign_purchase': 'ecom_table_model_campaign_purchase',
    'ecom_table_model_campaign_item': 'ecom_table_model_campaign_item',
    'ecom_table_model_campaign_roi': 'Effectiveness of invested funds by campaigns',
    'ecom_table_adv_impact_to_item': 'ecom_table_adv_impact_to_item',
    'behavior_index_uniq_user': 'uniq_user',
    'behavior_index_cr_returning_user': 'cr_returning_user',
    'behavior_index_returning_user': 'returning_user_amt',
    'behavior_index_lead': 'lead',
    'behavior_index_avg_time': 'avg_time_spent',
    'behavior_index_avg_page_amt': 'avg_page_amt',
    'behavior_table_ad_source': 'behavior_table_ad_source',
    'behavior_chart_distr_device': 'behavior_chart_distr_device',
    'behavior_chart_os_distr': 'behavior_chart_os_distr',
    'behavior_chart_distr_browser': 'behavior_chart_distr_browser',
    'behavior_table_chain_popularity': 'behavior_table_chain_popularity',
    'behavior_chart_distr_time_between': 'behavior_chart_distr_time_between',
    'behavior_chart_distr_chain_number': 'behavior_chart_distr_chain_number',
    'getintent_fraud_report': 'Fraud report',
    'getintent_fraud_bu_event_id': 'Fraud report by event id',

    //Charts
    'missing': 'missing',

    //TablePagination
    'Select aggregation': 'Select aggregation',
    'Ascending': 'Ascending',
    'Descending': 'Descending',
    'Default': 'Default',

    //TimeFilter
    'Last N days': 'Last N days',
    'Select a range': 'Select a range',
    //Select
    'Last': 'Last',

    //Conditions
    'More': 'More',
    'Less': 'Less',
    'Equal': 'Equal',

    //Create report
    'New report': 'New report',
    'Create a report': 'Create a report',
    'To create a report, fill in the fields below': 'To create a report, fill in the fields below',
    'Report type': 'Report type',
    'Report name': 'Report name',
    'Input name': 'Input name',
    'Group': 'Group',
    'Select the group': 'Select the group',
    'Select Target Events': 'Select Target Events',
    'Fields': 'Fields',
    'Metrics': 'Metrics',
    'Target events settings': 'Target events settings',
    'Send settings': 'Send settings',
    'Add field': 'Add field',
    'Add metric': 'Add metric',
    'Frequency': 'Frequency',
    'Select the frequency': 'Select the frequency',
    'Send time': 'Send time',
    'Select the time': 'Select the time',
    'Method of sending': 'Method of sending',
    'Select the method': 'Select the method',
    'Data format': 'Data format',
    'Recipients': 'Recipients',
    'Enter mail': 'Enter mail',
    'Create report': 'Create report',
    'A preview of your report will appear here': 'A preview of your report will appear here',
    'One-time': 'One-time',
    'Every day': 'Every day',
    'Every week': 'Every week',
    'Every month': 'Every month on the',
    'day_numbers': 'th',

    'Enter a number from 1 to': 'Enter a number from 1 to',
}