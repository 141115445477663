import {copyText} from "../../utils";
import './style.scss';
import cx from "classnames";
import {trans} from "../../_locales";
import React, {useEffect, useState} from "react";
import Btn from "../../ui/Btn";
import {useAppSelector} from "../../redux/hooks";
import {currentUserLang} from "../../redux/slices/user";
import {IBtnColor} from "../../ui/Btn/Btn.d";

const Code = ({
    code,
    widthAuto = false,
    className = '',
    copy = true,
    sizeText,
    isCopyBtn,
    copyBtnColor='blue'
}: {
    code: string,
    widthAuto?: boolean,
    copy?: boolean,
    className?: string,
    sizeText?: 's',
    isCopyBtn?: boolean,
    copyBtnColor?: IBtnColor
}) => {
    const language = useAppSelector(currentUserLang);
    const [checkCopy, setCheckCopy] = useState(false);
    const codeClassName = cx(
        'code',
        className,
        {
            'code--width-auto': widthAuto,
            'code--text-size-s': sizeText,
            'code--extend': isCopyBtn,
        }
    );
    const copyBtnClassName = cx(
        'code__copy-btn',
        {
            'code__copy-btn_copied': checkCopy
        }
    )

    useEffect(() => {
        if(checkCopy) {
            setTimeout(() => {
                setCheckCopy(false)
            }, 3000)
        }
    }, [checkCopy])

    return (
        <div className={codeClassName}>
            <div className='code__container'>
                <pre>
                    <code>{code}</code>
                </pre>
                {copy && <svg fill="#000000" height="800px" width="800px" xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 488.3 488.3" onClick={() => {
                                  copyText(code).then(()=> setCheckCopy(true));
                              }}>
                    <g strokeWidth="0"/>
                    <g strokeLinecap="round" strokeLinejoin="round"/>
                    <g>
                        <g>
                            <g>
                                <path
                                    d="M314.25,85.4h-227c-21.3,0-38.6,17.3-38.6,38.6v325.7c0,21.3,17.3,38.6,38.6,38.6h227c21.3,0,38.6-17.3,38.6-38.6V124 C352.75,102.7,335.45,85.4,314.25,85.4z M325.75,449.6c0,6.4-5.2,11.6-11.6,11.6h-227c-6.4,0-11.6-5.2-11.6-11.6V124 c0-6.4,5.2-11.6,11.6-11.6h227c6.4,0,11.6,5.2,11.6,11.6V449.6z"/>
                                <path
                                    d="M401.05,0h-227c-21.3,0-38.6,17.3-38.6,38.6c0,7.5,6,13.5,13.5,13.5s13.5-6,13.5-13.5c0-6.4,5.2-11.6,11.6-11.6h227 c6.4,0,11.6,5.2,11.6,11.6v325.7c0,6.4-5.2,11.6-11.6,11.6c-7.5,0-13.5,6-13.5,13.5s6,13.5,13.5,13.5c21.3,0,38.6-17.3,38.6-38.6 V38.6C439.65,17.3,422.35,0,401.05,0z"/>
                            </g>
                        </g>
                    </g>
                </svg>}
            </div>
            {
                isCopyBtn &&
                <Btn
                    text={checkCopy ? trans('Copied', language) : trans('Copy', language)}
                    className={copyBtnClassName}
                    type='submit'
                    onClick={() => {
                        copyText(code).then(()=> setCheckCopy(true));
                    }}
                    color={copyBtnColor}
                />
            }
        </div>
    )
}

export default Code;