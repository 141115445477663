import {ICurrentReports} from "../../../../redux/slices/reports";
import {trans} from "../../../../_locales";
import {DefaultObject} from "../../../../interfaces/common.d";
import {isNumber, parseCustomFilter, parseCustomFilterItem, parseFilter, parseSort} from "../../../../utils";
import {ICurrentEvents} from "../../../../redux/slices/events";

export const handleReportData = ({
    data
}: {
    data: ICurrentReports
}) => {
    if (data) {
        // try {
        //     data.type && setType(data.type);
        //     data.period && setPeriod(data.period);
        //     data.trg_period && setTrgPeriod(data.trg_period);
        //     data.interval_from && setPeriodStart(data.interval_from);
        //     data.interval_to && setPeriodEnd(data.interval_to);
        //     data.trg_interval_from && setTrgPeriodStart(data.trg_interval_from);
        //     data.trg_interval_to && setTrgPeriodEnd(data.trg_interval_to);
        //     data.grouping && setGroup(data.grouping);
        //     data.name && setReportName(data.name);
        //     data.attribution_model && setModel(data.attribution_model);
        //     data.attribution_window && setWindow(data.attribution_window);
        //     // data.event_target && setEventTarget(data.event_target.split(','));
        //     if (data.event_target) {
        //         setEventTargetActive(data.event_target.split(','));
        //         let name = data.event_target;
        //         if (name === 'all_target_events') {
        //             name = trans('All event targets', language)
        //         }
        //         setEventTarget(name);
        //     }
        //
        //     if (data.status !== 'notsend') {
        //         setIsSend(true);
        //     }
        //
        //     if (data.frequency_type) {
        //         if (data.period === 'choice') {
        //             setFrequency('one_time');
        //             setTime('');
        //             setAddSelect(false);
        //             setIsInput(false);
        //             setFrequencyDay(1);
        //             setFrequencyWeek(0);
        //         } else {
        //             data.frequency_type && setFrequency(data.frequency_type);
        //             if (data.frequency_day && data.frequency_type === 'month') {
        //                 setFrequencyDay(data.frequency_day);
        //                 setIsInput(true);
        //             }
        //             if (typeof data.frequency_weekday === 'number' && data.frequency_type === 'week') {
        //                 setFrequencyWeek(data.frequency_weekday);
        //                 setAddSelect(true);
        //             }
        //         }
        //     }
        //     data.end_send && setEndSend(data.end_send);
        //     data.start_send && setStartSend(data.start_send);
        //     data.time && setTime(data.time);
        //     data.send_method && setMethod(data.send_method);
        //     data.format && setFormat(data.format);
        //
        //     if (data.fields) {
        //         const temp: any = [];
        //         const tempCustomFields: DefaultObject<DefaultObject<string>> = {};
        //         data.fields.split(',').map((item: any, index) => {
        //             const id = index;
        //
        //             if (data.type === 'media_campaign_effectivity') {
        //                 let name = item;
        //                 if (item.startsWith('custommedia_')) {
        //                     const prefix = 'custommedia_';
        //                     const value = item.slice(prefix.length);
        //                     if (!tempCustomFields['custommedia_']) tempCustomFields['custommedia_'] = {};
        //                     tempCustomFields['custommedia_'][id] = value;
        //                     name = prefix;
        //                 }
        //                 if (item.startsWith('customevent_')) {
        //                     const prefix = 'customevent_';
        //                     const value = item.slice(prefix.length);
        //                     if (!tempCustomFields['customevent_']) tempCustomFields['customevent_'] = {};
        //                     tempCustomFields['customevent_'][id] = value;
        //                     name = prefix;
        //                 }
        //                 temp.push({id, name: name});
        //             } else {
        //                 temp.push({id, name: item});
        //             }
        //         })
        //         setCustomActiveFields(tempCustomFields);
        //         setSelectFields(temp);
        //     }
        //     if (typeof data.filter_fields === 'string') {
        //         const temp = parseFilter(data.filter_fields);
        //         const result = temp.map((item) => {
        //             const tempItem = JSON.parse(JSON.stringify(item));
        //             if (tempItem.name.startsWith('eventitem_')) {
        //                 const prefix = 'eventitem_';
        //                 const event_id = tempItem.name.slice(prefix.length);
        //                 if (isNumber(event_id)) {
        //                     reduxEvents.map((event: any) => {
        //                         if (event.id === +event_id) {
        //                             tempItem.name = `eventitem_${event.event_target_name}`;
        //                             return;
        //                         }
        //                     })
        //                 }
        //             }
        //             return tempItem;
        //         })
        //         NewFilters(result);
        //     }
        //     if (typeof data.filter_ecom === 'string') {
        //         NewEcomFilters(parseCustomFilter(data.filter_ecom));
        //     }
        //     if (typeof data.filter === 'string') {
        //         NewExtendFilters(parseFilter(data.filter));
        //     }
        //     if (typeof data.filter_trg === 'string') {
        //         NewCustomFilters(parseCustomFilterItem(data.filter_trg));
        //     }
        //     if (typeof data.sort === 'string') {
        //         const temp = parseSort(data.sort);
        //         const result = temp.map((item) => {
        //             const tempItem = JSON.parse(JSON.stringify(item));
        //             if (tempItem.name.startsWith('eventitem_')) {
        //                 const prefix = 'eventitem_';
        //                 const event_id = tempItem.name.slice(prefix.length);
        //                 if (isNumber(event_id)) {
        //                     reduxEvents.map((event: any) => {
        //                         if (event.id === +event_id) {
        //                             tempItem.name = `eventitem_${event.event_target_name}`;
        //                             return;
        //                         }
        //                     })
        //                 }
        //             }
        //             return tempItem;
        //         })
        //         NewFiltersSort(result);
        //     }
        //     if (typeof data.interaction_type === 'string') {
        //         const temp: any = data.interaction_type;
        //         const list = temp.split('&')
        //         setInteractionType(list);
        //
        //         const titles: any = [];
        //         list.map((item: string) => {
        //             if ((interactionTypes as any)[item]) {
        //                 titles.push((interactionTypes as any)[item])
        //             }
        //         })
        //         setInteractionTypeTitle(titles.join(', '));
        //     }
        //     if (data.metrics) {
        //         const temp: any = [];
        //         const tempCustomMetric: DefaultObject<DefaultObject<string>> = {};
        //         data.metrics.split(',').map((item: any, index) => {
        //             const id = index;
        //             if (item.startsWith('eventitem_')) {
        //                 const tempName = item;
        //                 const prefix = 'eventitem_';
        //                 const event_id = tempName.slice(prefix.length);
        //                 if (isNumber(event_id)) {
        //                     if (!tempCustomMetric[prefix]) tempCustomMetric[prefix] = {};
        //                     reduxEvents.map((event: any) => {
        //                         if (event.id === +event_id) {
        //                             const value = event.event_target_name;
        //                             tempCustomMetric[prefix][id] = value;
        //                             temp.push({id, name: `${prefix}`});
        //                             return;
        //                         }
        //                     })
        //                 }
        //             } else
        //                 temp.push({id, name: item});
        //         })
        //         setEventsActiveColumns(tempCustomMetric);
        //         setSelectMetrics(temp);
        //     }
        //     if (data.emails) {
        //         const temp: any = [];
        //         data.emails.split(',').map((item: any, index) => {
        //             temp.push({id: index, email: item});
        //         })
        //         setEmailList(temp);
        //     }
        //     setUpdateData(true);
        // } catch (e) {
        //     console.log('Error - ', e);
        // }
    }
}

export const getEventTitles = (data: Array<ICurrentEvents>) => {
    const temp: string[] = [];
    data.map((item)=> {
        if(item.event_target_name)
            temp.push(item.event_target_name);
    })
    return {
        title: temp.join(","),
        keys: temp
    };
}
