import { FC } from 'react'
import cx from 'classnames'
import { ReactComponent as CheckMarkIcon } from '../../assets/icons/icon-check-mark.svg'
import { IToggle} from './Toggle.d'
import './Toggle.scss'
import {Link} from "react-router-dom";

const Toggle: FC<IToggle> = ({
  className,
  type = 'checkbox',
  label = '',
  name,
  value = '',
  borderMod = false,
  isSlider = false,
  isChecked,
  defaultChecked,
  onChange = () => null,
  disabled = false,
  color,
  size,
  isInvalid,
  labelWithUrls
}) => {
  const toggleClassName = cx(
    'custom-toggle',
    className,
    {
      ['custom-toggle--checkbox']: !borderMod && !isSlider,
      ['custom-toggle--checkbox-border']: borderMod,
      ['custom-toggle--radio-checkbox']: isSlider,
      ['custom-toggle--invalid']: isInvalid,
      ['custom-toggle--gray']: color === 'gray',
      ['custom-toggle--small']: size === 'small',
    }
  );

  return (
    <div className={toggleClassName}>
      <label>
        <input
          type={type}
          name={name}
          value={value}
          checked={isChecked}
          defaultChecked={defaultChecked}
          onChange={onChange}
          disabled={disabled}
        />
          <span className="custom-toggle__icon">
            <CheckMarkIcon width={12} height={8} />
          </span>
        {label && <span className="custom-toggle__label">{label}</span>}
          {Array.isArray(labelWithUrls) && <span className="custom-toggle__label">
              {labelWithUrls.map((item)=> (
                  item.href ?
                      <Link className="custom-toggle__link" to={item.href as string} aria-label={item.text} >{item.text}</Link> :
                  item.text
              ))}
          </span>}
      </label>
    </div>
  );
};

export default Toggle;
