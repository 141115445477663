import React, {FC, useCallback, useEffect, useState} from 'react'
import { Form, Loader } from '../../index'
import {AccessField, Btn} from '../../../ui'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import {
  AccessList,
  getAccessList,
  getIsLoading,
  errorValue,
  removeError
} from '../../../redux/slices/access'
import { addAccess, fetchAccessList, removeAccess } from '../../../actions/access'
import { AnyEvent } from '../../../interfaces/common.d'
import { useShowError } from '../../../helpers'
import { IEditUserModal } from './interfaces.d'
import './style.scss';
import iconEdit from '../../../assets/icons/edit.svg'
import CalendarIcon from '../../../assets/icons/icon-calendar.svg'
import {ModalName, openModal} from "../../../redux/slices/modals";
import Select from "../../ui/Select";
import {trans} from "../../../_locales";
import {currentUserLang} from "../../../redux/slices/user";

const EditUserModal: FC<IEditUserModal> = ({
  userId,
  projectId
}) => {
  const dispatch = useAppDispatch();
  const list = useAppSelector(getAccessList);
  const isLoading = useAppSelector(getIsLoading);
  const errorMessage = useAppSelector(errorValue);
  const language = useAppSelector(currentUserLang);

  const currentList = list ? list : [] as AccessList;

  useEffect(() => {
    if(userId && projectId)
      dispatch(fetchAccessList({ userId, projectId }));
  }, [userId, projectId, dispatch]);

  useShowError(errorMessage as string, removeError);



  const handelAccessRemove = useCallback((evt: AnyEvent, email: string) => {
    evt.preventDefault();
    dispatch(removeAccess({ email, userId, projectId }));
  }, [dispatch, userId, projectId]);

  const handelAccessAdd = useCallback((email: string) => {
    dispatch(addAccess({ email, userId, projectId }));
  }, [dispatch, userId, projectId]);

  const [targetName, setTargetName] = useState('');
  const Role = {
    view: 'Просмотр',
    admin: 'Администратор'
  }
  const [role, setRole] = useState('view');
  const changeRole = (response:object): void => {
    for(const key in response) {
      setRole(key)
    }
    // setNameProject({...nameProject, currency: e.currentTarget.value})
  }
  const changeTargetName = (value:string)=> {
    setTargetName(value);
    // setData( {...data, event_target_name: targetName});
  }
  const onReset = ()=> {
    console.log('reset');
  }
  const onSubmit = ()=> {
    console.log('submit')
  }
  return (
    <>
      <div className="edit-user__channel">
        <div className={'input_section'}>
            <span className={'input_label'}>{trans('Role', language)}</span>
            <div className={'select_wrapper edit-user__select'}>
              <Select selectActive={(Role as any)[role]} dataset={Role} onChangeActive={changeRole} />
            </div>
          </div>
        <div className={'input_section'}>
          <span className={'input_label'}>{trans('Traffic limit', language)}</span>
          <button className={'input edit-user__btn'} onClick={() =>dispatch(openModal(ModalName.LIMIT_TRAFFIC))}>
            <span>{trans('No limit', language)}</span>
            <span className="edit-user__icon">
              <img src={iconEdit} alt=""/>
            </span>
          </button>
        </div>
        <div className={'input_section'}>
          <span className={'input_label'}>{trans('Access expiration date', language)}</span>
          <button className={'input edit-user__btn'} onClick={() =>dispatch(openModal(ModalName.ACCESS_TIME))}>
            <span>{trans('Unlimited', language)}</span>
            <span className="edit-user__icon edit-user__icon_l">
              <img src={CalendarIcon} alt=""/>
            </span>
          </button>
        </div>
      </div>
      <div className="edit-user-action">
        <Btn
          type='reset'
          text={trans('Cancel', language)}
          size='lg'
          widthAuto={false}
          onClick={onReset}
        />
        <Btn
          type='submit'
          text={trans('Save', language)}
          color='blue'
          widthAuto={false}
          size='lg'
          disabled={false}
          onClick={onSubmit}
        />
      </div>
      {isLoading && <Loader />}
    </>
  );
};

export default EditUserModal;
