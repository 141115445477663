import {DefaultObject} from "../../../../interfaces/common.d";
import {FC} from "react";
import Select from "../../../ui/Select";
import Tooltip from "../../../Tooltip";
import TableInteractiveOptions, {ITableOptions} from "../TableOptions";
import "./style.scss";

interface ITableHead {
    name: string,
    isDisableName?: boolean,
    isDisableAgg?: boolean,
    isDisableOptions?: boolean,
    tabs?: DefaultObject<string>,
    activeTab?: string,
    onChangeTab: any,
    tooltip?: string,
    posTooltip?: 'left' | 'right',
    description?: string,
    activeAggLabel?: string,
    datasetAgg?: DefaultObject<string>,
    activeAggList?: Array<string>,
    onChangeAggChoice?: any,
    options?: ITableOptions
}

const TableInteractiveHead: FC<ITableHead> = ({
    name,
    isDisableName=false,
    isDisableAgg=false,
    isDisableOptions=false,
    tabs,
    activeTab,
    onChangeTab,
    tooltip,
    posTooltip,
    description,
    activeAggLabel='',
    datasetAgg={},
    activeAggList=[],
    onChangeAggChoice=()=>null,
    options
}) => {
    return (
        <div className={'table-interactive-head'}>
            {!isDisableName && <span className='table-interactive-head__name'>{name}</span>}
            {tabs && activeTab && <Select
                className='table-interactive-head__tabs'
                selectActive={tabs[activeTab]}
                dataset={tabs}
                onChangeActive={onChangeTab}
                widthAuto={true}
                color={'transparent'}
            />}
            {!isDisableAgg && tooltip && <Tooltip text={tooltip} className={`bottom ${posTooltip ? posTooltip : ''}`}/>}
            {description && <span className='table-interactive-head__subtitle'>&nbsp;({description})</span>}
            {!isDisableAgg && Object.values(datasetAgg).length > 0 &&
                <div className={'table-interactive-head__agg'}>
                    <Select
                        selectActive={activeAggLabel}
                        dataset={datasetAgg}
                        onChangeActive={onChangeAggChoice}
                        color={'dark'}
                        widthAuto={true}
                        checkbox={true}
                        listActive={activeAggList}
                    />
                </div>
            }
            {!isDisableOptions && options &&
                <TableInteractiveOptions
                    onChangeMetricChoice={options.onChangeMetricChoice}
                    datasetMetric={options.datasetMetric}
                    activeMetricLabel={options.activeMetricLabel}
                    activeMetricList={options.activeMetricList}
                    btns={options.btns}
                />
            }
        </div>
    )
}

export default TableInteractiveHead;