import { FC } from 'react'
import cx from 'classnames'
import { IInfoCard } from './InfoCard.d'
import './InfoCard.scss'

const InfoCard: FC<IInfoCard> = ({
  className,
  children,
  headerText
}) => {
  return (
    <div className={cx('info-card', className)}>
      {headerText && <div className='info-card__header'>{headerText}</div>}
      <div className='info-card__content'>{children}</div>
    </div>
  );
}

export default InfoCard