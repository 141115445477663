import './style.scss';
import Btn from "../../ui/Btn";
import cx from "classnames";
import {FC} from "react";

interface ITooltip {
    className?:string,
    text:string,
    onClick?:any,
    color?: 'blue' | 'dark'
}

const Tooltip:FC<ITooltip> = ({
    className,
    text,
    onClick,
    color
}) => {

    const classesTooltip = cx(
        'tooltip',
        className,
        {
            'tooltip--color-blue': color === 'blue'
        }
    )

    return(
        <div className={classesTooltip}>
            {/*<span className='tooltip__icon'>?</span>*/}
            <Btn className='tooltip__icon' text={'?'} onClick={onClick}/>
            <div className="tooltip__alt">
                <span><pre>{text}</pre></span>
            </div>
        </div>
    )
}

export default Tooltip;