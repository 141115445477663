import SimpleBase from "../../../layouts/SimpleBase";
import "../style.scss";
import {trans} from "../../../_locales";

const AdvertisingPolicy = () => {
    const breadcrumbsData = {
        data: [
            {href: '', title: 'Согласие на получение рекламной и новостной рассылки'},
        ]
    };
    return(
        <SimpleBase headerData={{breadcrumbsData}}>
            <div className='information'>
                <h1>Согласие на получение рекламной рассылки</h1>
                <p>1. Я даю свое согласие ООО &quot;ТАРГЕТ АДС&quot; (адрес: 108849, Г.МОСКВА, ВН.ТЕР.Г. ПОСЕЛЕНИЕ ВНУКОВСКОЕ, УЛ БОРИСА ПАСТЕРНАКА, Д. 29, К. 1, КВ. 128, ИНН: 7751207185, КПП: 775101001, ОГРН: 1217700478412, дальше - Оператор) на обработку моих персональных данных с целью получения рекламной рассылки в виде e-mail писем, телефонных звонков, sms-сообщений и сообщений в мессенджерах (Telegram, What&apos;sApp).</p>
                <p>2. Для этой цели я даю согласие на обработку моих персональных данных: фамилия, имя, отчество, адрес электронной почты, номер телефона.</p>
                <p>3. Я даю согласие путем проставления &quot;галочки&quot; в соответствующем окошке. Моё согласие является конкретным, предметным, информированным, сознательным и однозначным.</p>
                <p>4. Оператор обрабатывает персональные данные следующими способами: сбор, запись, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, блокирование, удаление, уничтожение.</p>
                <p>5. Оператор обрабатывает персональные данные без использования средств автоматизации.</p>
                <p>6. Оператор обрабатывает персональные данные в соответствии с Политикой в области обработки персональных данных, размещенной на сайте <a className='information__link' href="https://targetads.io/">https://targetads.io/</a>.
                </p>
                <p>7. Согласие действует с момента предоставления и до момента отзыва.</p>
                <p>8. Я могу отписаться от рекламной рассылки и отозвать это согласие в любое время путем направления заявления на электронную почту: <a className='information__link' href="mailto://info@targetads.io">info@targetads.io</a>.
                </p>
            </div>
        </SimpleBase>
    )
}

export default AdvertisingPolicy;