import {useEffect, useState} from "react";
import cls from "./style.module.scss";
import {useNavigate} from "react-router-dom";
import {fourthDone, stepsData} from "../../../../redux/slices/projects";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";
import {currentUserLang, userId} from "../../../../redux/slices/user";
import {updateProjectAttributions} from "../../../../actions/project";
import Select from "../../../../components/ui/Select";
import Input from "../../../../ui/Input";

import './style.scss';
import {trans} from "../../../../_locales";
import {Models} from "../../../../data";
import {sendSdk} from "../../../../utils";

export const FourthStep = () => {
  const reduxUserId = useAppSelector(userId);
  const reduxStepsData = useAppSelector(stepsData);
  const language = useAppSelector(currentUserLang);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [windowAttribution, setWindowAttribution] = useState(30);
  const windows:any = {
    '15': '15',
    '30': '30',
    '60': '60',
    '90': '90'
  }
  const onChangeWindowAttribution = (response:object) => {
    for(const key in response) {
      setWindowAttribution(+key)
    }
  }
  const [model, setModel] = useState('mtd');
  const datasetModel = Models(language);
  const onChangeModelAttribution = (response:object) => {
    for(const key in response) {
      setModel(key)
    }
  }

  const [targetEventKpi, setTargetEventKpi] = useState<number>(0);
  const [currency, setCurrency] = useState<string>('USD');
  const currencyList = {
    'USD': '$',
    'EUR': '€',
    'RUB': '₽',
  }
  const onTargetEventKpi = (response:any)=> {
    if(response && typeof response.target_event_kpi === 'number')
      setTargetEventKpi(response.target_event_kpi);
  }


  useEffect(()=> {
    if(Object.values(reduxStepsData).length === 0 && location.hash !== '#/projects')
      navigate('/projects/create/1/');
    if(reduxStepsData && reduxStepsData.dataProject && typeof reduxStepsData.dataProject.currency === 'string')
      setCurrency(reduxStepsData.dataProject.currency);
  }, [reduxStepsData])

  const onSubmit = () => {
    const data = {attribution_window: windowAttribution, attribution_model: model, target_event_kpi: targetEventKpi};
    dispatch(updateProjectAttributions({
      data,
      projectId: Number(reduxStepsData.dataProject.id)
    })).then((r)=> {
      if(r) {
        dispatch(fourthDone());
        navigate('/projects');
      }
    })
  }
  useEffect(()=> {
    if (reduxUserId && reduxStepsData && reduxStepsData.dataProject && reduxStepsData.dataProject.id) {
      sendSdk('page_view', {
        'user': {
          'uid': reduxUserId
        },
        'event_params': {
          'project_id': reduxStepsData.dataProject.id,
          'user_id': reduxUserId,
          'page': 'project_create_attributions'
        }
      })
    }
  }, [reduxStepsData, reduxUserId])

  return (
    <>
      <div className={cls.wrapper}>
        <div className={cls.form}>
          <h3 className={cls.onboard_step}>{trans('Configuring Attribution', language)}</h3>
          <div className={cls.input_section}>
            <span className={cls.input_label}>{trans('Default attribution window', language)}</span>
            <div className={cls.select_wrapper + ' project-create__select'} >
              <Select selectActive={String(windowAttribution)} dataset={windows} onChangeActive={onChangeWindowAttribution} />
            </div>
          </div>
          <div className={cls.input_section}>
            <span className={cls.input_label}>{trans('Default attribution model', language)}</span>
            <div className={cls.select_wrapper + ' project-create__select'} >
              <Select selectActive={(datasetModel as any)[`${model}`]} dataset={datasetModel} onChangeActive={onChangeModelAttribution} />
            </div>
          </div>
          <div className={cls.input_section}>
            <Input
              name={'target_event_kpi'}
              type={'number'}
              value={`${targetEventKpi}`}
              placeholder={''}
              errorLabel={'Incorrect value'}
              onChange={onTargetEventKpi}
              label={trans('Target event KPI', language)}
            />
            <span className={cls.input_currency}>{(currencyList as any)[currency]}</span>
          </div>
        </div>
        <div className={cls.footer}>
          <button className={cls.btn} type='button' onClick={onSubmit}>{trans('Done', language)}</button>
          {/*<button className={cls.skipper} onClick={onSubmit}>{trans('Skip', language)}</button>*/}
        </div>
      </div>
    </>
  );
};
