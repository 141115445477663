import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import {setLogData} from "../../actions/logs";

interface ICurrentLog {
  name: string,
  message: string,
  stack?: string,
  componentStack: string,
  timestamp:number
}
interface LogState {
  data: ICurrentLog | Record<string, never>
  loaded: boolean
  isLoading: boolean
  error: string | null
}

const initialState: LogState = {
  data: {},
  loaded: false,
  isLoading: false,
  error: null
}

export const logsSlice = createSlice({
  name: 'logs',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(
      setLogData.fulfilled,
      (state, action: PayloadAction<ICurrentLog>) => {
      state.data = action.payload ? action.payload : {};
      state.loaded = true;
      state.isLoading = false;
    });
    builder.addCase(
      setLogData.pending,
      (state) => {
      state.isLoading = true;
      state.loaded = false;
    });
    builder.addCase(
      setLogData.rejected,
      (state,  action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload
    });
  }
})

export const logsData = createSelector(
  (state: RootState) => state.logs,
  (logs) => logs.data
)

export default logsSlice.reducer

