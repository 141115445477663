import {
  CSSProperties,
  FC,
  useCallback,
  useEffect,
  useState
} from 'react'
import { useIntersectionObserver } from '../../helpers'
import { getContextStyle } from '../../utils'
import { Portal } from '../index'
import { IContext } from './Context.d'
import './Context.scss'

const DEFAULT_SPACE = 8;

const Context: FC<IContext> = ({
  children,
  parentRef,
  space = DEFAULT_SPACE,
  onClose,
  isFullWidth = false,
  isIndentRight = false
}) => {
  const [tooltipRef, setTooltipRef] = useState<HTMLDivElement | null>(null);
  const [parentRect, setParentRect] = useState<DOMRect>({} as DOMRect);
  const [childrenRect, setChildrenRect] = useState<DOMRect>({} as DOMRect);
  const [style, setStyle] = useState<CSSProperties>({} as CSSProperties);

  const scrollBlock = document.querySelector('[data-name="scroll-block"]');

  const entry = useIntersectionObserver(
    parentRef,
    { root: scrollBlock ? scrollBlock : null }
  );

  const getParentNode = useCallback(() => parentRef?.current instanceof HTMLElement ?
    parentRef?.current : parentRef?.current.getInputDOMNode(),
    [parentRef]
  );

  const getChildrenRef = useCallback((node: HTMLDivElement | null) => {
    if (node !== null) setTooltipRef(node);
  }, []);

  const getCurrentRect = useCallback(() => {
    if (parentRef.current && tooltipRef) {
      const parentNode = getParentNode();
      setParentRect(parentNode.getBoundingClientRect());
      setChildrenRect(tooltipRef.getBoundingClientRect());
    }
  },[getParentNode, parentRef, tooltipRef]);

  useEffect(() => {
    if (typeof entry?.isIntersecting === 'boolean' && !entry?.isIntersecting) {
      getParentNode().blur();
      onClose();
    }
  }, [entry, getParentNode, onClose]);

  useEffect(() => {
    getCurrentRect();
  }, [parentRef, getCurrentRect, tooltipRef]);

  useEffect(() => {
    getContextStyle(
      parentRect,
      childrenRect,
      space,
      setStyle,
      isFullWidth,
      isIndentRight
    );
  }, [childrenRect, isFullWidth, isIndentRight, parentRect, space]);

  useEffect(() => {
    const handleFormScroll = () => {
      getCurrentRect();
      getContextStyle(
        parentRect,
        childrenRect,
        space,
        setStyle,
        isFullWidth,
        isIndentRight
      );
    };

    window.addEventListener('resize', handleFormScroll);
    scrollBlock ?
      scrollBlock.addEventListener('scroll', handleFormScroll) :
      window.addEventListener('scroll', handleFormScroll);

    return () => {
      window.removeEventListener('resize', handleFormScroll);
      scrollBlock ?
        scrollBlock.removeEventListener('scroll', handleFormScroll) :
        window.removeEventListener('scroll', handleFormScroll);
    };
  }, [childrenRect, scrollBlock, parentRect, getCurrentRect, space, isFullWidth, isIndentRight]);

  return (
    <Portal selector='#modal-root'>
      <div
        ref={el => getChildrenRef(el)}
        className='context'
        style={style}
      >
        {children}
      </div>
    </Portal>
  );
};

export default Context
