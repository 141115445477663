import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'

interface WarningsState {
  unsave: boolean
  error: string | null
}

const initialState: WarningsState = {
  unsave: false,
  error: null,
}

export const warningsSlice = createSlice({
  name: 'warnings',
  initialState,
  reducers: {
    setUnSaveData: (state) => {
      state.unsave = true;
    },
    clearUnSaveData: (state) => {
      state.unsave = false
    },
  }
});

export const { setUnSaveData, clearUnSaveData } = warningsSlice.actions

export const checkUnSave = createSelector(
  (state: RootState) => state.warnings,
  (warnings) => warnings.unsave
)

export default warningsSlice.reducer