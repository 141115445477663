import './style.scss';

const Alt = ({text, active=false}:{text:string, active?:boolean}) => {
    return(
        <div className={`alt ${active ? 'active' : ''}`}>
            <span>{text}</span>
        </div>
    )
}

export default Alt;