import { FC, useState, useRef } from 'react'
import cx from 'classnames'
import { IUserMenu } from './UserMenu.d'
import { Btn } from '../../ui'
import { ReactComponent as BellIcon } from '../../assets/icons/icon-bell.svg'
import { ReactComponent as ExitIcon } from '../../assets/icons/icon-exit.svg'
import './UserMenu.scss'
import { useAppDispatch } from '../../redux/hooks'
import { useOnClickOutside } from '../../helpers'
import { logout } from '../../actions/users';
import {useNavigate} from "react-router-dom";
import profileIcon from '../../assets/icons/Person.svg';

const UserMenu: FC<IUserMenu> = ({ className, notify = 0, userName }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const userPopupRef = useRef<HTMLDivElement>(null);

  const [userPopupActive, setUserPopupActive] = useState(false);
  useOnClickOutside(() => setUserPopupActive(false), [userPopupRef]);

  const handleUserLogout = () => {
    dispatch(logout()).then(()=> {
      navigate('/');
    })
  }

  return (
    <div className={cx('user-menu', className)}>
      {/*<Btn*/}
      {/*  icon={{*/}
      {/*    Component: BellIcon,*/}
      {/*    width: 21,*/}
      {/*    height: 23*/}
      {/*  }}*/}
      {/*  notify={notify}*/}
      {/*  isIconBtn={true}*/}
      {/*  size="md"*/}
      {/*  active={Boolean(notify)}*/}
      {/*/>*/}

      {/*  <div></div>*/}
      <button className={cx("user-menu__user-popup", {'is-active': userPopupActive})} onClick={()=>navigate('/profile')}>
        <span>
          <img src={profileIcon} alt=""/>
        </span>
        <p>{userName}</p>
        {/*<Btn*/}
        {/*  text={userName}*/}
        {/*  size="md"*/}
        {/*  href='/profile'*/}
        {/*  // withMenuTriangle={true}*/}
        {/*/>*/}
      </button>
    </div>
  )
}

export default UserMenu
