import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";

import {PopupType} from "../../../../data";
import {openPopup} from "../../../../redux/slices/popup";
import {getUserInfo} from "../../../../actions/users";
import {
    authorizeYandexDirect,
    selectClientForAuthYandexDirect,
    updateIntegrationName
} from "../../../../actions/project";

import Base from "../../../../layouts/Base";
import {Btn} from "../../../../ui";
import Loader from "../../../../components/Loader";
import Empty from "../../../../components/Empty";

import './style.scss';
import {checkVisible, getUrlParam, isNumber} from "../../../../utils";
import {trans} from "../../../../_locales";
import {currentUserLang} from "../../../../redux/slices/user";
import Input from "../../../../ui/Input";

const YandexDirect = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch()
  const language = useAppSelector(currentUserLang);

  const headerData = {
    breadcrumbsData:{
      data: [
        {
          href: '#',
          title: 'Подключение Яндекс Директ',
        },
      ]
    },
    userMenuData: {
      userName: '',
    },
  }
  const projectId = JSON.parse(`{${getUrlParam('state')}}`).project_id;
  const userId = JSON.parse(`{${getUrlParam('state')}}`).user_id;
  const code = getUrlParam('code');
  const [iid, setIid] = useState<number>(0);
  const [piid, setPiid] = useState<number>(0);
  const [isFirstLoading, setIsFirstLoading] = useState(true);

  useEffect(() => {
    if(getUrlParam('url')) {
        if(getUrlParam('client_id')) {
            window.location.href = `${getUrlParam('url')}&force_confirm=${getUrlParam('force_confirm')}&client_id=${getUrlParam('client_id')}&state=${getUrlParam('state')}`;
            // window.location.href = `${getUrlParam('url')}&force_confirm=${getUrlParam('force_confirm')}&client_id=${getUrlParam('client_id')}&state=${getUrlParam('state')}`;
        } else {
            window.opener.dispatchEvent(
                new CustomEvent("yandex_direct", {
                    detail: { error: trans('Something went wrong', language), data: false}
            }))
        }
      return;
    }
    else {
        if(isFirstLoading) {
            setIsFirstLoading(false);
            dispatch(getUserInfo());
            dispatch(authorizeYandexDirect({code: code, projectId: projectId, userId: userId})).then((r) => {
                setLoader(false);
                if (r.payload) {
                    if (r.meta.requestStatus === 'rejected') {
                        setShowError(true);
                        let error = trans('Something went wrong', language);
                        if (r.payload.message && typeof r.payload.message === 'string')
                            error = r.payload.message;
                        window.opener.dispatchEvent(
                            new CustomEvent("yandex_direct", {
                                detail: {error: error, data: false}
                            }))
                        return;
                    }
                    if (isNumber(r.payload.integration_id) && isNumber(r.payload.project_integration_id)) {
                        setIid(+r.payload.integration_id)
                        setPiid(+r.payload.project_integration_id)
                    }
                    if (r.payload.is_agency) {
                        setShowError(false);
                        setShowSelect(true);
                        setDatasetSelect(r.payload.clients);
                        return;
                    }
                    if (r.payload.clients && Array.isArray(r.payload.clients) && r.payload.clients.length && r.payload.clients[0].Login) {
                        setIntegrationName(r.payload.clients[0].Login)
                    }
                    setShowUpdateName(true);
                    setShowError(false);
                    // window.opener.dispatchEvent(
                    //     new CustomEvent("yandex_direct", {
                    //         detail: { error: "", data: true}
                    // }))
                    return;
                }


                window.opener.dispatchEvent(
                    new CustomEvent("yandex_direct", {
                        detail: {
                            error: trans('Sorry, the server is not responding at the moment', language),
                            data: false
                        }
                    }))
                setShowError(true);
            }).catch((r) => {
                window.opener.dispatchEvent(
                    new CustomEvent("yandex_direct", {
                        detail: {
                            error: trans('Sorry, the server is not responding at the moment', language),
                            data: false
                        }
                    }))
            })
        }
    }
  }, [])

  const [datasetSelect, setDatasetSelect] = useState<any>();
  const [selectActive, setSelectActive] = useState<any>({name: '', login: ''});
  const onChangeModelAttribution = (event:any, login:string, name='') => {
    Object.values(event.target.closest('button').parentNode.children).map((item:any)=> (
       item.className = ''
    ))
    event.target.closest('button').className = 'active';
    setShowSelectList(false);
    const temp:any = {
        name: name,
        login: login
    };
    setSelectActive(temp)
  }

  const onSubmit = () => {
    if(integrationName) {
        dispatch(updateIntegrationName({
            projectId: +projectId,
            piid: piid,
            name: integrationName
        }))
    }
    if(selectActive.login) {
        dispatch(selectClientForAuthYandexDirect({
            projectId: +projectId,
            login:selectActive.login,
            integrationId: iid,
            projectIntegrationId: piid
        })).then(r=> {
          if(r.meta.requestStatus === 'fulfilled') {
            window.opener.dispatchEvent(
                new CustomEvent("yandex_direct", {
                    detail: { error: "", data: true}
            }))
          }
        })
    }
  }

  const [showSelect, setShowSelect] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loader, setLoader] = useState(true);
  const [showSelectList, setShowSelectList] = useState(true);

  const onClickSelect = (e:any) => {
      if(e.target.closest('.select__input') === null) {
          setShowSelectList(!showSelectList)
      }
  }

  const [integrationName, setIntegrationName] = useState<string>('');
  const [showUpdateName, setShowUpdateName] = useState<boolean>(false);

  return (
    <>
      <Base headerData={headerData} border={true}>
        <div className='yandex-direct'>
            {
                !showError && showUpdateName && <div className="yandex-direct__wrapper">
                    <Input
                        name={'name'}
                        label={trans('The name for the integration', language)}
                        value={integrationName}
                        isDirectCbInput={true}
                        onChange={(value) => setIntegrationName(value)}
                    />
                    <Btn
                        type='submit'
                        text='Сохранить'
                        color='blue'
                        widthAuto={false}
                        size='lg'
                        disabled={!integrationName}
                        onClick={()=> {
                            dispatch(updateIntegrationName({
                              projectId: +projectId,
                              piid: piid,
                              name: integrationName
                            })).then((r)=> {
                                window.opener.dispatchEvent(
                                    new CustomEvent("yandex_direct", {
                                        detail: { error: "", data: true}
                                }))
                            })
                        }}
                    />
                </div>
            }
            {
              showSelect  && datasetSelect &&
              <div className="yandex-direct__wrapper">
                <Input
                    name={'name'}
                    label={trans('The name for the integration', language)}
                    value={integrationName}
                    isDirectCbInput={true}
                    onChange={(value) => setIntegrationName(value)}
                />
                <div className="yandex-direct__select">
                    <div>
                        <span className={'yandex-direct__title'}>Выберите юзера</span>
                        <button className={`select__block ${showSelectList ? 'active' : ''}`} onClick={(e)=>onClickSelect(e)}>
                            <span>{selectActive.login}</span>
                        </button>
                    </div>
                    <div className={`yandex-direct__content ${showSelectList ? 'active' : ''}`}>
                        {
                            datasetSelect.length > 0 ?
                            datasetSelect.map((item:any)=> (
                              <button key={item.id} className={'yandex-direct__select-item'} onClick={()=>onChangeModelAttribution(event, item.Login, item.ClientInfo)}>
                                <div className="yandex-direct__select-item__name">{item.ClientInfo}</div>
                                <span className="yandex-direct__select-item__login">{item.Login}</span>
                              </button>
                            )) : ''
                        }
                    </div>
                </div>
                <Btn
                  type='submit'
                  text='Сохранить'
                  color='blue'
                  widthAuto={false}
                  size='lg'
                  disabled={false}
                  onClick={onSubmit}
                />
              </div>
            }
            {
              showError &&
              <Empty title={trans('Something went wrong', language)} />
            }
            { loader &&
                <Loader inBlock={true}/>
            }
        </div>
      </Base>
    </>
  );
};


export default YandexDirect;