import SimpleBase from "../../../layouts/SimpleBase";
import {trans} from "../../../_locales";
import React from "react";
import "../style.scss";
const PersonalDataPolicy = () => {
    const breadcrumbsData = {
        data: [
            {href: '', title: 'Политика персональных данных'},
        ]
    };
    return(
        <SimpleBase headerData={{breadcrumbsData}}>
            <div className='information'>
                <div className='information__flex'>
                    <h1>Политика в области обработки персональных данных</h1>
                    <span>редакция от 12.08.2024</span>
                </div>


                <p>Этот документ определяет наши принципы и правила в области обработки персональных данных
                    пользователей Сервиса.</p>
                <p>Ознакомьтесь с политикой прежде, чем начать использовать Сервис или вводить данные в формах для
                    обратной связи.</p>
                <p>Если у вас возникнут вопросы, напишите нам на <a className='information__link'
                    href="mailto://info@targetads.io">info@targetads.io</a></p>
                <h2>Термины</h2>
                <div className='docs__table information__table'>
                    <table>
                        <thead>
                        <tr>
                            <th>{trans('Термин', 'ru')}</th>
                            <th>{trans('Описание', 'ru')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Сервис</td>
                            <td>сайт и программный комплекс Компании &quot;Target ADS&quot;, расположенный по адресу
                                https://targetads.io/ (включая поддомены)
                            </td>
                        </tr>
                        <tr>
                            <td>Пользователь</td>
                            <td>физическое лицо, использующее Сервис - субъект Персональных данных</td>
                        </tr>
                        <tr>
                            <td>Политика</td>
                            <td>данный документ</td>
                        </tr>
                        <tr>
                            <td>Персональные данные</td>
                            <td>любая информация, относящаяся прямо или косвенно к определенному или определяемому
                                Пользователю
                            </td>
                        </tr>
                        <tr>
                            <td>Оператор</td>
                            <td>ООО &quot;Таргет АДС&quot;, оператор Персональных данных</td>
                        </tr>
                        <tr>
                            <td>Обработка Персональных данных (Обработка)</td>
                            <td>любое действие / операция или их совокупность с Персональными данными</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <h2>1. Общие положения</h2>
                <p>1.1. Оператор разработал Политику, чтобы:</p>
                <ul className='information__list'>
                    <li>(1) защищать права и свободы Пользователя при обработке его Персональных данных;</li>
                    <li>(2) четко и неукоснительно соблюдать требования законодательства РФ в области персональных
                        данных, в том
                        числе требования Федерального закона от 27.07.2007 №152-ФЗ &quot;О персональных данных&quot;.
                    </li>
                </ul>

                <p>1.2. Оператор обрабатывает Персональные данные на основании:</p>
                <ul className='information__list'>
                    <li>(1) договора, который заключили Оператор и Пользователь;</li>
                    <li>(2) Пользовательского соглашения Сервиса, которое акцептовал Пользователь;</li>
                    <li>(3) согласия на обработку персональных данных;</li>
                    <li>(4) согласия на получение рекламной рассылки;</li>
                    <li>(5) согласия на обработку cookies (фрагменты текста, который автоматически сохраняется в память
                        интернет-браузера Пользователя).
                    </li>
                </ul>

                <h2>2. Перечень, цели и сроки Обработки</h2>
                <p>2.1. Оператор обрабатывает Персональные данные на следующих условиях:</p>
                <div className='docs__table information__table'>
                    <table>
                        <thead>
                        <tr>
                            <th>{trans('Название цели', 'ru')}</th>
                            <th>{trans('Описание цели', 'ru')}</th>
                            <th>{trans('Персональные данные', 'ru')}</th>
                            <th>{trans('Срок хранения', 'ru')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Обработка заявки для обсуждения проекта и заключения договора</td>
                            <td>Заполнение Пользователем формы на Сервисе в целях обратной связи для заключения
                                договора
                            </td>
                            <td>фамилия, имя; номер мобильного телефона; адрес электронной почты</td>
                            <td>30 дней после обсуждения проекта, или с момента получения отзыва согласия на обработку
                                персональных
                                данных, в зависимости от того, что произойдет раньше
                            </td>
                        </tr>
                        <tr>
                            <td>Исполнение обязательств перед Пользователем</td>
                            <td>Исполнение обязательств перед Пользователем, предусмотренных заключенным договором</td>
                            <td>фамилия, имя; номер мобильного телефона; адрес электронной почты</td>
                            <td>30 дней после исполнения Договора, или с момента получения отзыва согласия на обработку
                                персональных
                                данных, в зависимости от того, что произойдет раньше
                            </td>
                        </tr>
                        <tr>
                            <td>Регистрация на Сервисе</td>
                            <td>идентификация Пользователя в целях создания его персонального аккаунта на Сервисе</td>
                            <td>фамилия, имя; номер мобильного телефона; адрес электронной почты</td>
                            <td>30 дней после исполнения Договора, или с момента получения отзыва согласия на обработку
                                персональных
                                данных, в зависимости от того, что произойдет раньше
                            </td>
                        </tr>
                        <tr>
                            <td>Рекламная рассылка</td>
                            <td>Направление рекламных и новостных предложений Оператора</td>
                            <td>фамилия, имя; номер мобильного телефона; адрес электронной почты</td>
                            <td>10 дней после того, как вы отпишитесь от рассылки, или если мы перестанем проводить ее
                                до того, как вы
                                отпишитесь, в зависимости от того, что произойдет раньше.
                            </td>
                        </tr>
                        <tr>
                            <td>Обеспечение работы Сервиса</td>
                            <td>Корректная работа Сервиса и сбор статистики</td>
                            <td>cookie-файлы</td>
                            <td>В зависимости от вида cookie-файла.
                                <br/>Сроки и виды описаны ниже
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <p>2.2. Персональные данные, которые обрабатывает Оператор, не являются биометрическими и не относятся к
                    специальным категориям Персональных данных.</p>


                <h3>2.3. Обработка Персональных данных</h3>
                <p>2.4. Обработка включает сбор, запись, систематизацию, накопление, хранение, уточнение (обновление,
                    изменение), извлечение, использование, передачу (распространение, предоставление, доступ),
                    блокирование,
                    удаление, уничтожение.</p>
                <p>2.5. Пользователь соглашается с Политикой при направлении своих Персональных данных Оператору при
                    заполнении
                    форм на Сервисе или при передаче персональных данных о телефону, по электронной почте, в
                    мессенджерах и
                    др. средствах коммуникации.</p>
                <h3>Сбор Персональных данных</h3>
                <p>2.6. Оператор собирает Персональные данные:</p>
                <ul className='information__list'>
                    <li>(1) через формы Сервиса, которые заполняет Пользователь;</li>
                    <li>(2) с помощью средств коммуникации, в том числе по телефону, по электронной почте, в
                        мессенджерах и др;
                    </li>
                    <li>(3) автоматически с помощью технологий, веб-протоколов, файлов “cookie”, веб-отметок, которые
                        запускаются
                        только при вводе Пользователем своих данных.
                    </li>
                </ul>

                <h3>Обработка cookies и уникальных идентификаторов устройств</h3>
                <p>2.7. Основание для обработки cookies и уникальных идентификаторов устройств - это согласие на
                    обработку
                    персональных данных, предоставляемого Пользователем Сервиса путем совершения конклюдентных действий,
                    а
                    именно Пользователь продолжил использовать Сервис.</p>
                <p>2.8. Перечень cookie-файлов, которые обрабатывает Оператор:</p>
                <div className='docs__table information__table'>
                    <table>
                        <thead>
                        <tr>
                            <th>{trans('Наименование', 'ru')}</th>
                            <th>{trans('Провайдер', 'ru')}</th>
                            <th>{trans('Срок хранения', 'ru')}</th>
                            <th>{trans('Описание', 'ru')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>_TADUID</td>
                            <td>targetads.io</td>
                            <td>Срок хранения 10 лет</td>
                            <td>Позволяет обезличенно собирать статистику по пользователю</td>
                        </tr>
                        <tr>
                            <td>_TADF</td>
                            <td>targetads.io</td>
                            <td>Срок хранения 10 лет</td>
                            <td>Регистрирует данные о поведении посетителей на сайте</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <p>2.9. Перечень уникальных идентификаторов устройств, которые обрабатывает Оператор:</p>
                <div className='docs__table information__table'>
                    <table>
                        <thead>
                        <tr>
                            <th>{trans('Наименование', 'ru')}</th>
                            <th>{trans('Провайдер', 'ru')}</th>
                            <th>{trans('Срок хранения', 'ru')}</th>
                            <th>{trans('Описание', 'ru')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>GAID</td>
                            <td>targetads.io</td>
                            <td>Срок хранения 10 лет</td>
                            <td>Позволяет обезличенно собирать статистику о пользователе устройства на платформе Android
                                (Google)
                            </td>
                        </tr>
                        <tr>
                            <td>IDFA</td>
                            <td>targetads.io</td>
                            <td>Срок хранения 10 лет</td>
                            <td>Позволяет обезличенно собирать статистику о пользователе устройства на платформе iOS
                            </td>
                        </tr>
                        <tr>
                            <td>OAID</td>
                            <td>targetads.io</td>
                            <td>Срок хранения 10 лет</td>
                            <td>Позволяет обезличенно собирать статистику о пользователе устройства на платформе
                                Android
                            </td>
                        </tr>
                        <tr>
                            <td>WINDOWSID</td>
                            <td>targetads.io</td>
                            <td>Срок хранения 10 лет</td>
                            <td>Позволяет обезличенно собирать статистику о пользователе устройства на платформе
                                Windows
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <p>2.10. Пользователь вправе изменить настройки своего интернет-браузера и отказаться от сохранения
                    файлов
                    cookies (<a className='information__link'
                        href="https://support.google.com/accounts/answer/61416?hl=ru&co=GENIE.Platform%3DDesktop">Google
                        Chrome</a>, <a className='information__link'
                        href="https://browser.yandex.ru/help/personal-data-protection/cookies.html">Яндекс.Браузер</a>, <a className='information__link'
                        href="https://support.mozilla.org/ru/kb/otklyuchenie-kukov-so-storonnih-sajtov">Mozilla
                        Firefox</a>).</p>
                <p>2.11. С целью обеспечения работоспособности Сервиса для ведения статистики и анализа работы Сервиса
                    Оператор
                    обрабатывает cookie-файлы и уникальные идентификаторы устройств с использованием &quot;Target
                    ADS&quot;, который принадлежит Оператору.</p>
                <ul className='information__list'>
                    <li>2.11.1. Информация, хранящаяся в таких файлах передается и сохраняется на облачных серверах ООО
                        «Яндекс»,
                        зарегистрированного по адресу 119021, Москва, ул. Льва Толстого, д.16 (далее – Яндекс).
                        Пользователь
                        дает согласие Оператору на обработку и передачу данных Яндексу.
                    </li>
                    <li>2.11.2. Политика обработки персональных данных Яндекса размещена по <a className='information__link'
                        href="https://yandex.ru/legal/confidential/">ссылке</a>.
                    </li>
                    <li>2.11.3. При блокировке обработки cookies-файлов и уникальные идентификаторы устройств, некоторые
                        функции Сервиса
                        могут стать недоступны.
                    </li>
                </ul>

                <h3>Хранение Персональных данных</h3>

                <p>2.12. Оператор хранит Персональные данные:</p>
                <ul className='information__list'>
                    <li>(1) исключительно на должным образом защищенных электронных носителях;</li>
                    <li>(2) с использованием баз данных, находящихся на территории РФ;</li>
                    <li>
                        <p>(3) для всех целей с момента дачи согласия и до наступления одного из событий:</p>
                        <ul className='information__underlist'>
                            <li>- отзыв согласия,</li>
                            <li>- истечения 30 календарных дней с момента достижения цели обработки персональных
                                данных.
                            </li>
                        </ul>
                    </li>
                </ul>

                <h3>Доступ к Персональным данным</h3>

                <p>2.13. Оператор предоставляет Персональные данные только своим уполномоченным работникам.</p>
                <p>2.14. Оператор не распространяет и не предоставляет Персональные данные Пользователей третьим лицам
                    без их письменного согласия, за исключением следующих случаев:</p>
                <ul className='information__list'>
                    <li>(1) в целях предупреждения, пресечения незаконных действий Пользователя и защиты законных
                        интересов
                        Оператора и третьих лиц, а также в случаях, установленных законодательством РФ;
                    </li>
                    <li>(2) по мотивированному запросу судебных органов, органов государственной безопасности,
                        прокуратуры, полиции,
                        в следственные органы, в иные органы и организации в случаях, установленных нормативными
                        правовыми
                        актами, обязательными для исполнения.
                    </li>
                </ul>


                <h3>Актуализация и уничтожение Персональных данных</h3>
                <p>2.15. Оператор актуализирует Персональные данные, если подтверждается факт их неточности.</p>
                <p>2.16. Оператор уничтожает Персональные данные в следующих случаях:</p>
                <ul className='information__list'>
                    <li>(1) наличие угрозы безопасности Сервиса;</li>
                    <li>(2) истечение срока хранения Персональных данных;</li>
                    <li>(3) при достижении целей обработки Персональных данных;</li>
                    <li>(4) по запросу Пользователя.</li>
                </ul>


                <p>2.17. Оператор удаляет всю полученную от Пользователя информацию, если Пользователь отзывает свое
                    согласие на
                    Обработку. С этого момента право использования Сервиса прекращается.</p>
                <p>2.18. Оператор уничтожает Персональные данные путем стирания или форматирования носителя.</p>

                <h2>3. Права и гарантии Пользователя</h2>
                <p>3.1. Пользователь имеет следующие права:</p>
                <div className='docs__table information__table'>
                    <table>
                        <thead>
                        <tr>
                            <th>{trans('Право Пользователя', 'ru')}</th>
                            <th>{trans('Описание права Пользователя', 'ru')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Право на доступ к Персональным данным</td>
                            <td>Пользователь вправе запросить копию персональных данных, которые есть у Оператора</td>
                        </tr>
                        <tr>
                            <td>Право на уточнение Персональных данных</td>
                            <td>Пользователь может попросить Оператора исправить неточные или неполные Персональные данные</td>
                        </tr>
                        <tr>
                            <td>Право на отзыв согласия на Обработку</td>
                            <td>В любой момент Пользователь может отозвать свое согласие на Обработку</td>
                        </tr>
                        <tr>
                            <td>Право на удаление Персональных данных</td>
                            <td>Пользователь может запросить удаление данных, которые есть у Оператора относительно Пользователя, за
                исключением случаев, когда Оператор обязан хранить эти данные по закону РФ</td>
                        </tr>
                        <tr>
                            <td>Право на обжалование</td>
                            <td>Пользователь может обжаловать действия или бездействия Оператора</td>
                        </tr>
                        <tr>
                            <td>Право на обращения</td>
                            <td>Пользователь вправе направить Оператору запрос на получение информации об Обработке. Все запросы
                направляются в порядке, предусмотренном Политикой.</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <p>3.2. Пользователь гарантирует:</p>
                <ul className='information__list'>
                    <li>(1) что предоставленные Персональные данные являются достоверными, актуальными и не нарушают
                законодательство РФ;</li>
                    <li>(2) если предоставленные Персональные данные относятся к третьему лицу, то Пользователь получил согласие
                третьего лица на передачу Персональных данных Оператору для Обработки.</li>
                </ul>

                <h2>4. Обязанности оператора</h2>

                <p>4.1. Оператор обязан:</p>
                <ul className='information__list'>
                    <li>(1) предоставлять по запросу Пользователя информацию об Обработке или обоснованный отказ;</li>
                    <li>(2) принимать меры, необходимые и достаточные для выполнения обязанностей, предусмотренных законодательством
                РФ.</li>
                    <li>(3) по требованию Пользователя уточнять обрабатываемые Персональные данные, блокировать или удалять, если
                они являются неполными, устаревшими, неточными, незаконно полученными или ненужными для заявленной цели
                Обработки;</li>
                    <li>(4) обеспечить правомерность Обработки. Если обеспечить правомерность Обработки невозможно, Оператор в срок,
                не превышающий 10 рабочих дней с даты выявления неправомерной Обработки, обязан уничтожить или
                обеспечить уничтожение Персональных данных;</li>
                    <li>(5) в срок, не превышающий 30 дней с даты поступления от Пользователя отзыва согласия или достижения цели
                обработки персональных данных уничтожить Персональные данные. Исключение – когда Обработка должна быть
                продолжена в соответствии с законодательством РФ.</li>
                </ul>

                <p>4.2. Для защиты Персональных данных Оператор использует необходимые правовые, организационные и технические
                меры в соответствии с требованиями Федерального закона от 27.07.2007 №152-ФЗ &quot;О персональных
                данных&quot;.</p>

                <h2>5. Обращения Субъекта персональных данных</h2>



                <p>5.1. Пользователь, его представитель вправе направить Оператору свои обращения/запросы, в том числе
                относительно Обработки, отозвать согласие на Обработку в форме электронного документа по адресу
                электронной почты: <a className='information__link' href="mailto://info@targetads.io">info@targetads.io</a>.</p>
                <p>5.2. Запрос должен содержать следующую информацию:</p>
                <ul className='information__list'>
                    <li>(1) сведения о документе, удостоверяющего личность Пользователя;</li>
                    <li>(2) сведения, подтверждающие участие Пользователя в отношениях с Оператором – номер телефона, адрес
                электронной почты;</li>
                    <li>(3) данные представителя и подтверждение его полномочий, если обращается представитель Пользователя.</li>
                    <li>(4) подпись Пользователя, его представителя.</li>
                </ul>
                <p>5.3. Оператор рассмотрит и направит ответ на запрос в течение 10 рабочих дней с момента поступления
                обращения.</p>
                <p>5.4. Вся корреспонденция, полученная Оператором. в том числе обращения в письменной или электронной форме,
                относится к информации ограниченного доступа и не разглашается без письменного согласия Пользователя.</p>

                <h2>6. Заключительные положения</h2>
                <p>6.1. Действие Политики распространяется исключительно на Сервис и не применяется к другим интернет-ресурсам.</p>
                <p>6.2. Политика действует в отношении всех Персональных данных, которые Оператор получает от Пользователей.</p>
                <p>6.3. Оператор не несет ответственность за действия третьих лиц, получивших доступ к Персональным данным
                Пользователя по его вине.</p>
                <p>6.4. Оператор не проверяет:</p>
                <ul className='information__list'>
                    <li>(1) дееспособность Пользователя;</li>
                    <li>(2) достоверность предоставленных Пользователем Персональных данных.</li>
                </ul>
                <p>6.5. Оператор вправе вносить изменения в Политику. Новая редакция Политики вступает в силу с момента ее
                публикации.</p>
                <p>6.6. Если Пользователь продолжит использовать Сервис после вступления в силу новой редакции Политики, это
                будет означать, что он согласился с условиями новой редакции.</p>

                <h2>Реквизиты Оператора</h2>
                <p>ООО &quot;ТАРГЕТ АДС&quot;</p>
                <p>адрес: 108849, Г.МОСКВА, УЛ. БОРИСА ПАСТЕРНАКА, Д. 29, К. 1, КВ. 128</p>
                <p>ИНН: 7751207185</p>
                <p>КПП: 775101001</p>
                <p>ОГРН: 1217700478412</p>
            </div>
        </SimpleBase>
    )
}

export default PersonalDataPolicy;