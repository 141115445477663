import {uploadCsv} from "../../../../actions/upload";
import {sendSdk} from "../../../../utils";

export const updateUploadedFiles = (
    files:any,
    scale:string,
    name: string,
    projectId:number,
    setIsLoading: any,
    dispatch: any,
    userId: number,
    language: string = 'ru'
) => {
    const formData = new FormData();
    formData.append('report_file', files[0]);
    if (formData && projectId) {
        setIsLoading(true);
        dispatch(uploadCsv({
            data: formData,
            projectId: +projectId,
            reportType: name,
            date_scale: scale,
            language
        })).then(() => {
            setIsLoading(false);
            sendSdk('event', {
                'event_type': 'upload',
                'event_category': 'upload_'+name,
                'event_name': 'active',
                'event_value': '10',
                'event_params': {
                    'project_id': projectId,
                    'user_id': userId
                }
            });
        })
    }
}