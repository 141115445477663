import {en} from './en';
import {ru} from './ru';

const _locales:any = {
    en: en,
    ru: ru
}

export const trans= (
  phrase:string,
  lang:string='ru'
):string => {
    const result = lang && _locales[lang][phrase];
    if ( result === undefined ) return phrase;
    return result;
};