import '../style.scss';
import screenBtn from '../../../assets/docs/create_project/btn.png';
import screenStep1 from '../../../assets/docs/create_project/step1.png';
import screenStep2 from '../../../assets/docs/create_project/step2.png';
import screenStep3 from '../../../assets/docs/create_project/step3.png';
import screenStep4 from '../../../assets/docs/create_project/step4.png';
import Code from "../../Code";
import {useRef} from "react";
import {linkTo} from "../../../utils";

const DocsCreateProject = () => {
    const first = useRef<HTMLDivElement | null>(null);
    const second = useRef<HTMLDivElement | null>(null);
    const third = useRef<HTMLDivElement | null>(null);
    const fourth = useRef<HTMLDivElement | null>(null);
    const five = useRef<HTMLDivElement | null>(null);
    const second_one = useRef<HTMLDivElement | null>(null);
    const third_one = useRef<HTMLDivElement | null>(null);
    const third_two = useRef<HTMLDivElement | null>(null);
    const third_three = useRef<HTMLDivElement | null>(null);

    return(
        <div className='docs__wrapper'>
            <ol className='docs__head'>
                <li>
                    <button onClick={()=>first.current && linkTo(first.current)}>1. Создание проекта</button>
                </li>
                <li>
                    <button onClick={()=>second.current && linkTo(second.current)}>2. Создание, настройка, установка пикселя</button>
                    <ol>
                        <li>
                            <button onClick={()=>second_one.current && linkTo(second_one.current)}>1. Базовая инициализация счетчика</button>
                        </li>
                    </ol>
                </li>
                <li>
                    <button onClick={()=>third.current && linkTo(third.current)}>3. Дополнительные возможности пикселя</button>
                    <ol>
                        <li>
                            <button onClick={()=>third_one.current && linkTo(third_one.current)}>1. Передача кастомных параметров</button>
                        </li>
                        <li>
                            <button onClick={()=>third_two.current && linkTo(third_two.current)}>2. Передача целевых событий</button>
                        </li>
                        <li>
                            <button onClick={()=>third_three.current && linkTo(third_three.current)}>3. Настройка пикселя для e-commerce</button>
                        </li>
                    </ol>
                </li>
                <li>
                    <button onClick={()=>fourth.current && linkTo(fourth.current)}>4. Интеграция с сторонними рекламными системами</button>
                </li>
                <li>
                    <button onClick={()=>five.current && linkTo(five.current)}>5. Настройка атрибуции для отчетности</button>
                </li>
            </ol>
            <h2 ref={first}>1. Создание проекта</h2>
            <p>Создать новый проект можно на странице “Проекты”, нажав “+” в левом верхнем углу, справа от фильтра.</p>
            <img src={screenBtn} alt=""/>
            <p>Заполняем поля “Название проекта”, “Адрес сайта” и указываем Валюту, для отображения денежных показателей в отчетах.</p>
            <img src={screenStep1} alt=""/>

            <h2 ref={second}>2. Создание, настройка и установка пикселя</h2>
            <p>Скопируйте код пикселя из окна, в нём уже прописан ID вашего проекта и вносить никаких изменений в код не требуется.</p>
            <p>Код счетчика нужно устанавливать в HTML-код всех страниц сайта.</p>
            <p>Код необходимо разместить в пределах тегов:</p>
                <Code code={`<head></head>`} widthAuto={true} copy={false}/>
            <p>или</p>
                <Code code={`<body></body>`} widthAuto={true} copy={false}/>
            <p>Главное, как можно ближе к началу страницы - так пиксель будет раньше загружаться и сможет отправить данные о просмотре в TargetAds, даже если посетитель почти сразу же закроет страницу.</p>
            <img src={screenStep2} alt=""/>
            <p>После установки пикселя вы можете проверить корректность его установки, нажав кнопку “Проверить”.</p>

            <h3 ref={second_one}>2.1 Базовая инициализация счетчика</h3>
            <p>Вставьте следующий код как можно ближе к началу страницы, желательно разместить в теге &lt;HEAD&gt;</p>
            <Code code={`<script src='https://cdn.targetads.io/websdk/index.js'></script>
<script>
  window.targetAdsDataLayer = window.targetAdsDataLayer ||
    function _targetadsTag(){window.targetAdsDataLayer.data = arguments;}
  _targetadsTag('init', "<id проекта>")
  _targetadsTag('page_view');
</script>`} />
            <p><em><strong>id проекта</strong></em> - ваш id проекта в системе TargetADS</p>
            <p>Данным кодом вы отправляете на сервер событие о просмотре страницы. После установки кода на сайте вы уже сможете собирать базовые метрики с сайта, такие как просмотры страниц, уникальные пользователи и количество сессий по источникам трафика.</p>

            <h2 ref={third}>3. Дополнительные возможности пикселя</h2>
            <h3 ref={third_one}>3.1 Передача кастомных параметров</h3>
            <p>Функция отправки принимает два параметра: тип события (string) и объект, описывающий это событие. Для события page_view (просмотр страницы) в объект, описывающий событие, можно передать параметры юзера (user) и параметры события (event_params).</p>
            <p>Параметры сущностей также представляют собой объекты описывающие эту сущность, где ключ является названием параметра, а значение, доступное по нему, значением параметра. Исключение составляет ключ ‘uid’ у объекта user, в него передается значение userId</p>
            <p>Например, в параметры пользователя вы можете передать сегмент пользователя, лояльность пользователя, дату предыдущего заказа и многое другое, все зависит от типа вашего бизнеса и тех параметров, по которым вы хотите группировать и фильтровать пользователей. Максимально можно передавать до 5 параметров каждого типа.</p>
            <Code code={`<script src='https://cdn.targetads.io/websdk/index.js'></script>
<script>
  window.targetAdsDataLayer = window.targetAdsDataLayer ||
    function _targetadsTag(){window.targetAdsDataLayer.data = arguments;}
  _targetadsTag('init', "<id проекта>")
  _targetadsTag('page_view');
</script>`} />
            <p><strong>ID проекта</strong> - ваш id проекта в системе TargetADS</p>
            <p><strong>User</strong> - имя объекта описывающее параметры пользователя.</p>
            <p><strong>User_id</strong> - ваш внутренний id пользователя. Если не передать данный параметр, то остальные переданные параметры пользователя могут работать некорректно.</p>
            <p><strong>Параметр пользователя</strong> - название параметра пользователя.</p>
            <p><strong>Параметр события</strong> - название параметра события.</p>
            <p><strong>Значение параметра</strong> - значение параметра, соответствующее типу и названию параметра, по которому оно доступно.</p>

            <h3 ref={third_two}>3.2 Передача целевых событий</h3>
            <p>Для того, чтобы передать целевое событие, при срабатывании целевого действия, вам необходимо вызвать следующий метод:</p>
            <Code code={`<script src='https://cdn.targetads.io/websdk/index.js'></script>
<script>
  window.targetAdsDataLayer = window.targetAdsDataLayer ||
    function _targetadsTag(){\`{window.targetAdsDataLayer.data = arguments;}\`}
  _targetadsTag('init', "<id проекта>")
  _targetadsTag('page_view');
</script>`} />
            <p>Метод принимает объект со следующими значениями:</p>
            <p><strong>Тип события</strong> - значения типа события, может быть произвольное значение, предпочтительно латинскими буквами</p>
            <p><strong>Категория события</strong> - значение категории события, может быть произвольное значение, предпочтительно латинскими буквами. Обязательный параметр.</p>
            <p><strong>Название события</strong> - значение названия события, может быть произвольное значение, предпочтительно латинскими буквами. Обязательный параметр.</p>
            <p><strong>Ценность события</strong> - значение ценности события, принимает значения с плавающей точкой. Отображаться в интерфейсе в качестве ценности действия.</p>
            <p>Уникальная комбинация типа, категории и названия события - будет идентифицировать целевое действие и рассчитывать по нему атрибуционные модели.</p>
            <p>Можно передавать до 5 параметров события.</p>

            <h3 ref={third_three}>3.3 Настройки пикселя для e-commerce</h3>
            <p>Модуль электронной коммерции может собирать данные о добавлении конкретного товара в корзину и собирать данные о покупке и товарах в этой покупке</p>
            <h4>Добавление товара в корзину</h4>
            <p>Для вызова события “добавил в корзину”, используйте следующий метод:</p>
            <Code code={`_targetadsTag('add_to_cart', {
  'id': <id товара>,
  'title': '<название продукта>',
  'category1': '<категория 1 продукта>',
  'category2': '<категория 2 продукта>',
  'brand': '<бренд товара>',
  'variant': '<вариант товара>',
  'price': <стоимость товара>
})`} />
            <p>Обязательно должен присутствовать либо ID, либо название товара, все остальные параметры опциональны.</p>
            <h4>Покупка</h4>
            <Code code={`_targetadsTag('add_to_cart', {
  'id': <id товара>,
  'title': '<название продукта>',
  'category1': '<категория 1 продукта>',
  'category2': '<категория 2 продукта>',
  'brand': '<бренд товара>',
  'variant': '<вариант товара>',
  'price': <стоимость товара>
})`} />
            <p>Для вызова события используйте функцию с типом события “purchase” и передайте в нее объект описывающий покупку:</p>
            <p><strong>ID покупки</strong> - обязательный параметр. Уникальный идентификатор продажи.</p>
            <p><strong>Quantity</strong> - общее количество товаров в корзине.</p>
            <p><strong>Amount</strong> - общая сумма покупки.</p>
            <p><strong>Items</strong> - информация о купленных товарах. Массив не может быть пустой. А также в каждом товаре обязательно должен присутствовать либо ID, либо его название.</p>

            <h2 ref={fourth}>4. Интеграции со сторонними рекламными системами</h2>
            <p>При необходимости подключите интеграцию с CRM или рекламными площадками. Это необходимо для добавления в проект данных из сторонних систем. Подключить интеграции можно и после, в настройках проекта.</p>
            <img src={screenStep3} alt=""/>

            <h2 ref={five}>5. Настройка атрибуции для отчетности</h2>
            <p>Настройки атрибуции, которые вы выберите в данном окне, будут применены по умолчанию и данные в отчетах будут отображаться относительно заданных настроек. При этом функционал позволяет менять модель и окно атрибуции в окне с дашбордами.</p>
            <img src={screenStep4} alt=""/>
            {/*<p>Дополнительно о моделях атрибуции можно узнать по ссылки</p>*/}
            <p>После нажатия кнопки “Завершить” создание проекта будет завершено</p>
        </div>
    )
}

export default DocsCreateProject;
