import { createAsyncThunk } from '@reduxjs/toolkit'
import {contentJson, PopupType} from '../data'
import {updateToken} from "../utils";
import {openPopup} from "../redux/slices/popup";

export const getTags = createAsyncThunk(
  'tags/getTags',
  async (
    { projectId }: { projectId: number },
    {dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const response = await API.get(`/api/tags/?project_id=${projectId}`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      return response.data;
    } catch (e: any) {
      if (typeof e.response.data.detail === 'string')
        dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail }));
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const getTagsCampaign = createAsyncThunk(
  'tags/getTagsCampaign',
  async (
    { projectId, campaignId }: { projectId: number, campaignId: number },
    {dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const response = await API.get(`/api/tags/?project_id=${projectId}&campaign_id=${campaignId}`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      return response.data;
    } catch (e: any) {
      if (typeof e.response.data.detail === 'string')
        dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail }));
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const getTagById = createAsyncThunk(
  'tags/getTagById',
  async (
    { tagId }: { tagId: number },
    {dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const response = await API.get(`/api/tags/${tagId}/`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      return response.data;
    } catch (e: any) {
      if (typeof e.response.data.detail === 'string')
        dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail }));
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const createTag = createAsyncThunk(
  'tags/createTag',
  async (
    { data, projectId, campaignId }: { data: JSON, projectId: number, campaignId: number },
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const response = await API.post(`/api/tags?project_id=${projectId}&campaign_id=${campaignId}`, data, contentJson);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      dispatch(getTags({projectId}));
      return response.data.data;
    } catch (e: any) {
      if (typeof e.response.data.detail === 'string')
        dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail }));
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const addTagToCampaign = createAsyncThunk(
  'tags/addTagToCampaign',
  async (
    { data, projectId, campaignId }: { data: JSON, projectId: number, campaignId: number },
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const response = await API.post(`/api/tags?project_id=${projectId}&campaign_id=${campaignId}`, data, contentJson);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      dispatch(getTags({projectId}));
      return response.data.data;
    } catch (e: any) {
      if (typeof e.response.data.detail === 'string')
        dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail }));
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const updateTag = createAsyncThunk(
  'tags/updateTag',
  async (
    { data, projectId, tagId }:
      { data: JSON, userId: number, projectId: number, tagId: number },
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const response = await API.put(`/api/tags/${tagId}/`, data);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      dispatch(getTags({ projectId }));
      return response.data.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data.message);
    }
  }
);

export const deleteTag = createAsyncThunk(
  'tags/deleteTag',
  async (
    { projectId, tagId }: { projectId: number, tagId: number },
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const response = await API.delete(`/api/tags/${tagId}/`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      dispatch(getTags({ projectId }));
      return response.data.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data.message);
    }
  }
);
