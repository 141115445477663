import InputSearch from "../../../ui/InputSearch";
import SelectOptions from "../../../ui/Select/Options";
import {FC, useState} from "react";
import {trans} from "../../../../_locales";
import {useAppSelector} from "../../../../redux/hooks";
import {currentUserLang} from "../../../../redux/slices/user";
import { ReactComponent as FilterIcon} from '../../../../assets/icons/icon-filter-blue.svg';
import { ReactComponent as PinIcon} from '../../../../assets/icons/pin.svg';
import { ReactComponent as SortIcon} from '../../../../assets/icons/sort-arrow.svg';
import cx from "classnames";
import {DefaultObject} from "../../../../interfaces/common.d";
import {IStretchSettingItem, onStretchBlock} from "../../TablePagination/Head/functions";
import FilterColumnByValue from "../../components/FilterColumnByValue";
import Btn from "../../../../ui/Btn";
import "./style.scss";
import {
    IFilterColumnItem,
    IFilterColumns,
    IOnChangeFilter,
    IPinColumns,
    ISearchColumns,
    ISortColumns
} from "../../interfaces";

interface ITableLabelItem {
    title:string
    index:number
    name:string
    fieldType:string
    className?:string,
    activeSort?:ISortColumns
    activeSearch?:ISearchColumns
    activeFilter?:IFilterColumns,
    onFilter?:(r: IOnChangeFilter) => void
    activePin?:IPinColumns,
    onChangePin?:(i:number)=>void,
    offFilter?:boolean
    onStretch?: (setting: IStretchSettingItem) => void,
    lastElementWithType?:boolean,
    isLeft?:boolean,
    isEmptyReturn?:boolean,
    defaultWidth?: number,
    typeKeyFilter?: 'index'|'name',
    isExtendStringFilter?: boolean
}

const  TableLabelItem:FC<ITableLabelItem> = ({
    className,
    title,
    index,
    name,
    fieldType,
    activeFilter={},
    activeSearch={},
    activePin={},
    onChangePin=(i)=>null,
    onFilter=(r)=>null,
    activeSort={},
    offFilter=false,
    onStretch=()=>null,
    lastElementWithType,
    isLeft=false,
    isEmptyReturn=false,
    defaultWidth,
    typeKeyFilter='index',
    isExtendStringFilter
}) => {
    const language = useAppSelector(currentUserLang);
    const [flagSort, setFlagSort] = useState(false);
    const keyFilter = typeKeyFilter === 'index' ? index : name;
    const sortDataset = {
        'ascending': trans('Ascending', language),
        'descending': trans('Descending', language),
        'default': trans('Default', language),
    }
    const onChangeSort = (type:'ascending' | 'descending' | 'default'='default') => {
        setFlagSort(false);
        if(type === 'default') {
            onFilter({
                search: activeSearch,
                filter: activeFilter
            });
        } else {
            onFilter({
                search: activeSearch,
                filter: activeFilter,
                sort: {[keyFilter]: type}
            })
        }
    };
    const onChangeCurrentSearch = (value:string) => {
        const temp = JSON.parse(JSON.stringify(activeSearch));
        temp[keyFilter] = value;
        onFilter({
            search: temp,
            filter: activeFilter,
            sort: activeSort
        });
    }

    const [flagSortByValue, setFlagSortByValue] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const handleMouseEnter = (e:any) => {
       const box = e.target.closest('.table-label-item__title');
       if(box) {
           const elem = box.querySelector('.table-label-item__title-text');
           if(elem && elem.clientHeight > 30) {
               setShowTooltip(true);
           }
       }
    }

    const handleMouseLeave = () => {
       setShowTooltip(false);
    }

    const contentClassName = cx('table-label-item',
        className,
        fieldType === 'string' ? 'search' : '',
        fieldType === 'date' ? 'date' : '',
        lastElementWithType ? 'last' : '',
        activePin[index] ? 'sticky' : ''
    );
    const cellClassName = cx(
        'table-label-item__cell',
        !offFilter && fieldType === 'string' ? 'search' : '',
        !offFilter && fieldType === 'date' ? 'date' : '',
        !offFilter && fieldType === 'string' && isLeft ? 'left' : ''
    )
    const pinClassName = cx(
        'table-label-item__pin',
        activePin[index] ? 'active' : ''
    )
    const titleClassName = cx(
        'table-label-item__title',
        isLeft ? 'left' : ''
    )
    const textTitleClassName = cx(
        'table-label-item__title-text'
    )
    const filterClassName = cx(
        'table-label-item__filter',
        activeFilter[keyFilter] ? 'active' : ''
    )
    const sortClassName = cx(
        'table-label-item__filter',
        activeSort && (keyFilter in activeSort) && activeSort[keyFilter] !== 'default' ? 'active' : ''
    )

    const stretchClassName = cx(
        'table-label-item__stretch'
    )

    const stylesContent = {
        minWidth: defaultWidth?defaultWidth:undefined,
        width: defaultWidth?defaultWidth:undefined,
        maxWidth: defaultWidth?defaultWidth:undefined,
        left: !offFilter && activePin[index] && activePin[index].left?`${activePin[index].left}px`: undefined
    }

    if(isEmptyReturn) {
        return <></>;
    }
    return (
        <div key={index}
             className={contentClassName}
             style={stylesContent}
        >
            <div className={cellClassName}>
                <div
                    className={titleClassName}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    {
                        (()=> {
                            if(!offFilter && fieldType === 'string' && !isExtendStringFilter) {
                                return (
                                    <>
                                        <InputSearch
                                            className={'table-label-item__search'}
                                            name={title ? title : name}
                                            sortBackend={true}
                                            valueSearch={activeSearch[keyFilter]}
                                            onChange={onChangeCurrentSearch}
                                            onChangeValueSearch={onChangeCurrentSearch}
                                        />
                                        <Btn
                                            className={pinClassName}
                                            onClick={() => onChangePin(index)}
                                            isIconBtn={true}
                                            icon={{
                                                Component: PinIcon,
                                                width: 15,
                                                height: 15
                                            }}
                                        />
                                    </>
                                )
                            }
                            return (
                                <div className={textTitleClassName}>
                                    {title ? title : name}
                                </div>
                            )
                        })()
                    }
                    {(showTooltip && fieldType !== 'string' && title && title.toString().length > 23) &&
                        <div className="alt right active">
                            <span>{title ? title : name}</span>
                        </div>
                    }

                    { !offFilter && fieldType !== 'date' &&
                        <>
                            <Btn
                                className={filterClassName}
                                onClick={() => setFlagSortByValue(true)}
                                isIconBtn={true}
                                icon={{
                                    Component: FilterIcon,
                                    width: 15,
                                    height: 15
                                }}
                            />
                            <FilterColumnByValue
                                type={fieldType === 'string' ? 'string' : 'number'}
                                name={keyFilter}
                                activeFilter={activeFilter}
                                onChange={(res = {}) => {
                                    onFilter({
                                        search: activeSearch,
                                        filter: res,
                                        sort: activeSort
                                    });
                                }}
                                activeFlag={flagSortByValue}
                                onChangeActiveFlag={setFlagSortByValue}
                                isLeft={index === 0}
                                isExtendStringFilter={isExtendStringFilter}
                            />
                            {
                                (fieldType !== 'string' || isExtendStringFilter) &&
                                <>
                                    <Btn
                                        className={sortClassName}
                                        onClick={() => setFlagSort(true)}
                                        isIconBtn={true}
                                        icon={{
                                            Component: SortIcon,
                                            width: 15,
                                            height: 11
                                        }}
                                    />
                                    <SelectOptions
                                        onChange={onChangeSort}
                                        dataWrapper={sortDataset}
                                        activeFlag={flagSort}
                                        setFlagSort={setFlagSort}
                                        activeKey={activeSort && (keyFilter in activeSort) && activeSort[keyFilter] ? activeSort[keyFilter] : 'default'}
                                        isLeft={true}
                                    />
                                </>
                            }
                        </>
                    }
                </div>
                <button
                    className={stretchClassName}
                    onMouseDown={(e) => {
                        const result = onStretchBlock({
                            e,
                            parent: '.table-interactive',
                            selectorParentElements: '.table-interactive-body-row',
                            selectorElements: '.table-interactive-body-row__item',
                            selectorStretches: '.table-label-stretch-item',
                            selectorTitles: '.table-label-item',
                            selectorHeadBlock: '.table-interactive-labels',
                            selectorBodyBlock: '.table-interactive-body',
                            selectorTotalBlock: undefined
                        });
                        onStretch(result)
                    }}
                ></button>
            </div>
        </div>
    )
}

export default TableLabelItem;