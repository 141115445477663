import {FC, useEffect, useState} from "react";
import Input from "../../../ui/Input";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {currentUserLang} from "../../../redux/slices/user";
import Btn from "../../../ui/Btn";
import {IBtn} from "../../../ui/Btn/Btn.d";
import Tooltip from "../../Tooltip";

import './style.scss';
import {isValueObjectInArray} from "../../../utils";

export interface ISelectSearchList {
    id: number,
    name: string
}

interface ISelectSearch {
    name: string,
    value: string,
    label?: string,
    tooltip?: string,
    required?: boolean,
    errorLabel?: string,
    beta?: boolean,
    cb?: any
    placeholder?: string
    isInvalid?:boolean
    wrapper?:ISelectSearchList[],
    wrapperActive?:ISelectSearchList[],
    actionBtn?:IBtn
}

const SelectSearch: FC<ISelectSearch> = ({
    name,
    value,
    label,
    tooltip,
    placeholder,
    required=false,
    errorLabel,
    beta=false,
    cb,
    wrapper=[],
    wrapperActive=[],
    actionBtn
}) => {
    const [search, setSearch] = useState(value);
    const [showList, setShowList] = useState(false);
    const [activeKey, setActiveKey] = useState<number>(-1);
    const [list, setList] = useState<ISelectSearchList[]>(wrapper);
    const [listFiltered, setListFiltered] = useState<ISelectSearchList[]>(list)

    useEffect(()=> {
        setList(wrapper);
        setListFiltered(wrapper)
    }, [wrapper])

    const handleSearch = (data:any) => {
        setSearch(data[name])
        if(!data[name]) {
            setShowList(false);
            setListFiltered(list);
            return;
        }
        const temp = list.filter((item) => {
            return item && item.name.toLowerCase().includes(data[name].toLowerCase()) && !((isValueObjectInArray(wrapperActive, 'name', data[name]))) && !((isValueObjectInArray(wrapperActive, 'name', item.name)))
        });
        setListFiltered(temp);
        if(wrapperActive.length < 5 && (temp.length > 0 || !((isValueObjectInArray(wrapperActive, 'name', data[name]))))) setShowList(true);
        else setShowList(false);
    }

    const onApply = (id:number, name:string) => {
        cb(name, id);
        setActiveKey(id);
        setShowList(false);
        setSearch('')
    }

    return(
        <div className={`select-search`}>
            {label && <span className="select-search__label">{label}{required && <span className='red'>*</span>}{tooltip && <Tooltip text={tooltip}/>}{beta && <span className='beta'>beta</span>}</span>}
            <div className={'select-search__block'}>
                <Input
                    name={name}
                    className='select-search__input'
                    type='text'
                    value={search}
                    onChange={handleSearch}
                    placeholder={placeholder}
                    errorLabel={errorLabel}
                    autoComplete={false}
                />
            </div>
            <div className={`select-search__list ${showList ? 'active' : ''}`}>
                {
                    listFiltered.length ?
                        listFiltered.map((item:any)=> (
                            <Btn
                                key={`${item.id}_${item.name}`}
                                className={`select-search__item ${activeKey === item.id ? 'active':''}`}
                                onClick={()=>onApply(item.id, item.name)}
                                text={item.name}
                            />
                        ))
                    : ''
                }
                {
                    actionBtn && search && !((isValueObjectInArray(listFiltered, 'name', search) || (isValueObjectInArray(wrapperActive, 'name', search)))) && <Btn
                        {...actionBtn}
                        className={`select-search__action`}
                        onClick={()=>{
                            setShowList(false);
                            setSearch('');
                            actionBtn.onClick && actionBtn.onClick(search)
                        }}
                        text={`${actionBtn.text}${search}`}
                        color={'blue'}
                    />
                }
            </div>
        </div>
    )
}

export default SelectSearch;