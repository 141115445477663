import "./IntegrationCard.scss";
import editIcon from '../../assets/icons/edit.svg';
import removeIcon from '../../assets/icons/icon-close-red.svg';

export interface CardProps {
  className?: string;
  id: number;
  img: string;
  title: string;
  name?: string;
  width?: number;
  height?: number;
  onAdd?: () => void;
  onClick?: any;
  edit?: boolean;
  remove?: boolean;
  extra_data?: {
    auth_uri?:string
  }
}

const IntegrationCard = (props: CardProps) => {
  const {
    className,
    id,
    img,
    title,
    name,
    width = 70,
    height = 70,
    onClick,
    edit = false,
    remove = false
  } = props;
  return (
    <div className={`Integration-card Integration-card--${id}`} role={'presentation'} onClick={onClick}>
      <figure>
        <div className='Integration-card__img' style={{width: `${width}px`, height: `${height}px`}}>
          <img alt={title} src={img} width={width} height={height}/>
        </div>
        <figcaption>{title}</figcaption>
        {name && <span className='Integration-card__name'>{name}</span>}
      </figure>
      {edit &&
        <button className={'Integration-card__btn edit'}>
          <img src={editIcon} alt="edit"/>
        </button>
      }
      {remove &&
        <div className={'Integration-card__btn remove'}>
          <img src={removeIcon} alt="remove"/>
        </div>
      }
    </div>
  );
};

export default IntegrationCard;
