import {FC, useEffect, useState} from "react";

import './style.scss';
import SelectSearch, {ISelectSearchList} from "../../ui/SelectSearch";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {currentUserLang} from "../../../redux/slices/user";
import {clearNewTags, newTags, setNewTags, Tag, tags} from "../../../redux/slices/tags";
import {trans} from "../../../_locales";
import {isValueObjectInArray} from "../../../utils";
import iconClose from '../../../assets/icons/icon-close.svg';


interface ITagsAdd {
    name: string,
    value: Tag[],
    label?: string,
    tooltip?: string,
    isRequired?: boolean,
    errorLabel?: string,
    beta?: boolean,
    cb?: any
    placeholder?: string
    isInvalid?:boolean
    projectId?:number
    campaignId?:number
}

const TagsAdd:FC<ITagsAdd> = ({
    name,
    value,
    label,
    tooltip,
    placeholder,
    isInvalid,
    isRequired,
    errorLabel,
    cb,
    beta,
    projectId,
    campaignId
}) => {

    const dispatch = useAppDispatch();
    const language = useAppSelector(currentUserLang);
    const reduxTags = useAppSelector(tags);
    const reduxActiveTags = useAppSelector(newTags);

    useEffect(()=> {
        if(value) {
            dispatch(setNewTags(value))
        }
    }, [])

    const [wrapper, setWrapper] = useState<ISelectSearchList[]>([])
    const [wrapperActive, setWrapperActive] = useState(reduxActiveTags)

    useEffect(()=> {
        setWrapper(reduxTags)
    }, [reduxTags])

    useEffect(()=> {
        if(reduxActiveTags.length) {
            setWrapperActive(reduxActiveTags)
            dispatch(clearNewTags())
        }
    }, [reduxActiveTags])

    const addTag = (name:string, id?:number) => {
        if(projectId && name) {
            if(isValueObjectInArray(wrapperActive, 'name', name)) return;
            const temp = [...wrapperActive];
            temp.push({
                id: id?id:-1,
                name: name,
                project_id: +projectId
            })
            setWrapperActive(temp)
            return;
        }
    }

    const addActiveTag = (name:string, id?:number) => {
        if(projectId && name) {
            if(isValueObjectInArray(wrapperActive, 'name', name)) return;
            const temp = [...wrapperActive];
            temp.push({
                id: id?id:-1,
                name: name,
                project_id: +projectId
            })
            setWrapperActive(temp)
        }
    }

    const removeActiveTag = (name:string) => {
        const temp = [...wrapperActive];
        const item = temp.find((tag) => tag.name  === name);
        const index = item ? temp.indexOf(item) : -1;
        if(index > -1) {
            temp.splice(index, 1);
            setWrapperActive(temp);
        }
    }

    useEffect(()=> {
        cb({[name]: wrapperActive});

    }, [wrapperActive])

    return(
        <div className='tags-add'>
            <SelectSearch
              name={name}
              value={''}
              label={label}
              tooltip={tooltip}
              placeholder={placeholder}
              isInvalid={isInvalid}
              required={isRequired}
              errorLabel={errorLabel}
              cb={addActiveTag}
              beta={beta}
              wrapper={wrapper}
              wrapperActive={wrapperActive}
              actionBtn={{
                  onClick: addTag,
                  text: trans('Create tag', language)+' - '
              }}
            />
            {wrapperActive.length ? <ul className="tags-add__list">
                {
                    wrapperActive.map((item, index) => {
                        return(
                            <li key={`${item.name}_${index}`}>
                                <span>{item.name}</span>
                                <button onClick={()=>removeActiveTag(item.name)} type='button'>
                                    <img src={iconClose} alt="Close"/>
                                </button>
                            </li>
                        )
                    })
                }
            </ul> : ''}
        </div>
    )
}

export default TagsAdd;