import {useEffect} from 'react'
import { useNavigate} from 'react-router-dom'
import {useAppDispatch} from '../../redux/hooks'

const NotFound = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
      navigate('/')
  }, [dispatch, navigate])
    return (
        <></>
    )
}

export default NotFound;