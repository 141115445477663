import { FC, useState, useRef, useEffect } from 'react'
import cx from 'classnames'
import { IFilter } from './Filter.d'
import { Btn, Toggle, Input } from '../../ui'
import { useOnClickOutside } from '../../helpers'
import { ReactComponent as FilterIcon } from '../../assets/icons/icon-filter.svg'
import './Filter.scss'
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {currentUserLang, userId} from "../../redux/slices/user";
import {trans} from "../../_locales";
import {sources} from "../../redux/slices/sources";
import {sendSdk} from "../../utils";
import Select from "../ui/Select";
import {getDashboardsFilterValues} from "../../actions/dashboards";
import {DefaultObject} from "../../interfaces/common.d";

const Filter: FC<IFilter> = ({
  className,
  applyCallback ,
  type,
  geoList={},
  targetingList={},
  projectId,
  campaignId
}) => {
  const language = useAppSelector(currentUserLang);
  const reduxSources = useAppSelector(sources);
  const reduxUserId = useAppSelector(userId);
  const dispatch = useAppDispatch();
  const [isActive, setIsActive] = useState(false);
  const [isApplied, setIsApplied] = useState(true);
  const filterRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<HTMLFormElement>(null);

  useOnClickOutside(() => setIsActive(false), [filterRef]);

  const rangeInputName = 'filter-dates';
  const [rangeTextValue, setRangeTextValue] = useState<string>('');
  const handleRangeChange = (obj: any) => {
    const textValue = obj[rangeInputName];
    setRangeTextValue(textValue);
  };

  const onSubmit = () => {
    if (formRef.current) {
      const formData = new FormData(formRef.current);
      if (applyCallback) {
        applyCallback(formData);
      }

      if(Array.from(formData.values()).some((value) => value)) {
        setIsApplied(true);
      } else {
        setIsApplied(false);
      }

      setIsActive(false);
    }
  }

  const [defaultChecked, setDefaultChecked] = useState<boolean>(true);

  useEffect(() => {
    onSubmit();
    setDefaultChecked(false);

    if(type === 'campaigns' && projectId) {
      dispatch(getDashboardsFilterValues({projectId: projectId, name: 'tags'})).then((r) => {
        if (r.payload) {
          setDatasetTags(r.payload)
          // setReadyValue(true);
        } else {
          // setReadyValue(false);
        }
      })
    }
  }, []);

  const onReset = () => {
    if (formRef.current) {
      formRef.current.reset();
      setTags('');
      setActiveTags([]);

      setSource('');
      setActiveSource([]);

      setRangeTextValue('');
      const formData = new FormData(formRef.current);
      formData.set(rangeInputName, '');
      if (applyCallback) {
        applyCallback(formData);
      }

      setIsApplied(false);
      setIsActive(false);
    }
  }

  const filterClassName = cx('filter', className, {'is-active': isActive});

  const [datasetTags, setDatasetTags] = useState<any>({})
  const [activeTags, setActiveTags] = useState<Array<string>>([])
  const [datasetSource, setDatasetSource] = useState<DefaultObject<string>>({})
  const [activeSource, setActiveSource] = useState<Array<string>>([])

  useEffect(() => {
    if(reduxSources) {
      const temp: any = {};
      reduxSources.map((item, index) => {
        temp[item.name] = item.name;
      });
      setDatasetSource(temp)
    }
  }, [reduxSources]);



  const prepareValue = (dataset:any, active:Array<string>) => {
    return typeof active === 'object' && typeof dataset==='object' ?
        active.map((item:string) => dataset[item] && dataset[item]).join(', ') : '';
  }
  const [tags, setTags] = useState<string>(prepareValue(datasetTags, activeTags));
  const [source, setSource] = useState<string>(prepareValue(datasetSource, activeSource));
  const onChangeValueCheckbox = (response: any, setKeys: any, setTitle:any) => {
    const temp: Array<any> = [];
    const total: Array<any> = [];
    for (const key in response) {
      if (response[key] && response[key].checked) {
        temp.push(response[key].name);
        total.push(key);
      }
    }
    const result = temp.join(', ');
    setKeys(result ? result : '');
    setTitle(total);
  }

  return(
    <div className={filterClassName} ref={filterRef}>
      <Btn
        icon={{
          Component: FilterIcon,
          width: 16,
          height: 18,
        }}
        dot={true}
        active={isApplied}
        isIconBtn={true}
        size='lg'
        onClick={() => setIsActive(a => !a)}
      />
      <div className="filter__wrapper">
        <h2 className="title title--uppercase">{trans('Filter', language)}</h2>
        <form action="#" ref={formRef}>
          <fieldset>
            <legend className="filter__subtitle">{trans('Status', language)}</legend>
            <Toggle
              type={'checkbox'}
              label={trans('Actives', language)}
              name={'filter-active'}
              value={'filter-active'}
              defaultChecked={defaultChecked}
            />
            <Toggle
              type={'checkbox'}
              label={trans('Hold', language)}
              name={'filter-hold'}
              value={'filter-hold'}
            />
            <Toggle
              type={'checkbox'}
              label={trans('Archived', language)}
              name={'filter-archive'}
              value={'filter-archive'}
            />
          </fieldset>
          <fieldset>
            <Input
              className='filter__input'
              type='text'
              name={rangeInputName}
              value={rangeTextValue}
              placeholder={trans('Date range', language)}
              label={trans('Creation date', language)}
              // errorLabel={field.errorLabel}
              // isInvalid={errors[field.name]}
              onChange={handleRangeChange}
              isDateRange={true}
            />
          </fieldset>
          {
            type==='campaigns' &&
            <>
              <fieldset>
                <Select
                    label={trans('Tags', language)}
                    color='light-blue'
                    colorText='dark'
                    widthAuto={false}
                    checkbox={true}
                    selectActive={tags ? tags : ''}
                    dataset={datasetTags ? datasetTags : {}}
                    onChangeActive={(res:any)=>onChangeValueCheckbox(res, setTags, setActiveTags)}
                    listActive={activeTags}
                    search={true}
                    className={'custom-input--search'}
                    position={'right'}
                    maxChecked={5}
                />
                <input type="hidden" name='filter-tags' value={tags} />
              </fieldset>
              {reduxSources.length > 0 && <fieldset>
                <Select
                    label={trans('Source', language)}
                    color='light-blue'
                    colorText='dark'
                    widthAuto={false}
                    checkbox={true}
                    resetCheckbox={true}
                    selectActive={source ? source : ''}
                    dataset={datasetSource ? datasetSource : {}}
                    onChangeActive={(res:any)=>onChangeValueCheckbox(res, setSource, setActiveSource)}
                    listActive={activeSource}
                    search={true}
                    className={'custom-input--search'}
                    position={'right'}
                />
                <input type="hidden" name='filter-sources' value={source} />
                {/*<legend className="filter__subtitle">{trans('Source', language)}</legend>*/}
                {/*{reduxSources.map((item, index) => <Toggle*/}
                {/*    key={index}*/}
                {/*    type={'checkbox'}*/}
                {/*    label={item.name}*/}
                {/*    name={`filter-${item.name}`}*/}
                {/*    value={`filter-${item.name}`}*/}
                {/*    defaultChecked={defaultChecked}*/}
                {/*  />)*/}
                {/*}*/}
              </fieldset>}
              <fieldset>
                <legend className="filter__subtitle">{trans('Purchase type', language)}</legend>
                <Toggle
                  type={'checkbox'}
                  label={trans('CPM', language)}
                  name={'filter-cpm'}
                  value={'filter-cpm'}
                  defaultChecked={defaultChecked}
                />
                <Toggle
                  type={'checkbox'}
                  label={trans('CPC', language)}
                  name={'filter-cpc'}
                  value={'filter-cpc'}
                  defaultChecked={defaultChecked}
                />
                <Toggle
                  type={'checkbox'}
                  label={trans('CPV', language)}
                  name={'filter-cpv'}
                  value={'filter-cpv'}
                  defaultChecked={defaultChecked}
                />
                <Toggle
                  type={'checkbox'}
                  label={trans('FIX', language)}
                  name={'filter-fix'}
                  value={'filter-fix'}
                  defaultChecked={defaultChecked}
                />
              </fieldset>
            </>
          }
          {
            type==='placements' &&
            <>
              <fieldset>
                <legend className="filter__subtitle">{trans('Format', language)}</legend>
                <Toggle
                  type={'checkbox'}
                  label={trans('JPEG', language)}
                  name={'filter-jpeg'}
                  value={'filter-jpeg'}
                  // defaultChecked={defaultChecked}
                />
                <Toggle
                  type={'checkbox'}
                  label={trans('GIF', language)}
                  name={'filter-gif'}
                  value={'filter-gif'}
                  // defaultChecked={defaultChecked}
                />
                <Toggle
                  type={'checkbox'}
                  label={trans('HTML', language)}
                  name={'filter-html'}
                  value={'filter-html'}
                  // defaultChecked={defaultChecked}
                />
                <Toggle
                  type={'checkbox'}
                  label={trans('Video', language)}
                  name={'filter-video'}
                  value={'filter-video'}
                  // defaultChecked={defaultChecked}
                />
                <Toggle
                  type={'checkbox'}
                  label={trans('Other', language)}
                  name={'filter-other'}
                  value={'filter-other'}
                  // defaultChecked={defaultChecked}
                />
              </fieldset>
              {Object.values(geoList).length > 0 && <fieldset>
                <legend className="filter__subtitle">{trans('Geo', language)}</legend>
                {Object.keys(geoList).map((key, index) => <Toggle
                    key={index}
                    type={'checkbox'}
                    label={geoList[key]}
                    name={`filter-${key}`}
                    value={`filter-${key}`}
                    defaultChecked={defaultChecked}
                  />)
                }
              </fieldset>}
              {Object.values(targetingList).length > 0 && <fieldset>
                <legend className="filter__subtitle">{trans('Targeting', language)}</legend>
                {Object.keys(targetingList).map((key, index) => <Toggle
                    key={index}
                    type={'checkbox'}
                    label={targetingList[key]}
                    name={`filter-${key}`}
                    value={`filter-${key}`}
                    defaultChecked={defaultChecked}
                  />)
                }
              </fieldset>}
            </>
          }
          <Btn
            text={trans('Apply', language)}
            color='blue'
            size='sm'
            onClick={()=> {
              onSubmit();
              let category = 'projects';
              if(type) category = type;
              const params:any = {
                'event_type': 'filter',
                'event_category': category,
                'event_name': 'active',
                'event_value': '10',
                'event_params': {
                  'user_id': reduxUserId
                }
              };
              if(projectId) {
                params.event_params.project_id = projectId;
              }
              if(campaignId) {
                params.event_params.campaign_id = campaignId;
              }
              sendSdk('event', params);
            }}
          />
          <Btn
            text={trans('Clear', language)}
            size='sm'
            onClick={onReset}
          />
        </form>
      </div>
    </div>
  )
}

export default Filter