// import cls from './UserControl.module.scss';

import {Input, SearchForm} from "../../../../../ui";
import React, {useEffect, useState} from "react";
import cls from "../PlainSettings/PlainSettings.module.scss";
import iconTime from '../../../../../assets/icons/time.svg'
import iconEdit from '../../../../../assets/icons/edit.svg'
import selectArrow from '../../../../../assets/icons/icon-accordion.svg'
import iconDelete from '../../../../../assets/icons/icon-close-red.svg'
import './style.scss';
import {ModalName, openModal} from "../../../../../redux/slices/modals";
import {useAppDispatch, useAppSelector} from "../../../../../redux/hooks";
import CalendarIcon from "../../../../../assets/icons/icon-calendar.svg";
import {ReactComponent as IconDatabase} from "../../../../../assets/icons/icon-database.svg";
import axios, {AxiosResponse} from "axios";
import {useParams} from "react-router-dom";
import {getProjectAttributions, getProjectGeneral} from "../../../../../actions/project";
import {getProjectUsers, getRoles, updateProjectUser} from "../../../../../actions/projects";
import {IProjectUsers} from "../../../../../interfaces/common.d";
import {currentProjectUsers} from "../../../../../redux/slices/projects";
import {placementsData, placementsLoaded} from "../../../../../redux/slices/placements";
import {currentUser, currentUserLang, userId} from "../../../../../redux/slices/user";
import {getUserInfo} from "../../../../../actions/users";
import {trans} from "../../../../../_locales";
import {removeFromObjectByKey, sendSdk} from "../../../../../utils";
import {projectGeneral} from "../../../../../redux/slices/project";
import Select from "../../../../../components/ui/Select";
import {onChangeGeneralSelect} from "../../../../../components/ui/Select/functions";

interface UserControlProps {
  className?: string;
}

const UserControl = (props: UserControlProps) => {
    const {
        className,
    } = props;
    // postAuth().then(r => {
    //     navigate('/projects');
    // });
    const reduxUser =  useAppSelector(currentUser);
    const reduxUserId =  useAppSelector(userId);
    const {projectId} = useParams();
    const dispatch = useAppDispatch();
    const currentUsers = useAppSelector(currentProjectUsers);
    const language = useAppSelector(currentUserLang);
    const currentProject = useAppSelector(projectGeneral);
    const [tableData, setTableData] = useState(currentUsers);
    const permissions = reduxUser && reduxUser.permission ? reduxUser.permission[`${projectId}`] : undefined;
    const isView = permissions && permissions['can_view'] && permissions['can_view'].includes('settings_user_control') || currentProject && currentProject.owner_id === reduxUserId;
    const isCreate = permissions && permissions['can_create'] && permissions['can_create'].includes('settings_user_control') || currentProject && currentProject.owner_id === reduxUserId;
    const isEdit = permissions && permissions['can_edit'] && permissions['can_edit'].includes('settings_user_control') || currentProject && currentProject.owner_id === reduxUserId;
    const isDelete = permissions && permissions['can_delete'] && permissions['can_delete'].includes('settings_user_control') || currentProject && currentProject.owner_id === reduxUserId;
    const used_roles = permissions && permissions['roles'] ? permissions['roles'] : {};
    const [roles, setRoles] = useState(used_roles)
    useEffect(()=> {
        if (projectId && reduxUserId) {
            sendSdk('page_view', {
                'user': {
                    'uid': reduxUserId
                },
                'event_params': {
                    'project_id': projectId,
                    'user_id': reduxUserId,
                    'page': 'settings_users'
                }
            })
            dispatch(getRoles({projectId: Number(projectId)})).then((r)=> {
                if (Array.isArray(r.payload)) {
                    const temp: any = {}
                    r.payload.map((item:any)=> {
                        temp[item.name] = item.title;
                    })
                    setRoles(temp);
                }
            })
            dispatch(getProjectGeneral({currentUserId: reduxUserId, projectId: Number(projectId)}));
        }
    }, [projectId, reduxUserId])

    const [searchValue, setSearchValue] = useState<string>('');
    const onChangeValueSearch= (value:string) => {
        if (value === '') {
            setTableData(currentUsers);
            setSearchValue('')
            return;
        }
        const regex = new RegExp(value.toLowerCase());
        setSearchValue(value);
        setTableData(currentUsers.filter((item: any) => (regex.test(item.name && item.name.toLowerCase()) || regex.test(item.email.toLowerCase()))));
    }
    useEffect(() => {
        setTableData(currentUsers);
    }, [currentUsers]);
    const [userList, setUserList] = useState<IProjectUsers[]>(currentUsers);
    const setRole = (value:string,id:number, email: string) => {
        const data = {...userList[id], role: value, email: email};
        // setUserList( userList.map(note => {
        //     if(note.id === id) {
        //         return {...note, role: value}
        //     } else {
        //         return note;
        //     }
        // }));
        // setCurrentUser(data);
        updateUser(data);
        setUserList( userList.map(note => {
            if(note.id === data.id) {
                return data
            } else {
                return note;
            }
        }));
    }
    const Tab = {
        include: trans('Include', language),
        exclude: trans('Exclude', language)
    }
    // const [currentUser, setCurrentUser] = useState<any>({});
    // const updateCurrentUser = (user:any) => {
    //     setCurrentUser(user);
    // }
    const updateUser = (data:any) => {
        dispatch(updateProjectUser({data: data, projectId: projectId, userId: data.id}));
        sendSdk('event', {
            'event_type': 'edit',
            'event_category': 'settings_users',
            'event_name': 'user',
            'event_value': '10',
            'event_params': {
                'project_id': projectId,
                'user_id': data.id
            }
        });
    }
    const changeExpires = (value:string|null, email:string, projectId:number, userId:number, index:number) => {
        const data = {...userList[index], expires: value, email: email};
        // setCurrentUser(data);
        updateUser(data);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setUserList( userList.map(note => {
            if(note.id === data.id) {
                return data
            } else {
                return note;
            }
        }));
    }
    const changeTraffic = (name:string, type:string, channel:string, index:number, email:string): void => {
        const data = {...userList[index], traffic_type: type, traffic_name: name, traffic_channel: channel, email: email};
        // setCurrentUser(data);
        updateUser(data);
        setUserList( userList.map(note => {
            if(note.id === data.id) {
                return data
            } else {
                return note;
            }
        }));
    }

    useEffect(() => {
        dispatch(getProjectUsers({projectId: Number(projectId)})).then((r)=> {
            if(r) {
                setUserList(currentUsers)
            }
        })
    }, [dispatch, projectId, setUserList, currentProjectUsers])
    
  return (
    <div className={'user-control'}>
        {(isView && !isEdit && !isCreate && !isDelete) && <div className='user-control__unavailable'>
          {trans('User data is hidden in the project view mode', language)}
        </div>}
      <div className="user-control__header">
        <SearchForm
          value={searchValue}
          onChangeCb={(value) => {
            onChangeValueSearch(value);
          }}
        />
        {(isCreate) && <button className={'user-control__openPopup'} onClick={() => {
            dispatch(openModal({
                name: ModalName.ADD_EMAIL,
                data: {
                    projectId: projectId
                }
            }));
            sendSdk('event', {
                'event_type': 'open',
                'event_category': 'settings_users',
                'event_name': 'create',
                'event_value': '10',
                'event_params': {
                    'project_id': projectId,
                    'user_id': reduxUserId
                }
            });
        }}><span></span></button>}
      </div>
      <div className="user-control-table">
        <div className="user-control-table__head">
            <div className="user-control-table__title">{trans('User', language)}</div>
            <div className="user-control-table__title">{trans('Role', language)}</div>
            {/*<div className="user-control-table__title">{trans('Traffic limit', language)}</div>*/}
            <div className="user-control-table__title"></div>
            <div className="user-control-table__title">{trans('Access expires', language)}</div>
            <div className="user-control-table__title">{trans('Last visit', language)}</div>
        </div>
        <ul className="user-control-table-list">
            {
                tableData.length > 0 ? tableData.map((item, index) => (
                    <li key={item.id}>
                        <div className="user-control-table-list__item">
                            <div className="user-control-table-list__icon">
                                {item.verified ? '' : <img src={iconTime} alt=""/>}
                            </div>
                            <div className="user-control-table-list__user">
                                <div className={`user-control-table-list__block ${((currentProject && item.id === currentProject.owner_id) || ((isView && !isEdit)) || item.id === reduxUserId  || item.role === 'creator') ? 'noedit' : 'noedit'}`}>
                                    <div className="user-control-table-list__wrapper">
                                        <div className="user-control-table-list__name">{(reduxUser.email===item.email || (isEdit || isCreate || isDelete)) ? item.name : trans('Name hidden', language)}</div>
                                        <div className="user-control-table-list__email">{(reduxUser.email===item.email || (isEdit || isCreate || isDelete)) ? item.email : trans('Mail hidden', language)}</div>
                                    </div>
                                    <button className="user-control-table-list__edit" onClick={() =>dispatch(openModal(ModalName.EDIT_USER))}>
                                        <img src={iconEdit} alt=""/>
                                    </button>
                                </div>
                            </div>
                            <div className="user-control-table-list__role">
                                <div className={`user-control-table-list__role-wrapper ${((currentProject && item.id === currentProject.owner_id) || ((isView && !isEdit)) || item.id === reduxUserId || item.role === 'creator') ? 'noedit' : ''}`}>
                                    {(currentProject && item.id === currentProject.owner_id) || item.role === 'creator' ?
                                        <div className="user-control-table-list__text">{trans('Creator', language)}</div>
                                        : item.id === reduxUserId ?
                                            <div
                                                className="user-control-table-list__text">{roles[item.role] ? roles[item.role] : ''}</div>
                                        : (isView && !isEdit) ?
                                        <div className="user-control-table-list__text">{roles[item.role] ? roles[item.role] : ''}</div> :
                                        <>
                                            <Select
                                                selectActive={roles[item.role] ? roles[item.role] : ''}
                                                dataset={removeFromObjectByKey(roles, 'creator')}
                                                onChangeActive={(r:any)=> {
                                                    for (const key in r) {
                                                        setRole(key, index, item.email)
                                                    }
                                                }}
                                                colorText={'dark'}
                                            />
                                            {/*<select value={item.role} onChange={(e)=> setRole(e.target.value, index, item.email)}>*/}
                                            {/*    <option value='admin'>{trans('Admin', language)}</option>*/}
                                            {/*    <option value='view'>{trans('View', language)}</option>*/}
                                            {/*    <option value='specialist'>{trans('Specialist', language)}</option>*/}
                                            {/*</select>*/}
                                            {/*<div className="user-control-table-list__arrow">*/}
                                            {/*    <img src={selectArrow} alt=""/>*/}
                                            {/*</div>*/}
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="user-control-table-list__traffic"></div>
                            {/*<div className="user-control-table-list__traffic">*/}
                            {/*    <div className={`user-control-table-list__traffic-block ${item.role === 'creator' ? 'noedit' : ''}`}>*/}
                            {/*        <div className="user-control-table-list__wrapper">*/}
                            {/*            <div className="user-control-table-list__text">*/}
                            {/*                { item.traffic_type === 'no_limit' ?*/}
                            {/*                    trans('No limit', language) :*/}
                            {/*                    `${(Tab as any)[`${item.traffic_type}`]} -> ${item.traffic_channel} = ${item.traffic_name}`*/}
                            {/*                }*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*        <button className="user-control-table-list__edit" onClick={() =>{*/}
                            {/*            dispatch(openModal({*/}
                            {/*                name: ModalName.LIMIT_TRAFFIC,*/}
                            {/*                data: {*/}
                            {/*                    projectId: projectId,*/}
                            {/*                    trafficType: item.traffic_type,*/}
                            {/*                    trafficChannel: item.traffic_channel,*/}
                            {/*                    trafficName: item.traffic_name,*/}
                            {/*                    email: item.email,*/}
                            {/*                    indexList: index,*/}
                            {/*                    onChange: changeTraffic*/}
                            {/*                }*/}
                            {/*            }))*/}
                            {/*        }}>*/}
                            {/*            <img src={iconEdit} alt=""/>*/}
                            {/*        </button>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="user-control-table-list__access">
                                <div className={`user-control-table-list__access-block ${((currentProject && item.id === currentProject.owner_id) || ((isView && !isEdit)) || item.id === reduxUserId  || item.role === 'creator') ? 'noedit' : ''}`}>
                                    {
                                        (currentProject && item.id === currentProject.owner_id || item.role === 'creator') ?
                                        <div className="user-control-table-list__text">{trans('Unlimited', language)}</div>
                                        : item.id === reduxUserId ?
                                                <div
                                                    className="user-control-table-list__text">{item.expires ? item.expires : trans('Unlimited', language)}</div>
                                                : (isView && !isEdit) ?
                                                    <div
                                                        className="user-control-table-list__text">{item.expires ? item.expires : trans('Unlimited', language)}</div> :
                                                    <div className={'input_section'}>
                                                        <button className={'input user-control-time__btn'} onClick={() => {
                                                            dispatch(openModal({
                                                                name: ModalName.ACCESS_TIME,
                                                                data: {
                                                                    userId: item.id,
                                                                    projectId: projectId,
                                                                    expires: item.expires,
                                                                    email: item.email,
                                                                    indexList: index,
                                                                    onChange: changeExpires
                                                                }
                                                            }));
                                                            sendSdk('event', {
                                                                'event_type': 'edit',
                                                                'event_category': 'settings_users',
                                                                'event_name': 'user_expires',
                                                                'event_value': '10',
                                                                'event_params': {
                                                                    'project_id': projectId,
                                                                    'user_id': item.id
                                                                }
                                                            });
                                                        }}>
                                                            <span>{item.expires ? item.expires : trans('Unlimited', language)}</span>
                                                            <span
                                                                className="user-control-time__icon user-control-time__icon_l">
                                              <img src={CalendarIcon} alt=""/>
                                            </span>
                                                        </button>
                                                    </div>
                                    }
                                </div>
                            </div>
                            <div className="user-control-table-list__visit">
                                <div className="user-control-table-list__text">{item.last_seen}</div>
                            </div>
                            {
                                isDelete && currentProject && item.id !== currentProject.owner_id && item.id !== reduxUserId ?
                                <button className="user-control-table-list__delete" onClick={() => {
                                    dispatch(openModal({
                                        name:ModalName.DELETE_USER,
                                        data: {
                                            email: item.email,
                                            userId: item.id,
                                            projectId: projectId
                                        }
                                    }));
                                    sendSdk('event', {
                                        'event_type': 'delete',
                                        'event_category': 'settings_users',
                                        'event_name': 'user',
                                        'event_value': '10',
                                        'event_params': {
                                            'project_id': projectId,
                                            'user_id': item.id
                                        }
                                    });
                                }}>
                                    <img src={iconDelete} alt=""/>
                                </button> : ''
                            }
                        </div>
                    </li>
                )) : ''
                // currentUsers.length > 0 ? currentUsers.map((item, index) => (
                //     <li key={item.id}>
                //         <div className="user-control-table-list__item">
                //             <div className="user-control-table-list__icon">
                //                 <img src={iconTime} alt=""/>
                //             </div>
                //             <div className="user-control-table-list__user">
                //                 <div className={`user-control-table-list__block ${((currentProject && item.id === currentProject.owner_id) || ((isView && !isEdit)) || item.id === reduxUserId || item.role === 'creator') ? 'noedit' : ''}`}>
                //                     <div className="user-control-table-list__wrapper">
                //                         <div className="user-control-table-list__name">{(reduxUser.email===item.email || (isEdit || isCreate || isDelete)) ? item.name : trans('Name hidden', language)}</div>
                //                         <div className="user-control-table-list__email">{(reduxUser.email===item.email || (isEdit || isCreate || isDelete)) ? item.email : trans('Mail hidden', language)}</div>
                //                     </div>
                //                     <button
                //                         className="user-control-table-list__edit"
                //                         onClick={() => {
                //                             dispatch(openModal(ModalName.EDIT_USER));
                //                             sendSdk('event', {
                //                                 'event_type': 'open',
                //                                 'event_category': 'settings_users',
                //                                 'event_name': 'user_edit',
                //                                 'event_value': '10',
                //                                 'event_params': {
                //                                     'project_id': projectId,
                //                                     'user_id': item.id
                //                                 }
                //                             });
                //                         }}>
                //                         <img src={iconEdit} alt=""/>
                //                     </button>
                //                 </div>
                //             </div>
                //             <div className="user-control-table-list__role">
                //                 <div className={`user-control-table-list__role-wrapper ${((currentProject && item.id === currentProject.owner_id) || ((isView && !isEdit)) || item.id === reduxUserId  || item.role === 'creator') ? 'noedit' : ''}`}>
                //                     {(currentProject && item.id === currentProject.owner_id || item.role === 'creator') ?
                //                         <div className="user-control-table-list__text">{trans('Creator', language)}</div>
                //                         : item.id === reduxUserId ? <div
                //                                 className="user-control-table-list__text">{roles[item.role] ? roles[item.role] : ''}</div>
                //                             :
                //                             <>
                //                                 <Select
                //                                     selectActive={roles[item.role] ? roles[item.role] : ''}
                //                                     dataset={removeFromObjectByKey(roles, 'creator')}
                //                                     onChangeActive={(r: any) => {
                //                                         for (const key in r) {
                //                                             setRole(key, index, item.email)
                //                                         }
                //                                     }}
                //                                     colorText={'dark'}
                //                                 />
                //                             </>
                //                     }
                //                 </div>
                //             </div>
                //             <div className="user-control-table-list__traffic"></div>
                //             {/*<div className="user-control-table-list__traffic">*/}
                //             {/*    <div className={`user-control-table-list__traffic-block ${item.role === 'creator' ? 'noedit' : ''}`}>*/}
                //             {/*        <div className="user-control-table-list__wrapper">*/}
                //             {/*            <div className="user-control-table-list__text">*/}
                //             {/*                { item.traffic_type === 'no_limit' ?*/}
                //             {/*                    trans('No limit', language) :*/}
                //             {/*                    `${(Tab as any)[`${item.traffic_type}`]} -> ${item.traffic_channel} = ${item.traffic_name}`*/}
                //             {/*                }*/}
                //             {/*            </div>*/}
                //             {/*        </div>*/}
                //             {/*        <button className="user-control-table-list__edit" onClick={() =>{*/}
                //             {/*            // setCurrentUser(item);*/}
                //             {/*            dispatch(openModal({*/}
                //             {/*                name: ModalName.LIMIT_TRAFFIC,*/}
                //             {/*                data: {*/}
                //             {/*                    projectId: projectId,*/}
                //             {/*                    trafficType: item.traffic_type,*/}
                //             {/*                    trafficChannel: item.traffic_channel,*/}
                //             {/*                    trafficName: item.traffic_name,*/}
                //             {/*                    indexList: index,*/}
                //             {/*                    onChange: changeTraffic*/}
                //             {/*                }*/}
                //             {/*            }))*/}
                //             {/*        }}>*/}
                //             {/*            <img src={iconEdit} alt=""/>*/}
                //             {/*        </button>*/}
                //             {/*    </div>*/}
                //             {/*</div>*/}
                //             <div className="user-control-table-list__access">
                //                 <div className={`user-control-table-list__access-block ${((currentProject && item.id === currentProject.owner_id) || ((isView && !isEdit)) || item.id === reduxUserId || item.role === 'creator') ? 'noedit' : ''}`}>
                //                     {
                //                         (currentProject && item.id === currentProject.owner_id || item.role === 'creator') ?
                //                         <div className="user-control-table-list__text">{trans('Unlimited', language)}</div>
                //                         : item.id === reduxUserId ?
                //                                 <div className="user-control-table-list__text">{item.expires ? item.expires : trans('Unlimited', language)}</div>
                //                                 :
                //                                 <div className={'input_section'}>
                //                                     <button className={'input user-control-time__btn'} onClick={() => {
                //                                         dispatch(openModal({
                //                                             name: ModalName.ACCESS_TIME,
                //                                             data: {
                //                                                 userId: item.id,
                //                                                 projectId: projectId,
                //                                                 expires: item.expires,
                //                                                 indexList: index,
                //                                                 onChange: changeExpires
                //                                             }
                //                                         }));
                //                                         sendSdk('event', {
                //                                             'event_type': 'edit',
                //                                             'event_category': 'settings_users',
                //                                             'event_name': 'user_expires',
                //                                             'event_value': '10',
                //                                             'event_params': {
                //                                                 'project_id': projectId,
                //                                                 'user_id': item.id
                //                                             }
                //                                         });
                //                                     }}>
                //                                         <span>{item.expires ? item.expires : trans('Unlimited', language)}</span>
                //                                         <span className="user-control-time__icon user-control-time__icon_l">
                //                               <img src={CalendarIcon} alt=""/>
                //                             </span>
                //                                     </button>
                //                                 </div>
                //                     }
                //                 </div>
                //             </div>
                //             <div className="user-control-table-list__visit">
                //                 <div className="user-control-table-list__text">{item.last_seen}</div>
                //             </div>
                //             {
                //                 isDelete && currentProject && item.id !== currentProject.owner_id ?
                //                 <button className="user-control-table-list__delete" onClick={() => {
                //                     dispatch(openModal({
                //                         name:ModalName.DELETE_USER,
                //                         data: {
                //                             email: item.email,
                //                             userId: item.id,
                //                             projectId: projectId
                //                         }
                //                     }));
                //                     sendSdk('event', {
                //                         'event_type': 'delete',
                //                         'event_category': 'settings_users',
                //                         'event_name': 'user',
                //                         'event_value': '10',
                //                         'event_params': {
                //                             'project_id': projectId,
                //                             'user_id': item.id
                //                         }
                //                     });
                //                 }}>
                //                     <img src={iconDelete} alt=""/>
                //                 </button> : ''
                //             }
                //         </div>
                //     </li>
                // ))
            }
        </ul>
      </div>
    </div>
  );
};

export default UserControl;