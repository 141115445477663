import {IFieldItem} from "../interfaces.d";
import Select from "../../ui/Select";
import darkClose from "../../../assets/icons/icon-close.svg";
import React from "react";
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {ReactComponent as IconDrag} from "../../../assets/icons/drag.svg";
import {trans} from "../../../_locales";


const FieldItem = ({
    item,
    dataset,
    datasetBase,
    defaultActive,
    onRemove,
    onChange,
    isInput=false,
    isInnerSelect=false,
    innerSelectDataset={},
    innerSelectActive=trans('Select the value', 'ru'),
    inputDefaultValue=''
}:IFieldItem) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id: item.id});
    const style = {
        transform: CSS.Translate.toString({
            x: transform ? transform.x : 0,
            y: transform ? transform.y : 0,
            scaleX: 1,
            scaleY: 1
        }),
        transition
    };
    return(
        <div
            className="reports-create__field reports-create__field_auto"
            ref={setNodeRef}
            style={transform ? style : {}}
        >
            <div className="reports-create-box__field">
                <button className={'reports-create-box__drag'} {...attributes}{...listeners}>
                    <IconDrag />
                </button>
                 <Select
                    selectActive={(()=>{
                        if(datasetBase[item.name])
                            return datasetBase[item.name];
                        return defaultActive;
                    })()}
                    dataset={dataset}
                    onChangeActive={(r:any)=>onChange(r, item.id)}
                    color={'dark'}
                    widthAuto={true}
                    search={true}
                    input={isInput}
                    inputConfig={{
                        before: datasetBase[item.name],
                        after: '',
                        type: 'string',
                        limit:0,
                        default: inputDefaultValue
                    }}
                    onInput={(r:any)=> {
                        onChange(r, item.id, 'custom');
                    }}
                    size={'xs'}
                    isInnerSelect={isInnerSelect}
                    innerSelectConfig={{
                        before: datasetBase[item.name] + ":",
                        after: '',
                        dataset: innerSelectDataset,
                        selectActive: innerSelectActive,
                        onChangeActive: (r:any)=>onChange(r, item.id, 'event')
                    }}
                />
                <button className='reports-create-box__remove' onClick={()=>onRemove(item.id)}>
                    <img src={darkClose} alt=""/>
                </button>
            </div>
        </div>
    )
}

export default FieldItem;