import { FC, useState, useRef } from 'react'
import cx from 'classnames'
import { IPopup } from './Popup.d'
import { Btn } from '../../ui'
import { DropdownList } from '../index'
import { useOnClickOutside } from '../../helpers'
import './Popup.scss'

const Popup: FC<IPopup> = ({ className, btn, dropdownList, isCenter }) => {
  const [isActive, setIsActive] = useState(false);
  const [isTopView, setIsTopView] = useState(false);
  const popupRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(() => setIsActive(false), [popupRef]);

  const popupClassName = cx(
    'popup',
    className,
    {
      'is-active': isActive,
      'popup--center': isCenter,
      'is-top-view': isTopView
    })

  return (
    <div className={popupClassName} ref={popupRef}>
      <Btn {...btn} onClick={() => setIsActive(a => !a)}/>
      <DropdownList {...dropdownList} closePopup={() =>{
          setIsActive(false)
      }} />
    </div>
  )
}

export default Popup