import {FC, FormEvent, useCallback, useEffect, useState} from 'react'
import cx from 'classnames'
import { Form } from '../index'
import { TypeAuth } from '../../pages/Authorization'
import { Input } from '../../ui'
import {registration, login, resetPassword} from '../../actions/users'
import { useAppDispatch } from '../../redux/hooks';
import {getInitialState, validateOne, validateAll, getLangBrowser, getTimezoneBrowser} from '../../utils';
import { IField } from '../../interfaces/common.d';
import { IAccordion } from '../ModalForm/ModalForm.d';
import { IAuthorizationForm, IAuthForm } from './AuthorizationForm.d'
import './style.scss'
import {trans} from "../../_locales";
import Toggle from "../../ui/Toggle";

const AuthForm: IAuthForm = {
  [TypeAuth.REGISTRATION]: [
    {
      tag: 'input',
      type: 'text',
      name: 'name',
      placeholder: trans('Name', 'ru'),
      errorLabel: trans('Enter the name', 'ru'),
      isRequired: true
    },
    {
      tag: 'input',
      type: 'text',
      name: 'email',
      placeholder: 'E-mail',
      errorLabel: trans('Enter the correct E-mail', 'ru'),
      isRequired: true
    },
    {
      tag: 'input',
      type: 'password',
      name: 'password',
      placeholder: trans('Password', 'ru'),
      errorLabel: trans('Enter the password from 6 to 50 characters', 'ru'),
      isRequired: true,
      isPasswordSwitch: true
    },
    {
      tag: 'input',
      type: 'password',
      name: 'repeat_password',
      placeholder: trans('Repeat password', 'ru'),
      errorLabel: trans('Enter the correct password', 'ru'),
      isRequired: true,
      isPasswordSwitch: true
    },
    {
      tag: 'input',
      type: 'checkbox',
      name: 'privacy-policy',
      placeholder: '',
      errorLabel: '',
      isRequired: true,
      labelWithUrls: [
        {'text': 'Я ознакомился с условиями '},
        {'text': 'Политики обработки персональных данных', href: '/privacy-policy/'},
        {'text': ' и даю '},
        {'text': 'Согласие на обработку персональных данных', href: '/user-agreement/'},
      ]
    },
    {
      tag: 'input',
      type: 'checkbox',
      name: 'advertising-policy',
      placeholder: '',
      errorLabel: '',
      isRequired: false,
      labelWithUrls: [
        {'text': 'Я даю '},
        {'text': 'Согласие на получение рекламной и новостной рассылки', href: '/advertising-policy/'}
      ]
    },
  ],
  [TypeAuth.LOGIN]: [
    {
      tag: 'input',
      type: 'text',
      name: 'email',
      placeholder: 'E-mail',
      errorLabel: trans('Enter the correct E-mail', 'ru'),
      isRequired: true
    },
    {
      tag: 'input',
      type: 'password',
      name: 'password',
      placeholder: trans('Password', 'ru'),
      errorLabel: trans('Enter the correct password', 'ru'),
      isRequired: true,
      isPasswordSwitch: true
    }
  ],
  [TypeAuth.RECOVER]: [
    {
      tag: 'input',
      type: 'text',
      name: 'email',
      placeholder: 'E-mail',
      errorLabel: trans('Enter the correct E-mail', 'ru'),
      isRequired: true
    },
  ]
}

const AuthorizationForm: FC<IAuthorizationForm> = ({
  className,
  type,
  clearForm,
  isRecover
}) => {
  const dispatch = useAppDispatch();

  const [errors, setErrors] = useState(getInitialState<IField[], boolean>(
    AuthForm[type],
    false
  ));
  const [form, setForm] = useState(getInitialState<IField[], string>(
    AuthForm[type],
    ''
  ));

  useEffect(() => {
    if (clearForm) {
      setErrors(
        getInitialState<IField[], boolean>(
          AuthForm[type],
          false
        )
      );
      setForm(
        getInitialState<IField[], string>(
          AuthForm[type],
          ''
        )
      )
    }
  }, [clearForm, type]);


  const handleFieldChange = useCallback((data: {[index: string]: string }) => {
    const name = Object.keys(data)[0];
    setForm({...form, ...data})
    if (errors[name])
      setErrors({...errors, ...validateOne(name, data[name])})
  }, [errors, form]);

  const handleFormSubmit = useCallback((evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    const isValid = validateAll(form, errors, [] as (IField | IAccordion)[], setErrors);
    if (!isValid) return;
    let jsonData = {};
    Object.entries(form)
      .forEach(([name, value]) => {
        jsonData = Object.assign(
          {},
          jsonData,
          { [name]: value }
        )
      });
    if (type === TypeAuth.RECOVER) {
      dispatch(resetPassword(jsonData)).then((r)=> {
        if(r.payload.status === 204) {
          setErrors({...errors, ...{['email']: true}})
          AuthForm[TypeAuth.RECOVER][0].errorLabel = trans('We do not know such E-mail', 'ru');
          return;
        }
        AuthForm[TypeAuth.RECOVER][0].errorLabel = trans('Enter the correct E-mail', 'ru')
      });
      return;
    }
    const formData =  new FormData();
    Object.entries(form)
      .forEach(([name, value]) =>
        formData.append(name, value));

    if (type === TypeAuth.REGISTRATION) {
        const data = {...form, type: 'user', lang: getLangBrowser(), timezone: getTimezoneBrowser()}
        dispatch(registration({data})).then((r)=> {
          if(r.payload === 'User already exists') {
            setErrors({...errors, ...{['email']: true}})
            AuthForm[TypeAuth.REGISTRATION][1].errorLabel = trans('User already exists', 'ru');
            return;
          }
          AuthForm[TypeAuth.REGISTRATION][1].errorLabel = trans('Enter the correct E-mail', 'ru')
        })

      // dispatch(login(form))
      return;
    }
    dispatch(login(form)).then((r)=> {
      if(r.payload === 'Unauthorized') {
        setErrors({...errors, ...{['email']: true}})
        setErrors({...errors, ...{['password']: true}})
        AuthForm[TypeAuth.LOGIN][0].errorLabel = '';
        AuthForm[TypeAuth.LOGIN][1].errorLabel = trans('E-mail or password is invalid', 'ru');
        return;
      }
      AuthForm[TypeAuth.LOGIN][0].errorLabel = trans('Enter the correct E-mail', 'ru');
      AuthForm[TypeAuth.LOGIN][1].errorLabel = trans('Enter the correct password', 'ru');
    })
  }, [errors, form, type, dispatch]);

  return (
    <Form
      className={cx('form--in-auth', {[className as string]: className})}
      submitText={type}
      onSubmit={handleFormSubmit}
      isRecover={isRecover}
    >
      {
        AuthForm[type].length > 0 &&
        AuthForm[type].map((field) =>
          field.type !== 'checkbox' ?
          <Input
            key={field.name}
            className='has-login-input'
            type={field.type}
            name={field.name}
            value={form[field.name]}
            placeholder={field.placeholder}
            isInvalid={errors[field.name]}
            errorLabel={field.errorLabel}
            onChange={handleFieldChange}
            isPasswordSwitch={field.isPasswordSwitch}
          /> :
          <Toggle
              className="form__checkbox"
              key={field.name}
              name={field.name}
              isInvalid={errors[field.name]}
              onChange={(event)=>{
                if(event.target)
                  handleFieldChange({[field.name]: event.target.checked});
              }}
              labelWithUrls={field.labelWithUrls}
              size={'small'}
          />
        )
      }
    </Form>
  );
};

export default AuthorizationForm;
