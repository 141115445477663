import {createAsyncThunk} from "@reduxjs/toolkit";
import {updateToken} from "../utils";


export const setLogData = createAsyncThunk(
  'logs/setLogData',
  async (
    { data }: { data: any },
    { rejectWithValue, extra: API}: any
) => {
    try {
      const response = await API.post(`/api/logs/`, data);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data.detail);
    }
  }
);