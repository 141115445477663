import { FC } from 'react'
// import { ReactComponent as IconHand } from '../../assets/icons/icon-hand.svg'
import { IInfoModal } from './interfaces.d'
import './style.scss'
import Btn from "../../../ui/Btn";

const InfoModal: FC<IInfoModal> = ({
  title,
  description,
  icon,
  subBtn
}) => {
  return (
    <div className="info-modal">
      {
        icon &&
        <div className="info-modal__icon">
          <icon.Component width={icon.width} height={icon.height}/>
        </div>
      }
      <span className="info-modal__title">{title}</span>
      <p className="info-modal__description">{description}</p>
        {
            subBtn &&
            <Btn {...subBtn}/>
        }
    </div>
  );
};

export default InfoModal;
