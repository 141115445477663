import {FC, useEffect, useState} from "react";
import Btn from "../../../ui/Btn";
import { ReactComponent as PlusIcon } from '../../../assets/icons/icon-plus.svg'
import {
    datasetName,
    datasetConditions,
    datasetValue,
    datasetNameEcom,
    datasetConditionsEcom,
    datasetValueEcom
} from './data';
import FilterItem from "./FilterItem";
import './style.scss'
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {clearFilter, dashboardsFilter, setFilter} from "../../../redux/slices/dashboards";
import {currentUserLang} from "../../../redux/slices/user";
import {trans} from "../../../_locales";
import {removeObjectKeys} from "../../../utils";
import {DefaultObject} from "../../../interfaces/common.d";

interface IFilterDashboards {
    onSubmit: any
    projectId: number,
    page?: string
}

const FilterDashboards:FC<IFilterDashboards> = ({
    onSubmit,
    projectId,
    page
}) => {
    const reduxFilter = useAppSelector(dashboardsFilter);
    const language = useAppSelector(currentUserLang);
    const dispatch = useAppDispatch();

    const [filters, NewFilters] = useState<Array<any>>(reduxFilter && reduxFilter.length > 0 ? reduxFilter : [{id:0}]);
    const [filtersChange, setFiltersChange] = useState(reduxFilter && reduxFilter.length > 0);
    const addConditions = () => {
        NewFilters([...filters, {id:filters.length}])
    }
    const [dopName, setDopName] = useState<DefaultObject<any>>({});
    const [dopCondition, setDopCondition] = useState<DefaultObject<any>>({});
    const [dopValue, setDopValue] = useState<DefaultObject<any>>({});

    // useEffect(() => {
    //     if(page === 'ecom') {
    //         setDopName(datasetNameEcom);
    //         setDopCondition(datasetConditionsEcom);
    //         setDopValue(datasetValueEcom);
    //     }
    // }, []);

    const onHandlerChanges = (changes:any, id:number) => {
        try {
            for(const key in changes) {
                if(key === 'name') {
                    if(changes[key]) {
                        NewFilters(prevState =>
                            prevState.map(item =>
                              item.id === id
                                ? { ...item, name: changes[key] }
                                : item
                            )
                        );
                    } else {
                        onRemove(id);
                    }
                }
                if(key === 'condition') {
                    NewFilters(prevState =>
                        prevState.map(item => {
                          if(item.id === id) {
                              if(changes[key])
                                return { ...item, condition: changes[key] };
                              delete item.condition;
                              return item;
                          }
                          return item;
                        })
                    );
                }
                if(key === 'value') {
                    NewFilters(prevState =>
                        prevState.map(item => {
                            try {
                                if (item.id === id) {
                                    if ((typeof changes[key] === 'string') || (typeof changes[key] === 'object'))
                                        return {...item, value: changes[key]};
                                    delete item.value;
                                    return item;
                                }
                                return item;
                            } catch (e) {
                                console.log('Error', e);
                                return item;
                            }
                        })
                    )
                }
                setFiltersChange(true);
            }
        } catch (e) {
            console.log('Error', e);
            setFiltersChange(false);
        }
    }
    const onRemove = (id:number) => {
        try {
            NewFilters(filters.filter(item => item.id !== id));
        } catch (e) {
            console.log('Error', e);
        }
    }
    const onResetFilter = () => {
        dispatch(clearFilter());
        NewFilters([]);
    }

    const onSubmitFilter = () => {
        const result = filters.filter(item => Object.keys(item).length > 3);
        dispatch(setFilter(result));
        onSubmit(result);
    }

    return(
        <div className={'filter-dashboards'}>
            <div className="filter-dashboards__body">

                {filters && filters.length > 0 && filters.map((filter:any) => <FilterItem
                    key={filter.id} {...filter}
                    projectId={projectId}
                    filterName={filter.name}
                    filterCondition={filter.condition}
                    filterValue={filter.value ? filter.value : []}
                    onChange={onHandlerChanges}
                    onRemove={onRemove}
                    datasetName={removeObjectKeys(Object.assign(datasetName, dopName), filters.map((item)=>item.name))}
                    datasetNameBase={Object.assign(datasetName, dopName)}
                    datasetConditions={Object.assign(datasetConditions, dopCondition)}
                    datasetValue={Object.assign(datasetValue, dopValue)}
                />)}

                <div className="filter-dashboards__add">
                    <Btn
                      className='custom-select__add-btn'
                      text={trans('Filter', language)}
                      icon={{
                        Component: PlusIcon,
                        width: 12,
                        height: 12,
                      }}
                      widthAuto={true}
                      color='dark'
                      onClick={addConditions}
                    />
                </div>
            </div>


            <div className="filter-dashboards__btn">
                <Btn
                    text={trans('Apply', language)}
                    color={filtersChange ? 'blue' : 'light-blue'}
                    onClick={onSubmitFilter}
                    disabled={!filtersChange}
                />
                {filtersChange && <Btn
                    text={trans('Clear', language)}
                    color={'light-blue'}
                    onClick={onResetFilter}
                />}
            </div>

        </div>
    )
}

export default FilterDashboards;