import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '..'
import {
  checkAuthorization,
  login,
  logout,
  registration,
  resetPassword,
  newPassword,
  getUserInfo,
  updateUserName,
  updateUserPassword,
  updateUserEmail,
  logoutUsersDevices
} from '../../actions/users';
import {DefaultObject} from "../../interfaces/common.d";

export interface CurrentUser {
  id: number,
  name: string,
  last_name?: string,
  email: string,
  role: string,
  verified: boolean,
  type: string,
  site: string,
  created_at: string,
  activity?: any,
  roles?: any
  last_seen?: string | null
  lang?:string
  timezone?:string
  can_create_project?:boolean
  permission?: DefaultObject<DefaultObject<any>>
}

export interface UserState {
  currentUser: CurrentUser
  isLoading: boolean
  checkingAuth: boolean
  error: string | null
}

export enum UserStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

const initialState: UserState = {
  currentUser: {} as CurrentUser,
  isLoading: false,
  checkingAuth: false,
  error: null
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (
      state,
      action: PayloadAction<CurrentUser>
    ) => {
      state.currentUser = action.payload;
      state.error = null;
    },
    clearUser: (
      state
    ) => {
      if (localStorage.getItem('token')) localStorage.removeItem('token');
      state.currentUser = {} as CurrentUser;
      state.error = null;
    },
    addError: (
      state,
      action: PayloadAction<string>
    ) => {
      state.error = action.payload;
    },
    removeError: (state) => {
      state.error = null;
    },
    setCheckingAuth: (state, action: PayloadAction<boolean>) => {
      state.checkingAuth = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      login.fulfilled,
      (
        state,
        action: PayloadAction<CurrentUser>
      ) => {
      state.currentUser = action.payload;
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(
      login.pending,
      (
        state
      ) => {
      state.isLoading = true;
    });
    builder.addCase(
      login.rejected,
      (
        state,
        action: PayloadAction<any>
      ) => {
      state.isLoading = false;
      state.error = action.payload
    });
    builder.addCase(
      logout.fulfilled,
      (
        state
      ) => {
        state.isLoading = false;
        state.error = null;
        if (localStorage.getItem('token')) localStorage.removeItem('token');
        state.currentUser = {} as CurrentUser;
      });
    builder.addCase(
      logout.pending,
      (
        state
      ) => {
        state.isLoading = true;
      });
    builder.addCase(
      logout.rejected,
      (
        state,
        action: PayloadAction<any>
      ) => {
        state.isLoading = false;
        state.error = action.payload
      });
    builder.addCase(
      checkAuthorization.fulfilled,
      (
        state,
        action: PayloadAction<CurrentUser>
      ) => {
      if (!action.payload.id) return;
      state.currentUser = action.payload;
      state.isLoading = false;
      state.checkingAuth = false;
      state.error = null;
    });
    builder.addCase(
      checkAuthorization.pending,
      (
        state
      ) => {
      state.isLoading = true;
    });
    builder.addCase(
      checkAuthorization.rejected,
      (
        state,
        action: PayloadAction<any>
      ) => {
      state.isLoading = false;
      state.checkingAuth = false;
      state.error = action.payload
    });
    builder.addCase(
      registration.fulfilled,
      (
        state,
        action: PayloadAction<CurrentUser>
      ) => {
        state.currentUser = action.payload;
        state.isLoading = false;
        state.error = null;
    });
    builder.addCase(
      registration.pending,
      (
        state
      ) => {
      state.isLoading = true;
    });
    builder.addCase(
      registration.rejected,
      (
        state,
        action: PayloadAction<any>
      ) => {
      state.isLoading = false;
      state.error = action.payload
    });
    builder.addCase(
      resetPassword.fulfilled,
      (
        state,
        action: PayloadAction<CurrentUser>
      ) => {
        state.currentUser = action.payload;
        state.isLoading = false;
        state.error = null;
    });
    builder.addCase(
      resetPassword.pending,
      (
        state
      ) => {
      state.isLoading = true;
    });
    builder.addCase(
      resetPassword.rejected,
      (
        state,
        action: PayloadAction<any>
      ) => {
      state.isLoading = false;
      state.error = action.payload
    });
    builder.addCase(
      newPassword.fulfilled,
      (
        state,
        action: PayloadAction<CurrentUser>
      ) => {
        state.currentUser = action.payload;
        state.isLoading = false;
        state.error = null;
    });
    builder.addCase(
      newPassword.pending,
      (
        state
      ) => {
      state.isLoading = true;
    });
    builder.addCase(
      newPassword.rejected,
      (
        state,
        action: PayloadAction<any>
      ) => {
      state.isLoading = false;
      state.error = action.payload
    });
    builder.addCase(
      getUserInfo.fulfilled,
      (
        state,
        action: PayloadAction<CurrentUser>
      ) => {
      state.currentUser = action.payload;
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(
      getUserInfo.pending,
      (
        state
      ) => {
      state.isLoading = true;
    });
    builder.addCase(
      getUserInfo.rejected,
      (
        state,
        action: PayloadAction<any>
      ) => {
      state.isLoading = false;
      state.error = action.payload
    });
    builder.addCase(
      updateUserName.fulfilled,
      (
        state
      ) => {
        state.isLoading = false;
        state.error = null;
    });
    builder.addCase(
      updateUserName.pending,
      (
        state
      ) => {
      state.isLoading = true;
    });
    builder.addCase(
      updateUserName.rejected,
      (
        state,
        action: PayloadAction<any>
      ) => {
      state.isLoading = false;
      state.error = action.payload
    });
    builder.addCase(
      updateUserPassword.fulfilled,
      (
        state
      ) => {
        state.isLoading = false;
        state.error = null;
    });
    builder.addCase(
      updateUserPassword.pending,
      (
        state
      ) => {
      state.isLoading = true;
    });
    builder.addCase(
      updateUserPassword.rejected,
      (
        state,
        action: PayloadAction<any>
      ) => {
      state.isLoading = false;
      state.error = action.payload
    });
    builder.addCase(
      updateUserEmail.fulfilled,
      (
        state
      ) => {
        state.isLoading = false;
        state.error = null;
    });
    builder.addCase(
      updateUserEmail.pending,
      (
        state
      ) => {
      state.isLoading = true;
    });
    builder.addCase(
      updateUserEmail.rejected,
      (
        state,
        action: PayloadAction<any>
      ) => {
      state.isLoading = false;
      state.error = action.payload
    });
    builder.addCase(
      logoutUsersDevices.fulfilled,
      (
        state
      ) => {
        state.isLoading = false;
        state.error = null;
    });
    builder.addCase(
      logoutUsersDevices.pending,
      (
        state
      ) => {
      state.isLoading = true;
    });
    builder.addCase(
      logoutUsersDevices.rejected,
      (
        state,
        action: PayloadAction<any>
      ) => {
      state.isLoading = false;
      state.error = action.payload
    });
  }
})

export const {addError, removeError, setUser, clearUser, setCheckingAuth } = userSlice.actions

export const errorValue = createSelector(
  (state: RootState) => state.user,
  (user) => user.error
)

export const userId = createSelector(
  (state: RootState) => state.user,
  (user) => user.currentUser.id
)

export const currentUser = createSelector(
  (state: RootState) => state.user,
  (user) => user.currentUser
)
export const currentUserProfile = createSelector(
  (state: RootState) => state.user,
  (user) => user.currentUser
)

export const currentUserLang = createSelector(
  (state: RootState) => state.user,
  (user) => user.currentUser.lang
)

export const getIsLoading = createSelector(
  (state: RootState) => state.user,
  (user) => user.isLoading
)

export const isAuthed = createSelector(
  (state: RootState) => state.user,
  (user) => (!!user.currentUser.id && user.currentUser.verified)
)

export default userSlice.reducer
