import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import {
    getApiTokens,
    getApiTokenById,
    createApiToken,
    updateApiToken,
    deleteApiToken
} from '../../actions/tokens';

export interface ApiToken {
  id: number
  name: string
  token: string
  author: string
  created_at: string
}

export interface ApiTokensState {
  tokens: ApiToken[]
  isLoading: boolean
  error: string | null
}

const initialState: ApiTokensState = {
  tokens: [] as ApiToken[],
  isLoading: false,
  error: null
}

export const tokensSlice = createSlice({
  name: 'tokens',
  initialState,
  reducers: {
    setApiTokens: (state, action) => {
      state.tokens = action.payload;
    },
    clearApiTokens: (state) => {
      state.tokens = [] as ApiToken[];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      createApiToken.fulfilled,
      (
        state,
        action: PayloadAction<ApiToken>
      ) => {
        // state.sources.push(action.payload);
        // state.newTag = action.payload;
        state.isLoading = false;
        state.error = null;
      });
    builder.addCase
    (createApiToken.pending,
      (state) => {
        state.isLoading = true;
      });
    builder.addCase(
      createApiToken.rejected,
      (state,  action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload
      });
    builder.addCase(
      getApiTokens.fulfilled,
      (state, action: PayloadAction<ApiToken[] | null>) => {
        state.tokens = action.payload ? action.payload : [] as ApiToken[];
        state.isLoading = false;
        state.error = null;
      });
    builder.addCase
    (getApiTokens.pending,
      (state) => {
        state.isLoading = true;
      });
    builder.addCase(
      getApiTokens.rejected,
      (state,  action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload
      });
    builder.addCase(
      getApiTokenById.fulfilled,
      (state) => {
        state.isLoading = false;
        state.error = null;
      });
    builder.addCase
    (getApiTokenById.pending,
      (state) => {
        state.isLoading = true;
      });
    builder.addCase(
      getApiTokenById.rejected,
      (state,  action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload
      });
    builder.addCase(
      updateApiToken.fulfilled,
      (state, action: PayloadAction<ApiToken>) => {
        const prev = state.tokens.find((tag) => tag.id  === action.payload.id);
        const index = prev ? state.tokens.indexOf(prev) : -1;

        if (prev) {
          state.tokens[index] = action.payload;
        }

        state.isLoading = false;
        state.error = null;
      });
    builder.addCase
    (updateApiToken.pending,
      (state) => {
        state.isLoading = true;
      });
    builder.addCase(
      updateApiToken.rejected,
      (state,  action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload
      });
    builder.addCase(
      deleteApiToken.fulfilled,
      (state) => {
        state.isLoading = false;
        state.error = null;
      });
    builder.addCase
    (deleteApiToken.pending,
      (state) => {
        state.isLoading = true;
      });
    builder.addCase(
      deleteApiToken.rejected,
      (state,  action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload
      });
  }
})

export const {
    setApiTokens,
    clearApiTokens
} = tokensSlice.actions

export const tokens = createSelector(
  (state: RootState) => state.tokens,
  (tokens) => tokens.tokens
)

export default tokensSlice.reducer
