import { useEffect } from 'react'
import { PayloadAction } from '@reduxjs/toolkit'
import { useAppDispatch } from '../../redux/hooks'
import { openPopup } from '../../redux/slices/popup'
import { PopupType } from '../../data'

const useShowError = (error: string, clearError?: () => PayloadAction<any>) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!error) return;
    dispatch(openPopup({ type: PopupType.ERROR, description: error }))
    if (clearError) dispatch(clearError());
  }, [error, clearError, dispatch]);
};

export default useShowError
