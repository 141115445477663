import {FC} from 'react'
import {Loader, ModalForm} from '../../index'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import {
  getIsLoading,
  errorValue,
  removeError
} from '../../../redux/slices/access'
import { useShowError } from '../../../helpers'
import {IUpdateIntegrationModal} from "./interfaces.d";

import {currentUserLang} from "../../../redux/slices/user";
import './style.scss';
import {trans} from "../../../_locales";
const UpdateIntegrationModal: FC<IUpdateIntegrationModal> = ({
  projectId,
  dataIntegration,
  fields = [],
  initialValues = {},
  cb,
  onReset
}) => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(getIsLoading);
  const errorMessage = useAppSelector(errorValue);
  const language = useAppSelector(currentUserLang);

  useShowError(errorMessage as string, removeError);
  return (
    <>
      <div className="integrations-modal__block">
        <ModalForm
          submitText={trans('Save', language)}
          resetText={trans('Cancel', language)}
          fields={fields}
          initialValues={initialValues}
          onReset={onReset}
          cb={cb}
        />
      </div>
      {isLoading && <Loader />}
    </>
  );
};

export default UpdateIntegrationModal;
