import React, {useRef} from "react";
import {linkTo} from "../../../utils";
import {trans} from "../../../_locales";
import {useAppSelector} from "../../../redux/hooks";
import {currentUserLang} from "../../../redux/slices/user";

import '../style.scss';
import screenStartCreate from "../../../assets/docs/custom_dashboard/create_start.jpg";
import Note from "../../Note";
import screenFilterSort from "../../../assets/docs/custom_dashboard/filter_sort.jpg";

const DocsCreateCustomDashboard = () => {
    const language = useAppSelector(currentUserLang);
    const first = useRef<HTMLDivElement | null>(null);
    const second = useRef<HTMLDivElement | null>(null);
    const third = useRef<HTMLDivElement | null>(null);
    const fourth = useRef<HTMLDivElement | null>(null);

    return (
        <div className='docs__wrapper'>
            <ol className='docs__head'>
                <li>
                    <button onClick={() => first.current && linkTo(first.current)}>1. Создание отчёта</button>
                </li>
                <li>
                    <button onClick={() => second.current && linkTo(second.current)}>2. Доступные Поля</button>
                </li>
                <li>
                    <button onClick={() => third.current && linkTo(third.current)}>3. Метрики</button>
                </li>
                <li>
                    <button onClick={() => fourth.current && linkTo(fourth.current)}>4. Фильтры и Сортировка</button>
                </li>
            </ol>
            <h2 ref={first}>1. Создание кампании</h2>
            <p>Кастомные отчёты позволяют настроить отчёт только по нужным показателям</p>
            <img src={screenStartCreate} alt=""/>
            <p>Для настройки отчёта необходимо указать:</p>
            <Note
                definitiones={[
                    {name: 'Название отчёта', description: 'нейминг может быть любым'},
                    {
                        name: 'Период',
                        description: 'Текущий или прошлый месяцы, Текущая или прошлая неделя'
                    },
                    {name: 'Группировка', description: 'День, Неделя, Месяц, Год'}
                ]}
            />
            <h2 ref={second}>2. Доступные Поля</h2>
            <p>Разбивки, доступные во кладке <strong>Поля</strong>:</p>
            <Note
                definitiones={[
                    {name: 'Период', description: ''},
                    {name: 'Название Кампании', description: ''},
                    {name: 'SSP', description: ''},
                    {name: 'Домен', description: ''},
                    {name: 'Название Креатива', description: ''},
                    {name: 'Тип Девайса', description: ''},
                ]}
            />
            <h2 ref={third}>3. Метрики</h2>
            <Note
                definitiones={[
                    {name: 'Показы', description: ''},
                    {name: 'GIVT', description: ''},
                    // {name: 'Доля сомнительных показов', description: ''},
                    {name: 'Видимые показы', description: ''},
                    {name: 'Доля видимых показов', description: ''},
                    {name: 'Клики', description: ''},
                    {name: 'Охват', description: ''},
                    {name: 'Часть от экрана', description: ''},
                    {name: 'Видимая часть от экрана', description: ''},
                    {name: 'Видимая часть', description: ''},
                    {name: 'Ширина креатива', description: ''},
                    {name: 'Высота креатива', description: ''},
                    {
                        name: 'Видео метрики',
                        description: 'Старт видео; Старт видео с отключенным звуком; Квартели - 25%, 50%, 75%, Досмотр видео; Закрыто видео; Пропуск видео;'
                    },
                ]}
            />
            <h2 ref={fourth}>4. Фильтры и Сортировка</h2>
            <p><strong>Фильтры</strong> - позволяют получать более детальную информацию - возможности фильтров зависят
                от выбранных разбивок</p>
            <p>Например, отчёт по показам, по конкретному <strong>SSP</strong>, <strong>Домену</strong> и <strong>Типу
                Девайса</strong></p>
            <img src={screenFilterSort} alt=""/>
            <p><strong>Сортировка</strong> - отвечает за упорядоченность данных в таблице и сортирует данные от большего
                к меньшему и на оборот.</p>
        </div>
    )
}

export default DocsCreateCustomDashboard;
