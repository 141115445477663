import {FC, useEffect, useState} from 'react'
import { Loader } from '../../index'
import { Btn, Input} from '../../../ui'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import {
  getIsLoading,
  errorValue,
  removeError
} from '../../../redux/slices/access'
import { fetchAccessList } from '../../../actions/access'
import {currentUserLang} from "../../../redux/slices/user";
import { useShowError } from '../../../helpers'
import { IAccessTimeModal } from './interfaces.d'

import {trans} from "../../../_locales";
import './style.scss';

const AccessTimeModal: FC<IAccessTimeModal> = ({
  userId,
  projectId,
  expires,
  email,
  indexList,
  onChange,
  onClose
}) => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(getIsLoading);
  const errorMessage = useAppSelector(errorValue);
  const language = useAppSelector(currentUserLang);

  useEffect(() => {
    if(userId && projectId)
      dispatch(fetchAccessList({ userId, projectId }));
  }, [userId, projectId, dispatch]);

  useShowError(errorMessage as string, removeError);

  const [accessDate, setAccessDate] = useState(expires ? expires : '');
    const handleFieldChange = (value:any) => {
      setAccessDate(value['user-control_access'])
    }

  const onReset = ()=> {
    setAccessDate('')
  }
  const onSubmit = ()=> {
      if(accessDate === '') {
        onChange(null, email, projectId, userId, indexList)
      } else {
        onChange(accessDate, email, projectId, userId, indexList);
      }
      onClose('ACCESS_TIME');
  }
  return (
    <>
      <div className="access-time__block">
          <Input
              type={'text'}
              name={'user-control_access'}
              value={accessDate}
              placeholder={'____-__-__'}
              onChange={handleFieldChange}
              isDateField={true}
            />
      </div>
      <div className="access-time__action">
        <Btn
          type='reset'
          text={trans('Reset', language)}
          size='lg'
          widthAuto={false}
          onClick={onReset}
        />
        <Btn
          type='submit'
          text={trans('Save', language)}
          color='blue'
          widthAuto={false}
          size='lg'
          disabled={false}
          onClick={onSubmit}
        />
      </div>
      {isLoading && <Loader />}
    </>
  );
};

export default AccessTimeModal;
