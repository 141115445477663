import { ReactComponent as PersonIcon } from '../../../assets/icons/icon-person.svg'
import { ReactComponent as ProjectsIcon } from '../../../assets/icons/icon-projects.svg'
import {trans} from "../../../_locales";
export const Profile = (language?:string) => {
    return [
        {
            btn: {
                ariaLabel: trans('Profile', language),
                href: '#',
                icon: {
                    Component: PersonIcon,
                    width: 13,
                    height: 16,
                }
            },
            isActive: true,
        },
        {
            btn: {
                ariaLabel: trans('Projects', language),
                href: '/projects/',
                icon: {
                    Component: ProjectsIcon,
                    width: 20,
                    height: 20,
                }
            },
        },
    ]
}

export const Projects = (language?:string) => {
    return [
        {
            btn: {
                ariaLabel: trans('Projects', language),
                href: '/projects/',
                icon: {
                    Component: ProjectsIcon,
                    width: 20,
                    height: 20,
                }
            },
            isActive: true,
        }
    ]
}