import { FC, useCallback, useEffect } from 'react'
import { Form, Loader } from '../../index'
import { AccessField } from '../../../ui'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import {
  AccessList,
  getAccessList,
  getIsLoading,
  errorValue,
  removeError
} from '../../../redux/slices/access'
import { addAccess, fetchAccessList, removeAccess } from '../../../actions/access'
import { AnyEvent } from '../../../interfaces/common.d'
import { useShowError } from '../../../helpers'
import { IAddAccessModal } from './interfaces.d'
import './style.scss';

const AddAccessModal: FC<IAddAccessModal> = ({
  userId,
  projectId
}) => {
  const dispatch = useAppDispatch();
  const list = useAppSelector(getAccessList);
  const isLoading = useAppSelector(getIsLoading);
  const errorMessage = useAppSelector(errorValue);

  const currentList = list ? list : [] as AccessList;

  useEffect(() => {
    if(userId && projectId)
      dispatch(fetchAccessList({ userId, projectId }));
  }, [userId, projectId, dispatch]);

  useShowError(errorMessage as string, removeError);

  const handelAccessRemove = useCallback((evt: AnyEvent, email: string) => {
    evt.preventDefault();
    dispatch(removeAccess({ email, userId, projectId }));
  }, [dispatch, userId, projectId]);

  const handelAccessAdd = useCallback((email: string) => {
    dispatch(addAccess({ email, userId, projectId }));
  }, [dispatch, userId, projectId]);

  return (
    <>
      <Form key={projectId}>
        <AccessField
          list={currentList}
          onRemoveClick={handelAccessRemove}
          onAddClick={handelAccessAdd}
        />
      </Form>
      {isLoading && <Loader />}
    </>
  );
};

export default AddAccessModal;
