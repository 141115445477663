import { FC } from 'react'
import cx from 'classnames'
import { IDropdownList } from './DropdownList.d'
import { Btn } from '../../ui'
import { ReactComponent as CheckMarkIcon } from '../../assets/icons/icon-check-mark.svg'
import { ReactComponent as PlusIcon } from '../../assets/icons/icon-plus.svg'
import './DropdownList.scss'
import {trans} from "../../_locales";
import {useAppSelector} from "../../redux/hooks";
import {currentUserLang} from "../../redux/slices/user";

const DropdownList: FC<IDropdownList> = ({
  className,
  type='popup',
  items,
  addBtn,
  closePopup,
}) => {
  const language = useAppSelector(currentUserLang);
  return (
    <div className={cx('dropdown-list', className)}>
      {type === 'popup' && (
        <ul className="dropdown-list__list">
          {items && items.length > 0 && items.map((item, i) => {
            return (
              <li key={i} className={cx('dropdown-list__item', {'is-active': item.isActive})}>
                <Btn
                  {...item}
                  color='light-blue'
                  widthAuto={false}
                  onClick={(evt) => {
                    if (closePopup) {
                      closePopup();
                    }
                    if (item.onClick) {
                      item.onClick(evt);
                    }
                  }}
                />
              </li>
            )
          })}
        </ul>
      )}

      {type === 'checkbox' && items && items.length > 0 && items.map((item, i) => {
        return (
          <label key={i} className={cx('dropdown-list__item', {'is-active': item.isActive})}>
            <span className="dropdown-list__title">{item.text}</span>
            <span className="custom-toggle dropdown-list__right">
              <input type="checkbox" name="ui-select" checked={item.inputChecked} />
              <span className="custom-toggle__icon">
                <CheckMarkIcon width={12} height={18}/>
              </span>
            </span>
          </label>
        )
      })}

      {addBtn && (
        <Btn
          className='dropdown-list__add-btn'
          text={trans('Add source', language)}
          icon={{
            Component: PlusIcon,
            width: 12,
            height: 12,
          }}
          color='light-blue'
          size='sm'
        />
      )}
    </div>
  )
}

export default DropdownList