import { isValid, parse } from 'date-fns';
import { ItemStatus } from '../../interfaces/common.d'
import {trans} from "../../_locales";

interface IItemsFilterFunction {
  formData: FormData,
  statusField: string,
  dateField: string,
  nameField: string,
  searchValue: string,
  purchaseType?: 'CPM' | 'CPC' | 'CPV' | 'FIX',
  sourceId?: number,
  reduxSources?: Array<any>
  type?:'campaigns' | 'placements'
  formatField?: 'JPEG' | 'GIF' | 'HTML' | 'Video' | 'Other'
  geoField?:string
  geoList?:any
  targetingField?:string
  targetingList?:any
  language?:string
  tags?:Array<any>
}

const itemsFilterFunction = ({
  formData,
  statusField,
  dateField,
  nameField,
  searchValue,
  purchaseType,
  sourceId,
  reduxSources=[],
  type,
  formatField,
  geoField,
  geoList={},
  targetingField,
  targetingList={},
  language,
  tags
}:IItemsFilterFunction) => {
  const filterActiveValue = formData.get('filter-active');
  const filterHoldValue = formData.get('filter-hold');
  const filterArchiveValue = formData.get('filter-archive');
  const filterDeletedValue = formData.get('filter-deleted');
  const filterDateRangeValue = formData.get('filter-dates');

  const filterCpmValue = formData.get('filter-cpm');
  const filterCpcValue = formData.get('filter-cpc');
  const filterCpvValue = formData.get('filter-cpv');
  const filterFixValue = formData.get('filter-fix');

  const filterJpegValue = formData.get('filter-jpeg');
  const filterGifValue = formData.get('filter-gif');
  const filterHtmlValue = formData.get('filter-html');
  const filterVideoValue = formData.get('filter-video');
  const filterOtherValue = formData.get('filter-other');

  let bySources = true;
  if(formData.get(`filter-sources`)) {
    bySources = false;
    const activeSources = formData.get(`filter-sources`);
    if(typeof activeSources === "string" && reduxSources) {
      const listSources = activeSources.split(', ');
      reduxSources.length > 0 && reduxSources.map((item, index)=> {
        if(listSources.includes(`${item.name}`) && item.id === sourceId) {
          bySources = true;
        }
      })
    }
  }

  let byGeo;
  let countGeo = Object.keys(geoList).length;
  Object.keys(geoList).map((key, index) => {
    if(formData.has(`filter-${key}`) && geoList[key] === geoField) byGeo = true;
    else if(!formData.has(`filter-${key}`)) countGeo -=1;
  })
  if(countGeo === 0) byGeo = true;

  let byTargeting;
  let countTargeting = Object.keys(targetingList).length;
  Object.keys(targetingList).map((key, index) => {
    if(formData.has(`filter-${key}`) && targetingList[key] === targetingField) byTargeting = true;
    else if(!formData.has(`filter-${key}`)) countTargeting -=1;
  })
  if(countTargeting === 0) byTargeting = true;

  let byTags = true;
  if(formData.get(`filter-tags`)) {
    byTags = false;
    const activeTags = formData.get(`filter-tags`);
    if(typeof activeTags === "string" && tags) {
      const listTags = activeTags.split(', ');
      const allTagsNames = tags.map(item=>item.name);
      listTags.map((item)=> {
        if(allTagsNames.includes(item)){
          byTags = true;
        }
      })
    }
  }

  let byStatus;
  if (!(filterActiveValue || filterHoldValue || filterArchiveValue || filterDeletedValue)) {
    byStatus = true;
  } else {
    byStatus = (
      filterActiveValue && statusField === ItemStatus.ACTIVE
      || filterHoldValue && statusField === ItemStatus.INACTIVE
      || filterArchiveValue && statusField === ItemStatus.ARCHIVE
      || filterDeletedValue && statusField === ItemStatus.DELETED)
  }

  let byFormat;
  if (!(filterJpegValue || filterGifValue || filterHtmlValue || filterVideoValue || filterOtherValue)) {
    byFormat = true;
  } else {
    byFormat = (
      filterJpegValue && formatField === 'JPEG'
      || filterGifValue && formatField === 'GIF'
      || filterHtmlValue && formatField === 'HTML'
      || filterVideoValue && (formatField === trans('Video', language) || formatField === 'Video')
      || filterOtherValue && (formatField === trans('Other', language) || formatField === 'Other'))
  }

  let byPurchaseType;
  if (!(filterCpmValue || filterCpcValue || filterCpvValue || filterFixValue)) {
    byPurchaseType = true;
  } else {
    byPurchaseType = (
      filterCpmValue && purchaseType === 'CPM'
      || filterCpcValue && purchaseType === 'CPC'
      || filterCpvValue && purchaseType === 'CPV'
      || filterFixValue && purchaseType === 'FIX')
  }

  let byDate = true;
  let dateFrom;
  let dateTo;
  if (filterDateRangeValue) {
    const rangeArray = (filterDateRangeValue as string).split(' - ');
    dateFrom = parse(rangeArray[0], 'y-MM-dd', new Date());
    dateTo = parse(rangeArray[1], 'y-MM-dd', new Date());
  }

  if (dateFrom && dateTo && isValid(dateFrom) && isValid(dateTo)) {
    byDate = (parse(dateField, 'y-MM-dd', new Date()) >= dateFrom) && (parse(dateField, 'y-MM-dd', new Date()) <= dateTo)
  } else if (dateFrom && isValid(dateFrom)) {
    byDate = (new Date(dateField) === dateFrom)
  }

  const bySearch = (searchValue === '') || nameField.toLowerCase().includes(searchValue.toLocaleLowerCase());

  if(type === 'campaigns')
    return byStatus && byDate && bySearch && byPurchaseType && bySources && byTags;
  if(type === 'placements')
    return byStatus && byDate && bySearch && byFormat && byGeo && byTargeting;
  return byStatus && byDate && bySearch;
}

export default itemsFilterFunction