import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import {addAccess, fetchAccessList, removeAccess} from '../../actions/access'

export type AccessList = string[]

export interface AccessState {
  list: AccessList
  isLoading: boolean
  error: string | null
}

const initialState: AccessState = {
  list: [] as AccessList,
  isLoading: false,
  error: null
}

export const accessSlice = createSlice({
  name: 'access',
  initialState,
  reducers: {
    removeError: (state) => {
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchAccessList.fulfilled,
      (
        state,
        action: PayloadAction<AccessList>
      ) => {
        state.list = action.payload;
        state.isLoading = false;
        state.error = null;
      });
    builder.addCase(
      fetchAccessList.pending,
      (
        state
      ) => {
        state.isLoading = true;
      });
    builder.addCase(
      fetchAccessList.rejected,
      (
        state,
        action: PayloadAction<any>
      ) => {
        state.isLoading = false;
        state.error = action.payload
      });
    builder.addCase(
      addAccess.fulfilled,
      (
        state
      ) => {
        state.isLoading = false;
        state.error = null;
      });
    builder.addCase(
      addAccess.pending,
      (
        state
      ) => {
        state.isLoading = true;
      });
    builder.addCase(
      addAccess.rejected,
      (
        state,
        action: PayloadAction<any>
      ) => {
        state.isLoading = false;
        state.error = action.payload
      });
    builder.addCase(
      removeAccess.fulfilled,
      (
        state
      ) => {
        state.isLoading = false;
        state.error = null;
      });
    builder.addCase(
      removeAccess.pending,
      (
        state
      ) => {
        state.isLoading = true;
      });
    builder.addCase(
      removeAccess.rejected,
      (
        state,
        action: PayloadAction<any>
      ) => {
        state.isLoading = false;
        state.error = action.payload
      });
  }
})

export const { removeError } = accessSlice.actions

export const getAccessList = createSelector(
  (state: RootState) => state.access,
  (access) => access.list
)

export const getIsLoading = createSelector(
  (state: RootState) => state.access,
  (access) => access.isLoading
)

export const errorValue = createSelector(
  (state: RootState) => state.access,
  (access) => access.error
)

export default accessSlice.reducer
