import { createAsyncThunk } from '@reduxjs/toolkit';
import {showPopup, updateToken} from '../utils';

interface AccessListParams {
  userId: number,
  projectId: number,
  refresh?: boolean
}

interface AccessControlParams extends AccessListParams {
  email: string
}

export const fetchAccessList = createAsyncThunk(
  'access/fetchAccessList',
  async (
    { userId, projectId, refresh }: AccessListParams,
    { rejectWithValue, extra: API }: any
  ) => {
    try {
      const response = await API.get(`/api/project/${projectId}/users/`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data.message);
    }
  }
);

export const addAccess = createAsyncThunk(
  'access/addAccess',
  async (
    { email, userId, projectId }: AccessControlParams,
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const data = {'email': email}
      const response = await API.post(`/api/projects/${projectId}/users/`, data);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      showPopup(dispatch, response.data.message as string);
      dispatch(fetchAccessList({ userId, projectId, refresh: true }));
      return response.data.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data.message);
    }
  }
);

export const removeAccess = createAsyncThunk(
  'access/removeAccess',
  async (
    { email, userId, projectId }: AccessControlParams,
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const data = {'email': email};
      const response = await API.post(`/api/projects/${projectId}/users/delete/`, data);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      showPopup(dispatch, response.data.message as string);
      dispatch(fetchAccessList({ userId, projectId, refresh: true }));
      return response.data.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data.message);
    }
  }
);
