import cls from './style.module.scss'
import {ReactComponent as Logo} from "../../../assets/icons/logo.svg";
import {Link, NavLink, Outlet, useNavigate} from "react-router-dom";
import {ReactComponent as RocketIcon} from '../../../assets/icons/rocket_onboarding.svg';
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {fourthDone} from "../../../redux/slices/projects";
import {currentUser, currentUserLang} from "../../../redux/slices/user";
import {trans} from "../../../_locales";
import Btn from "../../../ui/Btn";
import {ModalName, openModal} from "../../../redux/slices/modals";
import {useEffect} from "react";

const ProjectsCreate = () => {
  const stepsDone = useAppSelector(state => state.projects.steps);
  const language = useAppSelector(currentUserLang);
  const user = useAppSelector(currentUser);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const ProjectsCreateSteps = {
    CREATE: trans('Create new project', language),
    COUNTER: trans('Counter code', language),
    INTEGRATIONS: trans('Configuring Integrations', language),
    ATTRIBUTIONS: trans('Configuring Attribution', language),
  }

  const onSubmit = () => {
    dispatch(fourthDone())
    navigate('/projects')
  }

  useEffect(() => {
    if(Object.keys(user).length > 0 && !user.can_create_project) navigate("/projects");
  }, [user]);

  return (
    <>
    <div className={cls.OnBoard}>
      <menu className={cls.sidebar}>
        <Logo className={`${cls.logo} logo`} width={155} height={40} />
        <div className={cls.link_wrapper}>
          {typeof ProjectsCreateSteps === 'object' && Object.values(ProjectsCreateSteps).map((step,i) => (
            <div className={cls.link_item} key={step}>
              <NavLink
                className={({ isActive }) =>
                  [
                    cls.link,
                    isActive ? cls.link_selected : null,
                    stepsDone[i] ? cls.step_done : null,
                  ]
                    .filter(Boolean)
                    .join(" ")
                }
                to={`/projects/create/${i+1}/`}
              >
                <div className={stepsDone[i] ? cls.step_number_done : cls.step_number}>{i + 1}</div> {step}
              </NavLink>
            </div>
          )) }
          <Btn text={trans('Instruction', language)} onClick={()=> dispatch(openModal({
            name: ModalName.DOCS_CREATE_PROJECT,
            data: {}
          }))}/>
          <Link to='/projects' className={cls.close_btn} onClick={onSubmit} >
            {trans('Complete acquaintance', language)}
          </Link>
          <div className={cls.rocket_icon}>
            <RocketIcon />
          </div>
        </div>

      </menu>
        <Outlet/>
    </div>
    </>
  );
};

export default ProjectsCreate;