import {FC, useEffect, useRef, useState} from "react";
import {useOnClickOutside} from "../../../../helpers";
import {trans} from "../../../../_locales";
import {removeSpacesInString} from "../../../../utils";
import Select from "../../../ui/Select";
import {onChangeGeneralSelect} from "../../../ui/Select/functions";
import Input from "../../../../ui/Input";
import Btn from "../../../../ui/Btn";
import {useAppSelector} from "../../../../redux/hooks";
import {currentUserLang} from "../../../../redux/slices/user";
import {DefaultObject} from "../../../../interfaces/common.d";
import {IFilterColumnItem, IFilterColumns} from "../../interfaces";
import cx from "classnames";
import './style.scss';


interface IFilterColumnByValue {
    type: 'number' | 'string',
    className?: string,
    activeFlag?:boolean,
    onChangeActiveFlag:any,
    name: string|number,
    activeFilter: IFilterColumns,
    onChange: (res: IFilterColumns) => void,
    isLeft?: boolean,
    isExtendStringFilter?: boolean
}

const FilterColumnByValue:FC<IFilterColumnByValue> = ({
    type,
    className,
    name,
    activeFlag=false,
    onChangeActiveFlag,
    activeFilter = {},
    onChange,
    isLeft,
    isExtendStringFilter
}) => {
    const language = useAppSelector(currentUserLang);
    const selectRef = useRef<HTMLDivElement>(null);
    useOnClickOutside(() => {
        onChangeActiveFlag(false)
    }, [selectRef]);

    const [value, setValue] = useState<string>(activeFilter[name] ? String(activeFilter[name].value) : '');
    const [condition, setCondition] = useState<string>(activeFilter[name] ? activeFilter[name].condition : '');
    const datasetConditions = {
        more: trans('More', language),
        less: trans('Less', language),
        equal: trans('Equal', language)
    }

    const datasetExtendConditions = {
        equal: trans('Equal', language),
        not_equal: trans('Not equal', language),
        contain: trans('Contain', language),
        not_contain: trans('Not contain', language),
    }
    const [dataset, setDataset] = useState<DefaultObject<string>>(datasetConditions);
    useEffect(() => {
        if(isExtendStringFilter && type === 'string') {
            setDataset(datasetExtendConditions);
        }
    }, [isExtendStringFilter]);

    const onSubmit = () => {
        const temp = JSON.parse(JSON.stringify(activeFilter));
        if(type === 'number' || isExtendStringFilter) {
            temp[name] = {
                condition: condition,
                value: removeSpacesInString(value)
            }
        }
        if(type === 'string' && !isExtendStringFilter) {
            temp[name] = {
                condition: 'no_contain',
                value: ''
            }
        }
        onChange(temp);
        onChangeActiveFlag(false);
    }
    const onReset = () => {
        setCondition('');
        setValue('');
        const temp = JSON.parse(JSON.stringify(activeFilter));
        delete temp[name];
        onChange(temp);
        onChangeActiveFlag(false);
    }

    const contentClassName = cx('number-sort-modal',
        className,
        activeFlag ? 'active' : '',
        isLeft ? 'left' : ''
    );
    return (
        <div className={contentClassName} ref={selectRef}>
            {(type === 'number' || isExtendStringFilter) && <div className="number-sort-modal__choice">
                <Select
                    color='dark'
                    selectActive={(dataset as any)[condition] ? (dataset as any)[condition] : trans('Condition', language)}
                    dataset={dataset}
                    onChangeActive={(res: any) => onChangeGeneralSelect(res, setCondition)}
                />
                <Input
                    name={'value'}
                    type={!isExtendStringFilter ? 'float' : 'string'}
                    value={value}
                    color={'dark'}
                    placeholder={trans('Value', language)}
                    onChange={(e) => setValue(e.value)}
                    isDisabled={!(dataset as any)[condition]}
                />
            </div>}
            {<div className="number-sort-modal__btn">
                <Btn
                    className={'number-sort-modal__submit'}
                    text={(()=>{
                        if(type === 'string' && !isExtendStringFilter)
                            return trans("Doesn't contain empty", language);
                        return trans('Apply', language);
                    })()}
                    color={'blue'}
                    onClick={onSubmit}
                    isfullWidth={true}
                    disabled={type !== 'string' && !(value !== '' && +(removeSpacesInString(value)) >= 0)}
                />
            </div>}
            <div className="number-sort-modal__btn">
                <Btn
                    className={'number-sort-modal__reset'}
                    text={trans('Reset', language)}
                    color={'light-blue'}
                    onClick={onReset}
                    isfullWidth={true}
                />
            </div>
        </div>
    )
}

export default FilterColumnByValue;