import { FC, useEffect, useState } from 'react'
import { ReactComponent as PlusIcon } from '../../assets/icons/icon-plus.svg'
import { IAccessField } from './AccessField.d'
import './AccessField.scss'
import { Btn, Input, Tag } from '../index';
import cx from 'classnames';
import { REG_EMAIL } from '../../data'
import { AnyEvent } from '../../interfaces/common.d'

const AccessField: FC<IAccessField> = ({
  list,
  onRemoveClick,
  onAddClick
}) => {
  const [isActive, setIsActive] = useState(false);
  const [error, setError] = useState(false);
  const [currentValue, setCurrentValue] = useState('');

  const isValid = REG_EMAIL.test(currentValue as string);
  const isCopy = list.includes(currentValue);
  const errorLabel = isCopy ? 'Пользователь уже имеет доступ к проекту' : 'Некорректный email';

  useEffect(() => {
    setError(false);
    setCurrentValue('');
  }, [list]);

  useEffect(() => {
    if (error && isValid && !isCopy)
      setError(false);
  }, [error, isValid, isCopy]);


  const handleResetField = (evt: AnyEvent) => {
    evt.preventDefault();
    setIsActive(false);
    setError(false);
    setCurrentValue('');
  }

  const handleInputChange = (data: {[index: string]: string }) => {
    setCurrentValue(Object.values(data)[0]);
  };

  const handleInputSubmit = (evt: AnyEvent) => {
    evt.preventDefault();
    if (!isValid || isCopy) {
      setError(true);
      return;
    }
    onAddClick(currentValue);
  }
  interface IList {
    created_at: string,
    email: string,
    id: number,
    last_name?: string,
    name: string,
    password: string,
    role: string,
    site: string,
    type: string,
    verified: boolean
  }
  return (
    <div className={cx('input-fieldset', 'custom-input', {['is-active']: isActive})}>
      <p className="custom-input__label">Пользователи&nbsp;(<span>{ list.length }</span>)</p>
      <div className="input-fieldset__wrapper">
        <ul className="input-fieldset__users-list">
          {
            list.length > 0 && list.map((item:IList) =>
              <li key={item.email} className="input-fieldset__users-item">
                <Tag
                  text={item.email}
                  onClick={(evt: AnyEvent) => onRemoveClick(evt, item.email)}
                />
              </li>
            )
          }
        </ul>
        <Btn
          className={'input-fieldset__btn'}
          text='Добавить пользователя'
          color='light-blue'
          size='sm'
          widthAuto={false}
          icon={{
            Component: PlusIcon,
            width: 12,
            height: 12,
          }}
          onClick={() => setIsActive(true)}
        />

        <div className="input-fieldset__access">
          <Input
            type={'text'}
            name={'email'}
            placeholder={'Email'}
            label={'Email'}
            value={currentValue}
            errorLabel={errorLabel}
            isInvalid={error}
            onChange={handleInputChange}
          />

          <div className="double-btn">
            <Btn
              text='Отменить'
              widthAuto={false}
              onClick={handleResetField}
            />
            <Btn
              text='Добавить'
              type='button'
              color='light-blue'
              widthAuto={false}
              onClick={handleInputSubmit}
            />
          </div>
        </div>

      </div>
    </div>
  );
};

export default AccessField;
