import React, { Component, ErrorInfo } from 'react';
import Btn from "../../ui/Btn";
import './style.scss';
import {IHeader} from "../Header/Header.d";
import {store} from "../../redux";
import {setLogData} from "../../actions/logs";

interface ErrorBoundaryProps {
  headerData: IHeader
}
interface ErrorBoundaryState {
  hasError: boolean;
  errorInfo: {
    name: string;
    message: string;
    componentStack: string;
    lineNumber: number;
    timestamp: number;
  } | null;
}

class ErrorBoundary extends Component<any, ErrorBoundaryState> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
      errorInfo: null
    };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { name, message, stack } = error;
    const componentStack = errorInfo.componentStack;
    const timestamp = new Date();

    const errorInfoObj = {
      name,
      message,
      stack,
      component_stack: componentStack
    };

    const data = {
      service: 'front',
      level: 'error',
      timestamp,
      message: errorInfoObj
    }
    if(window.location.hostname.includes('localhost')) {
      //skip
    } else if(window.location.hostname.includes('dev')) {
      //skip
    } else if(window.location.hostname.includes('app')) {
      store.dispatch(setLogData({data}));
    }
  }

  render() {
    if (this.state.hasError) {
      return (
          <div className='wrapper'>
            <div className='error-boundary'>
              <h1>Что-то пошло не так.</h1>
              <Btn
                text={'Обновить страницу'}
                onClick={()=> window.location.reload()}
                color={'blue'}
              />
            </div>
          </div>
      )
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
