import {filterObject} from "../../../../utils";
import {openPopup} from "../../../../redux/slices/popup";
import {PopupType} from "../../../../data";
import {trans} from "../../../../_locales";
import {DefaultObject} from "../../../../interfaces/common.d";
import {ICurrentEvents} from "../../../../redux/slices/events";

export const onChangeGeneralSelect = (
    response:object,
    cb:any,
    generalCb?:any,
    isNumber?:boolean
) => {
    if(typeof generalCb === 'function') generalCb();
    for (const key in response) {
        if(isNumber) cb(+key);
        else cb(key);
    }
}

export const getSelectChangesTitle = (
    response: DefaultObject<any>,
    defaultTitle: string
) => {
    const titles: Array<string> = [];
    const keys: Array<string> = [];
    for (const key in response) {
        if (response[key].checked) {
            keys.push(key);
            titles.push(response[key].name);
        }
        if (typeof response[key] === 'string') {
            keys.push(key);
            titles.push(response[key]);
        }
    }

    return {
        title: titles.length ? titles.join(",") : defaultTitle,
        listActive: keys
    }
}

export const onChangeGeneralCheckbox = (response: any) => {
    const temp: Array<any> = [];
    const total: Array<any> = [];
    for (const key in response) {
        if (response[key] && response[key].checked) {
            temp.push(response[key].name);
            total.push(key);
        }
    }
    const result = temp.join(', ');

    return {
        title: result,
        checked: total
    }
}

export const onChangeGeneralCheckboxItem = ({
    checked,
    key,
    dataCheckbox,
    selectActive,
    allNames,
    dispatch,
    minChecked,
    maxChecked,
    language='ru',
    placeholder='Value'
}: {
    checked: boolean,
    key: string,
    dispatch: any,
    dataCheckbox: DefaultObject<any>,
    language?: string,
    placeholder?: string,
    allNames: DefaultObject<string>,
    selectActive: string,
    minChecked?: number,
    maxChecked?: number

}) => {
    if (maxChecked && Object.keys(filterObject(dataCheckbox, (val: any, key: string) => val.checked)).length >= maxChecked) {
        if (!(key in dataCheckbox) || dataCheckbox[key].checked === false) {
            dispatch(openPopup({
                type: PopupType.WARNING,
                description: `${trans('You can select a maximum of', language)} ${maxChecked}`
            }));
            return {};
        }
    }
    const copy = selectActive && selectActive !== trans(placeholder, language) ? JSON.parse(JSON.stringify(dataCheckbox)) : {};
    copy[key] = {
        checked: checked,
        name: (allNames as any)[`${key}`],
        key: key
    };
    if (minChecked && Object.keys(filterObject(copy, (val: any, key: string) => val.checked)).length < minChecked) {
        if ((key in dataCheckbox) && dataCheckbox[key].checked === true && !copy[key].checked) {
            dispatch(openPopup({
                type: PopupType.WARNING,
                description: `${trans('You can select a minimum of', language)} ${minChecked}`
            }));
            return {};
        }
    }
    return {result: copy}
}
