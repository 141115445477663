import {createAsyncThunk} from "@reduxjs/toolkit";
import {closeModal, ModalName, openModal} from "../redux/slices/modals";
import {setUpload, clearUpload} from "../redux/slices/upload";
import {updateToken} from "../utils";
import {trans} from "../_locales";
import {getEvents} from "./events";

const REQUEST_TIMEOUT_TO_LOAD = 30000;

export const uploadCsv = createAsyncThunk(
  'upload/uploadCsv',
  async (
    { data, projectId, reportType,date_scale, language }: { data: FormData, projectId: number, reportType:string,date_scale?:string, language?:string },
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const response = await API.post(`/api/reports/project/uploads/?project_id=${projectId}&report_type=${reportType}&date_scale=${date_scale}`, data, {
        timeout: REQUEST_TIMEOUT_TO_LOAD
      });
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      dispatch(setUpload());
      let check_response;
      let count_request = 20;
      const check = setInterval(async () => {
        if(count_request <= 0) {
          clearInterval(check);
          dispatch(clearUpload());
          dispatch(openModal({
            name: ModalName.MESSAGE,
            data: {
              title: trans('Error', language),
              message: trans('Upload timeout limit exceeded', language),
              buttons: {
                cancel: {
                  text: trans('Cancel', language),
                  onClick: () => dispatch(closeModal(ModalName.MESSAGE))
                },
                confirmation: {
                  text: trans('Retry', language),
                  onClick: () => {
                    dispatch(closeModal(ModalName.MESSAGE));
                    dispatch(uploadCsv({data, projectId, reportType, date_scale}));
                  }
                },
              }
            }
          }))
          return;
        }
        if(!(response)) return;
        check_response = await API.get(`/api/reports/status/${response.data.upload_id}/?project_id=${projectId}`);
        const new_token = check_response.headers["x-new-token"];
        updateToken(new_token);
        if(check_response.data.status === 'ready') {
          clearInterval(check);
          dispatch(clearUpload());
          dispatch(getEvents({projectId}))
          dispatch(openModal({
            name: ModalName.MESSAGE,
            data: {
              title: trans('Uploading', language),
              message: check_response.data,
              buttons: {
                confirmation: {
                  text: `${trans('Great', language)}!`,
                  onClick: () => dispatch(closeModal(ModalName.MESSAGE))
                },
              }
            }
          }))
          return check_response.data;
        }
        if(check_response.data.status === 'error') {
          clearInterval(check);
          dispatch(clearUpload());
          dispatch(openModal({
            name: ModalName.MESSAGE,
            data: {
              title: trans('Error', language),
              message: check_response.data,
              buttons: {
                cancel: {
                  text: trans('Cancel', language),
                  onClick: () => dispatch(closeModal(ModalName.MESSAGE))
                },
                confirmation: {
                  text: trans('Retry', language),
                  onClick: () => {
                    dispatch(closeModal(ModalName.MESSAGE));
                    dispatch(uploadCsv({data, projectId, reportType, date_scale}));
                  }
                },
              }
            }
          }))
          return check_response.data;
        }
        count_request--;
      }, 2000);
    } catch (e: any) {
      dispatch(clearUpload());
      dispatch(openModal({
        name: ModalName.MESSAGE,
        data: {
          title: trans('Error', language),
          message: e.response.data.detail,
          buttons: {
            cancel: {
              text: trans('Cancel', language),
              onClick: () => dispatch(closeModal(ModalName.MESSAGE))
            },
            confirmation: {
              text: trans('Retry', language),
              onClick: () => {
                dispatch(closeModal(ModalName.MESSAGE));
                dispatch(uploadCsv({data, projectId, reportType, date_scale}));
              }
            },
          }
        }
      }))
      return rejectWithValue(e.response.data.detail.detail);
    }
  }
);