import {formatDate} from "../../../utils";

export function getDateAgo(date:any, days:number) {
  const dateCopy = new Date(date);

  dateCopy.setDate(date.getDate() - days);
  return dateCopy;
}

export const onDaysAgo = (days:any) => {
    const date = new Date();
    const dateAgo = getDateAgo(date, days);
    return {
        start: formatDate(dateAgo),
        end: formatDate(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1))
    };
}

export function getMonday(date?:Date) {
    const temp_date = date ? new Date(date) : new Date();
    const m = date ? new Date(date) : new Date();
    if (temp_date.getDay()) {
        m.setDate(temp_date.getDate() + 1 - temp_date.getDay())
    } else {
        m.setDate(temp_date.getDate() - 6)
    }
    return m;
}