import {FC, useState} from "react";
import {useNavigate} from "react-router-dom";
import { Loader} from "../../../components";
import {ReactComponent as ChartIcon} from "../../../assets/icons/chart.svg";
import {ReactComponent as LogoIcon} from "../../../assets/icons/logo.svg";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {getIsLoading} from "../../../redux/slices/user";
import './style.scss'
import {createAsyncThunk} from "@reduxjs/toolkit";
import {login} from "../../../actions/users";
import {closeModal, ModalName, openModal} from "../../../redux/slices/modals";
import {ReactComponent as IconWarning} from "../../../assets/icons/icon-warning.svg";
import {openPopup} from "../../../redux/slices/popup";
import {PopupType} from "../../../data";

const ConfirmInvite: FC = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const navigate = useNavigate();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const isLoading = useAppSelector(getIsLoading);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [inputName, setInputName] = useState('');
    const [errorName, setErrorName] = useState(false);
    const [inputNewPassword, setInputNewPassword] = useState('');
    const [errorNewPassword, setErrorNewPassword] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [inputRepeatPassword, setInputRepeatPassword] = useState('');
    const [errorRepeatPassword, setErrorRepeatPassword] = useState(false);
    const dispatch = useAppDispatch();

    const setNewPassword = createAsyncThunk(
        'user/setNewPassword',
        async (
          _,
          { rejectWithValue, extra: API}: any
        ) => {
          try {
            const projectId = getParam('project_id')
            const token = getParam('token');
            const data = {
                "name": inputName,
                "new_password": inputNewPassword,
                "token": token
            };
            const response = await API.post(`/api/project/${projectId}/users/invite/verify/`, data);
            return response.data;
          } catch (e: any) {
              if(e.response && e.response.data && typeof e.response.data.detail === 'string') {
                  dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail }));
                  if(e.response.data.detail === 'Invalid reset token') {
                      const projectId = getParam('project_id')
                      const token = getParam('token');
                      const response = await API.get(`/api/project/${projectId}/users/invite/verify/update/?token=${token}`)
                      if(response.data && response.data.token) {
                          dispatch(openModal({
                              name: ModalName.INFORMATION,
                              data: {
                                  title: `Ошибка!`,
                                  description: 'Извините, ваш токен не действителен! Для продолжения перейдите по новой ссылке',
                                  icon: {
                                      Component: IconWarning,
                                      width: 28,
                                      height: 28
                                  },
                                  subBtn: {
                                      text: 'Перейти',
                                      onClick: () => {
                                          navigate(`/invite/confirm/?token=${response.data.token}&project_id=${projectId}`);
                                          dispatch(closeModal(ModalName.INFORMATION))
                                      }
                                  }
                              }
                          }));
                      }
                  }
              }
            return rejectWithValue(e.response.data.detail);
          }
        }
    );
    const getParam = (value:string) => {
        const loc:string = window.location.href;
        const regexp = new RegExp(`${value}=([^&]*)`);
        const searchParams = loc.match(regexp);
        return searchParams ? searchParams[1] : '';
    }
    const checkPassword = (one:string, two:string):boolean => {
        return one !== '' && one === two;
    }

    const onHandlerNewPassword = () => {
        if(inputName === '') setErrorName(true);
        else setErrorName(false);
        if(inputNewPassword === '') setErrorNewPassword(true);
        else setErrorNewPassword(false);
        if(!checkPassword(inputNewPassword, inputRepeatPassword)) setErrorRepeatPassword(true);
        else setErrorRepeatPassword(false);
        if(checkPassword(inputNewPassword, inputRepeatPassword) && inputName) {
            dispatch(setNewPassword()).then( (r)=> {
                if(r.payload && r.payload.email) {
                    const projectId = getParam('project_id');
                    dispatch(login({email: r.payload.email, password: inputNewPassword}));
                    navigate(`/projects/${projectId}/`);
                    return;
                }
            })
        }
    }

    return(
       <>
      <div className="login">
        <div className="container container--no-padding">
          <div className="login__title-wrapper">
            <h1 className="title title--lg login__title">Простой сервис <span>глубокой аналитики</span></h1>
            <div className="login__chart">
              <ChartIcon width={720} height={450} />
            </div>
          </div>
          <div className="login__wrapper">
            <div className="login__form-wrapper">
              <div className="login__logo">
                <LogoIcon width={155} height={40} />
              </div>
              <div className="recover__wrapper">
                  <div className="recover__title">Создание пользователя</div>
                  <div className="recover-input">
                      <label>
                          <input
                              type="text"
                              name="name"
                              placeholder="Имя"
                              value={inputName}
                              onChange={(e) => setInputName(e.target.value)}/>
                      </label>
                      {errorName && <div className="recover-input__error">Введите имя</div>}
                  </div>
                  <div className="recover-input">
                      <label>
                          <input
                              type="password"
                              name="new_password"
                              placeholder="Пароль"
                              value={inputNewPassword}
                              onChange={(e) => setInputNewPassword(e.target.value)}/>
                      </label>
                      {errorNewPassword && <div className="recover-input__error">Введите пароль</div>}
                  </div>
                  <div className="recover-input">
                      <label>
                          <input
                              type="password"
                              name="repeat_password"
                              placeholder="Подтвердите Пароль"
                              value={inputRepeatPassword}
                              onChange={(e) => setInputRepeatPassword(e.target.value)}/>
                      </label>
                      {errorRepeatPassword && <div className="recover-input__error">Проверьте введенный пароль</div>}
                  </div>
                  <button className="recover-input__btn" onClick={onHandlerNewPassword}>
                    <span className="btn__text">Отправить</span>
                  </button>
              </div>

            </div>
          </div>
        </div>
      </div>
      {isLoading && <Loader />}
    </>
    )
}

export default ConfirmInvite;