import {DefaultObject} from "../../../../interfaces/common.d";
import {FC} from "react";
import {useAppSelector} from "../../../../redux/hooks";
import {currentUserLang} from "../../../../redux/slices/user";
import TableLabelStretchItem from "../TableLabelStretchItem";
import {trans} from "../../../../_locales";
import TableLabelItem from "../TableLabelItem";
import {IStretchSettingItem} from "../../TablePagination/Head/functions";
import {
    IFilterColumns,
    IOnChangeFilter,
    IPinColumns,
    ISearchColumns,
    ISortColumns,
    IStretchList
} from "../../interfaces";
import {keyToSkip} from "../../../../data";
import "./style.scss";

interface ITableLabels {
    data: Array<Array<any>>,
    columnPreferences: Array<DefaultObject<any>>,
    stretch?: IStretchList,
    metricChoice: Array<string>,
    metricColIndexes: Array<number>,
    aggColIndexesDefault: Array<number>,
    activeFilter?: IFilterColumns,
    activeSearch?: ISearchColumns,
    activeSort?:ISortColumns,
    activePin?: IPinColumns,
    onChangeFilter?: (r: IOnChangeFilter) => void,
    onChangePin?:(i:number)=>void,
    onStretch?: (setting: IStretchSettingItem) => void,
    offFilter?:boolean,
    lastElementsIndexesWithTypes?:number[],
    settingSize?:DefaultObject<number>,
    typeKeyFilter?: 'name' | 'index',
    isExtendStringFilter?: boolean
}

const TableInteractiveLabels:FC<ITableLabels> = ({
    data,
    columnPreferences,
    stretch,
    metricChoice,
    metricColIndexes,
    aggColIndexesDefault,
    lastElementsIndexesWithTypes=[],
    activeFilter={},
    activeSort={},
    activeSearch={},
    activePin={},
    onChangeFilter,
    onChangePin,
    onStretch,
    offFilter,
    settingSize={},
    typeKeyFilter='index',
    isExtendStringFilter
}) => {
    const language = useAppSelector(currentUserLang);
    return(
        <div className="table-interactive-labels">
            { stretch && stretch.length > 0 && (metricChoice.length===0 || metricColIndexes.length>0) &&
                <div className="table-interactive-labels__stretch">
                {
                    stretch.map((item:any, index:number)=> {
                        let colSpan = item.list && item.list.length;
                        if(metricChoice.length && item.list) {
                            let temp = 0;
                            item.list.map((itemList:string, key:number)=> {
                                const i = columnPreferences.findIndex((el:any) => el.name === itemList);
                                if(aggColIndexesDefault.includes(i) || metricColIndexes.includes(i)) temp++;
                            })
                            colSpan = temp;
                        }
                        if(metricChoice.length && colSpan === 0) {
                            if(item.title) return '';
                            if(item.list && item.list.length === 1) {
                                return '';
                            }
                        }
                        return <TableLabelStretchItem
                            key={index}
                            title={trans(item.title, language)}
                            index={index}
                            colSpan={colSpan}
                            isEmptyReturn={!(data && data[0] && data[0][index] !== keyToSkip)}
                        />
                    })
                }
            </div>}
            <div className="table-interactive-labels__items">
                {data.length > 0 && columnPreferences.map((item:any, index:number)=> ((metricChoice.length) ? (aggColIndexesDefault.includes(index) || metricColIndexes.includes(index)) : true) && (
                    <TableLabelItem
                        key={index}
                        title={item.title}
                        index={index}
                        name={item.name}
                        fieldType={item.type}
                        lastElementWithType={lastElementsIndexesWithTypes.includes(index)}
                        offFilter={offFilter}
                        activeFilter={activeFilter}
                        activeSearch={activeSearch}
                        activeSort={activeSort}
                        onFilter={onChangeFilter}
                        activePin={activePin}
                        onChangePin={onChangePin}
                        onStretch={onStretch}
                        defaultWidth={settingSize[index]}
                        typeKeyFilter={typeKeyFilter}
                        isExtendStringFilter={isExtendStringFilter}
                    />
                ))}
            </div>
        </div>
    )
}

export default TableInteractiveLabels;