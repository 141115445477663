import { createAsyncThunk } from '@reduxjs/toolkit'
import {contentJson, PopupType} from '../data'
import {updateToken} from "../utils";
import {openPopup} from "../redux/slices/popup";

export const getApiTokens = createAsyncThunk(
  'tokens/getApiTokens',
  async (
    { projectId }: { projectId: number },
    {dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const response = await API.get(`/api/projects/${projectId}/tokens/`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      return response.data;
    } catch (e: any) {
      // if (typeof e.response.data.detail === 'string')
      //   dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail }));
      return rejectWithValue(e.response.data.detail);
    }
  }
);


export const getApiTokenById = createAsyncThunk(
  'tokens/getApiTokenById',
  async (
    { projectId, tokenId }: { projectId: number, tokenId: number },
    {dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const response = await API.get(`/api/projects/${projectId}/tokens/${tokenId}/`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      return response.data;
    } catch (e: any) {
      if (typeof e.response.data.detail === 'string')
        dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail }));
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const createApiToken = createAsyncThunk(
  'tokens/createApiToken',
  async (
    { data, projectId, userId }: { data: JSON, projectId: number, userId: number },
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const response = await API.post(`/api/projects/${projectId}/tokens/?user_id=${userId}`, data, contentJson);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      dispatch(getApiTokens({projectId}));
      dispatch(openPopup({ type: PopupType.SUCCESS, description: 'The token was created' }))
      return response.data.data;
    } catch (e: any) {
      if (typeof e.response.data.detail === 'string')
        dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail }));
      return rejectWithValue(e.response.data.detail);
    }
  }
);


export const updateApiToken = createAsyncThunk(
  'tokens/updateApiToken',
  async (
    { data, projectId, tokenId, userId }:
      { data: JSON, projectId: number, tokenId: number, userId: number },
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const response = await API.put(`/api/projects/${projectId}/tokens/${tokenId}/?user_id=${userId}`, data);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      dispatch(getApiTokens({ projectId }));
      dispatch(openPopup({ type: PopupType.SUCCESS, description: 'The token was updated' }))
      return response.data.data;
    } catch (e: any) {
      if (typeof e.response.data.detail === 'string')
        dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail }));
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const deleteApiToken = createAsyncThunk(
  'tokens/deleteApiToken',
  async (
    { projectId, tokenId }: { projectId: number, tokenId: number },
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const response = await API.delete(`/api/projects/${projectId}/tokens/${tokenId}/`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      dispatch(getApiTokens({ projectId }));
      dispatch(openPopup({ type: PopupType.SUCCESS, description: 'The token was deleted' }))
      return response.data.data;
    } catch (e: any) {
      if (typeof e.response.data.detail === 'string')
        dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail }));
      return rejectWithValue(e.response.data.detail);
    }
  }
);
