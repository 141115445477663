import { FC } from 'react'
import cx from 'classnames'
import { Link } from 'react-router-dom'
import {IHeader} from './Header.d'
import {useAppDispatch, useAppSelector} from '../../redux/hooks'
import {currentUser, currentUserLang} from '../../redux/slices/user'
import { Breadcrumbs, Btn } from '../../ui'
import { UserMenu } from '../index'
import { ReactComponent as Logo } from '../../assets/icons/logo.svg'
import {trans} from "../../_locales";
import './style.scss'
import {ModalName, openModal} from "../../redux/slices/modals";
import {currentInstructions} from "../../redux/slices/instructions";
import Tooltip from "../Tooltip";

const Header: FC<IHeader> = ({
  className,
  isIndexPage=false,
  breadcrumbsData,
  btns,
  userMenuData
}) => {
  const user = useAppSelector(currentUser);
  const language = useAppSelector(currentUserLang);
  const instructions = useAppSelector(currentInstructions);

  const LogoWrapperTag = isIndexPage ? 'span' : Link;
  const logoHref = isIndexPage ? undefined : '/projects';
  const logoAriaLabel = isIndexPage ? undefined : trans('Go to the main page', language);

  return (
    <header className={cx('header', className)}>
      <div className="container">
        <LogoWrapperTag className='header__logo' to={logoHref as string} aria-label={logoAriaLabel} >
          <Logo className='logo' width={155} height={40} />
        </LogoWrapperTag>

        {breadcrumbsData && <Breadcrumbs {...breadcrumbsData}/>}

        {btns && btns.length > 0 && (
          <div className="header__btn-wrapper">
            {btns.map((btn, i) => {
              return <Btn key={i} {...btn} className='header__dashboard-btn' />
            })}
          </div>
        )}
        {
          instructions && <Tooltip className='header__docs' text={instructions.title} onClick={instructions.cb} color={'blue'}/>
            // <Btn className='header__docs' text={'?'} onClick={instructions.cb} tooltip={instructions.title}/>
        }

        {userMenuData && <UserMenu {...userMenuData} userName={user.name} />}
      </div>
    </header>
  );
}

export default Header