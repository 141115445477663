import Code from "../../Code";
import React, {useRef} from "react";
import {generateAndDownloadXlsx, linkTo} from "../../../utils";
import {trans} from "../../../_locales";
import {useAppSelector} from "../../../redux/hooks";
import {currentUserLang} from "../../../redux/slices/user";

import '../style.scss';
import screenCreateCampaignBtn from '../../../assets/docs/create_campaign/create_campaign.png';
import screenCreateCampaign from '../../../assets/docs/create_campaign/create_campaign_modal.png';
import screenCreateCampaignModalEmpty from "../../../assets/docs/create_campaign/create_campaign_modal_empty.png";
import screenCreateCampaignName from "../../../assets/docs/create_campaign/create_campaign_name.png";
import screenCreateSourceBtn from "../../../assets/docs/create_campaign/create_source_btn.png";
import screenCreateSourceFields from "../../../assets/docs/create_campaign/create_source_fields.png";
import screenCreateCampaignExtend from '../../../assets/docs/create_campaign/create_campaign_extend.png';

import screenCampaignInList from "../../../assets/docs/create_campaign/campaign_in_list.png";
import screenPlacementCreateBtn from "../../../assets/docs/create_campaign/placement_create_btn.png";
import screenPlacementCreative from "../../../assets/docs/create_campaign/placement_creative.png"

import screenExampleSimpleUpload from "../../../assets/docs/create_campaign/example_simple_upload.png";
import screenUploadCampaignBtn from "../../../assets/docs/create_campaign/upload_campaign_btn.png";
import screenOpenFile from "../../../assets/docs/create_campaign/open_file.png";
import screenListCampaigns from "../../../assets/docs/create_campaign/list_campaigns.png";
import screenListPlacements from "../../../assets/docs/create_campaign/list_placements.png";
import screenDownloadPixel from "../../../assets/docs/create_campaign/download_pixels.png";
import screenExamplePixel from "../../../assets/docs/create_campaign/example_pixels.png";

import screenCreatePlacement from '../../../assets/docs/create_campaign/create_placement.png';

import Note from "../../Note";


const DocsCreateCampaignFull = () => {
    const language = useAppSelector(currentUserLang);
    const first = useRef<HTMLDivElement | null>(null);
    const first_one = useRef<HTMLDivElement | null>(null);
    const first_two = useRef<HTMLDivElement | null>(null);
    const second = useRef<HTMLDivElement | null>(null);
    const third = useRef<HTMLDivElement | null>(null);
    const fourth = useRef<HTMLDivElement | null>(null);
    const five = useRef<HTMLDivElement | null>(null);
    const five_one = useRef<HTMLDivElement | null>(null);
    const five_two = useRef<HTMLDivElement | null>(null);
    const five_three = useRef<HTMLDivElement | null>(null);
    const six = useRef<HTMLDivElement | null>(null);
    const six_one = useRef<HTMLDivElement | null>(null);
    const six_two = useRef<HTMLDivElement | null>(null);
    const six_three = useRef<HTMLDivElement | null>(null);

    const fileRequired = [
        ['CampaignName', 'SourceName', 'PlacementName', 'PlacementLandingPage'],
        ['Яндекс Директ', 'Yandex Direct', 'Креатив 1', 'https://targetads.io/?utm_source=yandex_direct&utm_medium=cpm&utm_campaign=targetads_banners_socdem'],
        ['Яндекс Директ', 'Yandex Direct', 'Креатив 2', 'https://targetads.io/?utm_source=yandex_direct&utm_medium=cpm&utm_campaign=targetads_banners_socdem'],
        ['mytarget', 'mytarget', 'Креатив 1', 'https://targetads.io/?utm_source=mytarget&utm_medium=cpm&utm_campaign=targetads_banners_socdem'],
    ]
    const fileFull = [
        ['CampaignName', 'SourceName', 'SourceClickParams', 'SourceImpressionParams', 'CampaignDescription', 'CampaignLandingPage', 'CampaignPaidType', 'CampaignAdvId', 'PlacementName', 'PlacementFormat', 'PlacementGEO', 'PlacementTargeting', 'PlacementAdSize', 'PlacementDuration', 'PlacementLandscape', 'PlacementLandingPage', 'PlacementAdvId', 'CampaignTags'],
        ['Target ADS / Yandex_Banners / 1-30 Dec','Yandex Direct','','','','https://targetads.io/?utm_source=yandex_direct&utm_medium=cpm&utm_campaign=targetads_banners_socdem','CPM','1234567890','Banners_1','HTML','Россия','Socdem','300х600','','','https://targetads.io/?utm_source=yandex_direct&utm_medium=cpm&utm_campaign=targetads_banners_socdem', '1234567890', 'abc,ab,er,rhg'],
        ['Target ADS / MyTarget_Banners / 1-30 Dec','MyTarget','','','','https://targetads.io/?utm_source=mytarget&utm_medium=cpm&utm_campaign=targetads_banners_socdem','CPM','','Banners_1','HTML','Россия','Socdem','300х600','','','https://targetads.io/?utm_source=mytarget&utm_medium=cpm&utm_campaign=targetads_banners_socdem', '', 'ab,er'],
        ['Target ADS / Yandex_Banners / 1-30 Dec','Yandex Direct','','','','https://targetads.io/?utm_source=yandex_direct&utm_medium=cpm&utm_campaign=targetads_banners_interest','CPM','1234567890','Banners_2','HTML','Россия','Interests','300х600','','','https://targetads.io/?utm_source=yandex_direct&utm_medium=cpm&utm_campaign=targetads_banners_interest', '', 'qwerty'],
        ['Target ADS / MyTarget_Banners / 1-30 Dec','MyTarget','','','','https://targetads.io/?utm_source=mytarget&utm_medium=cpm&utm_campaign=targetads_banners_interest','CPM','','Banners_2','HTML','Россия','Interests','300х600','','','https://targetads.io/?utm_source=mytarget&utm_medium=cpm&utm_campaign=targetads_banners_interest', '', '12, 42'],
        ['Target ADS / Yandex_Video / 1-30 Dec','Yandex Direct','','','','https://targetads.io/?utm_source=yandex_direct&utm_medium=cpm&utm_campaign=targetads_banners_segments_aidata','CPM','','Banners_3','HTML','Россия','Segments_aidata','300х600','','','https://targetads.io/?utm_source=yandex_direct&utm_medium=cpm&utm_campaign=targetads_banners_segments_aidata', '', 'test, test2, ewq2'],
        ['Target ADS / MyTarget_Video / 1-30 Dec','MyTarget','','','','https://targetads.io/?utm_source=mytarget&utm_medium=cpm&utm_campaign=targetads_banners_segments_aidata','CPM','','Banners_3','HTML','Россия','Segments_aidata','300х600','','','https://targetads.io/?utm_source=mytarget&utm_medium=cpm&utm_campaign=targetads_banners_segments_aidata', '', 'ab, test2, 12'],
        ['Target ADS / getintent_Banners / 1-30 Dec','Getintent','ssp=[[SSP]]&domain=[[DOMAIN]]','ssp=[[SSP]]&domain=[[DOMAIN]]','','https://targetads.io/?utm_source=getintent&utm_medium=cpm&utm_campaign=targetads_banners_segments','CPM','','Banners_1','HTML','Россия','Segments','1280х720px','','','https://targetads.io/?utm_source=getintent&utm_medium=cpm&utm_campaign=targetads_banners_segments', '', 'jump, cool'],
        ['Target ADS / getintent_Video / 1-30 Dec','Getintent','ssp=[[SSP]]&domain=[[DOMAIN]]','ssp=[[SSP]]&domain=[[DOMAIN]]','','https://targetads.io/?utm_source=getintent&utm_medium=cpm&utm_campaign=targetads_video_segments','CPM','','Video','Video','Россия','Segments','1280х720px','15','horizontal','https://targetads.io/?utm_source=getintent&utm_medium=cpm&utm_campaign=targetads_video_segments', '', 'new, old, qwerty']
    ]
    const fileFullMin = [
        ['CampaignName', 'SourceName', 'SourceClickParams', 'SourceImpressionParams', 'CampaignDescription', 'CampaignLandingPage', 'CampaignPaidType', 'CampaignAdvId', 'PlacementName', 'PlacementFormat', 'PlacementGEO', 'PlacementTargeting', 'PlacementAdSize', 'PlacementDuration', 'PlacementLandscape', 'PlacementLandingPage', 'PlacementAdvId', 'CampaignTags'],
        ['Yandex Direct / Banners / Flight Vesna / 1 - 30 April', 'Yandex', '', '', '', 'https://targetads.io/?utm_source=yandex_direct&utm_medium=cpm&utm_campaign=targetads_banners', '', '','Banner_Vesna_1', '', '', '', '', '', '', 'https://targetads.io/?utm_source=yandex_direct&utm_medium=cpm&utm_campaign=targetads_banners', '', ''],
        ['Yandex Direct / Banners / Flight Vesna / 1 - 30 April', 'Yandex', '', '', '', 'https://targetads.io/?utm_source=yandex_direct&utm_medium=cpm&utm_campaign=targetads_banners', '','', 'Banner_Vesna_2', '', '', '', '', '', '', 'https://targetads.io/?utm_source=yandex_direct&utm_medium=cpm&utm_campaign=targetads_banners', '', ''],
        ['Yandex Direct / Video / Flight Vesna / 1 - 30 April', 'Yandex', '', '', '', 'https://targetads.io/?utm_source=yandex_direct&utm_medium=cpm&utm_campaign=targetads_video', '','', 'Video_Vesna_1', '', '', '', '', '', '', 'https://targetads.io/?utm_source=yandex_direct&utm_medium=cpm&utm_campaign=targetads_video', '', ''],
        ['Yandex Direct / Video / Flight Vesna / 1 - 30 April', 'Yandex', '', '', '', 'https://targetads.io/?utm_source=yandex_direct&utm_medium=cpm&utm_campaign=targetads_video', '','', 'Video_Vesna_2', '', '', '', '', '', '', 'https://targetads.io/?utm_source=yandex_direct&utm_medium=cpm&utm_campaign=targetads_video', '', ''],
        ['MyTarget / Banners / Flight Vesna / 1 - 30 April', 'MyTarget', '', '', '', 'https://targetads.io/?utm_source=mytarget&utm_medium=cpm&utm_campaign=targetads_banners', '','', 'Banner_Vesna_1', '', '', '', '', '', '', 'https://targetads.io/?utm_source=mytarget&utm_medium=cpm&utm_campaign=targetads_banners', '', ''],
        ['MyTarget / Banners / Flight Vesna / 1 - 30 April', 'MyTarget', '', '', '', 'https://targetads.io/?utm_source=mytarget&utm_medium=cpm&utm_campaign=targetads_banners', '','', 'Banner_Vesna_2', '', '', '', '', '', '', 'https://targetads.io/?utm_source=mytarget&utm_medium=cpm&utm_campaign=targetads_banners', '', ''],
        ['Getintent / Banners / Flight Vesna / 1 - 30 April', 'Getintent', '', '', '', 'https://targetads.io/?utm_source=getinent&utm_medium=cpm&utm_campaign=targetads_banners', '','', 'Banner_Vesna_1', '', '', '', '', '', '', 'https://targetads.io/?utm_source=getinent&utm_medium=cpm&utm_campaign=targetads_banners', '', ''],
        ['Getintent / Banners / Flight Vesna / 1 - 30 April', 'Getintent', '', '', '', 'https://targetads.io/?utm_source=getinent&utm_medium=cpm&utm_campaign=targetads_banners', '','', 'Banner_Vesna_2', '', '', '', '', '', '', 'https://targetads.io/?utm_source=getinent&utm_medium=cpm&utm_campaign=targetads_banners', '', ''],
    ]

    const fileFullDop = [
        ['CampaignName', 'SourceName', 'SourceClickParams', 'SourceImpressionParams', 'CampaignDescription', 'CampaignLandingPage', 'CampaignPaidType', 'CampaignAdvId', 'PlacementName', 'PlacementFormat', 'PlacementGEO', 'PlacementTargeting', 'PlacementAdSize', 'PlacementDuration', 'PlacementLandscape', 'PlacementLandingPage', 'PlacementAdvId', 'CampaignTags'],
        ['Yandex Direct / Banners / Flight Vesna / 1 - 30 April', 'Yandex', 'campaing={campaign_id}&creative={creative_id}&device={device_type}', 'rnd=%random%&domain={domain}', '', 'https://targetads.io/?utm_source=yandex_direct&utm_medium=cpm&utm_campaign=targetads_banners', 'CPM', '1234567890', 'Banner_Vesna_1', 'HTML', 'Россия', 'Socdem', '300х600', '', '', 'https://targetads.io/?utm_source=yandex_direct&utm_medium=cpm&utm_campaign=targetads_banners', '1234567890', 'abc,ab,er,rhg'],
        ['Yandex Direct / Banners / Flight Vesna / 1 - 30 April', 'Yandex', 'campaing={campaign_id}&creative={creative_id}&device={device_type}', 'rnd=%random%&domain={domain}', '', 'https://targetads.io/?utm_source=yandex_direct&utm_medium=cpm&utm_campaign=targetads_banners', 'CPM', '1234567890', 'Banner_Vesna_2', 'HTML', 'Россия', 'Socdem', '300х600', '', '', 'https://targetads.io/?utm_source=yandex_direct&utm_medium=cpm&utm_campaign=targetads_banners', '', 'ab,er'],
        ['Yandex Direct / Video / Flight Vesna / 1 - 30 April', 'Yandex', 'campaing={campaign_id}&creative={creative_id}&device={device_type}', 'rnd=%random%&domain={domain}', '', 'https://targetads.io/?utm_source=yandex_direct&utm_medium=cpm&utm_campaign=targetads_video', 'CPM', '', 'Video_Vesna_1', 'Video', 'Россия', 'Interests', '300х600', '15', 'horizontal', 'https://targetads.io/?utm_source=yandex_direct&utm_medium=cpm&utm_campaign=targetads_video', '', 'qwerty'],
        ['Yandex Direct / Video / Flight Vesna / 1 - 30 April', 'Yandex', 'campaing={campaign_id}&creative={creative_id}&device={device_type}', 'rnd=%random%&domain={domain}', '', 'https://targetads.io/?utm_source=yandex_direct&utm_medium=cpm&utm_campaign=targetads_video', 'CPM', '', 'Video_Vesna_2', 'Video', 'Россия', 'Interests', '300х600', '15', 'vertical', 'https://targetads.io/?utm_source=yandex_direct&utm_medium=cpm&utm_campaign=targetads_video', '', '12, 42'],
        ['MyTarget / Banners / Flight Vesna / 1 - 30 April', 'MyTarget', '', '', '', 'https://targetads.io/?utm_source=mytarget&utm_medium=cpm&utm_campaign=targetads_banners', 'CPM', '', 'Banner_Vesna_1', 'HTML', 'Россия', 'Segments_aidata', '300х600', '', '', 'https://targetads.io/?utm_source=mytarget&utm_medium=cpm&utm_campaign=targetads_banners', '', 'test, test2, ewq2'],
        ['MyTarget / Banners / Flight Vesna / 1 - 30 April', 'MyTarget', '', '', '', 'https://targetads.io/?utm_source=mytarget&utm_medium=cpm&utm_campaign=targetads_banners', 'CPM', '', 'Banner_Vesna_2', 'HTML', 'Россия', 'Segments_aidata', '300х600', '', '', 'https://targetads.io/?utm_source=mytarget&utm_medium=cpm&utm_campaign=targetads_banners', '', 'ab, test2, 12'],
        ['Getintent / Banners / Flight Vesna / 1 - 30 April', 'Getintent', '', 'domain=[[DOMAIN]]&ssp=[[SSP]]&rnd=[[RANDOM]]', '', 'https://targetads.io/?utm_source=getinent&utm_medium=cpm&utm_campaign=targetads_banners', 'CPM', '', 'Banner_Vesna_1', 'HTML', 'Россия', 'Segments', '1280х720px', '', '', 'https://targetads.io/?utm_source=getinent&utm_medium=cpm&utm_campaign=targetads_banners', '', 'jump, cool'],
        ['Getintent / Banners / Flight Vesna / 1 - 30 April', 'Getintent', '', 'domain=[[DOMAIN]]&ssp=[[SSP]]&rnd=[[RANDOM]]', '', 'https://targetads.io/?utm_source=getinent&utm_medium=cpm&utm_campaign=targetads_banners', 'CPM', '', 'Banner_Vesna_2', 'HTML', 'Россия', 'Segments', '1280х720px', '', '', 'https://targetads.io/?utm_source=getinent&utm_medium=cpm&utm_campaign=targetads_banners', '', 'new, old, qwerty'],
    ]

    return(
        <div className='docs__wrapper'>
            <ol className='docs__head'>
                <li>
                    <button onClick={() => first.current && linkTo(first.current)}>1. Создание кампании</button>
                    <ol>
                        <li>
                            <button onClick={() => first_one.current && linkTo(first_one.current)}>1. Основные поля
                            </button>
                        </li>
                        <li>
                            <button onClick={() => first_two.current && linkTo(first_two.current)}>2. Дополнительные
                                поля
                            </button>
                        </li>
                    </ol>
                </li>
                <li>
                    <button onClick={() => second.current && linkTo(second.current)}>2. Создание креатива</button>
                </li>
                <li>
                    <button onClick={() => third.current && linkTo(third.current)}>3. Пиксели для креативов</button>
                </li>
                <li>
                    <button onClick={() => fourth.current && linkTo(fourth.current)}>4. Массовое создание кампаний через
                        загрузочный файл
                    </button>
                </li>
                <li>
                    <button onClick={() => five.current && linkTo(five.current)}>5. Кейс по созданию загрузочного
                        файла
                    </button>
                    <ol>
                        <li>
                            <button onClick={() => five_one.current && linkTo(five_one.current)}>1. Вариант загрузки
                                минимальным шаблоном
                            </button>
                        </li>
                        <li>
                            <button onClick={() => five_two.current && linkTo(five_two.current)}>2. Вариант загрузки
                                расширенным шаблоном
                            </button>
                        </li>
                    </ol>
                </li>
                <li>
                    <button onClick={() => six.current && linkTo(six.current)}>6. Скачивание пикселей</button>
                </li>
            </ol>
            <h2 ref={first}>1. Создание кампании</h2>
            <p>Для создания кампании, необходимо нажать кнопку “Создать”.</p>
            <p>Также доступен вариант загрузки через <button
                onClick={() => fourth.current && linkTo(fourth.current)}>файл</button> для опытных пользователей.
            </p>
            <img src={screenCreateCampaignBtn} alt=""/>
            <h3 ref={first_one}>1.1 Основные поля</h3>
            <p>В открывшемся окне необходимо заполнить следующие поля:</p>
            <img src={screenCreateCampaignModalEmpty} alt=""/>
            <p><strong>Название кампании</strong> - нейминг кампании может быть любым.</p>
            <img src={screenCreateCampaignName} alt=""/>
            <p><strong>Источник</strong> - площадка, на которой будет идти размещение. При создании первой кампании,
                доступных источников не будет, и его необходимо создать</p>
            <p>Нажимаем <strong>Добавить источник</strong></p>
            <img src={screenCreateSourceBtn} alt=""/>
            <p>Можно воспользоваться функцией <strong>“Создать на основе”</strong>, но если нужного источника нет, можно
                создать его самостоятельно.</p>
            <p>Для этого указываем название источника - в примере ниже это <strong>Yandex Direct</strong></p>

            <p><strong>Параметры на клик и параметры на показ:</strong></p>
            <p>В данные поля можно добавить необходимые <strong>динамические</strong> параметры, которые доступны на
                площадках, на которых вы хотите размещаться. Эти параметры передают в наш пиксель нужные данные.</p>
            <p>В случае с Яндекс Директом - <a
                href="https://yandex.ru/support/direct/statistics/url-tags.html#url-tags__dynamic">Параметры URL</a></p>
            <p>Например, нам нужно передавать <strong>ID кампании</strong>, <strong>ID Креатива</strong> и <strong>Тип
                Устройства</strong> - в таком случае, конструкция для параметров будет выглядеть таким образом</p>
            <Code code={'campaing={campaign_id}&creative={creative_id}&device={device_type}'}/>
            <p>Если нужно передавать такие же данные в пиксель на показ, то также вставляем конструкцию в поле <strong>“Параметры
                на показ”</strong></p>
            <p>Обычно для показов рекомендуется добавлять макрос %random%, для более точного отслеживания показов и
                минимизации расхождений между площадкой и трекинг-системой.</p>
            <Code code={"rnd=%random%"}/>
            <p>После заполнения всех полей должно получится следующее:</p>
            <img src={screenCreateSourceFields} alt=""/>
            <Note
                text={'Обратите внимание, что при выборе этого источника, все кампании с ним будут использовать динамические параметры, которые в нем указаны.'}/>
            <p>Нажимаем <strong>“Создать”</strong> и источник готов.</p>

            <p><strong>Посадочная страница</strong> - ссылка которая включает себя только utm-метки</p>
            <Note
                code={'https://targetads.io/?utm_source=yandex_direct&utm_medium=cpm&utm_campaign=targetads_banners'}
                text={'Обратите внимание, что динамические параметры вставлять не нужно, только utm-метки со статическим параметрами'}
            />
            <p>Таким образом получились следующие заполненные поля:</p>
            <img src={screenCreateCampaign} alt=""/>

            <h3 ref={first_two}>1.2 Дополнительные поля</h3>
            <p>В расширенных настройках можно выбрать дополнительные параметры для кампании:</p>
            <p><strong>ID кампании в рекламной системе</strong> - Указываем ID нужной кампании из рекламного кабинета
                Яндекс Директ</p>
            <Note
                text={'Если кампания создана через Мастер, то API Яндекса не передает ID и подтянуть расходы в автоматическом режиме не получится'}/>
            <p><strong>Тип закупки</strong> - выбираем тип закупки выбранной кампании</p>
            <p><strong>Целевые действия</strong> - не является обязательным - так как это первая кампания и целевые
                действия ещё не настроены, это поле можно настроить позже при необходимости.</p>
            <p><strong>Теги</strong> - кастомный фильтр - не является обязательным, но помогает быстро найти кампании с
                нужным тегом в отчетах</p>
            <p><strong>Описание</strong> - текстовое поля для заметок</p>
            <img src={screenCreateCampaignExtend} alt=""/>
            <p>На этом создание кампании завершено.</p>


            <h2 ref={second}>2. Создание креатива</h2>
            <p>“Проваливаемся” в созданную ранее кампанию</p>
            <img src={screenCampaignInList} alt=""/>
            <p>И создаем креатив</p>
            <img src={screenPlacementCreateBtn} alt=""/>

            <p>В открывшемся окне нужно указать:</p>
            <p><strong>Название креатива</strong> - нейминг может быть любой</p>
            <p><strong>Посадочная страница</strong> - автоматически подтягивается из кампании.</p>
            <Note
                text={'Если разные креативы используют разные посадочные, в таком случае, нужно указывать посадочные в ручную.'}/>
            <p><strong>Формат</strong> - Выбираем нужный формат креатива. Так как кампания баннерная, то выбираем один
                из баннерных форматов (JPEG, GIF, HTML). При выборе “Видео” также будут доступны пиксели на квартели.
            </p>
            <img src={screenCreatePlacement} alt=""/>

            <p><strong>Дополнительные настройки</strong> - не обязательны к заполнению</p>
            <p><strong>ID в рекламной системе</strong> - Указываем ID креатива из рекламного кабинета Яндекс Директ</p>
            <Note text={'Если используется 1 пиксель на группу креативов - указывать ID не нужно'}/>
            <p><strong>Гео, Таргетинги, Размер Креатива, Описание</strong> - свободные текстовые поля, можно их
                заполнить, чтобы в дальнейшем использовать для фильтрации в отчетности</p>
            <p>Нажимаем создать и креатив готов.</p>

            <h2 ref={third}>3. Пиксели для креативов</h2>
            <p>Выделяем сделанный нами креатив и скачиваем excel файл.</p>
            <img src={screenPlacementCreative} alt=""/>

            <p>В полученной таблице есть несколько типов пикселей для выбранной кампании и креатива</p>
            <div className='docs__table'>
                <table>
                    <thead>
                    <tr>
                        <th>Source</th>
                        <th>Campaign</th>
                        <th>Placement</th>
                        <th>ImpressionPixel</th>
                        <th>ImpressionPixel(js)</th>
                        <th>ClickPixel</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Yandex Direct</td>
                        <td>Target ADS / Yandex_Banners / 1-30 Dec</td>
                        <td>Banner_1</td>
                        <td>{`<img src="https://verify.yandex.ru/verify_target_ads/view/pixel?pid=12535&cn=239&pl=616&rnd=%25random%25"/>`}</td>
                        <td>
                            {`<script type="text/javascript" data-pixel="https://verify.yandex.ru/verify_target_ads/view/pixel?pid=12535&cn=239&pl=616&rnd=%25random%25" data-extend="true" src="https://cdn.targetads.io/viewability/v1/viewability.js "></script>`}
                        </td>
                        <td>
                            {`https://verify.yandex.ru/verify_target_ads/view/click?pid=12535&cn=239&pl=616&campaing={campaign_id}&creative={creative_id}&device={device_type}`}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <p><strong>ImpressionPixel</strong> - пиксель для отслеживания показов - также включает динамические
                параметры, которые указаны в настройке кампании.</p>
            <p><strong>ImpressionPixel(js)</strong> - JavaScript версия пикселя, позволяет собирать больше данных.</p>
            <p><strong>ClickPixel</strong> - кликовый пиксель, включает себя динамические параметры, которые указывались
                при настройке кампании.</p>
            <p>Для того, чтобы начать собирать данные в кампании на Яндексе, необходимо установить пиксель <strong>ImpressionPixel
                и ClickPixel</strong></p>

            <h2>Важные замечания</h2>
            <Note definitiones={[
                {
                    name: 'ClickPixel',
                    description: 'при настройке кампании в Яндекс Директ ставится на место посадочной страницы.'
                },
                {
                    name: 'ImpressionPixel(js)',
                    description: 'не используется в Яндекс Директе'
                },
                {
                    name: 'Квартели (дополнительные видео пиксели)',
                    description: 'не используются в Яндекс Директ'
                }
            ]}/>

            <p>На этом создание кампании завершено и можно приступать к сбору статистики.</p>


            <h2 ref={fourth}>4. Массовое создание кампаний через загрузочный файл</h2>
            <p>В случае, если нужно загрузить сразу много кампаний, можно воспользоваться загрузочным файлом.</p>
            <p>
                <button onClick={() => generateAndDownloadXlsx(fileRequired, 'Шаблон_загрузки_минимальный')}>Шаблон
                    загрузки минимальный.xlsx
                </button>
                - содержит минимальное количество полей, необходимых для создания кампании и креативов
            </p>

            <div className='docs__table'>
                <table>
                    <thead>
                    <tr>
                        <th>{trans('Поле', language)}</th>
                        <th>{trans('Описание', language)}</th>
                        <th>{trans('Пример', language)}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>CampaingName</td>
                        <td>Название кампании</td>
                        <td>Target ADS / Yandex_Banners / 1-30 Dec</td>
                    </tr>
                    <tr>
                        <td>SourceName</td>
                        <td>Название площадки</td>
                        <td>Yandex Direct</td>
                    </tr>
                    <tr>
                        <td>PlacementName</td>
                        <td>Название креатива</td>
                        <td>
                            Banners_1<br/>
                            Banners_2<br/>
                            Video_1<br/>
                            Video_Retargeting<br/>
                            и так далее
                        </td>
                    </tr>
                    <tr>
                        <td>PlacementLandingPage</td>
                        <td>Посадочная</td>
                        <td>https://targetads.io/?utm_source=yandex_direct&utm_medium=cpm&utm_campaign=targetads_banners</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <p>При загрузке по примеру из шаблона получится 1 кампания в Яндекс Директ с двумя креативами и одна
                кампания в MyTarget с одним креативом.</p>
            <p>
                <button onClick={() => generateAndDownloadXlsx(fileFull, 'Шаблон_загрузки_расширенный')}>Шаблон загрузки
                    расширенный.xlsx
                </button>
                - содержит большее количество параметров.
            </p>
            <p>Описание полей в файле - инструкция:</p>
            <div className='docs__table'>
                <table>
                    <thead>
                    <tr>
                        <th>{trans('Поле', language)}</th>
                        <th>{trans('Описание', language)}</th>
                        <th>{trans('Пример', language)}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>CampaingName</td>
                        <td>Название кампании</td>
                        <td>Target ADS / Yandex_Banners / 1-30 Dec</td>
                    </tr>
                    <tr>
                        <td>SourceName</td>
                        <td>Название площадки</td>
                        <td>Yandex Direct</td>
                    </tr>
                    <tr>
                        <td>SourceClickParams</td>
                        <td>Макросы, которые автоматически добавятся к кликовому пикселю<br/>
                            Обратите внимание, что разные системы могут использовать разные макросы
                        </td>
                        <td>ssp=[[SSP]]&domain=[[domain]]</td>
                    </tr>
                    <tr>
                        <td>SourceImpressionParams</td>
                        <td>Макросы, которые автоматически добавятся к пикселю на показ<br/>
                            Обратите внимание, что разные системы могут использовать разные макросы
                        </td>
                        <td>ssp=[[SSP]]&domain=[[domain]]</td>
                    </tr>
                    <tr>
                        <td>CampaignDescription</td>
                        <td>Описание кампании<br/>
                            Не обязательно к заполнению
                        </td>
                        <td>Баннерная кампания на Яндексе</td>
                    </tr>
                    <tr>
                        <td>CampaignLandingPage</td>
                        <td>Посадочная страница, на которую идет размещение<br/>
                            Адрес указывает вместе с utm-метками
                        </td>
                        <td>https://targetads.io/?utm_source=yandex_direct&utm_medium=cpm&utm_campaign=targetads_banners</td>
                    </tr>
                    <tr>
                        <td>CampaignPaidType</td>
                        <td>Тип закупки кампании на выбор: CPM, CPC, CPV, FIX</td>
                        <td>CPM</td>
                    </tr>
                    <tr>
                        <td>CampaignAdvId</td>
                        <td>ID кампании в рекламной системе</td>
                        <td>1234567890</td>
                    </tr>
                    <tr>
                        <td>PlacementName</td>
                        <td>Название креатива</td>
                        <td>
                            Banners_1<br/>
                            Banners_2<br/>
                            Video_1<br/>
                            Video_Retargeting<br/>
                            и так далее
                        </td>
                    </tr>
                    <tr>
                        <td>PlacementFormat</td>
                        <td>Форматы на выбор из доступных вариантов:<br/>
                            - HTML<br/>
                            - JPEG<br/>
                            - GIF<br/>
                            - Video<br/>
                            - Other
                        </td>
                        <td>HTML</td>
                    </tr>
                    <tr>
                        <td>PlacementGEO</td>
                        <td>Гео размещения<br/>
                            Заполняется в случае необходимости
                        </td>
                        <td>
                            Россия<br/>
                            Москва+Облать<br/>
                            и так далее
                        </td>
                    </tr>
                    <tr>
                        <td>PlacementTargeting</td>
                        <td>Таргетинг размещения<br/>
                            Заполняется в случае необходимости
                        </td>
                        <td>
                            Segments<br/>
                            Retargeting<br/>
                            aidata_segments_socdem<br/>
                            и так далее
                        </td>
                    </tr>
                    <tr>
                        <td>PlacementAdSize</td>
                        <td>Размер баннера или видео<br/>
                            Заполняется в случае необходимости
                        </td>
                        <td>
                            250x250px<br/>
                            1920х1080px<br/>
                            300х600
                        </td>
                    </tr>
                    <tr>
                        <td>PlacementDuration</td>
                        <td>Если необходимо указать длительность видео в секундах<br/>
                            В случае с баннерами не заполняется
                        </td>
                        <td>10<br/>60<br/>90</td>
                    </tr>
                    <tr>
                        <td>PlacementLandscape</td>
                        <td>Ориентация видео</td>
                        <td>vertical или horizontal</td>
                    </tr>
                    <tr>
                        <td>PlacementLandingPage</td>
                        <td>Посадочная</td>
                        <td>https://targetads.io/?utm_source=yandex_direct&utm_medium=cpm&utm_campaign=targetads_banners</td>
                    </tr>
                    <tr>
                        <td>PlacementAdvId</td>
                        <td>ID креатива в рекламной системе</td>
                        <td>1234567890</td>
                    </tr>
                    <tr>
                        <td>CampaignTags</td>
                        <td>Дополнительный фильтр для кампаний. Может использоваться при составлении отчётности.</td>
                        <td>some, example, test</td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <h2 ref={five}>5. Кейс по созданию загрузочного файла</h2>
            <p><strong>Вводные:</strong></p>
            <Note
                definitiones={[
                    {name: 'Площадки', description: 'Яндекс Директ (видео + баннеры), Getintent, MyTarget.'},
                    {
                        name: 'Креативы',
                        description: 'по 2 креатива на каждой площадке (в случае яндекса - 2 баннера и 2 видео)'
                    }
                ]}
                text={'Нужно получить отдельный пиксель для каждого креатива'}
            />
            <h3 ref={five_one}>5.1 Вариант загрузки минимальным шаблоном</h3>
            <p>Скачиваем <button
                onClick={() => generateAndDownloadXlsx(fileRequired, 'Шаблон_загрузки_минимальный')}>Шаблон загрузки
                минимальный.xlsx</button> и заполняем поля:
            </p>
            <Note
                definitiones={[
                    {name: 'CampaingName', description: 'Название кампании - нейминг может быть любым'},
                    {
                        name: 'SourceName',
                        description: 'Источник, площадка, на которой будет размещение - например, Yandex, Getintent и т.д.'
                    },
                    {name: 'PlacementName', description: 'название креатива - нейминг может быть любым'},
                    {
                        name: 'PlacementLandingPage',
                        description: 'посадочная страница с utm-метками - https://targetads.io/?utm_source=yandex_direct&utm_medium=cpm&utm_campaign=targetads_banners_socdem'
                    },
                ]}
            />
            <p>Пример заполнения таблицы по вводным выше:</p>
            <img src={screenExampleSimpleUpload} alt=""/>
            <p>Возвращаемся в интерфейс в раздел <strong>“Медийные кампании”</strong> и нажимаем <strong>“Загрузить
                Excel”</strong></p>
            <img src={screenUploadCampaignBtn} alt=""/>
            <p>В открывшемся окне выбираем подготовленный Excel файл и нажимает “Открыть”</p>
            <img src={screenOpenFile} alt=""/>
            <p>Получилось 4 кампании по 2 креатива в каждой</p>
            <img src={screenListCampaigns} alt=""/>
            <img src={screenListPlacements} alt=""/>

            <h3 ref={five_two}>5.2 Вариант загрузки расширенным шаблоном</h3>
            <p>Данный способ имеет расширенное количество заполняемых полей, однако они не обязательны к заполнению.</p>
            <p>Скачиваем <button onClick={() => generateAndDownloadXlsx(fileFull, 'Шаблон_загрузки_расширенный')}>Шаблон загрузки расширенный.xlsx</button> и заполняем необходимые поля.</p>
            <p>Поля, которые необходимо заполнить:</p>
            <Note
                definitiones={[
                    {name:'CampaingName', description: 'название кампании - нейминг может быть любым'},
                    {name:'SourceName', description: 'источник, площадка, на которой будет размещение - например, Yandex, Getintent и т.д.'},
                    {name:'CampaignLandingPage', description: 'посадочная страница с utm-метками - https://targetads.io/?utm_source=yandex_direct&utm_medium=cpm&utm_campaign=targetads_banners_socdem'},
                    {name:'PlacementName', description: 'название креатива - нейминг может быть любым'},
                    {name:'PlacementLandingPage', description: 'посадочная страница с utm-метками - https://targetads.io/?utm_source=yandex_direct&utm_medium=cpm&utm_campaign=targetads_banners_socdem'},
                ]}
            />
            <p>Пример минимально заполненного файла <button onClick={() => generateAndDownloadXlsx(fileFullMin, 'Шаблон_загрузки_расширенный_пример_минимум.xlsx')}>Шаблон_загрузки_расширенный_пример_минимум.xlsx</button></p>
            <p>Пример заполненного файла с дополнительными полями <button onClick={() => generateAndDownloadXlsx(fileFullDop, 'Шаблон_загрузки_расширенный_пример.xlsx')}>Шаблон_загрузки_расширенный_пример.xlsx</button></p>


            <h2 ref={six}>6. Скачивание пикселей</h2>
            <p>Выделяем полученные кампании и выбираем удобный способ скачивания</p>
            <img src={screenDownloadPixel} alt=""/>
            <p>В полученном Excel файле есть пиксели для каждого креатива</p>
            <img src={screenExamplePixel} alt=""/>

            <p><strong>ImpressionPixel</strong> - пиксель для отслеживания показов</p>
            <p><strong>ImpressionPixel(js)</strong> - JavaScript версия пикселя, позволяет собирать больше данных (не используется в Яндекс Директ)</p>
            <p><strong>ClickPixel</strong> - кликовый пиксель, включает себя динамические параметры, которые указывались при настройке кампании</p>
            <p><strong>Квартели (дополнительные видео пиксели)</strong> - не используются в Яндексе</p>
        </div>
    )
}

export default DocsCreateCampaignFull;
