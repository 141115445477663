import {FC, useEffect} from 'react'
import cx from 'classnames';
import { Btn } from '../../../ui';
import '../MessageModal/style.scss';
import {useAppSelector} from "../../../redux/hooks";
import {currentUserLang} from "../../../redux/slices/user";
import {sources} from "../../../redux/slices/sources";
import {trans} from "../../../_locales";

interface IConfirmDeleteSources {
  message: any
  cancelText?: string
  confirmationText: string
  onCancelClick?: () => any
  onConfirmationClick: () => any
}

const ConfirmDeleteSources: FC<IConfirmDeleteSources> = ({
  message,
  cancelText,
  confirmationText,
  onCancelClick,
  onConfirmationClick
}) => {
  const language = useAppSelector(currentUserLang);
  const reduxSources = useAppSelector(sources);
  const buttonsClassName = cx(
    'message-modal__buttons',
      {
        ['message-modal__buttons--one-col']: !cancelText || !onCancelClick
      }
  );

    const getSourcesNameByIds = (sources: Array<any>, sourcesIds: Array<number>)=> {
      const result:string[] = [];
      if(sources) {
        sources.map((item:any)=> {
          if(sourcesIds.includes(item.id)) {
            result.push(item.name);
          }
        })
      }
      return result;
    }

  return (
    <div className="message-modal">
        {
            typeof message === 'object' && message.stats && Array.isArray(message.stats.source_for_confirmation) && message.stats.source_for_confirmation.length ?
            <div>
              <p>{trans('Do you want to delete sources that are not linked to campaigns?', language)}<br/>{trans('List of sources', language)}: {getSourcesNameByIds(reduxSources, message.stats.source_for_confirmation).join(', ')}</p>
            </div> : ''
        }

        {
            typeof message === 'object' && typeof message.errors === 'object' && Object.keys(message.errors).map((key)=>
              typeof message.errors[key] === 'string' ? <p key={key} className="message-modal__text">{key}: { trans(message.errors[key], language) }</p> : ''
            )
        }

      <div className={buttonsClassName}>
        {
          cancelText &&
          onCancelClick &&
          <Btn
            type='button'
            text={cancelText}
            size='lg'
            widthAuto={false}
            onClick={onCancelClick}
          />
        }
        <Btn
          type='button'
          text={confirmationText}
          color='blue'
          widthAuto={false}
          size='lg'
          onClick={onConfirmationClick}
        />
      </div>
    </div>
  );
};

export default ConfirmDeleteSources;
