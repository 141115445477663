import React, {FC, useState} from "react";
import Select from "../../ui/Select";
import Btn from "../../../ui/Btn";
import './style.scss';
import {useAppSelector} from "../../../redux/hooks";
import {currentUserLang} from "../../../redux/slices/user";
import {trans} from "../../../_locales";
import {onChangeGeneralSelect} from "../../ui/Select/functions";

interface IEditSelectModal {
    onSubmit:any,
    active?:string,
    dataset:any,
    onClose:any
}
const EditSelectModal:FC<IEditSelectModal> = ({
    onSubmit,
    active='',
    dataset={},
    onClose
}) => {

    const [selectActive, setSelectActive] = useState<string>(active ? active : 'Выберите нужный вариант');
    const [result, setResult] = useState<any>();
    const language = useAppSelector(currentUserLang);

    const submit = () => {
        if(result)
            onSubmit(result);
        onClose();
    }

    return (
        <div className='edit-select-modal'>
            <Select
                selectActive={selectActive}
                dataset={dataset}
                onChangeActive={(res:any)=> onChangeGeneralSelect(res, (key:any)=> {
                    setSelectActive(res[key]);
                    setResult(key);
                })}
                color={'dark'}
                search={true}
            />
            <div className={'edit-select-modal__action'}>
                <Btn
                  type='reset'
                  text={trans('Cancel', language)}
                  size='lg'
                  widthAuto={false}
                  onClick={onClose}
                />
                <Btn
                  type='submit'
                  text={trans('Save', language)}
                  color='blue'
                  widthAuto={false}
                  size='lg'
                  disabled={false}
                  onClick={submit}
                />
            </div>
        </div>
    )
}

export default EditSelectModal;