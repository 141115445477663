import {FC, useEffect} from 'react'
import cx from 'classnames';
import { Btn } from '../../../ui';
import { IMessageModal } from './interfaces.d'
import './style.scss';
import {useAppSelector} from "../../../redux/hooks";
import {currentUserLang} from "../../../redux/slices/user";
import {sources} from "../../../redux/slices/sources";
import {trans} from "../../../_locales";

const MessageModal: FC<IMessageModal> = ({
  message,
  cancelText,
  confirmationText,
  onCancelClick,
  onConfirmationClick
}) => {
  const language = useAppSelector(currentUserLang);
  const reduxSources = useAppSelector(sources);
  const buttonsClassName = cx(
    'message-modal__buttons',
      {
        ['message-modal__buttons--one-col']: !cancelText || !onCancelClick
      }
  );

    const ErrorsView = ({
        columns
    }: {
        columns: any
    }) => {
      return(
        <div className='message-modal__errors'>
            <span>{trans('Errors in file', language)}:</span>
            <ul className='message-modal-errors'>
                {Object.keys(columns).length > 0 && Object.keys(columns).map((key: string, index:number)=> (
                    typeof columns[key] == 'string' ?
                        <li key={index} className='message-modal__error'>{columns[key]}</li> :
                        typeof columns[key] === 'object' ?
                            <li key={index} className='message-modal-errors__item'>
                                <p>колонка &quot;{key}&quot;:</p>
                                <ul>
                                    {columns[key].err_in_positions && columns[key].err_in_positions.length && columns[key].err_in_positions.map((item: any, i:number)=> (
                                        <li key={i}>- Строка {item.row_num}: {trans(item.err_msg, language)}</li>
                                    ))}
                                </ul>
                            </li> : ''
                ))}
            </ul>
        </div>
      )
    }

    const ErrorActionView = ({errors}:{errors:any})=> {
        return(
            <div className='message-modal__errors'>
                <span>{trans('Errors', language)}:</span>
                <ul className='message-modal-errors'>
                    {Object.keys(errors).length > 0 && Object.keys(errors).map((key: string, index:number)=> (
                        typeof errors[key] == 'string' ?
                            <li key={index} className='message-modal__error'>- Строка {key}: {trans(errors[key], language)}</li> : ''
                    ))}
                </ul>
            </div>
        )
    }

    const getSourcesNameByIds = (sources: Array<any>, sourcesIds: Array<number>)=> {
      const result:string[] = [];
      if(sources) {
        sources.map((item:any)=> {
          if(sourcesIds.includes(item.id)) {
            result.push(item.name);
          }
        })
      }
      return result;
    }

  return (
    <div className="message-modal">
        { typeof message === 'object' && message.stats ? typeof message.stats.campaigns_created === 'number' ?
            <div>
                <p className="message-modal__text">{trans('Loaded', language)}:
                    {message.stats.campaigns_created} {trans('campaigns', language)},
                    {message.stats.placements_created} {trans('placements', language)},
                    {message.stats.sources_created} {trans('sources', language)}
                </p>
                <p className="message-modal__text">
                    {(message.stats.campaigns_updated || message.stats.placements_updated || message.stats.sources_updated) ? `${trans('Updated', language)}:
                    ${message.stats.campaigns_updated} ${trans('campaigns', language)},
                    ${message.stats.placements_updated} ${trans('placements', language)},
                    ${message.stats.sources_updated} ${trans('sources', language)}` : ''}
                </p>
            </div>
            : (typeof message.stats.inserted_count === 'number' || typeof message.stats.updated_count === 'number') &&
            <p className="message-modal__text">
                {trans('Loaded', language)}: { message.stats.inserted_count ? message.stats.inserted_count : 0},
                {trans('Updated', language)}: { message.stats.updated_count ? message.stats.updated_count : 0 }
            </p> :
            message.error_message ? <p className="message-modal__text">{ message.error_message }</p> :
            typeof message === 'string'?<p className="message-modal__text">{trans(message, language)}</p>:''
        }

        {
            typeof message === 'object' && message.error_message &&
            <p className="message-modal__text">{ message.error_message }</p>
        }
        {
            typeof message === 'object' && typeof message.errors === 'object' && Object.keys(message.errors).map((key)=>
              typeof message.errors[key] === 'string' ? <p key={key} className="message-modal__text">{key}: { trans(message.errors[key], language) }</p> : ''
            )
        }

        {
            typeof message === 'object' && message.stats && (Array.isArray(message.stats.campaign_with_confirmation) && message.stats.campaign_with_confirmation.length ?
            <div>
                <p className='message-modal__info'>{trans('Are you sure you want to delete all creatives from campaigns', language)}: {message.stats.campaign_with_confirmation.join(', ')}</p>
            </div> : Array.isArray(message.stats.source_for_confirmation) && message.stats.source_for_confirmation.length ?
            <div>
              <p className='message-modal__info'>{trans('Do you want to delete sources that are not linked to campaigns?', language)}<br/>{trans('List of sources', language)}: {getSourcesNameByIds(reduxSources, message.stats.source_for_confirmation).join(', ')}</p>
            </div> : '')
        }

        { message.errors && typeof message.errors.action === 'object' && Object.keys(message.errors.action).length > 0 && <ErrorActionView errors={message.errors.action} />}
        { message.errors && typeof message.errors.columns === 'object' && Object.keys(message.errors.columns).length > 0 && <ErrorsView columns={message.errors.columns} />}

      <div className={buttonsClassName}>
        {
          cancelText &&
          onCancelClick &&
          <Btn
            type='button'
            text={cancelText}
            size='lg'
            widthAuto={false}
            onClick={onCancelClick}
          />
        }
        <Btn
          type='button'
          text={confirmationText}
          color='blue'
          widthAuto={false}
          size='lg'
          onClick={onConfirmationClick}
        />
      </div>
    </div>
  );
};

export default MessageModal;
