import axios from 'axios'

const REQUEST_TIMEOUT = 360000;
axios.defaults.timeout = REQUEST_TIMEOUT;
const HttpCode = {
  SUCCESS: 200,
  UNAUTHORIZED: 401
};

const { UNAUTHORIZED } = HttpCode;

export const createAPI = (onUnauthorized: () => any, showErrorNetwork: () => any, sendLog: (data:any) => any, showDefaultErrorDetail: () => any,) => {
  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: REQUEST_TIMEOUT
  });
  api.defaults.timeout = REQUEST_TIMEOUT;

  const onSuccess = (response: any) => response;

  const onFail = (error: any) => {
    const { response, request, message, stack } = error;
    const stat = {
      response: response?.data,
      status: response?.status,
      url: request?.responseURL,
      message,
      stack,
      method: response?.config?.method,
      body: request?.__sentry_xhr__?.body,
      location: window.location.href
    }
    const log = {
      service: 'api',
      level: 'error',
      message: stat
    }
    sendLog(log);
    if(response && response.data && response.data.detail && typeof response.data.detail !== 'string') {
      showDefaultErrorDetail();
    }
    if (response && (response.status === UNAUTHORIZED || response.data === 'Invalid or expired JWT')) {
      onUnauthorized();
      throw error;
    }

    if (error.code === 'ERR_NETWORK') {
      showErrorNetwork();
      return;
    }

    throw error;
  };

  api.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  api.interceptors.response.use(onSuccess, onFail);

  return api;
};

