import {FC, useEffect, useState} from 'react'
import {Outlet, useNavigate} from "react-router-dom";
import { IBase } from '../Base/Base.d'
import {Header, Loader, SideMenu} from '../../components'
import cls from "../Base/Base.module.scss";
import "../style.scss";

const SimpleBase: FC<IBase> = ({
  children,
  headerData={},
  border
}) => {
  return (
      <div className='wrapper'>
        <Header {...headerData}/>
        <div className={`base__wrapper ${cls.settings_wrapper} ${border && cls.wrapper_border} base__alone`}>
          {children ? children : <Outlet/>}
        </div>
      </div>
  )
}

export default SimpleBase
