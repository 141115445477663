import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import {
    getTags,
    getTagById,
    createTag,
    updateTag
} from '../../actions/tags';

export interface Tag {
  id: number
  name: string
  project_id: number
  campaign_id?: number
}

export interface TagsState {
  tags: Tag[]
  newTags: Tag[]
  isLoading: boolean
  error: string | null
}

const initialState: TagsState = {
  tags: [] as Tag[],
  newTags: [] as Tag[],
  isLoading: false,
  error: null
}

export const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    setTags: (state, action) => {
      state.tags = action.payload;
    },
    setNewTags: (state, action: PayloadAction<Tag[] | null>) => {
      state.newTags = action.payload ? action.payload : [] as Tag[];
    },
    setNewTag: (state, action: PayloadAction<Tag>) => {
      if(action.payload) state.newTags.push(action.payload);
    },
    deleteNewTag: (state, action: PayloadAction<string>) => {
      if(action.payload) {
        const item = state.tags.find((tag) => tag.name  === action.payload);
        const index = item ? state.tags.indexOf(item) : -1;
        index && state.newTags.splice(index, 1)
      }
    },
    clearNewTags: (state) => {
      state.newTags = [] as Tag[];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      createTag.fulfilled,
      (
        state,
        action: PayloadAction<Tag>
      ) => {
        // state.sources.push(action.payload);
        // state.newTag = action.payload;
        state.isLoading = false;
        state.error = null;
      });
    builder.addCase
    (createTag.pending,
      (state) => {
        state.isLoading = true;
      });
    builder.addCase(
      createTag.rejected,
      (state,  action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload
      });
    builder.addCase(
      getTags.fulfilled,
      (state, action: PayloadAction<Tag[] | null>) => {
        state.tags = action.payload ? action.payload : [] as Tag[];
        state.isLoading = false;
        state.error = null;
      });
    builder.addCase
    (getTags.pending,
      (state) => {
        state.isLoading = true;
      });
    builder.addCase(
      getTags.rejected,
      (state,  action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload
      });
    builder.addCase(
      getTagById.fulfilled,
      (state) => {
        state.isLoading = false;
        state.error = null;
      });
    builder.addCase
    (getTagById.pending,
      (state) => {
        state.isLoading = true;
      });
    builder.addCase(
      getTagById.rejected,
      (state,  action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload
      });
    builder.addCase(
      updateTag.fulfilled,
      (state, action: PayloadAction<Tag>) => {
        const prev = state.tags.find((tag) => tag.id  === action.payload.id);
        const index = prev ? state.tags.indexOf(prev) : -1;

        if (prev) {
          state.tags[index] = action.payload;
        }

        state.isLoading = false;
        state.error = null;
      });
    builder.addCase
    (updateTag.pending,
      (state) => {
        state.isLoading = true;
      });
    builder.addCase(
      updateTag.rejected,
      (state,  action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload
      });
  }
})

export const {
    setTags,
    setNewTags,
    setNewTag,
    deleteNewTag,
    clearNewTags
} = tagsSlice.actions

export const tags = createSelector(
  (state: RootState) => state.tags,
  (tags) => tags.tags
)

export const newTags = createSelector(
  (state: RootState) => state.tags,
  (tags) => tags.newTags
)

export default tagsSlice.reducer
