import {FC, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {clearUser, currentUser, errorValue, getIsLoading, isAuthed, removeError} from '../../../redux/slices/user'
import {useAppDispatch, useAppSelector} from '../../../redux/hooks'
import {useShowError} from '../../../helpers'
import {AuthorizationForm, Loader} from '../../../components'
import {getAuthData} from '../../../utils';
import {checkAuthorization} from '../../../actions/users'
import {ReactComponent as ChartIcon} from '../../../assets/icons/chart.svg'
import {ReactComponent as LogoIcon} from '../../../assets/icons/logo.svg'
import './style.scss'

export enum TypeAuth {
  REGISTRATION = 'Зарегистрироваться',
  LOGIN = 'Войти',
  RECOVER = 'Отправить',
}

const RecoverPassword: FC = () => {
  const navigate = useNavigate();
  const authed = useAppSelector(isAuthed);
  const user = useAppSelector(currentUser);
  const isLoading = useAppSelector(getIsLoading);
  const errorMessage = useAppSelector(errorValue);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const { tokenIsAlive } = getAuthData();

    if (!tokenIsAlive) {
      dispatch(clearUser())
      return;
    }

    if (!authed) {
      dispatch(checkAuthorization())
    }
  }, [dispatch, authed])

  useEffect(()=> {
    if (authed) navigate('/projects')
  }, [authed, user, dispatch, navigate])

  useShowError(errorMessage as string, removeError);

  return (
    <>
      <div className="login">
        <div className="container container--no-padding">
          <div className="login__title-wrapper">
            <h1 className="title title--lg login__title">Простой сервис <span>глубокой аналитики</span></h1>
            <div className="login__chart">
              <ChartIcon width={720} height={450} />
            </div>
          </div>
          <div className="login__wrapper">
            <div className="login__form-wrapper">
              <div className="login__logo">
                <LogoIcon width={155} height={40} />
              </div>
              <div className="recover__wrapper">
                <div className="recover__title">Восстановление пароля</div>
                <AuthorizationForm
                  key={TypeAuth.RECOVER}
                  type={TypeAuth.RECOVER}
                />
                <button onClick={()=> navigate('/')} className='recover__back'>Назад</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <Loader />}
    </>
  );
};

export default RecoverPassword;
