import SimpleBase from "../../../layouts/SimpleBase";
import "../style.scss";
import {trans} from "../../../_locales";

const UserAgreement = () => {
    const breadcrumbsData = {
        data: [
            {href: '', title: 'Согласие на обработку персональных данных'},
        ]
    };
    return(
        <SimpleBase headerData={{breadcrumbsData}}>
            <div className='information'>
                <h1>Согласие на обработку персональных данных</h1>
                <p>1. Я даю свое согласие ООО &quot;ТАРГЕТ АДС&quot; (ИНН: 7751207185 КПП: 775101001 ОГРН: 1217700478412, дальше - Оператор) на обработку моих персональных данных: фамилия, имя; номер мобильного телефона; адрес электронной почты.</p>
                <p>2. Цели дачи согласия:</p>
                <ul className='information__list'>
                    <li>2.1. обработка заявки для обсуждения проекта и заключения договора с Оператором;</li>
                    <li>2.2. исполнение обязательств по договору, который заключен между Оператором и мной;</li>
                    <li>2.3. регистрация на Сервисе <a className='information__link' href="https://targetads.io/">https://targetads.io/</a> и использования его функционала.</li>
                </ul>
                <p>3. Моё согласие является конкретным, предметным, информированным, сознательным и однозначным.</p>
                <p>4. Оператор обрабатывает персональные данные следующими способами: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, блокирование, удаление, уничтожение.</p>
                <p>5. Оператор обрабатывает персональные данные как с использованием средств автоматизации, так и без использования таких средств.</p>
                <p>6. Оператор обрабатывает персональные данные в соответствии с Политикой в области обработки персональных данных, размещенной по адресу: <a className='information__link' href="https://targetads.io/">https://targetads.io/</a>.
                </p>
                <p>7. Согласие действует с момента его предоставления и до достижения цели Согласия или отзыва Согласия.</p>
                <p>8. Я вправе отозвать согласие путем направления Оператору заявления:</p>
                <ul className='information__list'>
                    <li>8.1. в бумажном виде по адресу: 108849, Г.МОСКВА, УЛ. БОРИСА ПАСТЕРНАКА, Д. 29, К. 1, КВ. 128;</li>
                    <li>8.2. в форме электронного документа по адресу электронной почты: <a className='information__link' href="mailto://info@targetads.io">info@targetads.io</a>.
                    </li>
                </ul>
                <p>9. Оператор рассматривает заявление в течение 10 рабочих дней с момента его получения.</p>
            </div>
        </SimpleBase>
    )
}

export default UserAgreement;