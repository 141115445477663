import {createSelector, createSlice} from "@reduxjs/toolkit";
import {mediaSlice} from "./media";
import {RootState} from "../index";

interface Upload {
    isUpload: boolean
}

const initialState: Upload = {
  isUpload: false
}

export const uploadSlice = createSlice({
    name: 'upload',
    initialState,
    reducers: {
    clearUpload: (state) => {
      state.isUpload = false;
    },
    setUpload: (state) => {
      state.isUpload = true;
    }
  }
})

export const { clearUpload, setUpload } = uploadSlice.actions;

export const getUploadIsLoading = createSelector(
  (state: RootState) => state.upload,
  (upload) => upload.isUpload
)

export default uploadSlice.reducer