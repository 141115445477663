import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'

interface InstructionsState {
  data?: IInstructionsParams
  isLoading: boolean
  error: string | null
}
interface IInstructionsParams {
    title: string,
    cb: any
}

const initialState: InstructionsState = {
  data: undefined,
  isLoading: false,
  error: null,
}

export const instructionsSlice = createSlice({
  name: 'instructions',
  initialState,
  reducers: {
    setInstructions: (state, action: PayloadAction<IInstructionsParams>) => {
      state.data = action.payload;
    },
    clearInstructions: (state) => {
      state.data = undefined
    },
  },
  extraReducers: (builder) => null
});

export const { setInstructions, clearInstructions } = instructionsSlice.actions


export const currentInstructions = createSelector(
  (state: RootState) => state.instructions,
  (instructions) => instructions.data
)


export default instructionsSlice.reducer