import {FC, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {clearUser, currentUser, errorValue, getIsLoading, isAuthed, removeError} from '../../redux/slices/user'
import {useAppDispatch, useAppSelector} from '../../redux/hooks'
import {useShowError} from '../../helpers'
import {Tabs} from '../../ui'
import {AuthorizationForm, Loader} from '../../components'
import {getAuthData} from '../../utils';
import {checkAuthorization} from '../../actions/users'
import {ReactComponent as ChartIcon} from '../../assets/icons/chart.svg'
import {ReactComponent as LogoIcon} from '../../assets/icons/logo.svg'
import {Components, Content} from '../../interfaces/common.d'
import './style.scss'
import {projectsData} from "../../redux/slices/projects";
import {downloadProjectsData} from "../../actions/projects";

export enum TypeAuth {
  LOGIN = 'Войти',
  REGISTRATION = 'Зарегистрироваться',
  RECOVER = 'Отправить',
  NEW_PASSWORD = 'Отправить',
}

const Authorization: FC = () => {
  const [registrationFormCleared, setRegistrationFormCleared] = useState(false);
  const navigate = useNavigate();
  const authed = useAppSelector(isAuthed);
  const user = useAppSelector(currentUser);
  const isLoading = useAppSelector(getIsLoading);
  const errorMessage = useAppSelector(errorValue);
  const reduxProjects = useAppSelector(projectsData);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const { tokenIsAlive } = getAuthData();

    if (!tokenIsAlive) {
      dispatch(clearUser())
      return;
    }

    if (!authed) {
      dispatch(checkAuthorization())
    }
  }, [dispatch, authed])

  useEffect(() => {
    if (user && user.verified) {
      // dispatch(openModal({
      //   name: ModalName.INFORMATION,
      //   data: {
      //     title: `Здравствуйте, ${user.name}!`,
      //     description: 'Регистрация прошла успешно, ожидайте активации администратором',
      //     icon: {
      //       Component: IconHand,
      //       width: 28,
      //       height: 28
      //     }
      //   }
      // }))

      setRegistrationFormCleared(true);
      setTimeout(() => setRegistrationFormCleared(false), 100)
    }
  }, [user, dispatch]);

  useEffect(()=> {
    if (authed) {
      dispatch(downloadProjectsData()).then((r)=> {
        if(typeof r.payload=== 'object' && r.payload.length > 0) {
          const projects = r.payload.filter((item:any) => item.status === 'active');
          if(projects.length === 1 && +projects[0].id !== 777) {
            navigate(`/projects/${projects[0].id}`);
          } else navigate('/projects');
        } else navigate('/projects');
      });
    }
  }, [authed, user, dispatch, navigate])

  useShowError(errorMessage as string, removeError);

  const tabComponents : Components<TypeAuth, Content> = {
    [TypeAuth.REGISTRATION]:
      <AuthorizationForm
        key={TypeAuth.REGISTRATION}
        type={TypeAuth.REGISTRATION}
        clearForm={registrationFormCleared}
      />,
    [TypeAuth.LOGIN]:
      <AuthorizationForm
        key={TypeAuth.LOGIN}
        type={TypeAuth.LOGIN}
        isRecover={true}
      />,
    [TypeAuth.RECOVER]:
      <AuthorizationForm
        key={TypeAuth.RECOVER}
        type={TypeAuth.RECOVER}
      />,
  };

  return (
    <>
      <div className="login">
        <div className="container container--no-padding">
          <div className="login__title-wrapper">
            <h1 className="title title--lg login__title">Простой сервис <span>глубокой аналитики</span></h1>
            <div className="login__chart">
              <ChartIcon width={720} height={450} />
            </div>
          </div>
          <div className="login__wrapper">
            <div className="login__form-wrapper">
              <div className="login__logo">
                <LogoIcon width={155} height={40} />
              </div>
              <Tabs tabNames={TypeAuth} tabComponents={tabComponents}/>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <Loader />}
    </>
  );
};

export default Authorization;
