import {FC, useCallback, useEffect, useState} from 'react'
import cx from 'classnames'
import { ReactComponent as IconRocket } from '../../assets/icons/icon-rocket.svg'
import { ReactComponent as IconSad } from '../../assets/icons/icon-sad.svg'
import { ReactComponent as IconSetting } from '../../assets/icons/icon-setting.svg'
import { ReactComponent as IconHello } from '../../assets/icons/icon-hand.svg'
import { ReactComponent as CloseIcon } from '../../assets/icons/icon-close-small.svg'
import { Portal } from '../index'
import { Btn } from '../../ui'
import { PopupTitle, PopupType } from '../../data'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { closePopup, popupData } from '../../redux/slices/popup'
import './MessagePopup.scss'
import {trans} from "../../_locales";
import {currentUserLang} from "../../redux/slices/user";

const MessagePopup: FC = () => {
  const [isClose, setIsClose] = useState(false);
  const dispatch = useAppDispatch();
  const data = useAppSelector(popupData);
  const language = useAppSelector(currentUserLang);

  const isDataEmpty = Object.keys(data).length === 0;

  const handlePopupClose = useCallback(() => {
    setIsClose(true);
    setTimeout(() => {
      dispatch(closePopup());
      setIsClose(false);
    }, 400);
  }, [dispatch]);

  useEffect(() => {
    if (isDataEmpty) {
      return;
    }
    const autoCloseTimer = setTimeout(() => handlePopupClose(), 10000);
    return () => clearTimeout(autoCloseTimer);
  }, [isDataEmpty, handlePopupClose]);

  if (isDataEmpty) return null;

  const title = data.type === PopupType.SUCCESS ?
    PopupTitle[PopupType.SUCCESS] : data.type === PopupType.WARNING ? PopupTitle[PopupType.WARNING] :PopupTitle[PopupType.ERROR];

  const choiceIcon = (type:string) => {
    if(type === 'setting')
      return IconSetting;
    if(type === 'hello')
      return IconHello;
    if(type === 'error')
      return IconSad;
    if(type === 'rocket')
      return IconRocket;
  }
  const Icon:any = data.icon ? choiceIcon(data.icon) :
      data.type === PopupType.SUCCESS ?
        IconRocket : IconSad;
  const iconWidth = data.type === PopupType.SUCCESS ? 24 : 22;
  const iconHeight = data.type === PopupType.SUCCESS ? 24 : 22;
  const messagePopupClassName = cx(
    'message-popup',
    {
      ['is-error']: data.type === PopupType.ERROR || data.type === PopupType.WARNING,
      ['is-close']: isClose,
    }
  );

  return (
    <Portal selector='#modal-root'>
      <div className={messagePopupClassName}>
        <div className="message-popup__icon">
          <Icon width={iconWidth} height={iconHeight}/>
        </div>
        <div className="message-popup__wrapper">
          <span className="message-popup__title">{data.title ? data.title : title}</span>
          {data.description && <p className="message-popup__description">{trans(data.description, language)}</p>}
        </div>
        <Btn
          className={'message-popup__close'}
          isIconBtn={true}
          size='sm'
          color='transparent'
          onClick={handlePopupClose}
          icon={{
            Component: CloseIcon,
            width: 10,
            height: 10
          }}
        />
      </div>
    </Portal>
  );
};

export default MessagePopup;
