import {DefaultObject} from "../../interfaces/common.d";
import {IColumnPreferences, IOnChangeFilter, IPinColumns} from "./interfaces";
import {changeFilter} from "./TablePagination/functions";
import {keyToSkip} from "../../data";

export const getMinLengthTable = ({
    columnPreferences=[],
    data=[],
    metricChoice=[],
    aggColIndexes=[],
    metricColIndexes=[]
}: {
    columnPreferences: Array<any>,
    data: Array<Array<any>>,
    metricChoice: Array<string>,
    aggColIndexes: Array<number>,
    metricColIndexes: Array<number>
}) => {
    const temp = [];
    if (columnPreferences.length > 0 && data.length > 0) {
        data[0].map((value: string, key: number) => {
            if (value !== keyToSkip && ((metricChoice.length) ? (aggColIndexes.includes(key) || metricColIndexes.includes(key)) : true) && typeof columnPreferences === 'object' && columnPreferences[key]) {
                temp.push(value);
            }
        })
    }
    return 150 * temp.length;
}


export const onPin = ({
    index,
    activePin={},
    activeStretch,
    minWidth=150
}:{
    index:number,
    activePin:IPinColumns,
    activeStretch: DefaultObject<number>,
    minWidth?: number
}) => {
    const temp = {...activePin};
    if (temp[index]) {
        delete temp[index];
        if (Object.keys(temp).length > 0) {
            const first_key = Object.keys(temp)[0];
            temp[first_key] = {
                left: 0
            }
        }
        return temp;
    }
    if (Object.keys(temp).length > 1) return;
    if (Object.keys(temp).length > 0) {
        const first_key = Object.keys(temp)[0];
        if (+first_key < index) {
            temp[index] = {
                left: activeStretch[first_key] ? activeStretch[first_key] : minWidth
            }
        } else {
            temp[index] = {
                left: 0
            }
            temp[first_key] = {
                left: activeStretch[index] ? activeStretch[index] : minWidth
            }
        }
    } else {
        temp[index] = {
            left: 0
        }
    }

    return temp;
}


export const handlePin = ({
    activePin,
    activeStretch
}: {
    activePin:IPinColumns,
    activeStretch: DefaultObject<number>
}) => {
    if (Object.keys(activeStretch).length > 0 && Object.keys(activePin).length > 1) {
        try {
            const temp = {...activePin};
            const key_a = Object.keys(temp)[0];
            const key_b = Object.keys(temp)[1];
            if (key_a < key_b && activeStretch[key_a]) {
                temp[key_b] = {
                    left: activeStretch[key_a] ? activeStretch[key_a] : 150
                }
            } else {
                temp[key_a] = {
                    left: activeStretch[key_b] ? activeStretch[key_b] : 150
                }
            }
            return temp;
        } catch (e) {
            console.warn(e);
        }
    }
}

export const findLastUniqueTypeIndices = ({
    data,
    wrapperItem=[]
}: {
    data: IColumnPreferences,
    wrapperItem: Array<string | number>
}): number[] => {
    const uniqueTypes = new Set<string>();
    const lastUniqueTypeIndices = [];

    for (let i = data.length - 1; i >= 0; i--) {
        if (wrapperItem[i] && wrapperItem[i] !== keyToSkip) {
            const item = data[i];
            if (!uniqueTypes.has(item.type)) {
                uniqueTypes.add(item.type);
                lastUniqueTypeIndices.push(i);
            }
        }
    }

    return lastUniqueTypeIndices;
};