import {FC, useRef, useState} from "react";
import {ReactComponent as UploadIcon} from '../../assets/icons/upload.svg';
import {Btn} from "../../ui";

import './style.scss';
import {useAppSelector} from "../../redux/hooks";
import {getUploadIsLoading} from "../../redux/slices/upload";
import Loader from "../Loader";
import {currentUser, currentUserLang} from "../../redux/slices/user";
import {trans} from "../../_locales";
import {useParams} from "react-router-dom";
import iconDocument from '../../assets/icons/document.svg';
import iconClose from '../../assets/icons/icon-close.svg';
import {projectGeneral} from "../../redux/slices/project";


interface IUploadContent {
    title: string;
    subtitle: string;
    info: {
            title: string;
            dataset: string[]
    }[]
    updateFilesCb:any
    multiple?:boolean
    maxFileSizeInBytes?:number
    example:string,
    type?: 'targets' | 'spent'
}

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 50000000;

const UploadContent:FC<IUploadContent> = ({
    title,
    subtitle,
    info,
    updateFilesCb,
    multiple=false,
    example,
    maxFileSizeInBytes=DEFAULT_MAX_FILE_SIZE_IN_BYTES,
    type='spent'
}) => {
    const uploadIsLoading = useAppSelector(getUploadIsLoading);
    const language = useAppSelector(currentUserLang);
    const reduxUser = useAppSelector(currentUser);
    const currentProject = useAppSelector(projectGeneral);
    const { projectId } = useParams();
    const permissions = reduxUser && reduxUser.permission ? reduxUser.permission[`${projectId}`] : undefined;
    const isView = permissions && permissions['can_view'] && permissions['can_view'].includes('upload') || currentProject && currentProject.owner_id === reduxUser.id;
    const isCreate = permissions && permissions['can_create'] && permissions['can_create'].includes('upload') || currentProject && currentProject.owner_id === reduxUser.id;
    const isEdit = permissions && permissions['can_edit'] && permissions['can_edit'].includes('upload') || currentProject && currentProject.owner_id === reduxUser.id;
    const isDelete = permissions && permissions['can_delete'] && permissions['can_delete'].includes('upload') || currentProject && currentProject.owner_id === reduxUser.id;
    const [flagUpload, setFlagUpload] = useState(false);
    const [file, setFile] = useState<any>();

    const onFileChosen = (evt: any) => {
        const formData = new FormData();
        formData.append('csv_file', evt.currentTarget.files[0]);
    };

    const convertNestedObjectToArray = (nestedObj:any) =>
        Object.keys(nestedObj).map((key) => nestedObj[key]);

    const fileInputField = useRef(null);
    const [files, setFiles] = useState<any>({});

    const handleUploadBtnClick = () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fileInputField.current.click();
    };

    const addNewFiles = (newFiles:any) => {
        for (const file of newFiles) {
          if (file.size <= maxFileSizeInBytes) {
            if (!multiple) {
              return { file };
            }
            files[file.name] = file;
          }
        }
        return { ...files };
    };

    const [scale, setScale] = useState('day');

    const callUpdateFilesCb = (files:any) => {
        const filesAsArray = convertNestedObjectToArray(files);
        setFlagUpload(true);
        setFile(filesAsArray);
        // updateFilesCb(filesAsArray, scale);
    };

    const handleNewFileUpload = (e:any) => {
        const { files: newFiles } = e.target;
        if (newFiles.length) {
          const updatedFiles = addNewFiles(newFiles);
          setFiles(updatedFiles);
          callUpdateFilesCb(updatedFiles);
        }
    };


    return (
        <div className='upload-content'>
            <h1 className='upload-content__title'>
                {title}
            </h1>
            <p className='upload-content__subtitle'>
                {subtitle}
            </p>
            <div className='upload-content__info'>
                {
                    info.map((item:any, index:number)=> (
                      <div key={index} className='upload-content__instruction'>
                        <p className='upload-content__text'>{item.title}:</p>
                        <ul className='upload-content__list'>
                            {
                                item.dataset.map((item:string, index:number)=>(
                                    <li key={index}>{index+1}) {item}</li>
                                ))
                            }
                        </ul>
                      </div>
                    ))
                }
            </div>
            <a className='upload-content__link' href={example} download>{trans('Download an example', language)}</a>

            {!flagUpload ? <>
                <div className='upload-content-type'>
                    <label className="upload-content-type__item">
                        <input type="radio" name="radio" value="day" checked={scale == 'day'} onChange={(event)=> setScale(event.target.value)}/>
                        <span>{trans(`Upload daily ${type === 'targets' ? 'targets' : 'expense'}`, language)}</span>
                    </label>
                    <label className="upload-content-type__item">
                        <input type="radio" name="radio" value="month" checked={scale == 'month'} onChange={(event)=> setScale(event.target.value)}/>
                        <span>{trans(`Upload monthly ${type === 'targets' ? 'targets' : 'expense'}`, language)}</span>
                    </label>
                </div>

                <div className='upload-content-drag'>
                    <p className='upload-content-drag__title'>{title}</p>
                    <Btn
                      text={trans('Upload your file', language)}
                      icon={{
                        Component: UploadIcon,
                        width: 12,
                        height: 12,
                      }}
                      color='light-blue'
                      size='md'
                      isDownloadBtn={true}
                      onFileChosen={handleNewFileUpload}
                      disabled={isView && !isCreate && !isEdit && !isDelete}
                    />
                    <span className='upload-content-drag__text'>{trans('Or drag it to this area', language)}</span>
                    <input
                      className='upload-content-drag__formUpload'
                      type="file"
                      accept=".csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      ref={fileInputField}
                      onChange={handleNewFileUpload}
                      title=""
                      value=""
                      multiple
                      disabled={isView && !isCreate && !isEdit && !isDelete}
                    />
                </div>
            </> : file &&
            <div className='upload-content-preview'>
                <p>{scale == 'day' ?
                        `${trans('Upload daily expense', language)}?` :
                        `${trans('Upload monthly expense', language)}?`}
                </p>
                <div className='upload-content-preview__block'>
                    <img src={iconDocument} alt=""/>
                    <span>{file[0] && file[0].name}</span>
                    <button onClick={()=>setFlagUpload(false)}><img src={iconClose} alt=""/></button>
                </div>
                <Btn
                  text={trans('Upload', language)}
                  color='blue'
                  size='md'
                  onClick={()=>{updateFilesCb(file, scale); setFlagUpload(false)}}
                  disabled={isView && !isCreate && !isEdit && !isDelete}
                />
            </div>
            }

            {uploadIsLoading && <Loader />}
        </div>
    )
}

export default UploadContent;