import {FC, useState} from "react";
import {useNavigate} from "react-router-dom";
import { Loader} from "../../../components";
import {ReactComponent as ChartIcon} from "../../../assets/icons/chart.svg";
import {ReactComponent as LogoIcon} from "../../../assets/icons/logo.svg";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {getIsLoading} from "../../../redux/slices/user";
import './style.scss'
import {createAsyncThunk} from "@reduxjs/toolkit";
import {login} from "../../../actions/users";

const resetPassword: FC = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const navigate = useNavigate();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const dispatch = useAppDispatch();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const isLoading = useAppSelector(getIsLoading);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [inputNewPassword, setInputNewPassword] = useState('');
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [inputRepeatPassword, setInputRepeatPassword] = useState('');

    const setNewPassword = createAsyncThunk(
        'user/setNewPassword',
        async (
          _,
          { rejectWithValue, extra: API}: any
        ) => {
          try {
            const token = getToken();
            const data = {
                "new_password": inputNewPassword,
                "token": token
            };
            const response = await API.post(`/api/auth/password/new/`, data);
            return response.data;
          } catch (e: any) {
            return rejectWithValue(e.response.data.detail);
          }
        }
    );
    const getToken = () => {
        const loc:string = window.location.href;
        const regexp = new RegExp(`token=([^&]*)`);
        const searchParams = loc.match(regexp);
        return searchParams ? searchParams[1] : '';
    }
    const checkPassword = (one:string, two:string):boolean => {
        return one !== '' && one === two;
    }

    const onHandlerNewPassword = () => {
        if(checkPassword(inputNewPassword, inputRepeatPassword)) {
            dispatch(setNewPassword()).then( (r)=> {
                if(r.payload && typeof r.payload === 'string') {
                    dispatch(login({email: r.payload, password: inputNewPassword}));
                    navigate('/');
                    return;
                }
            })
        }
    }

    return(
       <>
      <div className="login">
        <div className="container container--no-padding">
          <div className="login__title-wrapper">
            <h1 className="title title--lg login__title">Простой сервис <span>глубокой аналитики</span></h1>
            <div className="login__chart">
              <ChartIcon width={720} height={450} />
            </div>
          </div>
          <div className="login__wrapper">
            <div className="login__form-wrapper">
              <div className="login__logo">
                <LogoIcon width={155} height={40} />
              </div>
              <div className="recover__wrapper">
                  <div className="recover__title">Восстановление пароля</div>
                  <div className="recover-input">
                      <label>
                          <input
                              type="password"
                              name="new_password"
                              placeholder="Пароль"
                              value={inputNewPassword}
                              onChange={(e) => setInputNewPassword(e.target.value)}/>
                      </label>
                      <div className="recover-input__error">Введите пароль</div>
                  </div>
                  <div className="recover-input">
                      <label>
                          <input
                              type="password"
                              name="repeat_password"
                              placeholder="Подтвердите Пароль"
                              value={inputRepeatPassword}
                              onChange={(e) => setInputRepeatPassword(e.target.value)}/>
                      </label>
                      <div className="recover-input__error">Введите пароль</div>
                  </div>
                  <button className="recover-input__btn" onClick={onHandlerNewPassword}>
                    <span className="btn__text">Отправить</span>
                  </button>
              </div>

            </div>
          </div>
        </div>
      </div>
      {isLoading && <Loader />}
    </>
    )
}

export default resetPassword;