import React, {FC, useEffect, useState} from 'react'
import cx from 'classnames'
import { IHeaderTable } from './HeaderTable.d'
import './HeaderTable.scss'
import {useAppSelector} from "../../redux/hooks";
import {currentUserLang} from "../../redux/slices/user";
import {trans} from "../../_locales";
import {Toggle} from "../../ui";

const HeaderTable: FC<IHeaderTable> = ({ className, isProject ,onChangeSort, onAllChecked, allChecked=false}) => {
  const language = useAppSelector(currentUserLang);
  const headerClassName = cx(
    'table',
    'table--header',
    {
      'table--project': isProject
    },
    className
  );
  const projectTable = [
    // trans("Choice", language),
    trans("Name", language),
    trans("Status", language),
    trans("Author", language),
    trans("Creation date", language),
    ''
  ];

const campaignTable = [
    trans("Choice", language),
    trans("Status", language),
    trans("Name", language),
    trans("Impressions", language),
    trans("Clicks", language),
    trans("Reach", language),
    trans("Cost", language),
    trans("Target events", language),
    trans("Cost of target events", language),
    trans("Creation date", language),
    ''
  ];
  const data = isProject ? projectTable : campaignTable;

  const [isChecked, setIsChecked] = useState(false);
  useEffect(()=> {
      setIsChecked(allChecked);
  }, [allChecked])

  return (
    <div className={headerClassName}>
      <table>
        <thead>
            <tr>
              {
                data.map((item:string, index:number)=> (
                    item ?
                    <th key={index} className={!isProject && (index=== 0 || index === 1) ? 'not-sort' : ''} onClick={(event)=>{
                        if(isProject || (index!== 0 && index !== 1)) onChangeSort(event, index);
                    }}>
                      <div className="table__title">
                        {item!==trans("Choice", language) ?
                            <span>{item}</span>
                            : <div className='table__choice'>
                                <Toggle
                                    name='all-choice'
                                    borderMod={true}
                                    onChange={(evt)=>{
                                        // setIsChecked(evt.currentTarget.checked);
                                        onAllChecked(evt.currentTarget.checked);
                                    }}
                                    isChecked={isChecked}
                                  />
                            </div>
                        }
                        <svg className='table__arrow' width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.6 1C5.6 0.668629 5.33137 0.4 5 0.4C4.66863 0.4 4.4 0.668629 4.4 1L5.6 1ZM4.57574 11.4243C4.81005 11.6586 5.18995 11.6586 5.42426 11.4243L9.24264 7.60589C9.47696 7.37157 9.47696 6.99167 9.24264 6.75736C9.00833 6.52304 8.62843 6.52304 8.39411 6.75736L5 10.1515L1.60589 6.75736C1.37157 6.52304 0.991674 6.52304 0.757359 6.75736C0.523045 6.99167 0.523045 7.37157 0.757359 7.60589L4.57574 11.4243ZM4.4 1L4.4 11L5.6 11L5.6 1L4.4 1Z" fill="transparent"/>
                        </svg>
                      </div>
                    </th> :
                    <th key={index}></th>
                ))
              }
            </tr>
        </thead>
      </table>
    </div>
  )
}

export default HeaderTable