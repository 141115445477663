import { FC } from "react";
import "./style.scss";
import Code from "../Code";


interface INoteDefinition {
    name: string, 
    description: string,
}

interface INote {
    text?:string, 
    definitiones?:Array<INoteDefinition>,
    code?:string
}

const Note:FC<INote> = ({text, definitiones, code}) => {
    return (
        <div className="note">
            <div className="note__content">
                {
                    definitiones && definitiones.map((item:INoteDefinition, index:number)=> (
                        <p key={index}><strong>{item.name}</strong> {item.description?'- '+item.description: ''}</p>
                    ))
                }
                {code && <Code code={code} />}
                {text && <p>{text}</p>}
            </div>
        </div>
    )
}

export default Note;