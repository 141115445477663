import {FC, useEffect, useRef, useState} from "react";
import {DefaultObject} from "../../../interfaces/common.d";
import {useAppSelector} from "../../../redux/hooks";
import {projectGeneral} from "../../../redux/slices/project";
import {currentUserLang} from "../../../redux/slices/user";
import {eventsData} from "../../../redux/slices/events";
import {
    aggregateData, changeFilter, downloadReport, getColorValue, getCountPage,
    getFuncIndexes, getMaxTargetPrice,
    getStringColIndexes,
    getTotalValues,
    groupByIndex, handleMouseMove
} from "../TablePagination/functions";
import {trans} from "../../../_locales";
import {findLastUniqueTypeIndices, getMinLengthTable, handlePin, onPin} from "../functions";
import Loader from "../../Loader";
import Empty from "../../Empty";
import Pagination from "../../ui/Pagination";
import TableInteractiveBody from "./TableBody";
import TableInteractiveHead from "./TableHead";
import TableInteractiveLabels from "./TableLabels";
import { ReactComponent as FullScreenIcon } from '../../../assets/icons/fullscreen.svg'
import { ReactComponent as FullScreenExitIcon } from '../../../assets/icons/fullscreen-exit.svg'
import { ReactComponent as ExcelIcon} from '../../../assets/icons/excel-file.svg';
import { ReactComponent as CsvIcon} from '../../../assets/icons/csv-file.svg';

import {
    IColumnPreferences, IColumnPreferencesItem, IFilterArrayColumns,
    IFilterColumns,
    IOnChangeFilter,
    ISearchColumns, ISortArrayColumns,
    ISortColumns,
    IStretchList, ITableDownload
} from "../interfaces";
import "./style.scss";
import {useElementWidth} from "../../../hooks/useElementWidth";
import cx from "classnames";

interface ITableInteractive {
    data: Array<any>
    title: Array<string>,
    subDescription?: string,
    columnPreferences?: IColumnPreferences,
    fieldTypes: Array<string>
    sortFront: boolean
    onChangeLimitChannel?: any
    pageCount?: number
    loader?: boolean
    dateGrouping?: string
    name: string
    disableName?: boolean
    tooltip?: string
    onRepeat?:any
    aggFunctions?:Array<IColumnPreferencesItem['agg_function']>
    aggColIndexes?:number[]
    aggChoice?:string[]
    aggChoiceTitles?:string[]
    metricColIndexesDefault?:number[],
    metricChoice?:string[],
    offFilter?:boolean
    serverGroup?:boolean
    targetEvents?:any
    offAgg?:boolean
    isDisableOptions?:boolean
    tabs?:DefaultObject<string>
    activeTab?:string,
    onChangeTab?:any
    isFullScreen?:boolean,
    onOpenFullScreen?:any,
    onCloseFullScreen?:any,
    isBigData?:boolean,
    defaultRowsOnPage?: number,
    defaultSearch?:ISearchColumns
    defaultFilter?:IFilterColumns
    defaultSort?: ISortColumns,
    stretch?:IStretchList,
    onChangeActiveFilter?:any,
    onChangeActiveSearch?:any,
    onChangeActiveSort?:any,
    hideTotal?:boolean,
    settings?:DefaultObject<any>,
    onChangeSettings?: any,
    posTooltip?: 'left' | 'right',
    limit?: number,
    offset?: number,
    onDownload?: (data:ITableDownload) => void,
    isUpdateByNoActual?: boolean,
    isNoActual?: boolean,
    onChangeActual?: (data: boolean) => void,
    typeKeyFilter?: 'name' | 'index',
}


const TableInteractive: FC<ITableInteractive> = ({
    data = [],
    title,
    subDescription,
    columnPreferences = [],
    fieldTypes,
    sortFront = true,
    onChangeLimitChannel,
    pageCount,
    loader = true,
    dateGrouping,
    name,
    disableName = false,
    tooltip,
    onRepeat,
    aggFunctions = [],
    aggColIndexes = [],
    aggChoice = [],
    aggChoiceTitles = [],
    metricColIndexesDefault = [],
    metricChoice = [],
    offFilter = false,
    serverGroup = false,
    targetEvents,
    stretch,
    offAgg = false,
    isDisableOptions = false,
    tabs,
    activeTab,
    onChangeTab,
    isFullScreen = false,
    onOpenFullScreen,
    onCloseFullScreen,
    isBigData = false,
    defaultRowsOnPage = 10,
    defaultSort = {},
    defaultSearch = {},
    defaultFilter = {},
    onChangeActiveFilter = (val: any) => null,
    onChangeActiveSearch = (val: any) => null,
    onChangeActiveSort = (val: any) => null,
    hideTotal = false,
    settings,
    onChangeSettings,
    posTooltip,
    onDownload = () => null,
    isUpdateByNoActual=false,
    isNoActual=false,
    onChangeActual = ()=> null,
    offset=0,
    limit,
    typeKeyFilter='index'
}) => {
    const generalProjectInfo = useAppSelector(projectGeneral);
    const language = useAppSelector(currentUserLang);
    const reduxEvents = useAppSelector(eventsData);
    const currency = generalProjectInfo && generalProjectInfo.currency || 'USD';

    const [dataWrapper, setDataWrapper] = useState<Array<any>>([]);
    const [dataReady, setDataReady] = useState(false);
    const [dataCopy, setDataCopy] = useState<Array<any>>(JSON.parse(JSON.stringify(data)));
    const [itemCountPercent, setItemCountPercent] = useState<Array<any>>([]);
    const [itemCountPercentCopy, setItemCountPercentCopy] = useState<Array<any>>([]);
    const [dataTotal, setDataTotal] = useState<Array<any>>([]);
    const [countTotal, setCountTotal] = useState<number>(1);
    const [sumNotIncludeData, setSumNotIncludeData] = useState<any>({});
    const [countNotIncludeData, setCountNotIncludeData] = useState<any>(0);

    const [aggColIndexesDefault, setAggColIndexes] = useState<Array<any>>(aggColIndexes.length > 0 ? aggColIndexes : getStringColIndexes(fieldTypes, title))
    const [selectActive, setSelectActive] = useState<string>(trans('Select aggregation', language));
    const [selectDataset, setSelectDataset] = useState<any>({});
    const [aggUpdate, setAggUpdate] = useState<boolean>(true);
    const arrStringFunc = getFuncIndexes(aggFunctions, 'string');

    const [metricColIndexes, setMetricColIndexes] = useState<Array<any>>(metricColIndexesDefault.length > 0 ? metricColIndexesDefault : []);
    const [selectMetricActive, setSelectMetricActive] = useState<string>(trans('Select metric', language));
    const [selectMetricDataset, setSelectMetricDataset] = useState<any>({});

    const [noScroll, setNoScroll] = useState<boolean>(true);

    const [lastElementsIndexesWithTypes, setLastElementsIndexesWithTypes] = useState<Array<number>>([]);

    useEffect(()=> {
        const tempSettings:any = {
            agg: aggColIndexes.length > 0 ? aggColIndexes : getStringColIndexes(fieldTypes, title),
            metrics: metricColIndexesDefault.length > 0 ? metricColIndexesDefault : []
        }
        if(aggColIndexes.length > 0) {
            if(arrStringFunc.length > 0) {
                tempSettings.agg = aggColIndexesDefault.concat(arrStringFunc).sort((a, b) => a - b);
            } else {
                tempSettings.agg = aggColIndexes;
            }
        }
        if(navigator && navigator.platform.includes('Win')) {
            setNoScroll(false)
        }

        if(settings) {
            tempSettings.agg = settings.agg?settings.agg:[];
            tempSettings.metrics = settings.metrics?settings.metrics:[];
        } else if(onChangeSettings) {
            onChangeSettings(tempSettings);
        }
        setAggColIndexes(tempSettings.agg);
        setMetricColIndexes(tempSettings.metrics);
        setDefaultSetting(tempSettings);

    }, [])
    const onChangeSelect = (response:any) => {
        try {
            setAggUpdate(true);
            const tempIndexes:any = []
            const tempTitles:any = []
            Object.keys(response).map((index:any)=> {
                if(response[index].checked) {
                    tempIndexes.push(+index);
                    tempTitles.push(response[index].name)
                }
            })
            if(tempTitles.length === 0)
                setSelectActive(trans('Select aggregation', language));
            else
                setSelectActive(tempTitles.join(', '));
            setAggColIndexes([...tempIndexes].sort((a, b)=> a-b));
            if(onChangeSettings)
                onChangeSettings({...defaultSetting, metrics: metricColIndexes, agg: [...tempIndexes].sort((a, b)=> a-b)});
            // setAggUpdate(false);
        } catch (e) {
            console.log('Error - ', e);
        }
    }
    const onChangeMetricSelect = (response:any) => {
        try {
            setAggUpdate(true);
            const tempIndexes:any = []
            const tempTitles:any = []
            Object.keys(response).map((index:any)=> {
                if(response[index].checked) {
                    tempIndexes.push(+index);
                    tempTitles.push(response[index].name)
                }
            })

            if(tempTitles.length === 0)
                setSelectMetricActive(trans('Select metric', language));
            else
                setSelectMetricActive(tempTitles.join(', '));
            setMetricColIndexes([...tempIndexes].sort((a, b)=> a-b));
            if(onChangeSettings)
                onChangeSettings({...defaultSetting, agg: aggColIndexesDefault, metrics: [...tempIndexes].sort((a, b)=> a-b)});
            // setAggUpdate(false);
        } catch (e) {
            console.log('Error - ', e);
        }
    }
    useEffect(()=> {
        if(aggChoice.length > 0 && columnPreferences.length > 0) {
            try {
                setAggUpdate(true);
                const dataset:any = {};
                const datasetMetrics:any = {};
                aggChoice.map((item:any)=> {
                    const index = title.indexOf(item);
                    if(index !== -1)
                        dataset[index] = columnPreferences[index].title;
                })
                setSelectDataset(dataset);
                const tempTitlesStretches:string[] = [];
                if(stretch && stretch.length > 0) {
                    stretch.map((item)=> {
                        if(item.list) {
                            for(let i = 0;i < item.list.length; i++) {
                                tempTitlesStretches.push(item.title?item.title:'');
                            }
                        }
                    })
                }
                metricChoice.map((item:any)=> {
                    const index = title.indexOf(item);
                    if(index !== -1) {
                        datasetMetrics[index] = columnPreferences[index].title;
                        if(tempTitlesStretches[index]) {
                            datasetMetrics[index] += ` (${trans(tempTitlesStretches[index], language)})`;
                        }
                    }
                })
                setSelectMetricDataset(datasetMetrics);
                if(Object.values(dataset).length > 0 && aggColIndexesDefault.length) {
                    const temp:Array<string> = [];
                    aggColIndexesDefault.map((index)=> {
                        temp.push(columnPreferences[+index].title)
                    })
                    setSelectActive(temp.join(', '))
                } else {
                    // const index = title.indexOf(aggChoice[0]);
                    // if(index !== -1 && columnPreferences[index]) {
                        // setSelectActive(columnPreferences[index].title);
                        // setAggColIndexes([index])
                    // }
                }
            } catch (e) {
                console.log('Error - ', e);
            }
            setAggUpdate(false);
        }else if(metricChoice.length > 0 && columnPreferences.length > 0) {
            try {
                setAggUpdate(true);
                const datasetMetrics:any = {};
                const tempTitlesStretches:string[] = [];
                if(stretch && stretch.length > 0) {
                    stretch.map((item)=> {
                        if(item.list) {
                            for(let i = 0;i < item.list.length; i++) {
                                tempTitlesStretches.push(item.title?item.title:'');
                            }
                        }
                    })
                }
                metricChoice.map((item:any)=> {
                    const index = title.indexOf(item);
                    if(index !== -1) {
                        datasetMetrics[index] = columnPreferences[index].title;
                        if(tempTitlesStretches[index]) {
                            datasetMetrics[index] += ` (${trans(tempTitlesStretches[index], language)})`;
                        }
                    }
                })
                setSelectMetricDataset(datasetMetrics);
            } catch (e) {
                console.log('Error - ', e);
            }
            setAggUpdate(false);
        }
    }, [])

    useEffect(()=> {
        if(typeof fieldTypes === 'object' && fieldTypes.length > 0 && fieldTypes.indexOf("date") > -1) {
            if(data.length>0 && dateGrouping && columnPreferences.length > 0) {
                try {
                    setDataWrapper([]);
                    setDataCopy([]);
                    let result;
                    if(offAgg) {
                        result = [
                            JSON.parse(JSON.stringify(data)),
                            Array(100).fill(1),
                            {data: [], count: 0}
                        ]
                    } else if(dateGrouping === 'day' || serverGroup) {
                      result = aggregateData(JSON.parse(JSON.stringify(data)), columnPreferences, aggColIndexesDefault);
                    } else
                        result = groupByIndex(JSON.parse(JSON.stringify(data)), dateGrouping, columnPreferences, aggColIndexesDefault);
                    setItemCountPercent(result[1])
                    setItemCountPercentCopy(result[1])
                    setDataWrapper(result[0]);
                    setDataCopy(result[0]);

                    setDataTotal(getTotalValues(result[0], columnPreferences, result[1]));
                    // setDataTotal(result[2].data);
                    setCountTotal(result[2].count);
                    if(Object.values(selectDataset).length > 0 && !offAgg) {
                        const temp:Array<string> = [];
                        aggColIndexesDefault.map((index)=> {
                            temp.push(columnPreferences[+index].title)
                        })
                        if(temp.length === 0)
                            setSelectActive(trans('Select aggregation', language));
                        else
                            setSelectActive(temp.join(', '));
                    }
                    setDataReady(true);
                    setAggUpdate(false);
                    return;
                } catch (e) {
                    console.log('Error - ', e);
                    setDataWrapper([]);
                    setDataCopy([]);
                    setAggUpdate(false);
                    return;
                }
            } else {
                setDataCopy([]);
            }
        }
        setAggUpdate(false);
    },[data, dateGrouping, aggColIndexesDefault])
    useEffect(()=> {
        if(fieldTypes.indexOf("date") === -1) {
            if (columnPreferences.length > 0 && data.length>0 && typeof fieldTypes === 'object' && fieldTypes.length > 0) {
                try {
                    let result;
                    if(offAgg) {
                        result = [
                            JSON.parse(JSON.stringify(data)),
                            Array(100).fill(1),
                            {data: [], count: 0}
                        ]
                    } else {
                        result = aggregateData(JSON.parse(JSON.stringify(data)), columnPreferences, aggColIndexesDefault);
                    }
                    setItemCountPercent(result[1])
                    setItemCountPercentCopy(result[1])
                    setDataWrapper(result[0]);
                    setDataCopy(result[0]);
                    // setDataTotal(result[2].data);
                    setDataTotal(getTotalValues(result[0], columnPreferences, result[1]));
                    setCountTotal(result[2].count);
                    if(Object.values(selectDataset).length > 0 && !offAgg) {
                        const temp:Array<string> = [];
                        aggColIndexesDefault.map((index)=> {
                            temp.push(columnPreferences[+index].title)
                        })
                        if(temp.length === 0)
                            setSelectActive(trans('Select aggregation', language));
                        else
                            setSelectActive(temp.join(', '));
                    }
                    setDataReady(true);
                } catch (e) {
                    console.log('Error - ', e);
                    setDataWrapper([]);
                    setDataCopy([]);
                }
            } else {
                setDataCopy([]);
            }
        }
        setAggUpdate(false);
    }, [data, columnPreferences, aggColIndexesDefault])

    useEffect(() => {
        if(dataWrapper && dataWrapper[0]) {
            const temp: number[] = findLastUniqueTypeIndices({
                data: columnPreferences,
                wrapperItem: dataWrapper[0]
            });
            setLastElementsIndexesWithTypes(temp);
        }
    }, [dataWrapper, columnPreferences]);


    const [limitChannelCustom, setLimitChannelCustom] = useState(defaultRowsOnPage);

    const per_page_max = (!sortFront && pageCount) ? pageCount : dataWrapper ? getCountPage(dataWrapper.length, limitChannelCustom) : 1;

    const [currentPage, setCurrentPage] = useState(1);
    const pageItems = [];
    for(let i = 1; i <= per_page_max; i++) {
        pageItems.push(i);
    }

    const [activeSort, setActiveSort] = useState<DefaultObject<'default' | 'ascending' | 'descending'>>(defaultSort);
    const [activeSearch, setActiveSearch] = useState<ISearchColumns>(defaultSearch);
    const [activeFilter, setActiveFilter] = useState<DefaultObject<any>>(defaultFilter);
    const [activePin, setActivePin] = useState<DefaultObject<any>>({});
    const [defaultSetting, setDefaultSetting] = useState<any>(settings);

    const onChangeFilter = ({
        search={},
        filter={},
        sort={}
    }:IOnChangeFilter) => {
        onChangeActiveSearch(search);
        onChangeActiveFilter(filter);
        onChangeActiveSort(sort);
        setAggUpdate(true);
        setCurrentPage(1);
        const tempFilterData = JSON.parse(JSON.stringify(filter));
        Object.keys(tempFilterData).map((key) => {
            if (tempFilterData[key]) {
                if (tempFilterData[key].condition === 'default') {
                    delete tempFilterData[key];
                }
            }
        })
        setActiveSearch(search);
        setActiveFilter(tempFilterData);
        setActiveSort(sort);

        if(isUpdateByNoActual) {
            onChangeActual(true);
            return;
        }

        const {
            sumNotInclude,
            countNotInclude,
            tempCountPercent,
            tempData,
            tempTotal,
            tempFilter
        } = changeFilter({
            search,
            filter,
            sort,
            dataCopy: dataCopy,
            itemCountPercentCopy: itemCountPercentCopy,
            columnPreferences,
            fieldTypes: fieldTypes
        })

        setSumNotIncludeData(sumNotInclude);
        setCountNotIncludeData(countNotInclude);

        // setActiveIndex(tempActiveSortIndex);

        setItemCountPercent(tempCountPercent)
        setDataWrapper(tempData);

        setDataTotal(tempTotal);

        setAggUpdate(false);
    }

    const onChangePin = (index:number) => {
        const temp = onPin({index, activePin, activeStretch});
        if(temp) setActivePin(temp);
    }

    const onStretch = (cur: any) => {
        setCur(cur);
    }

    useEffect(()=>{
        if(!isUpdateByNoActual) {
            if(dataCopy.length > 0 && dataReady) {
                onChangeFilter({
                    search: activeSearch,
                    filter: activeFilter,
                    sort: activeSort
                });
            } else setDataWrapper([]);
        }
    }, [dataCopy])

    const [defaultWidthCol, setDefaultWidthCol] = useState<number>(0);
    const [minWidthCol, setMinWidthCol] = useState<number>(150);
    const [activeStretch, setActiveStretch] = useState<any>({})
    useEffect(()=> {
        if(dataWrapper.length > 0) {
            setDefaultWidthCol(getMinLengthTable({
                columnPreferences, data: dataWrapper, metricChoice, aggColIndexes: aggColIndexesDefault, metricColIndexes
            }));
        }
    }, [dataWrapper])

    const [cur, setCur] = useState<any>(null);

    const handleMouseUp = (e:any) => {
        if(cur) {
            setCur(null);
        }
    }

    useEffect(() => {
        if(Object.keys(activeStretch).length > 0 && Object.keys(activePin).length > 1) {
            const temp = handlePin({activePin, activeStretch});
            if(temp)
                setActivePin(temp);
        }
    }, [cur, activeStretch, activePin]);

    const [settingSize, setSettingSize] = useState<DefaultObject<number>>({});
    const tableRef = useRef<HTMLDivElement>(null);
    const tableWidth = useElementWidth(tableRef);
    const getDefaultWidth = (width:number, count:number, min:number =150) => {
        const result = width / count;
        if(result < min) return min;
        return result;
    }

    const onPrepareFilterData = ({
        name,
        activeSort={},
        activeFilter={},
        aggColIndexesDefault=[],
        columnPreferences=[]
    }:{
        name: string,
        activeSort: ISortColumns,
        activeFilter: IFilterColumns,
        aggColIndexesDefault: number[],
        columnPreferences: IColumnPreferences
    }) => {
        const sort: ISortArrayColumns = []
        Object.keys(activeSort).map((name) => {
            if (activeSort[name] !== 'default') {
                sort.push({
                    name: name,
                    value: activeSort[name]
                });
            }
        })
        const aggregation: string[] = []
        aggColIndexesDefault.map((item) => {
            if (columnPreferences[item])
                aggregation.push(columnPreferences[item].name);
        })
        const filter: IFilterArrayColumns = [];
        Object.keys(activeFilter).map((name) => {
            filter.push({
                name: name,
                condition: activeFilter[name].condition,
                value: activeFilter[name].value
            });
        })

        return {
            sort,
            filter,
            aggregation
        }
    }

    const onDownloadReport = (type: 'csv'|'xlsx' = 'xlsx') => {
        if(!serverGroup && name) {
            downloadReport({
                type: 'xlsx',
                name,
                dataWrapper,
                setAggUpdate,
                dateGrouping,
                itemCountPercent,
                titles: title,
                aggFunctions,
                columnPreferences,
                fieldTypes,
                currency,
                metricChoice,
                aggColIndexesDefault,
                metricColIndexes
            });
            return;
        }
        if(onDownload && name) {
            const {sort, filter, aggregation} = onPrepareFilterData({
                name,
                activeSort,
                activeFilter,
                aggColIndexesDefault,
                columnPreferences
            })
            onDownload({
                format: type,
                sort,
                filterFields: filter,
                aggregation
            });
        }
    }

    useEffect(() => {
        if(tableWidth && dataWrapper.length > 0 && dataWrapper[0].length > 0) {
            const indexesWrapper: number[] = [];
            const columns = columnPreferences.filter((item:any, index:number)=> {
                if(metricChoice.length) {
                    if(aggColIndexesDefault.includes(index) || metricColIndexes.includes(index)) {
                        indexesWrapper.push(index);
                        return true;
                    }
                    return false;
                }
                indexesWrapper.push(index);
                return true;
            });
            const countColumns = columns.length;
            const defaultWidth = getDefaultWidth(tableWidth, countColumns);
            setMinWidthCol(defaultWidth);
            const temp:DefaultObject<number> = {};
            for(let i = 0; i < dataWrapper[0].length; i++) {
                const index = indexesWrapper[i];
                if(Object.keys(activeStretch).length > 0 && activeStretch[index]) {
                    temp[i] = activeStretch[index];
                } else
                    temp[i] = defaultWidth;
            }
            setSettingSize(temp);
        }
    }, [dataWrapper, tableWidth, metricColIndexes, limitChannelCustom, activeStretch]);

    const dataClassName = cx(
        'table-interactive-data',
        isNoActual ? 'no-of' : ''
    )

    return(
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div className="table-interactive"
             onMouseMove={(e)=>handleMouseMove(e, cur, activeStretch, defaultWidthCol, setActiveStretch, minWidthCol)}
             onMouseUp={handleMouseUp}
             onMouseLeave={handleMouseUp}
             ref={tableRef}
        >
            {name &&
                <div className="table-interactive__head">
                    <TableInteractiveHead
                        name={name}
                        isDisableName={disableName}
                        isDisableAgg={offAgg || dataWrapper.length === 0 || aggChoice.length === 0}
                        isDisableOptions={isDisableOptions || columnPreferences.length===0 || dataWrapper.length === 0}
                        tabs={tabs}
                        activeTab={activeTab}
                        onChangeTab={onChangeTab}
                        tooltip={tooltip}
                        posTooltip={posTooltip}
                        description={subDescription}
                        activeAggLabel={selectActive}
                        datasetAgg={selectDataset}
                        activeAggList={aggColIndexesDefault.map((item)=>String(item))}
                        onChangeAggChoice={onChangeSelect}
                        options={{
                            isDisabledMetricChoice: metricChoice.length === 0,
                            onChangeMetricChoice: onChangeMetricSelect,
                            datasetMetric: selectMetricDataset,
                            activeMetricLabel: selectMetricActive,
                            activeMetricList: metricColIndexes.map((item)=>String(item)),
                            btns: (()=> {
                                const temp = [];
                                if(onOpenFullScreen && !isFullScreen) {
                                    temp.push({
                                        onClick: onOpenFullScreen,
                                        icon: {
                                            Component: FullScreenIcon,
                                            width: 15,
                                            height: 15
                                        }
                                    })
                                }
                                if(isFullScreen && onCloseFullScreen) {
                                    temp.push({
                                        onClick: onCloseFullScreen,
                                        icon: {
                                            Component: FullScreenExitIcon,
                                            width: 15,
                                            height: 15
                                        }
                                    })
                                }
                                if(dataWrapper) {
                                    if(serverGroup) {
                                        temp.push({
                                            onClick: ()=>onDownloadReport('csv'),
                                            icon: {
                                                Component: CsvIcon,
                                                width: 18,
                                                height: 18
                                            }
                                        })
                                    }
                                    temp.push({
                                        onClick: ()=>onDownloadReport('xlsx'),
                                        icon: {
                                            Component: ExcelIcon,
                                            width: 18,
                                            height: 18
                                        }
                                    })
                                }
                                return temp;
                            })()
                        }}
                    />
                </div>
            }
            <div className={dataClassName}>
                <TableInteractiveLabels
                    data={dataCopy}
                    columnPreferences={columnPreferences}
                    metricChoice={metricChoice}
                    metricColIndexes={metricColIndexes}
                    aggColIndexesDefault={aggColIndexesDefault}
                    stretch={stretch}
                    lastElementsIndexesWithTypes={lastElementsIndexesWithTypes}
                    activeFilter={activeFilter}
                    activeSort={activeSort}
                    activeSearch={activeSearch}
                    activePin={activePin}
                    onChangeFilter={onChangeFilter}
                    onChangePin={onChangePin}
                    onStretch={onStretch}
                    offFilter={offFilter}
                    settingSize={settingSize}
                    typeKeyFilter={typeKeyFilter}
                    isExtendStringFilter={serverGroup}
                />
                <TableInteractiveBody
                    columnPreferences={columnPreferences}
                    dataWrapper={dataWrapper}
                    activePin={activePin}
                    metricChoice={metricChoice}
                    metricColIndexes={metricColIndexes}
                    aggColIndexesDefault={aggColIndexesDefault}
                    currentPage={currentPage}
                    limitChannelCustom={limitChannelCustom}
                    targetEvents={targetEvents}
                    itemCountPercent={itemCountPercent}
                    settingSize={settingSize}
                    isNoActual={isNoActual}
                    isUpdateByNoActual={isUpdateByNoActual}
                    onUpdate={()=>{
                        const {sort, filter, aggregation} = onPrepareFilterData({
                            name,
                            activeSort,
                            activeFilter,
                            aggColIndexesDefault,
                            columnPreferences
                        })
                        onRepeat(sort, filter, aggregation, offset, limit)
                    }}
                />
                {/*{*/}
                {/*    !hideTotal && dataTotal.length > 0 && dataWrapper.length > 1 &&*/}
                {/*    <TableInteractiveTotal dataTotal={dataTotal} />*/}
                {/*}*/}
                { (aggUpdate && !dataReady) &&
                    <div className="table-pagination__loader">
                        <Loader inBlock={true} isNotTransparent={true}/>
                    </div>
                }
                {
                    (dataWrapper.length === 0 || columnPreferences.length===0) && !aggUpdate ? (Object.keys(activeSearch).length === 0 && Object.keys(activeFilter).length === 0 ?
                    <Empty className={'absolute'} title={trans('Nothing found', language)} btnCallback={onRepeat} /> :
                    <Empty className={'absolute'} title={trans('Nothing found', language)} btnCallback={()=>{
                        setActiveFilter({})
                        setActiveSearch({})
                        setActiveSort({})
                        onChangeFilter({})
                    }} btnText={'Clear filters'} />) :
                    <></>
                }
            </div>
            { (loader) &&
                <Loader inBlock={true} isNotTransparent={true}/>
            }
            {
              data.length > 0 && per_page_max > 1 && columnPreferences.length>0 &&
                <Pagination
                    sortFront={sortFront}
                    onChangeLimitChannel={onChangeLimitChannel}
                    limitChannelCustom={limitChannelCustom}
                    setLimitChannelCustom={setLimitChannelCustom}
                    per_page_max={per_page_max}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    bigData={isBigData}
                />
            }
        </div>
    )
}

export default TableInteractive;