import { FC, useCallback, useState } from 'react'
import cx from 'classnames'
import { Btn } from '../index';
import { ITabsItem, ITabs } from './Tabs.d'
import './Tabs.scss'

const TabsItem: FC<ITabsItem> = ({
  tab,
  activeTab,
  onClick
}) => {
  const itemClassName = cx(
    'tabs__control-item',
    {'is-active': activeTab === tab}
  );

  return (
    <li
      className={itemClassName}
    >
      <Btn
        className={'tabs__control'}
        text={tab}
        data-name={tab}
        onClick={onClick}
      />
    </li>
  );
};

const Tabs: FC<ITabs> = ({
  className,
  tabComponents,
  tabNames
}) => {
  const [activeTab, setActiveTab] = useState<string>(Object.values(tabNames)[0]);

  const tabsClassName = cx('tabs', {className: className,});

  const handleTabClick = useCallback((evt: any) => {
      evt.preventDefault();

      const name = evt.target.dataset.name;

      if (!name) {
        return;
      }

      setActiveTab(name);
    }, []
  );

  return (
    <div className={tabsClassName}>
      <ul className="tabs__controls">
        {typeof tabNames === 'object' && Object.values(tabNames).filter((item)=> item !== 'Отправить').map((tab, i) =>
          <TabsItem
            key={`${i}-${tab}`}
            tab={tab as string}
            activeTab={activeTab}
            onClick={handleTabClick}
          />
        )}
      </ul>

      {tabComponents[activeTab]}
    </div>
  );
};

export default Tabs;
