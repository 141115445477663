import {FC, useState} from 'react'
import { Loader } from '../../index'
import {Btn} from '../../../ui'
import { useAppSelector } from '../../../redux/hooks'
import {
  getIsLoading,
  errorValue,
  removeError
} from '../../../redux/slices/access'
import { useShowError } from '../../../helpers'
import {ILimitTrafficModal} from './interfaces.d'
import './style.scss';
import {trans} from "../../../_locales";
import {currentUserLang} from "../../../redux/slices/user";

const LimitTrafficModal: FC<ILimitTrafficModal> = ({
  userId,
  projectId,
  trafficType,
  trafficChannel,
  trafficName,
  indexList,
  email,
  onChange,
  onClose
}) => {
  const isLoading = useAppSelector(getIsLoading);
  const errorMessage = useAppSelector(errorValue);
  const language = useAppSelector(currentUserLang);

  useShowError(errorMessage as string, removeError);
  const [channelValue, setChannelValue] = useState<string>(trafficChannel)
  const Channels = {
    source: trans('Source', language),
    channel: trans('Channel', language),
    campaign: trans('Campaign', language),
    // media_source: 'Медиа источник',
    // media_campaign: 'Медийная кампания'
  }

  const [targetName, setTargetName] = useState(trafficName);
  const [errorName, setErrorName] = useState(false);
  const changeCurrency = (value: string): void => {
    setChannelValue(value)
  }
  const changeTargetName = (value:string)=> {
    setTargetName(value);
  }

  const [activeTab, setActiveTab] = useState(trafficType);
  const Tab = {
    include: 'include',
    exclude: 'exclude'
  }
  const changeActiveTab = (value: string): void => {
    setActiveTab((Tab as any)[`${value}`])
  }
  const onReset = ()=> {
    setActiveTab('no_limit');
    setTargetName('');
    setChannelValue('source');
  }
   const onSubmit = ()=> {
    if((activeTab && channelValue && targetName) || activeTab==='no_limit') {
      onChange(targetName, activeTab, channelValue, indexList, email);
      onClose('LIMIT_TRAFFIC');
    }
    if(activeTab!=='no_limit') {
      if(!(targetName))
        setError(setErrorName);
    }
  }
  const setError = (callbackError:any) => {
    callbackError(true);
    setTimeout(()=> {
      callbackError(false);
    }, 2000)
  }
  return (
    <>
      <div className="limit-traffic-tab">
        <button className={`limit-traffic-tab__btn ${activeTab === 'include' ? 'active' : ''}`} onClick={()=>changeActiveTab('include')}>{trans('Include', language)}</button>
        <button className={`limit-traffic-tab__btn ${activeTab === 'exclude' ? 'active' : ''}`} onClick={()=>changeActiveTab('exclude')}>{trans('Exclude', language)}</button>
      </div>
      <div className="limit-traffic__channel">
        <div className={'input_section'}>
            <span className={'input_label'}>{trans('Source', language)}</span>
            <div className={'select_wrapper'}>
            <select value={channelValue} onChange={(e)=>changeCurrency(e.target.value)}>
              <option value='source'>{Channels.source}</option>
              <option value={'channel'}>{Channels.channel}</option>
              <option value={'campaign'}>{Channels.campaign}</option>
              {/*<option value={Channels.media_source}>{Channels.media_source}</option>*/}
              {/*<option value={Channels.media_campaign}>{Channels.media_campaign}</option>*/}
            </select>
              <div className={'select_arrow'}></div>
            </div>
          </div>
        <div className={'input_section'}>
          <span className={'input_label'}>{trans('Naming', language)}</span>
          <input className={`input ${errorName ? 'input_error': ''}`} type='text' value={targetName} onChange={(e)=>changeTargetName(e.target.value)} />
        </div>
      </div>
      <div className="limit-traffic-action">
        <Btn
          type='reset'
          text={trans('Clear', language)}
          size='lg'
          widthAuto={false}
          onClick={onReset}
        />
        <Btn
          type='submit'
          text={trans('Save', language)}
          color='blue'
          widthAuto={false}
          size='lg'
          disabled={false}
          onClick={onSubmit}
        />
      </div>
      {isLoading && <Loader />}
    </>
  );
};

export default LimitTrafficModal;
