import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ICampaignWithStats } from '../../interfaces/common.d'
import { RootState } from '..'
import {createCampaign, deleteCampaign, downloadCampaignsData, updateCampaignById, uploadMediaCsv, changeCampaignStatus} from '../../actions/media';
import {uploadCsv} from "../../actions/upload";

interface MediaState {
  data: { [id: string]: ICampaignWithStats[] }
  loaded: boolean
  isLoading: boolean
  uploadIsLoading: boolean
  error: string | null
}

interface downloadCampaignsDataPayload {
  projectId: number
  data: ICampaignWithStats[]
}

const initialState: MediaState = {
  data: {},
  loaded: false,
  isLoading: false,
  uploadIsLoading: false,
  error: null
}

export const mediaSlice = createSlice({
  name: 'media',
  initialState,
  reducers: {
    // setCampaigns: (state, action) => {
    //   state.data = action.payload;
    // },
    clearCampaigns: (state) => {
      state.data = {};
    },
    setCampaignsLoaded: (state, action) => {
      state.loaded = action.payload
    },
    removeError: (state) => {
      state.error = null;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    }
  },
  extraReducers: (builder) => {
    // builder.addCase(downloadMediaData.fulfilled, (state, action: PayloadAction<IMediaArrayItem[]>) => {
    //   state.data = action.payload ? action.payload : [];
    //   state.loaded = true;
    //   state.isLoading = false;
    // });
    // builder.addCase(downloadMediaData.pending, (state) => {
    //   state.isLoading = true;
    //   state.loaded = false;
    // });
    // builder.addCase(downloadMediaData.rejected, (state,  action: PayloadAction<any>) => {
    //   state.isLoading = false;
    //   state.error = action.payload
    // });
    builder.addCase(downloadCampaignsData.fulfilled, (state, action: PayloadAction<downloadCampaignsDataPayload>) => {
      // state.data = action.payload ? action.payload : [];
      const projectId = action.payload.projectId;
      const data = action.payload.data;

      if (projectId && data) {
        state.data[projectId] = data;
      }

      state.loaded = true;
      state.isLoading = false;
    });
    builder.addCase(downloadCampaignsData.pending, (state) => {
      state.isLoading = true;
      state.loaded = false;
    });
    builder.addCase(downloadCampaignsData.rejected, (state,  action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload
    });
    builder.addCase(
      createCampaign.fulfilled,
      (state) => {
      state.isLoading = false;
    });
    builder.addCase(
      createCampaign.pending,
      (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      createCampaign.rejected,
      (state,  action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload
    });
    builder.addCase(
      deleteCampaign.fulfilled,
      (state) => {
        state.isLoading = false;
      });
    builder.addCase(
      deleteCampaign.pending,
      (state) => {
        state.isLoading = true;
      });
    builder.addCase(
      deleteCampaign.rejected,
      (state,  action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload
      });
    builder.addCase(
      updateCampaignById.fulfilled,
      (state) => {
        state.isLoading = false;
      });
    builder.addCase(
      updateCampaignById.pending,
      (state) => {
        state.isLoading = true;
      });
    builder.addCase(
      updateCampaignById.rejected,
      (state,  action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload
      });
    builder.addCase(
      uploadMediaCsv.fulfilled,
      (
        state,
        // action: PayloadAction<ICampaignWithStats[]>
      ) => {
        // state.data = action.payload ? action.payload : [];
        state.uploadIsLoading = false;
        state.isLoading = true;
      });
    builder.addCase(
      uploadMediaCsv.pending,
      (state) => {
        state.uploadIsLoading = true;
      });
    builder.addCase(
      uploadMediaCsv.rejected,
      (state,  action: PayloadAction<any>) => {
        state.uploadIsLoading = false;
        state.error = action.payload
      });
    builder.addCase(
      changeCampaignStatus.fulfilled,
      (
        state,
        // action: PayloadAction<any>
      ) => {
        // const campaignIndex = state.data.findIndex((item) => {
        //   return item.id === action.payload.campaignId
        // })
        // if (action.payload.requestStatus === 'success') {
        //   state.data[campaignIndex].status = action.payload.newStatus;
        // }
        state.isLoading = false;
      });
    builder.addCase(
      changeCampaignStatus.pending,
      (state) => {
        state.isLoading = true;
      });
    builder.addCase(
      changeCampaignStatus.rejected,
      (state,  action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload
      });
  }
})

export const { removeError, clearCampaigns, setCampaignsLoaded, setIsLoading } = mediaSlice.actions

export const campaignsData = createSelector(
  (state: RootState) => state.media,
  (media) => media.data
)

export const campaignsLoaded = createSelector(
  (state: RootState) => state.media,
  (media) => media.loaded
)

export const getIsLoading = createSelector(
  (state: RootState) => state.media,
  (media) => media.isLoading
)

export const getUploadIsLoading = createSelector(
  (state: RootState) => state.media,
  (media) => media.uploadIsLoading
)

export const errorValue = createSelector(
  (state: RootState) => state.media,
  (media) => media.error
)

export default mediaSlice.reducer
