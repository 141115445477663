import './style.scss';
import iconEmpty from "../../assets/icons/empty.svg";
import Btn from "../../ui/Btn";
import {trans} from "../../_locales";
import {useAppSelector} from "../../redux/hooks";
import {currentUserLang} from "../../redux/slices/user";
import cx from "classnames";

const Empty = ({title, btnCallback, btnText='Retry', className}:{title:string, btnText?:string, btnCallback?:()=>void, className?:string}) => {
    const language = useAppSelector(currentUserLang);
    return (
        <div className={cx('empty', className)}>
            <img className='empty__img' src={iconEmpty} alt="empty"/>
            <span className='empty__text'>{title}</span>
            {btnCallback && <Btn
                text={trans(btnText, language)}
                color='blue'
                size='sm'
                onClick={btnCallback}
            />}
        </div>
    )
}

export default Empty;