import {FC, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/hooks";

import Input from "../../../../../../ui/Input";

import iconCart from "../../../../../../assets/icons/icon-cart.svg";
import {currentUserLang} from "../../../../../../redux/slices/user";
import {trans} from "../../../../../../_locales";

interface IFilterItem {
    id:number
    projectId:number
    onChange:any,
    onRemove:any,
    filterName:any
    filterValue:any
    isDelay?:boolean
}

const CustomFilterItem:FC<IFilterItem> = ({
        id,
        projectId,
        onChange,
        onRemove,
        filterName,
        filterValue,
        isDelay=false
    }) => {
    const dispatch = useAppDispatch();
    const language = useAppSelector(currentUserLang);

    const [name, setName] = useState<string>(filterName);
    const onChangeName = (value: any) => {
        setName(value);
        onChange({name: value, value: ''}, id);
        setValue('')
    }

    const [value, setValue] = useState<string>(filterValue);

    const onChangeValue = (value: any) => {
        setValue(value);
        if(!isDelay) {
            onChange({value: value}, id);
        }
    }

    useEffect(()=> {
        if (isDelay) {
            const delay = setTimeout(() => {
                onChange({value: [value]}, id);
            }, 1000);
            return () => clearTimeout(delay);
        }
    }, [value])

    const remove = () => {
        onRemove(id);
    }

    return (
        <div className="filter-dashboards-item">
            <div className="filter-dashboards-item__choice">
                <Input
                    className='filter-dashboards-item__choice_input'
                    name={'value'}
                    value={name}
                    color={'dark'}
                    placeholder={trans('Name', language)}
                    onChange={(e:any) => onChangeName(e.value)}
                />
            </div>
            <div className="filter-dashboards-item__choice">
                <Input
                    className='filter-dashboards-item__choice_input'
                    name={'value'}
                    value={value}
                    color={'dark'}
                    placeholder={trans('Value', language)}
                    onChange={(e:any) => onChangeValue(e.value)}
                />
            </div>
            <button onClick={remove} className='filter-dashboards-item__remove'>
                <img src={iconCart} alt=""/>
            </button>
        </div>
    )
}

export default CustomFilterItem;