import React, {FC, useCallback, useEffect, useState} from 'react'
import { Form, Loader } from '../../index'
import {AccessField, Btn, Input} from '../../../ui'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import {
  AccessList,
  getAccessList,
  getIsLoading,
  errorValue,
  removeError
} from '../../../redux/slices/access'
import { addAccess, fetchAccessList, removeAccess } from '../../../actions/access'
import { AnyEvent } from '../../../interfaces/common.d'
import { useShowError } from '../../../helpers'
import { IAddEventChartModal } from './interfaces.d'
import './style.scss';

import infoIcon from '../../../assets/icons/icon-star.svg';
import warningIcon from '../../../assets/icons/icon-warning.svg';
import {createLinearChart, getLinearChart} from "../../../actions/project";

const AddEventChartModal: FC<IAddEventChartModal> = ({
  projectId,
  periodStart,
  periodEnd,
  categorize,
  windowAttributionValue,
  model,
  onChange
}) => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(getIsLoading);
  const errorMessage = useAppSelector(errorValue);

  useEffect(() => {
    // dispatch(fetchAccessList({  projectId }));
  }, [ projectId, dispatch]);

  useShowError(errorMessage as string, removeError);

  const [accessDate, setAccessDate] = useState('');
    const handleFieldChange = (value:any) => {
      setAccessDate(value['user-control_access'])
    }


  const [accessName, setAccessName] = useState('');
  const handleNameChange = (value:any) => {
    setAccessName(value['name'])
  }


  const [typeEvent, setTypeEvent] = useState('info');

  const onReset = ()=> {
    setAccessDate('')
  }
  const onSubmit = ()=> {
      dispatch(createLinearChart({projectId:projectId, infoEvent: accessName, typeEvent: typeEvent, dateEvent: accessDate})).then((r)=> {
        if(r.payload && periodStart && periodEnd && categorize && windowAttributionValue && model) {
          dispatch(getLinearChart({ projectId: projectId, periodStart: periodStart, periodEnd: periodEnd, categorize: categorize, window: +windowAttributionValue, model: model}))
        }
      })
  }
  return (
    <>
      <div className="access-time__block">
          <Input
              type={'text'}
              name={'user-control_access'}
              value={accessDate}
              label={'Выберите дату'}
              placeholder={'____.__.__'}
              onChange={handleFieldChange}
              isDateField={true}
            />
      </div>
      <Input
              type={'text'}
              name={'name'}
              value={accessName}
              label={'Текст события'}
              onChange={handleNameChange}
      />
      <div className="event-type">
        <span>Иконка события</span>
        <div className="event-type__block">
          <button className={`event-type__item ${typeEvent ==='info' ? 'active' : ''}`} onClick={()=>setTypeEvent('info')}>
            <img src={infoIcon} alt=""/>
          </button>
          <button className={`event-type__item ${typeEvent ==='warning' ? 'active' : ''}`} onClick={()=>setTypeEvent('warning')}>
            <img src={warningIcon} alt=""/>
          </button>
        </div>
      </div>
      <div className="access-time__action">
        <Btn
          type='reset'
          text={'Сбросить'}
          size='lg'
          widthAuto={false}
          onClick={onReset}
        />
        <Btn
          type='submit'
          text='Сохранить'
          color='blue'
          widthAuto={false}
          size='lg'
          disabled={false}
          onClick={onSubmit}
        />
      </div>
      {isLoading && <Loader />}
    </>
  );
};

export default AddEventChartModal;
