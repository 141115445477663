import {FC, useEffect, useState} from 'react'
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from '../../redux/hooks'

import { ReactComponent as ExitIcon } from '../../assets/icons/icon-exit.svg'

import {currentUserLang, currentUserProfile, userId} from '../../redux/slices/user'
import { openModal, ModalName } from '../../redux/slices/modals'
import {
    getUserInfo,
    logout,
    updateUserName,
    updateUserPassword,
    updateUserEmail, updateUserTimeAndLang
} from '../../actions/users';

import { InfoCard } from '..'
import {Btn} from '../../ui'

import { IProfilePanel } from './interfaces.d'

import cx from 'classnames'
import './style.scss'
import {LANGUAGES, TIMEZONES} from "../../data";
import {trans} from '../../_locales';
import {sendSdk} from "../../utils";

const ProfilePanel: FC<IProfilePanel> = ({ className }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector(currentUserProfile);
  const reduxUserId = useAppSelector(userId);
  const language = useAppSelector(currentUserLang);
  const [userInfo, setUserInfo] = useState(user);
  const [name, setName] = useState(user.name);
  const [lastName, setLastName] = useState(user.last_name);
  const [email, setEmail] = useState(user.email);
  const [timezone, setTimezone] = useState('');
  const [lang, setLang] = useState('');
  const [listActivity, setListActivity] = useState([])
  useEffect(() => {
    dispatch(getUserInfo()).then((r)=> {
        if(r.payload) {
            setUserInfo(r.payload)
            setName(user.name);
            setLastName(user.last_name ? user.last_name : '');
            setEmail(user.email);
            setListActivity(user.activity);
        }
    })
  }, [dispatch, getUserInfo, setUserInfo])

    useEffect(()=> {
        if(user && user.timezone)
            setTimezone(user.timezone);
        if(user && user.lang)
            setLang(user.lang);
    },[user])

  const changeName = (response:any) => {
      const data = {
          name: response.first_name,
          last_name: response.last_name
      }

      dispatch(updateUserName({data})).then((r)=> {
        if(r) {
            setName(response.first_name);
            setLastName(response.last_name);
            sendSdk('event', {
                'event_type': 'edit',
                'event_category': 'profile',
                'event_name': 'name',
                'event_value': '10',
                'event_params': {
                    'user_id': reduxUserId
                }
            });
        }
      })
  }
  const changeEmail = (response:any) => {
      if (!response.email || !response.password) return;
      const data = {
          email: response.email,
          password: response.password
      }
      dispatch(updateUserEmail({data})).then((r)=> {
        if(typeof r.payload === 'object') {
            setEmail(response.email);
            sendSdk('event', {
                'event_type': 'edit',
                'event_category': 'profile',
                'event_name': 'email',
                'event_value': '10',
                'event_params': {
                    'user_id': reduxUserId
                }
            });
        }
      })
  }
  const changePassword = (response:any) => {
      const data = {
          new_password: response.password,
          current_password: response.current_password
      }
      dispatch(updateUserPassword({data})).then((r)=> {
          if(r.payload) {
              sendSdk('event', {
                  'event_type': 'edit',
                  'event_category': 'profile',
                  'event_name': 'password',
                  'event_value': '10',
                  'event_params': {
                      'user_id': reduxUserId
                  }
              });
          }
      })
  }

  const changeTimezone = (response:any) => {
      const data = {
          lang: lang,
          timezone: response
      }
      dispatch(updateUserTimeAndLang({data, successTitle:trans('Timezone has been updated', lang)}));
      sendSdk('event', {
          'event_type': 'edit',
          'event_category': 'profile',
          'event_name': 'timezone',
          'event_value': '10',
          'event_params': {
              'user_id': reduxUserId
          }
      });
  }
  const changeLang = (response:any) => {
      const data = {
          lang: response,
          timezone: timezone
      }
      dispatch(updateUserTimeAndLang({data, successTitle:trans('Language has been updated', lang)}));
      sendSdk('event', {
          'event_type': 'edit',
          'event_category': 'profile',
          'event_name': 'language',
          'event_value': '10',
          'event_params': {
              'user_id': reduxUserId
          }
      });
  }

  return (
    <div className={cx('profile-panel', className)}>
      <div className='profile-panel__left'>
        <InfoCard
          headerText={trans('General information', language)}
        >
          <Btn
            isfullWidth
            isInfoBtn
            infoTitle={trans('Name', language)}
            infoValue={name || lastName ? `${name ? name : ''} ${lastName ? lastName : ''}` : `${user.name ? user.name : ''} ${user.last_name ? user.last_name : ''}`}
            onClick={() => dispatch(openModal({
              name: ModalName.NAME_EDITING,
              data: {
                userFirstName: name ? name : user.name,
                userLastName: lastName ? lastName : user.last_name,
                onChange: changeName
              }
            }))}
          />
          <Btn
            isfullWidth
            isInfoBtn
            infoTitle='E-mail'
            infoValue={email ? email : user.email}
            onClick={() => dispatch(openModal({
              name: ModalName.EMAIL_EDITING,
              data: {
                userEmail: email ? email : user.email,
                onChange: changeEmail
              }
            }))}
          />
          <Btn
            isfullWidth
            isInfoBtn
            infoTitle={trans('Timezone', language)}
            infoValue={timezone && (TIMEZONES as any)[timezone] ? (TIMEZONES as any)[timezone] : user.timezone}
            onClick={() => dispatch(openModal({
              name: ModalName.EDIT_SELECT,
              data: {
                  title: trans('Change timezone', language),
                  onSubmit: changeTimezone,
                  active: timezone && (TIMEZONES as any)[timezone] ? (TIMEZONES as any)[timezone] : user.timezone,
                  dataset: TIMEZONES
              }
            }))}
          />
          <Btn
            isfullWidth
            isInfoBtn
            infoTitle={trans('Language', language)}
            infoValue={lang && (LANGUAGES as any)[lang] ? (LANGUAGES as any)[lang] : user.lang}
            onClick={() => dispatch(openModal({
              name: ModalName.EDIT_SELECT,
              data: {
                title: trans('Change language', language),
                onSubmit: changeLang,
                active: lang && (LANGUAGES as any)[lang] ? (LANGUAGES as any)[lang] : user.lang,
                dataset: LANGUAGES
              }
            }))}
          />
        </InfoCard>
        <Btn
          className='profile-panel__exit-btn'
          color={'red'}
          icon={{
            Component: ExitIcon,
            width: 16,
            height: 16,
          }}
          text={trans('Exit', language)}
          onClick={() => {
            sendSdk('event', {
                'event_type': 'link',
                'event_category': 'profile',
                'event_name': 'logout',
                'event_value': '10',
                'event_params': {
                    'user_id': reduxUserId
                }
            });
            dispatch(logout()).then(()=> {
                navigate('/');
            })
          }}
        />
      </div>
      <div className='profile-panel__right'>
        <InfoCard
          headerText={trans('Security', language)}
        >
          <Btn
            isfullWidth
            isInfoBtn
            isInfoPassword
            infoTitle={trans('Password', language)}
            infoValue='········'
            onClick={() => dispatch(openModal({
                name:ModalName.PASSWORD_EDITING,
                data: {
                    onChange: changePassword
                }
            }))}
          />
          <Btn
            isfullWidth
            isInfoBtn
            infoTitle={trans('Last activity', language)}
            onClick={() => dispatch(openModal({
                name:ModalName.LAST_ACTIVITY,
                data: {
                    activity: user ? user.activity : listActivity
                }
            }))}
            infoListItems={user ? user.activity : listActivity}
          />
        </InfoCard>
      </div>
    </div>
  );
}

export default ProfilePanel