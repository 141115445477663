import {useEffect, useRef} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";

import {useAppDispatch, useAppSelector} from "../../../../../redux/hooks";
import {currentUser, currentUserLang, userId} from "../../../../../redux/slices/user";
import {setBreadcrumbs} from "../../../../../redux/slices/breadcrumbs";
import {setSidebar} from "../../../../../redux/slices/sidebar";

import EventsCreate, {IEventsCreateType} from "../../../../../components/EventsCreate";
import {trans} from "../../../../../_locales";
import cx from "classnames";
import {sendSdk} from "../../../../../utils";
import {clearInstructions} from "../../../../../redux/slices/instructions";
import {getPages} from "../../../../../components/SideMenu/Role";
import {projectGeneral} from "../../../../../redux/slices/project";

const EventsAdd = () => {
  const reduxUser = useAppSelector(currentUser);
  const reduxUserId = useAppSelector(userId);
  const language = useAppSelector(currentUserLang);
  const reduxCurrentProject = useAppSelector(projectGeneral);
  const dispatch = useAppDispatch()
  const { projectId } = useParams();
  const navigate = useNavigate();
  const permissions = reduxUser && reduxUser.permission ? reduxUser.permission[`${projectId}`] : undefined;
  const isView = permissions && permissions['can_view'] && permissions['can_view'].includes('events') || reduxCurrentProject && reduxCurrentProject.owner_id === reduxUserId;
  const isCreate = permissions && permissions['can_create'] && permissions['can_create'].includes('events')  || reduxCurrentProject && reduxCurrentProject.owner_id === reduxUserId;
  const isEdit = permissions && permissions['can_edit'] && permissions['can_edit'].includes('events')  || reduxCurrentProject && reduxCurrentProject.owner_id === reduxUserId;
  const isDelete = permissions && permissions['can_delete'] && permissions['can_delete'].includes('events')  || reduxCurrentProject && reduxCurrentProject.owner_id === reduxUserId;

  if(permissions && !isCreate) {
    navigate(`/projects/${projectId}/events/`)
  }

  useEffect(()=> {
    if(projectId && reduxUserId) {
        sendSdk('page_view', {'user': {'uid': reduxUserId},'event_params': {'project_id': projectId, 'user_id': reduxUserId, 'page': 'events_create'}})
    }
  }, [projectId, reduxUserId])

  useEffect(() => {
    dispatch(setBreadcrumbs([
        {
          href: '/projects/',
          title: 'Проект',
        },
        {
          href: `/projects/${projectId}/events/`,
          title: trans('Event targets', language)
        },
        {
            href: '#',
            title: trans('Create', language),
        },
    ]))
    const permissions = reduxUser && reduxUser.permission ? reduxUser.permission[`${projectId}`] : undefined;
    dispatch(setSidebar(getPages({
        projectId: Number(projectId),
        access: permissions && permissions['can_view'],
        language,
        active: 'events'
    })))
    dispatch(clearInstructions());
  }, [dispatch, projectId, reduxUser.roles, permissions]);

  const contentClassName = cx('page-content__content', 'page-content__content_scroll', 'page-content__shadow');

  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const types:IEventsCreateType[] = ['web_event', 'purchase', 'add_to_cart', 'offline_event', 'offline_call', 'in_app']

  return (
        <div className={contentClassName}>
          <EventsCreate type={type && types.includes(type as IEventsCreateType) ? type as IEventsCreateType : undefined}/>
        </div>
  );
};


export default EventsAdd;
