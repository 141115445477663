import { createAsyncThunk } from '@reduxjs/toolkit'
import {updateToken} from '../utils'
import {openPopup} from "../redux/slices/popup";
import {PopupType} from "../data";
import {trans} from "../_locales";
import {ICustomReport} from "../components/ReportsUpdate/interfaces.d";
import {closeModal, ModalName, openModal} from "../redux/slices/modals";
import {removeMultipleSource} from "./source";

export const getReports = createAsyncThunk(
  'reports/getReports',
  async (
    { projectId }: { projectId: any },
    { dispatch, rejectWithValue, extra: API }: any

  ) => {
    try {
      const response = await API.get(`/api/projects/${projectId}/custom-reports/`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      return response.data;
    } catch (e: any) {
      if (typeof e.response.data.detail === 'string' && e.response.data.detail !== 'Somehow settings were not found')
        dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail }));
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const getTestTable = createAsyncThunk(
  'reports/getReports',
  async (
    { projectId }: { projectId: any },
    { dispatch, rejectWithValue, extra: API }: any

  ) => {
    try {
      const response = await API.get(`/api/projects/${projectId}/reports/preview/`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      return response.data;
    } catch (e: any) {
      if (typeof e.response.data.detail === 'string' && e.response.data.detail !== 'Somehow settings were not found')
        dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail }));
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const createReport = createAsyncThunk(
  'reports/createReport',
  async (
    { projectId, data, lang='ru' }: { projectId: any, data: ICustomReport, lang?:string },
    { dispatch, rejectWithValue, extra: API }: any

  ) => {
    try {
      let url = `/api/projects/${projectId}/custom-reports/?draft=${data.draft?1:0}`;
      if(data.not_send) {
        url += `&not_send=${data.not_send}`
      }
      const response = await API.post(url, data);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      dispatch(getReports({projectId}))
      dispatch(openPopup({ type: PopupType.SUCCESS, title: trans('The report was created', lang) }))
      return response.data;
    } catch (e: any) {
      if(typeof e.response.data.detail === 'string')
        dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail }));
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const getReport = createAsyncThunk(
  'reports/getReports',
  async (
    { projectId, reportId }: { projectId: any, reportId: number },
    { dispatch, rejectWithValue, extra: API }: any

  ) => {
    try {
      const response = await API.get(`/api/projects/${projectId}/custom-reports/${reportId}/`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      return response.data;
    } catch (e: any) {
      if (typeof e.response.data.detail === 'string' && e.response.data.detail !== 'Somehow settings were not found')
        dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail }));
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const updateReport = createAsyncThunk(
  'reports/updateReport',
  async (
    { projectId, reportId, data, lang='ru' }: { projectId: any, reportId:number, data: ICustomReport, lang?:string },
    { dispatch, rejectWithValue, extra: API }: any

  ) => {
    try {
      let url = `/api/projects/${projectId}/custom-reports/${reportId}/`
      if(data.not_send) {
        url += `?not_send=${data.not_send}`
      }
      const response = await API.put(url, data);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      dispatch(getReports({projectId}))
      dispatch(openPopup({ type: PopupType.SUCCESS, title: trans('The report was updated', lang) }))
      return response.data;
    } catch (e: any) {
      if (typeof e.response.data.detail === 'string')
        dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail }));
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const deleteReport = createAsyncThunk(
  'reports/deleteReport',
  async (
    { projectId, reportId, confirm=false, language='ru'}:
        { projectId: any, reportId:number, confirm?: boolean, language?:string },
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      let url = `/api/projects/${projectId}/custom-reports/${reportId}/`;
      if(confirm) {
        url += `?cd=${confirm}`
      }
      const response = await API.delete(url);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      dispatch(getReports({projectId}))
      dispatch(openPopup({ type: PopupType.SUCCESS, description: trans('Custom report has been successfully deleted', language) }));
      return response.data;
    } catch (e: any) {
      if (typeof e.response.data.detail === 'string') {
        if(e.response.data.detail === 'Confirmation is needed to delete custom report with its dependencies') {
          dispatch(openModal({
            name: ModalName.CONFIRM_DELETE,
            data: {
              text: trans('Are you sure you want to delete a custom report? The periodic sending of the report to the mail will stop', language),
              textConfirm: trans('Delete', language),
              onConfirm: () => {
                dispatch(closeModal(ModalName.CONFIRM_DELETE));
                dispatch(deleteReport({
                  projectId,
                  reportId,
                  confirm: true
                }))
              }
            }
          }))
        } else
          dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail }));

        return rejectWithValue(e.response.data.detail);
      }
      return rejectWithValue('');
    }
  }
);

export const updateReportStatus = createAsyncThunk(
  'reports/updateReportStatus',
  async (
    { projectId, reportId, status, lang='ru' }: { projectId: any, reportId:number, status: ICustomReport['status'], lang?:string },
    { dispatch, rejectWithValue, extra: API }: any

  ) => {
    try {
      const response = await API.put(`/api/projects/${projectId}/custom-reports/${reportId}/status/?status=${status}`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      dispatch(getReports({projectId}))
      dispatch(openPopup({ type: PopupType.SUCCESS, title: trans('The report was updated', lang) }))
      return response.data;
    } catch (e: any) {
      if (typeof e.response.data.detail === 'string')
        dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail }));
      return rejectWithValue(e.response.data.detail);
    }
  }
);