import cls from "./Counter.module.scss";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Btn from "../../../../../ui/Btn";
import DocsCounter from "../../../../../components/Modals/DocsCounter";
import {useAppDispatch, useAppSelector} from "../../../../../redux/hooks";
import {ModalName, openModal} from "../../../../../redux/slices/modals";
import {trans} from "../../../../../_locales";
import {currentUser, currentUserLang, userId} from "../../../../../redux/slices/user";
import {sendSdk} from "../../../../../utils";
import {projectGeneral} from "../../../../../redux/slices/project";

interface CounterProps {
  className?: string;
}

const Counter = (props: CounterProps) => {
  const {
    className,
  } = props;
  const {projectId} = useParams();
  const dispatch = useAppDispatch();
  const language = useAppSelector(currentUserLang);
  const reduxUserId = useAppSelector(userId);
  const reduxUser = useAppSelector(currentUser);
  const currentProject = useAppSelector(projectGeneral);
  const navigate = useNavigate();
  const permissions = reduxUser && reduxUser.permission ? reduxUser.permission[`${projectId}`] : undefined;
  const isView = permissions && permissions['can_view'] && permissions['can_view'].includes('settings_counter') || currentProject && currentProject.owner_id === reduxUserId;
  const isCreate = permissions && permissions['can_create'] && permissions['can_create'].includes('settings_counter') || currentProject && currentProject.owner_id === reduxUserId;
  const isEdit = permissions && permissions['can_edit'] && permissions['can_edit'].includes('settings_counter') || currentProject && currentProject.owner_id === reduxUserId;
  const isDelete = permissions && permissions['can_delete'] && permissions['can_delete'].includes('settings_counter') || currentProject && currentProject.owner_id === reduxUserId;

  const [sdkFuncText, setSdkFuncText] = useState<string>(`<script src="https://cdn.targetads.io/websdk/index.js"></script>
<script>
   window.targetAdsDataLayer = window.targetAdsDataLayer || {}
   function _targetadsTag(){window.targetAdsDataLayer.data = arguments;}
   _targetadsTag('init', ${projectId})
   _targetadsTag('page_view');
</script>
`);
  const [checkCopy, setCheckCopy] = useState<boolean>(false)
  const copyToClipboard = () => {
    navigator.clipboard.writeText(sdkFuncText).then(r => document.execCommand('Copy')).then(()=>setCheckCopy(true));
  }

  useEffect(()=> {
    if (projectId && reduxUserId) {
      sendSdk('page_view', {
        'user': {
          'uid': reduxUserId
        },
        'event_params': {
          'project_id': projectId,
          'user_id': reduxUserId,
          'page': 'settings_counter'
        }
      })
      if(permissions && !isView) {
        navigate(`/projects/${projectId}/settings/`)
      }
    }
  }, [projectId, reduxUserId])

  const [showDocs, setShowDocs] = useState(false);
  const onHandlerModal = ()=> {
    setShowDocs(true);
    sendSdk('event', {
        'event_type': 'open',
        'event_category': 'settings_counter',
        'event_name': 'documentation',
        'event_value': '10',
        'event_params': {
            'project_id': projectId,
            'user_id': reduxUserId
        }
    });
  }

  return (
    <div className={cls.content}>
      <div className={cls.wrapper}>
        <h3 className={cls.title}>{trans('Counter code', language)}</h3>
        <div className={cls.copy_section}>
          <textarea
            className={cls.sdk_code}
            value={sdkFuncText}
            disabled={true}
          />
          <button
            className={ checkCopy ? cls.copied : cls.copy }
            type='submit'
            onClick={copyToClipboard}
          >
            { checkCopy ? trans('Copied', language) : trans('Copy', language)}
          </button>
        </div>

        <p className={cls.text}>
          {trans('Add the counter code to the HTML code of all the pages of the site. The code must be placed within the <head> </head> or <body> </body> tags as close to the top of the page as possible: this way it will load earlier and will be able to send browsing data to TargetAds, even if the visitor closes the page almost immediately.', language)}
        </p>

        <Btn
            text={trans('Counter documentation', language)}
            color='light-blue'
            onClick={onHandlerModal}
        />

      </div>
      {showDocs && <div className={cls.modal}>
        <DocsCounter title={trans('Counter documentation', language)} onClose={()=>setShowDocs(false)}/>
      </div>}
    </div>
  );
};

export default Counter;