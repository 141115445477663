import {FC} from 'react'
import { Loader } from '../../index'
import {Btn} from '../../../ui'
import { useAppSelector } from '../../../redux/hooks'
import {
  getIsLoading,
} from '../../../redux/slices/access'
import { IConfirmDeleteModal } from './interfaces.d'
import './style.scss';
import {currentUserLang} from "../../../redux/slices/user";
import {trans} from "../../../_locales";

const ConfirmDeleteModal: FC<IConfirmDeleteModal> = ({
  text,
  onReset,
  onConfirm,
  textConfirm='Delete'
}) => {
  const isLoading = useAppSelector(getIsLoading);
  const language = useAppSelector(currentUserLang);

  const onCancel = ()=> {
    onReset()
  }
  const onSubmit = ()=> {
    onConfirm()
    onReset()
  }
  return (
    <>
      <p className={'delete-access__title'}>{trans(text, language)}</p>
      <div className="delete-access-action">
        <Btn
          type='reset'
          text={trans('Cancel', language)}
          size='lg'
          widthAuto={false}
          onClick={onCancel}
        />
        <Btn
          type='submit'
          text={trans(textConfirm, language)}
          color='blue'
          widthAuto={false}
          size='lg'
          disabled={false}
          onClick={onSubmit}
        />
      </div>
      {isLoading && <Loader />}
    </>
  );
};

export default ConfirmDeleteModal;
