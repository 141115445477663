import {useEffect, useState} from 'react'
import {Outlet, useNavigate} from 'react-router-dom'
import {checkAuthorization} from './actions/users'
import {useAppDispatch, useAppSelector} from './redux/hooks'
import {getAuthData} from './utils'
import {clearUser, currentUser, isAuthed} from './redux/slices/user'
import ErrorBoundary from "./components/ErrorBoundary";

export const RequireAuth = ({ children }: { children?: JSX.Element }) => {
  const authed = useAppSelector(isAuthed)
  const user = useAppSelector(currentUser)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    const { tokenIsAlive, token } = getAuthData()

    if (tokenIsAlive !== null && !tokenIsAlive) {
      dispatch(clearUser())
    }

    if (!token) {
      navigate('/')
    } else if (!authed) {
      dispatch(checkAuthorization())
    }
  }, [dispatch, navigate, authed, user])


  return (
      <ErrorBoundary>
        {children ? children : <Outlet />}
      </ErrorBoundary>
  )
}
