import {FC, useState} from "react";

import Select from "../Select";

import {changeCurrentPage} from "../../Tables/TablePagination/functions";
import {IPagination, IRowsCount} from "./interfaces.d";

import './style.scss';
import {trans} from "../../../_locales";
import {useAppSelector} from "../../../redux/hooks";
import {currentUserLang} from "../../../redux/slices/user";
import {onChangeGeneralSelect} from "../Select/functions";

const Pagination:FC<IPagination> = ({
    limitChannelCustom,
    setLimitChannelCustom,
    per_page_max,
    currentPage,
    setCurrentPage,
    bigData=false,
    limit,
    offset=0,
    onChangeOffset,
    noRowCount=false

}) => {
    const language = useAppSelector(currentUserLang);
    const [pageValue, setPageValue] = useState<string>('');
    const [middlePage, setMiddlePage] = useState(4);

    const pageItems = [];
    for(let i = 1; i <= per_page_max; i++) {
        pageItems.push(i);
    }

    const RowsCount:FC<IRowsCount> = ({
        onChangeLimitChannel,
        limitChannel
    }) => {
        const datasetSelect = {
            '10': '10',
            '20': '20',
            '30': '30'
        }
        const datasetSelectTables = {
            '30': '30',
            '50': '50',
            '100': '100',
        }

        return(
            <div className="pagination-count">
                <span>{trans('Lines per page', language)}:</span>
                <div className="pagination-count__select">
                    <Select
                        selectActive={String(limitChannel)}
                        dataset={bigData ? datasetSelectTables : datasetSelect}
                        onChangeActive={(res:object)=> onChangeGeneralSelect(res, onChangeLimitChannel)}
                        className={'pagination_select'}
                    />
                </div>
            </div>
        )
    }

    return(
        <div className="pagination__footer">
            {
                !noRowCount && <RowsCount
                    onChangeLimitChannel={setLimitChannelCustom}
                    limitChannel={limitChannelCustom}
                />
            }
            <div className="pagination-paginator">
                <button
                    className='pagination-paginator__arrow'
                    onClick={
                        (event)=> changeCurrentPage(
                            event,
                            currentPage - 1,
                            per_page_max,
                            setPageValue,
                            setMiddlePage,
                            setCurrentPage,
                            limitChannelCustom,
                            limit,
                            offset,
                            onChangeOffset
                        )
                    }
                >
                  <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 1L1 5L5 9" stroke="#8E9EB5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </button>
                {
                    per_page_max > 6 && currentPage !== 1 && currentPage !== 2 && currentPage !== 3 ?
                    <ul className="pagination-paginator__pages">
                      <li className={`${currentPage === 1 ? 'active' : ''}`}>
                          <button
                              onClick={(event)=>changeCurrentPage(
                                event,
                                1,
                                per_page_max,
                                setPageValue,
                                setMiddlePage,
                                setCurrentPage,
                                limitChannelCustom,
                                limit,
                                offset,
                                onChangeOffset
                              )}
                              className={`pagination-paginator__page ${currentPage === 1 ? 'active': ''}`}>{1+(offset/limitChannelCustom)}</button>
                      </li>

                      <li>...</li>

                      <li className={`${currentPage === middlePage - 1 ? 'active' : ''}`}>
                          <button
                            onClick={(event)=>changeCurrentPage(
                                event,
                                middlePage - 1,
                                per_page_max,
                                setPageValue,
                                setMiddlePage,
                                setCurrentPage,
                                limitChannelCustom,
                                limit,
                                offset,
                                onChangeOffset
                            )}
                            className='pagination-paginator__page'>
                              {middlePage - 1 + (offset/limitChannelCustom)}
                          </button>
                      </li>
                      <li className={`${middlePage === currentPage ? 'active' : ''}`}>
                          <button
                              onClick={(event)=>changeCurrentPage(
                                  event,
                                  middlePage,
                                  per_page_max,
                                  setPageValue,
                                  setMiddlePage,
                                  setCurrentPage,
                                  limitChannelCustom,
                                  limit,
                                  offset,
                                  onChangeOffset
                              )}
                              className='pagination-paginator__page'>
                              {middlePage + (offset/limitChannelCustom)}
                          </button>
                      </li>
                      <li className={`${currentPage === middlePage + 1 ? 'active' : ''}`}>
                          <button
                            onClick={(event)=>changeCurrentPage(
                                event,
                                middlePage + 1,
                                per_page_max,
                                setPageValue,
                                setMiddlePage,
                                setCurrentPage,
                                limitChannelCustom,
                                limit,
                                offset,
                                onChangeOffset
                            )}
                            className='pagination-paginator__page'>
                              {middlePage + 1 +(offset/limitChannelCustom)}
                          </button>
                      </li>

                      <li>
                        <input
                            className='more'
                            placeholder='...'
                            value={pageValue}
                            onChange={(e)=>{
                                setPageValue(e.target.value)}}
                            onKeyDown={
                                (e)=>pageValue && e.key==='Enter' &&
                                changeCurrentPage(
                                    e,
                                    +pageValue-(offset/limitChannelCustom),
                                    per_page_max,
                                    setPageValue,
                                    setMiddlePage,
                                    setCurrentPage,
                                    limitChannelCustom,
                                    limit,
                                    offset,
                                    onChangeOffset
                                )}
                        />
                      </li>
                      <li className={`${currentPage === per_page_max ? 'active' : ''}`}>
                          <button
                            onClick={(event)=>changeCurrentPage(
                                event,
                                per_page_max,
                                per_page_max,
                                setPageValue,
                                setMiddlePage,
                                setCurrentPage,
                                limitChannelCustom,
                                limit,
                                offset,
                                onChangeOffset
                            )}
                            className='pagination-paginator__page'>
                              {per_page_max+(offset/limitChannelCustom)}
                          </button>
                      </li>
                    </ul> : per_page_max > 6 && (currentPage === 1 || currentPage === 2 || currentPage === 3) ?
                    <ul className="pagination-paginator__pages">
                      <li className={`${currentPage === 1 ? 'active' : ''}`}>
                          <button
                            onClick={(event)=>changeCurrentPage(
                                event,
                                1,
                                per_page_max,
                                setPageValue,
                                setMiddlePage,
                                setCurrentPage,
                                limitChannelCustom,
                                limit,
                                offset,
                                onChangeOffset
                            )}
                            className='pagination-paginator__page'>
                              {1+(offset/limitChannelCustom)}
                          </button>
                      </li>
                      <li className={`${currentPage === 2 ? 'active' : ''}`}>
                          <button
                            onClick={(event)=>changeCurrentPage(
                                event,
                                2,
                                per_page_max,
                                setPageValue,
                                setMiddlePage,
                                setCurrentPage,
                                limitChannelCustom,
                                limit,
                                offset,
                                onChangeOffset
                            )}
                            className='pagination-paginator__page'>
                              {2+(offset/limitChannelCustom)}
                          </button>
                      </li>
                      <li className={`${currentPage === 3 ? 'active' : ''}`}>
                          <button
                            onClick={(event)=>changeCurrentPage(
                                event,
                                3,
                                per_page_max,
                                setPageValue,
                                setMiddlePage,
                                setCurrentPage,
                                limitChannelCustom,
                                limit,
                                offset,
                                onChangeOffset
                            )}
                            className='pagination-paginator__page'>
                              {3+(offset/limitChannelCustom)}
                          </button>
                      </li>
                      <li>
                          <button
                            onClick={(event)=>changeCurrentPage(
                                event,
                                4,
                                per_page_max,
                                setPageValue,
                                setMiddlePage,
                                setCurrentPage,
                                limitChannelCustom,
                                limit,
                                offset,
                                onChangeOffset
                            )}
                            className='pagination-paginator__page'>
                              {4+(offset/limitChannelCustom)}
                          </button>
                      </li>

                      <li>
                        <input
                            className='more'
                            placeholder='...'
                            value={pageValue}
                            onChange={(e)=>{setPageValue(e.target.value)}}
                            onKeyDown={
                                (e)=>pageValue && e.key==='Enter' &&
                                changeCurrentPage(
                                    e,
                                    +pageValue-(offset/limitChannelCustom),
                                    per_page_max,
                                    setPageValue,
                                    setMiddlePage,
                                    setCurrentPage,
                                    limitChannelCustom,
                                    limit,
                                    offset,
                                    onChangeOffset
                                )}
                        />
                      </li>
                      <li className={`${currentPage === per_page_max ? 'active' : ''}`}>
                          <button
                            onClick={(e)=>changeCurrentPage(
                                e,
                                per_page_max,
                                per_page_max,
                                setPageValue,
                                setMiddlePage,
                                setCurrentPage,
                                limitChannelCustom,
                                limit,
                                offset,
                                onChangeOffset
                            )}
                            className='pagination-paginator__page'>
                              {per_page_max+(offset/limitChannelCustom)}
                          </button>
                      </li>
                    </ul>

                    :
                    <ul className="pagination-paginator__pages">
                        {
                            pageItems.map((item:number, index: number)=> (
                                <li key={index} className={`${currentPage === item ? 'active' : ''}`}>
                                  <button
                                    onClick={(e)=>changeCurrentPage(
                                        e,
                                        item,
                                        per_page_max,
                                        setPageValue,
                                        setMiddlePage,
                                        setCurrentPage,
                                        limitChannelCustom,
                                        limit,
                                        offset,
                                        onChangeOffset
                                    )}
                                    className={`pagination-paginator__page`}>
                                      {item+(offset/limitChannelCustom)}
                                  </button>
                                </li>
                            ))
                        }
                    </ul>
                }
                <button
                    className='pagination-paginator__arrow'
                    onClick={(e)=> changeCurrentPage(
                        e,
                        currentPage + 1,
                        per_page_max,
                        setPageValue,
                        setMiddlePage,
                        setCurrentPage,
                        limitChannelCustom,
                        limit,
                        offset,
                        onChangeOffset
                    )}
                >
                  <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 9L5 5L1 1" stroke="#8E9EB5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </button>
            </div>
          </div>
    )
}

export default Pagination;