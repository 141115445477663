import {useEffect, useState} from "react";
import {NavLink, Outlet, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";

import {projectGeneral} from "../../../../redux/slices/project";
import {currentUser, currentUserLang} from "../../../../redux/slices/user";
import {setBreadcrumbs} from "../../../../redux/slices/breadcrumbs";
import {setSidebar} from "../../../../redux/slices/sidebar";

import './style.scss';
import {trans} from "../../../../_locales";
import {clearInstructions} from "../../../../redux/slices/instructions";
import {getPages} from "../../../../components/SideMenu/Role";

interface Path {
  title: string;
  path: string
}

const Upload = () => {
  const reduxCurrentProject = useAppSelector(projectGeneral);
  const reduxUser = useAppSelector(currentUser);
  const currentProject = useAppSelector(projectGeneral);
  const language = useAppSelector(currentUserLang);
  const dispatch = useAppDispatch()
  const { projectId } = useParams();
  const permissions = reduxUser && reduxUser.permission ? reduxUser.permission[`${projectId}`] : undefined;
  const isView = permissions && permissions['can_view'] && permissions['can_view'].includes('upload') || currentProject && currentProject.owner_id === reduxUser.id;
  const isCreate = permissions && permissions['can_create'] && permissions['can_create'].includes('upload') || currentProject && currentProject.owner_id === reduxUser.id;
  const isEdit = permissions && permissions['can_edit'] && permissions['can_edit'].includes('upload') || currentProject && currentProject.owner_id === reduxUser.id;
  const isDelete = permissions && permissions['can_delete'] && permissions['can_delete'].includes('upload') || currentProject && currentProject.owner_id === reduxUser.id;


  const SettingsPath: Record<string, Path> = {
    'GENERAL': {
      title: trans('Media campaign expenses', language),
      path: 'upload',
    },
    'PERFORMANCE': {
      title: trans('Campaign performance expenses', language),
      path: 'upload-performance',
    },
    'PLAN_MEDIA': {
      title: trans('Planned values for media campaigns', language),
      path: 'upload-plan-media',
    },
    'PLAN_PERFORMANCE': {
      title: trans('Planned values for performance campaigns', language),
      path: 'upload-plan-performance',
    },
  }

  useEffect(() => {
    dispatch(clearInstructions());
    dispatch(setBreadcrumbs([
        {
          href: '/projects',
          title: 'Проект',
          subtitle: reduxCurrentProject?.name
        },
        {
          href: '#',
          title: trans('Upload data into a project', language),
        },
      ]))

      dispatch(setSidebar(getPages({
        projectId: Number(projectId),
        access: permissions && permissions['can_view'],
        language,
        active: 'upload'
      })))
  }, [dispatch, projectId, reduxCurrentProject?.name, permissions]);

  useEffect(() => {
    const header = document.querySelector('header');
    const body = document.querySelector('body');
    const content = document.querySelector('.page-content');
    header && header.classList.remove('hide');
    content && content.classList.remove('stretch');
    body && body.classList.remove('no-scroll');
  }, []);

  return (
    <>
      <div className={'ProjectSettings'}>
        {
          typeof SettingsPath === 'object' && Object.values(SettingsPath).map((link) => (
            <div className={'link_item'} key={link.title}>
              <NavLink
                className={({ isActive }) =>
                  [
                    'link',
                    isActive ? 'link_selected' : null,
                  ]
                    .filter(Boolean)
                    .join(" ")
                }
                to={`/projects/${projectId}/${link.path}`}
              >{link.title}
              </NavLink>
            </div>
          ))
        }
      </div>
      <div className={'foo'}>
        {isView && !isCreate && !isEdit && !isDelete && <div className='upload__unavailable'>
          {trans('Data loading is not available in view mode', language)}
        </div>}
        <Outlet />
      </div>
    </>
  );
};


export default Upload;