import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import {
    getRoles,
    createProjectRole,
    updateProjectRole,
    deleteProjectRole
} from '../../actions/projects';
import {DefaultObject} from "../../interfaces/common.d";

export interface Role {
    id: number
    name: string
    title: string
    author?: string
    created_at?: string
    permissions?: DefaultObject<any>
}

export interface RolesState {
    roles: Role[]
    isLoading: boolean
    error: string | null
}

const initialState: RolesState = {
    roles: [] as Role[],
    isLoading: false,
    error: null
}

export const rolesSlice = createSlice({
    name: 'roles',
    initialState,
    reducers: {
        setReduxRoles: (state, action) => {
            state.roles = action.payload;
        },
        clearReduxRoles: (state) => {
            state.roles = [] as Role[];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(
            createProjectRole.fulfilled,
            (
                state,
                action: PayloadAction<Role>
            ) => {
                // state.sources.push(action.payload);
                // state.newTag = action.payload;
                state.isLoading = false;
                state.error = null;
            });
        builder.addCase
        (createProjectRole.pending,
            (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            createProjectRole.rejected,
            (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.error = action.payload
            });
        builder.addCase(
            getRoles.fulfilled,
            (state, action: PayloadAction<Role[] | null>) => {
                state.roles = action.payload ? action.payload : [] as Role[];
                state.isLoading = false;
                state.error = null;
            });
        builder.addCase
        (getRoles.pending,
            (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            getRoles.rejected,
            (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.error = action.payload
            });
        builder.addCase(
            updateProjectRole.fulfilled,
            (state, action: PayloadAction<Role>) => {
                const prev = state.roles.find((role) => role.id === action.payload.id);
                const index = prev ? state.roles.indexOf(prev) : -1;

                if (prev) {
                    state.roles[index] = action.payload;
                }

                state.isLoading = false;
                state.error = null;
            });
        builder.addCase
        (updateProjectRole.pending,
            (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            updateProjectRole.rejected,
            (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.error = action.payload
            });
        builder.addCase(
            deleteProjectRole.fulfilled,
            (state) => {
                state.isLoading = false;
                state.error = null;
            });
        builder.addCase
        (deleteProjectRole.pending,
            (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            deleteProjectRole.rejected,
            (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.error = action.payload
            });
    }
})

export const {
    setReduxRoles,
    clearReduxRoles
} = rolesSlice.actions

export const reduxRoles = createSelector(
  (state: RootState) => state.roles,
  (roles) => roles.roles
)

export default rolesSlice.reducer
