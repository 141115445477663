import {useEffect, useState} from "react";

export const useElementWidth = (elementRef: React.RefObject<HTMLDivElement>) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (elementRef.current) {
      const element = elementRef.current;
      const paddingWidth = 2;
      setWidth(element.getBoundingClientRect().width - paddingWidth);
    }
  }, [elementRef]);

  return width;
};