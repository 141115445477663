import {DefaultObject} from "../../../../interfaces/common.d";
import {downloadReport, IFunctionDownloadReport} from "../../TablePagination/functions";
import {FC, useRef, useState} from "react";
import {useOnClickOutside} from "../../../../helpers";
import Select from "../../../ui/Select";
import settingIcon from "../../../../assets/icons/icon-setting.svg";
import Btn from "../../../../ui/Btn";
import { ReactComponent as FullScreenIcon } from '../../../../assets/icons/fullscreen.svg'
import { ReactComponent as FullScreenExitIcon } from '../../../../assets/icons/fullscreen-exit.svg'
import { ReactComponent as DotsIcon } from '../../../../assets/icons/icon-dots-black.svg';
import { ReactComponent as DownloadIcon} from '../../../../assets/icons/download.svg';
import "./style.scss";
import {IIcon} from "../../../../ui/Btn/Btn.d";

export interface ITableOptions {
    isDisabledMetricChoice?: boolean,
    onChangeMetricChoice: any,
    datasetMetric: DefaultObject<string>,
    activeMetricLabel: string,
    activeMetricList: Array<string>,
    btns?: {
        onClick: () => void,
        icon: IIcon
    }[]
}

const TableInteractiveOptions:FC<ITableOptions> = ({
    isDisabledMetricChoice=false,
    onChangeMetricChoice,
    datasetMetric,
    activeMetricLabel,
    activeMetricList,
    btns=[]
}) => {
    const onChangeMetricSelect = (r:any) => {
        onChangeMetricChoice(r);
    }

    const [flagOptionsModal, setFlagOptionsModal] = useState(false);
    const optionsRef = useRef<HTMLDivElement>(null);
    useOnClickOutside(() => {
        setFlagOptionsModal(false)
    }, [optionsRef]);

    return (
        <div className='table-interactive-options'>
            {!isDisabledMetricChoice && Object.values(datasetMetric).length > 0 &&
                <div className={'table-interactive-options__metrics'}>
                    <Select
                        icon={settingIcon}
                        onlyIcon={true}
                        selectActive={activeMetricLabel}
                        dataset={datasetMetric}
                        onChangeActive={onChangeMetricSelect}
                        color={'dark'}
                        widthAuto={true}
                        checkbox={true}
                        listActive={activeMetricList}
                    />
                </div>
            }
            <div className='table-interactive-options__body' ref={optionsRef}>
                <Btn
                    className='table-interactive-options__btn'
                    color={'dark'}
                    onClick={() => {
                        setFlagOptionsModal(!flagOptionsModal);
                    }}
                    isIconBtn={true}
                    icon={{
                        Component: DotsIcon,
                        width: 15,
                        height: 15
                    }}
                />
                {flagOptionsModal && <div className="table-interactive-options-popup">
                    {
                        btns.map((item, index)=> (
                            <div key={index}>
                                <Btn
                                    color={'dark'}
                                    className={'table-interactive-options__btn'}
                                    isIconBtn={true}
                                    icon={item.icon}
                                    onClick={() => item.onClick()}
                                />
                            </div>
                        ))
                    }
                </div>}
            </div>
        </div>
    )
}

export default TableInteractiveOptions;