import { createAsyncThunk, isRejectedWithValue } from '@reduxjs/toolkit'
import {contentJson, PopupType} from '../data'
import {updateToken} from "../utils";
import {openPopup} from "../redux/slices/popup";
import {DefaultObject} from "../interfaces/common.d";
import { trans } from '../_locales';
import { ModalName, closeModal, openModal } from '../redux/slices/modals';

export const fetchSources = createAsyncThunk(
  'source/fetchSource',
  async (
    { projectId }: { projectId: number },
    {dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const response = await API.get(`/api/sources/?project_id=${projectId}`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      return response.data;
    } catch (e: any) {
      if (typeof e.response.data.detail === 'string') {
        let type = PopupType.ERROR;
        if (e.response.data.detail.includes('You dont have access')) {
          type = PopupType.WARNING;
        }
        dispatch(openPopup({type: type, description: e.response.data.detail}));
      }
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const getBaseSources = createAsyncThunk(
  'source/getBaseSources',
  async (
    { projectId }: { projectId: number },
    {dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const response = await API.get(`/api/sources/base/?project_id=${projectId}`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      return response.data;
    } catch (e: any) {
      if (typeof e.response.data.detail === 'string')
        dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail }));
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const fetchSourceById = createAsyncThunk(
  'source/fetchSourceById',
  async (
    { userId, projectId, sourceId, refresh }: { userId: number, projectId: number, sourceId: number, refresh?: boolean },
    { rejectWithValue, extra: API }: any
  ) => {
    const currentRefresh = typeof refresh !== 'undefined' ? `?refresh=${refresh}` : '';
    try {
      const response = await API.get(`/api/user/${userId}/project/${projectId}/media/source/${sourceId}${currentRefresh}/`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      return response.data.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data.message);
    }
  }
);

export const creatSource = createAsyncThunk(
  'source/creatSource',
  async (
    { data, userId, projectId }: { data: FormData | DefaultObject<any>, userId?: number, projectId: number },
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      // const response = await API.post(`/api/user/${userId}/project/${projectId}/media/source`, data, contentJson);
      const response = await API.post(`/api/sources/?project_id=${projectId}`, data, contentJson);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      dispatch(fetchSources({projectId}));
      return response.data.data;
    } catch (e: any) {
      if (typeof e.response.data.detail === 'string')
        dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail }));
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const updateSource = createAsyncThunk(
  'source/updateSource',
  async (
    { data, userId, projectId, sourceId }:
      { data: DefaultObject<any>, userId: number, projectId: number, sourceId?: number },
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      // const response = await API.patch(`/api/user/${userId}/project/${projectId}/media/source/${sourceId}`, data);
      const response = await API.put(`/api/sources/${sourceId}/`, data);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      dispatch(fetchSources({ projectId }));
      return response.data.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data.message);
    }
  }
);

export const removeSource = createAsyncThunk(
  'source/removeSource',
  async (
      {projectId, sourceId}:
      {projectId: number, sourceId: number},
      {dispatch, rejectWithValue, extra: API}: any
  )=> {
    try {
      const response = await API.delete(`/api/sources/${sourceId}/`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      dispatch(fetchSources({ projectId }));
      return response.data;
    } catch (e: any) {
      if (e.response && e.response.data && typeof e.response.data.detail === 'string') {
        dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail }));
        return rejectWithValue(e.response.data.detail);
      }
      return rejectWithValue(e.message)
    }
  }
);

export const removeMultipleSource = createAsyncThunk(
  'source/removeMultipleSource',
  async (
    {projectId, sourcesIds, language='ru'}:
    {projectId: number, sourcesIds: Array<number>, language?: string},
    {dispatch, rejectWithValue, extra: API}: any
  ) => {
    try {
      const response = await API.delete(`/api/sources/?ids=${sourcesIds.join(',')}`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      dispatch(fetchSources({ projectId }));
      if(response.data && response.data.errors && Object.keys(response.data.errors).length > 0) {
        dispatch(openModal({
          name: ModalName.MESSAGE,
          data: {
            title: trans('Error', language),
            message: response.data,
            buttons: {
              confirmation: {
                text: trans('Close', language),
                onClick: () => {
                  dispatch(closeModal(ModalName.MESSAGE));
                }
              },
            }
          }
        }))
      }
      return response.data;
    } catch (e: any) {
      if (e.response && e.response.data && typeof e.response.data.detail === 'string') {
        dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail }));
        return rejectWithValue(e.response.data.detail);
      }
      return rejectWithValue(e.message)
    }
  }
)