import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../../redux/hooks";
import {uploadCsv} from "../../../../../actions/upload";
import UploadContent from "../../../../../components/UploadContent";
import './style.scss';
import {Loader} from "../../../../../components";
import {currentUserLang, userId} from "../../../../../redux/slices/user";
import {trans} from "../../../../../_locales";
import {updateUploadedFiles} from "../functions";
import {sendSdk} from "../../../../../utils";

const UploadMedia = () => {
    const dispatch = useAppDispatch();
    const {projectId} = useParams();
    const language = useAppSelector(currentUserLang);
    const reduxUserId = useAppSelector(userId);

    useEffect(()=> {
        if (projectId && reduxUserId) {
            sendSdk('page_view', {
                'user': {
                    'uid': reduxUserId
                },
                'event_params': {
                    'project_id': projectId,
                    'user_id': reduxUserId,
                    'page': 'upload_medium_plan'
                }
            })
        }
    }, [projectId, reduxUserId])

    const [isLoading , setIsLoading] = useState(false);
    const data = {
        title: trans('Upload planned values for media campaigns', language),
        subtitle: trans("After downloading the planned indicators, the dashboard plan/fact and the indicators '% of plan completion' and 'Run Rate' will be available to you. It is possible to upload retrospectively, the data is automatically pulled up for past periods", language),
        info: [
            {
                title: trans('The file must contain the following fields', language),
                dataset: [
                    trans('Month (in the format YYYY-MM)', language),
                    trans('Campaign name (must match the name of the created or imported campaigns)', language),
                    trans('The plan for impressions', language),
                    trans('The plan for clicks', language),
                    trans('The plan for cost', language),
                    trans('The plan for reach', language),
                ]
            },
            {
                title: trans('Optional fields', language),
                dataset: [
                    trans('The name of the creative (must match the name of the created or imported creatives)', language),
                    trans('The plan for event target', language)
                ]
            }
        ],
    }
    const handleUploadedFiles = (files:any, scale:string) => {
        if(projectId) {
            updateUploadedFiles(files, scale, 'medium_plan', +projectId, setIsLoading, dispatch, reduxUserId, language);
        }
    }
    return(
        <div className='upload__wrapper'>
            <UploadContent
                title={data.title}
                subtitle={data.subtitle}
                info={data.info}
                updateFilesCb={handleUploadedFiles}
                multiple={false}
                example='https://cdn.targetads.io/files/plan_media.xlsx'
                type={'targets'}
            />
            {(isLoading) &&
                <Loader inBlock={true}/>}
        </div>
    )
}

export default UploadMedia;