import { createAsyncThunk } from '@reduxjs/toolkit';
import {updateToken} from "../utils";
import {openPopup} from "../redux/slices/popup";
import {PopupType} from "../data";

export const getDashboardsFilterValues = createAsyncThunk(
  'dashboards/getDashboardsFilterValues',
  async (
    { projectId, name }: { projectId: number, name:string },
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const response = await API.get(`/api/dashboard/filter/?project_id=${projectId}&name=${name}`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      return response.data;
    } catch (e: any) {
      if (typeof e.response.data.detail === 'string')
        dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail || e.message }));
      return rejectWithValue(e.response.data.message);
    }
  }
);

export const getEcomCustomDashboardsFilterValues = createAsyncThunk(
  'dashboards/getEcomCustomDashboardsFilterValues',
  async (
    {
      projectId,
      name,
      periodStart,
      periodEnd,
      trgPeriodStart,
      trgPeriodEnd,
      aggregation,
      categorize,
      model,
      window,
      interaction_type,
      event_dt
    }: {
      projectId: number,
      name:string,
      periodStart:string,
      periodEnd:string,
      trgPeriodStart: string,
      trgPeriodEnd: string,
      aggregation?:Array<string>,
      categorize?:string,
      model?:string,
      window?:number,
      interaction_type?: Array<string>,
      event_dt?: Array<string>
    }, { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const data = {
        name_id: name,
        common_params: {
          interval_from: periodStart,
          interval_to: periodEnd,
          model: model,
          window: window
        },
        aggregation: aggregation,
        custom: {
          interaction_type: interaction_type,
          event_dt: event_dt,
          trg_event_dt_from: trgPeriodStart,
          trg_event_dt_to: trgPeriodEnd
        }
      }
      const response = await API.post(`/api/projects/${projectId}/custom-reports/filter/values/`, data);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      return response.data;
    } catch (e: any) {
      if (typeof e.response.data.detail === 'string')
        dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail || e.message }));
      return rejectWithValue(e.response.data.message);
    }
  }
);