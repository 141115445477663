import {FC, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";

import {dashboardsFilterValues} from "../../../../redux/slices/dashboards";
import {getDashboardsFilterValues} from "../../../../actions/dashboards";

import Select from "../../../ui/Select";
import Input from "../../../../ui/Input";
import Loader from "../../../Loader";

import iconCart from "../../../../assets/icons/icon-cart.svg";
import {currentUserLang} from "../../../../redux/slices/user";
import {trans} from "../../../../_locales";

interface ISortItem {
    id:number,
    onChange:any,
    onRemove:any,
    filterName:any
    filterValue:any
    datasetName:any
    datasetNameBase:any
    datasetValue?:any
}

const SortItem:FC<ISortItem> = ({
        id,
        onChange,
        onRemove,
        filterName,
        filterValue,
        datasetName,
        datasetNameBase,
        datasetValue
    }) => {
    const dispatch = useAppDispatch();
    const reduxFilterValues = useAppSelector(dashboardsFilterValues);
    const language = useAppSelector(currentUserLang);

    const [showLoader, setShowLoader] = useState(false);
    const [readyValue, setReadyValue] = useState(false);

    const [name, setName] = useState<string>(filterName);
    const onChangeName = (response: object) => {
        for (const value in response) {
            setName(value)
            onChange({name: value, value: ''}, id);
            setValue('')
            if(datasetValue && typeof (datasetValue as any)[value] === 'object') {
                setDatasetValues((datasetValue as any)[value]);
            }
            setReadyValue(true);
            setShowLoader(false);
        }
    }

    useEffect(()=> {
       if(typeof filterValue === 'object' && filterValue.length > 0) setReadyValue(true);
       if(typeof filterValue === 'string' || typeof filterValue === 'number') setReadyValue(true);
    },[])
    const prepareValue = typeof filterValue === 'object' && typeof datasetValue==='object' && filterName ?
        filterValue.map((item:string) => datasetValue[filterName][item] ? datasetValue[filterName][item] : item).join(', ') :
        (typeof filterValue === 'string' || typeof filterValue === 'number') ? filterValue : '';
    const [value, setValue] = useState<string>(prepareValue);
    const [datasetValues, setDatasetValues] = useState<any>({'ascending': 'По возрастанию', 'descending': 'По убыванию'})

    const onChangeValue = (response: object) => {
        for (const value in response) {
            setValue(value);
            onChange({value: value}, id)
        }
    }
    const onChangeValueCheckbox = (response: any) => {
        for (const key in response) {
            setValue(response[key] ? response[key] : trans('Value', language));
            onChange({value: key}, id);
        }
    }

    const remove = () => {
        onRemove(id);
    }

    return (
        <div className="filter-dashboards-item">
            <div className="filter-dashboards-item__choice">
                <Select
                    color='dark'
                    widthAuto={true}
                    selectActive={name && datasetNameBase && (datasetNameBase as any)[name] ? (datasetNameBase as any)[name] : trans('Filter', language)}
                    dataset={datasetName}
                    onChangeActive={onChangeName}
                />
            </div>
            <div className="filter-dashboards-item__choice">
                {showLoader && <Loader inBlock={true} size={'xs'}/>}
                <Select
                    color='dark'
                    widthAuto={true}
                    selectActive={value ? value : trans('Value', language)}
                    dataset={{'ascending': 'По возрастанию', 'descending': 'По убыванию'}}
                    onChangeActive={onChangeValueCheckbox}
                    isDisabled={!name}
                />
            </div>
            <button onClick={remove} className='filter-dashboards-item__remove'>
                <img src={iconCart} alt=""/>
            </button>
        </div>
    )
}

export default SortItem;