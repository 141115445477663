import { createAsyncThunk } from '@reduxjs/toolkit'
/*eslint import/no-unresolved: [2, { amd: true }]*/
import { ItemStatus } from '../interfaces/common.d'
import { closeModal, ModalName, openModal } from '../redux/slices/modals'
import {downloadCsv, showPopup, updateToken} from '../utils'
import {setIsLoading} from "../redux/slices/media";
import {getTags} from "./tags";
import {fetchSources, removeMultipleSource} from "./source";
import {trans} from "../_locales";

const REQUEST_TIMEOUT_TO_LOAD = 30000;

interface DownloadParams {
  projectId: number
}

interface UpdateParams {
  data: JSON,
  userId: number,
  projectId: number,
  campaignId: number
}

interface CsvParams {
  mode: 'all' | 'active' | number[]
  userId: number
  projectId: number
  campaignId?: number
  type?:'xlsx'|'csv'
}

interface StatusParams {
  status: ItemStatus
  userId: number
  projectId: number
  campaignId: number
}

// export const downloadMediaData = createAsyncThunk(
//   'media/download',
//   async ({currentUserId, projectId}: DownloadParams , { rejectWithValue, extra: API}: any) => {
//     try {
//       const response = await API.get(`/api/user/${currentUserId}/project/${projectId}/media`);
//       return response.data.data.MediaCampaign;
//     } catch (e: any) {
//       return rejectWithValue(e.response.data.message);
//     }
//   }
// );

export const downloadCampaignsData = createAsyncThunk(
  'campaign/download',
  async ({projectId }: DownloadParams , { rejectWithValue, extra: API}: any) => {
    try {
      const response = await API.get(`/api/campaigns/?project_id=${projectId}`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      return { projectId, data: response.data };
    } catch (e: any) {
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const createCampaign = createAsyncThunk(
  'campaigns/createCampaign',
  async (
    { data, userId, projectId }: { data: FormData | JSON, userId: number, projectId: number },
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      // const response = await API.post(`/api/user/${userId}/project/${projectId}/media/campaign`, data, {
      const response = await API.post(`/api/campaigns/?project_id=${projectId}`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      dispatch(closeModal(ModalName.NEW_CAMPAIGN));
      showPopup(dispatch, response.data.message as string);
      dispatch(downloadCampaignsData({projectId: projectId}));
      dispatch(getTags({projectId: projectId}));
      dispatch(fetchSources({projectId: projectId}));
      return response.data.data;
    } catch (e: any) {
      if(typeof e.response.data.detail === 'object' && e.response.data.detail.length > 0) {
        return rejectWithValue(e.response.statusText);
      }
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const deleteCampaign = createAsyncThunk(
  'campaigns/deleteCampaign',
  async (
    { userId, projectId, campaignId, confirm=false, language='ru' }: { userId: number, projectId: number, campaignId: number, language?: string, confirm?:boolean },
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    const openMultipleRemoveSource = (response: any) => {
      if(response.data && response.data.stats && Array.isArray(response.data.stats.source_for_confirmation) && response.data.stats.source_for_confirmation.length > 0) {
        dispatch(openModal({
          name: ModalName.CONFIRM_DELETE_SOURCES,
          data: {
            title: trans('Error', language),
            message: response.data,
            buttons: {
              cancel: {
                text: trans('Cancel', language),
                onClick: () => dispatch(closeModal(ModalName.CONFIRM_DELETE_SOURCES))
              },
              confirmation: {
                text: trans('Delete', language),
                onClick: () => {
                  dispatch(closeModal(ModalName.CONFIRM_DELETE_SOURCES));
                  dispatch(removeMultipleSource({
                      projectId: projectId,
                      sourcesIds: response.data.stats.source_for_confirmation,
                      language
                  }))
                }
              },
            }
          }
        }))
      }
    }
    try {
      const response = await API.delete(`/api/campaigns/${campaignId}/?confirm_delete=${confirm}`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      showPopup(dispatch, response.data.message as string);
      openMultipleRemoveSource(response);
      dispatch(downloadCampaignsData({ projectId: projectId }));
      dispatch(getTags({projectId: projectId}));
      dispatch(fetchSources({projectId: projectId}));
      return response.data.data;
    } catch (e: any) {
      if(e.response.data.message) {
        return rejectWithValue(e.response.data.message);
      }
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const deleteMultipleCampaign = createAsyncThunk(
  'campaigns/deleteMultipleCampaign',
  async (
    { projectId, campaignIds, language='ru', recursive=false }: { projectId: number, campaignIds: Array<number>, language?: string, recursive?: boolean },
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    const openMultipleRemoveSource = (response: any) => {
      if(response.data && response.data.stats && Array.isArray(response.data.stats.source_for_confirmation) && response.data.stats.source_for_confirmation.length > 0) {
        dispatch(openModal({
          name: ModalName.CONFIRM_DELETE_SOURCES,
          data: {
            title: trans('Error', language),
            message: response.data,
            buttons: {
              cancel: {
                text: trans('Cancel', language),
                onClick: () => dispatch(closeModal(ModalName.CONFIRM_DELETE_SOURCES))
              },
              confirmation: {
                text: trans('Delete', language),
                onClick: () => {
                  dispatch(closeModal(ModalName.CONFIRM_DELETE_SOURCES));
                  dispatch(removeMultipleSource({
                      projectId: projectId,
                      sourcesIds: response.data.stats.source_for_confirmation,
                      language
                  }))
                }
              },
            }
          }
        }))
      }
    }
    try {
      let url = `/api/campaigns/?ids=${campaignIds.join(',')}`;
      if(recursive) {
        url += `&confirm_delete=${recursive}`
      }
      const response = await API.delete(url);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      showPopup(dispatch, response.data.message as string);
      dispatch(downloadCampaignsData({ projectId: projectId }));
      dispatch(getTags({projectId: projectId}));
      dispatch(fetchSources({projectId: projectId}));
      if(response.data && response.data.stats && Array.isArray(response.data.stats.campaign_with_confirmation) && response.data.stats.campaign_with_confirmation.length > 0) {
        dispatch(openModal({
          name: ModalName.MESSAGE,
          data: {
            title: trans('Error', language),
            message: response.data,
            buttons: {
              cancel: {
                text: trans('Cancel', language),
                onClick: () => {
                  dispatch(closeModal(ModalName.MESSAGE));
                  openMultipleRemoveSource(response);
                }
              },
              confirmation: {
                text: trans('Delete', language),
                onClick: () => {
                  dispatch(closeModal(ModalName.MESSAGE));
                  dispatch(deleteMultipleCampaign({
                      projectId: projectId,
                      campaignIds: campaignIds,
                      language,
                      recursive: true
                  }));
                }
              },
            }
          }
        }))
        openMultipleRemoveSource(response);
      }
      else if(response.data && response.data.errors && Object.keys(response.data.errors).length > 0) {
        dispatch(openModal({
          name: ModalName.MESSAGE,
          data: {
            title: trans('Error', language),
            message: response.data,
            buttons: {
              confirmation: {
                text: trans('Close', language),
                onClick: () => {
                  dispatch(closeModal(ModalName.MESSAGE));
                  openMultipleRemoveSource(response);
                }
              },
            }
          }
        }))
      }
      else {
        openMultipleRemoveSource(response);
      }
      return response.data.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const updateCampaignById = createAsyncThunk(
  'campaigns/updateCampaignById',
  async (
    { data, userId, projectId, campaignId }: UpdateParams,
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const response = await API.put(`/api/campaigns/${campaignId}/`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      dispatch(closeModal(ModalName.CAMPAIGN_EDITING));
      showPopup(dispatch, response.data.message as string);
      dispatch(downloadCampaignsData({ projectId: projectId }));
      dispatch(getTags({projectId: projectId}));
      dispatch(fetchSources({projectId: projectId}));
      return response.data.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const uploadMediaCsv = createAsyncThunk(
  'media/upload',
  async (
    { data, userId, projectId }: { data: FormData, userId: number, projectId: number },
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      // const response = await API.post(`api/user/${userId}/project/${projectId}/media`, data, {
      const response = await API.post(`/api/reports/csv/?project_id=${projectId}`, data, {
        timeout: REQUEST_TIMEOUT_TO_LOAD
      });
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      let check_response;
      let count_request = 20;
      const check = setInterval(async () => {
        if(count_request <= 0) {
          clearInterval(check);
          dispatch(setIsLoading(false));
          dispatch(openModal({
            name: ModalName.MESSAGE,
            data: {
              title: 'Ошибка',
              message: 'Превышен лимит времени ожидания загрузки',
              buttons: {
                cancel: {
                  text: 'Отменить',
                  onClick: () => dispatch(closeModal(ModalName.MESSAGE))
                },
                confirmation: {
                  text: 'Повторить',
                  onClick: () => {
                    dispatch(closeModal(ModalName.MESSAGE));
                    dispatch(uploadMediaCsv({data, userId, projectId}));
                  }
                },
              }
            }
          }))
          dispatch(downloadCampaignsData({ projectId: projectId }));
          dispatch(getTags({projectId: projectId}));
          dispatch(fetchSources({projectId: projectId}));
          return;
        }
        check_response = await API.get(`/api/reports/status/${response.data.upload_id}/?project_id=${projectId}`);
        const new_token = check_response.headers["x-new-token"];
        updateToken(new_token);
        if(check_response.data.status === 'ready') {
          clearInterval(check);
          dispatch(setIsLoading(false));
          dispatch(openModal({
            name: ModalName.MESSAGE,
            data: {
              title: 'Загрузка',
              message: check_response.data,
              buttons: {
                confirmation: {
                  text: 'Отлично!',
                  onClick: () => dispatch(closeModal(ModalName.MESSAGE))
                },
              }
            }
          }))
          dispatch(downloadCampaignsData({ projectId: projectId }));
          dispatch(getTags({projectId: projectId}));
          dispatch(fetchSources({projectId: projectId}));
          return check_response.data;
        }
        if(check_response.data.status === 'error') {
          clearInterval(check);
          dispatch(setIsLoading(false));
          dispatch(openModal({
            name: ModalName.MESSAGE,
            data: {
              title: 'Ошибка',
              message: check_response.data.error_message,
              buttons: {
                cancel: {
                  text: 'Отменить',
                  onClick: () => dispatch(closeModal(ModalName.MESSAGE))
                },
                confirmation: {
                  text: 'Повторить',
                  onClick: () => {
                    dispatch(closeModal(ModalName.MESSAGE));
                    dispatch(uploadMediaCsv({data, userId, projectId}));
                  }
                },
              }
            }
          }))
          return check_response.data;
        }
        count_request--;
      }, 2000);

      // return response.data.updateProjectData.MediaCampaign.map((mc: any) => {
      //   return mc.MediaCampaign;
      // })
    } catch (e: any) {
      dispatch(openModal({
        name: ModalName.MESSAGE,
        data: {
          title: 'Ошибка',
          message: e.response.data.detail,
          buttons: {
            cancel: {
              text: 'Отменить',
              onClick: () => dispatch(closeModal(ModalName.MESSAGE))
            },
            confirmation: {
              text: 'Повторить',
              onClick: () => {
                dispatch(closeModal(ModalName.MESSAGE));
                dispatch(uploadMediaCsv({data, userId, projectId}));
              }
            },
          }
        }
      }))
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const downloadPixelsCsv = createAsyncThunk(
  'media/downloadPixelsCsv',
  async (
    { mode, userId, projectId, type='xlsx' }: CsvParams,
    { rejectWithValue, extra: API }: any
  ) => {
    try {
      let apiString;
      if (Array.isArray(mode) && mode.length > 0) {
        apiString = `/api/reports/${type}/?p_id=${projectId}&pc_ids=${mode.join('&pc_ids=')}&pac=false`;
      } else if (mode === 'active') {
        apiString = `/api/reports/${type}/?p_id=${projectId}&pac=true`;
      } else if (mode === 'all') {
        apiString = `/api/reports/${type}/?p_id=${projectId}`;
      }

      if (apiString) {
        if(type === 'csv') {
          const response = await API.get(apiString);
          const new_token = response.headers["x-new-token"];
          updateToken(new_token);
          const fileName = response.headers['content-disposition'] && response.headers['content-disposition'].split('; ').find((item: string) => item.startsWith('filename=')).replace('filename=', '');
          try {
            downloadCsv(response.data, type, (fileName && `${fileName}_${new Date().toISOString().split('T')[0]}`) || `targetads_pixels_for_project_${projectId}_${new Date().toISOString().split('T')[0]}`);
          } catch (error) {
            console.error('Ошибка при скачивании файла', error);
          }
        } else {
          const response = await API.get(apiString, {responseType: 'blob'});
          const new_token = response.headers["x-new-token"];
          updateToken(new_token);
          const fileName = response.headers['content-disposition'] && response.headers['content-disposition'].split('; ').find((item: string) => item.startsWith('filename=')).replace('filename=', '');
          try {
            downloadCsv(response.data, 'xlsx', (fileName && `${fileName}_${new Date().toISOString().split('T')[0]}`) || `targetads_pixels_for_project_${projectId}_${new Date().toISOString().split('T')[0]}`);
          } catch (error) {
            console.error('Ошибка при скачивании файла', error);
          }
        }
      }
      return;
    } catch (e: any) {
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const downloadCampaignsTemplateXlsx = createAsyncThunk(
  'media/downloadCampaignsTemplateXlsx',
  async (
    { mode, userId, projectId, type='xlsx', campaignId }: CsvParams,
    { rejectWithValue, extra: API }: any
  ) => {
    try {
      let apiString;
      if (Array.isArray(mode) && mode.length > 0) {
        if(campaignId) {
          apiString = `/api/reports/campaigns/${type}/?p_id=${projectId}&c_id=${campaignId}&cp_ids=${mode.join('&cp_ids=')}&cap=false`;
        } else {
          apiString = `/api/reports/campaigns/${type}/?p_id=${projectId}&pc_ids=${mode.join('&pc_ids=')}&pac=false`;
        }
      } else if (mode === 'active') {
        if(campaignId) {
          apiString = `/api/reports/campaigns/${type}/?p_id=${projectId}&c_id=${campaignId}&cap=true`;
        } else {
          apiString = `/api/reports/campaigns/${type}/?p_id=${projectId}&pac=true`;
        }
      } else if (mode === 'all') {
        if(campaignId) {
          apiString = `/api/reports/campaigns/${type}/?p_id=${projectId}&c_id=${campaignId}`;
        } else
          apiString = `/api/reports/campaigns/${type}/?p_id=${projectId}`;
      }

      if (apiString) {
        if(type === 'csv') {
          const response = await API.get(apiString);
          const new_token = response.headers["x-new-token"];
          updateToken(new_token);
          const fileName = response.headers['content-disposition'] && response.headers['content-disposition'].split('; ').find((item: string) => item.startsWith('filename=')).replace('filename=', '');
          try {
            downloadCsv(response.data, type, (fileName && `${fileName}_${new Date().toISOString().split('T')[0]}`) || `targetads_pixels_for_project_${projectId}_${new Date().toISOString().split('T')[0]}`);
          } catch (error) {
            console.error('Ошибка при скачивании файла', error);
          }
        } else {
          const response = await API.get(apiString, {responseType: 'blob'});
          const new_token = response.headers["x-new-token"];
          updateToken(new_token);
          const fileName = response.headers['content-disposition'] && response.headers['content-disposition'].split('; ').find((item: string) => item.startsWith('filename=')).replace('filename=', '');
          try {
            downloadCsv(response.data, 'xlsx', (fileName && `${fileName}_${new Date().toISOString().split('T')[0]}`) || `targetads_pixels_for_project_${projectId}_${new Date().toISOString().split('T')[0]}`);
          } catch (error) {
            console.error('Ошибка при скачивании файла', error);
          }
        }
      }
      return;
    } catch (e: any) {
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const changeCampaignStatus = createAsyncThunk(
  'campaigns/changeCampaignStatus',
  async (
    { status, userId, projectId, campaignId }: StatusParams,
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const data = {'status':status};
      const response = await API.post(`/api/campaigns/${campaignId}/status/`, data);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      dispatch(downloadCampaignsData({ projectId: projectId }));
      // return ({
      //   campaignId,
      //   newStatus: status,
      //   requestStatus: response.data.status
      // })
      return response.data.status;
    } catch (e: any) {
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const getCampaignsGenerals = createAsyncThunk(
  'campaigns/getCampaignsGenerals',
  async (
    { projectId, campaignIds }: {projectId:number, campaignIds:Array<number>},
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const response = await API.get(`/api/projects/${projectId}/campaigns/general/?ids=${campaignIds.join(',')}`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const updateCampaignsMulti = createAsyncThunk(
  'campaigns/updateCampaignsMulti',
  async (
    { data, projectId, campaignIds }: {
      data: any,
      projectId:number,
      campaignIds:Array<number>
    },
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const response = await API.put(`/api/projects/${projectId}/campaigns/update/?ids=${campaignIds.join(',')}`, data);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      dispatch(downloadCampaignsData({ projectId: projectId }));
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data.detail);
    }
  }
);
