import {DefaultObject} from "../../../../interfaces/common.d";
import {FC, useEffect, useRef, useState} from "react";
import {getColorValue, getMaxTargetPrice} from "../../TablePagination/functions";
import DashboardOutputValue from "../../TablePagination/OutputValue";
import {useAppSelector} from "../../../../redux/hooks";
import {eventsData} from "../../../../redux/slices/events";
import {projectGeneral} from "../../../../redux/slices/project";
import {currentUserLang} from "../../../../redux/slices/user";
import {IPinColumns} from "../../interfaces";
import {keyToSkip} from "../../../../data";

import "./style.scss";
import {trans} from "../../../../_locales";
import Btn from "../../../../ui/Btn";

interface ITableBody {
    columnPreferences: Array<DefaultObject<any>>,
    dataWrapper: Array<Array<any>>,
    activePin: IPinColumns,
    metricChoice: Array<string>,
    metricColIndexes: Array<number>,
    aggColIndexesDefault: Array<number>,
    currentPage: number,
    limitChannelCustom: number,
    targetEvents?: DefaultObject<any>,
    itemCountPercent?:Array<any>,
    targetPrice?: number,
    settingSize?: DefaultObject<number>,
    isUpdateByNoActual?: boolean,
    isNoActual?: boolean,
    onUpdate?: () => void
}

const TableInteractiveBody:FC<ITableBody> = ({
    columnPreferences,
    dataWrapper,
    activePin,
    metricChoice,
    metricColIndexes,
    aggColIndexesDefault,
    currentPage,
    limitChannelCustom,
    targetEvents,
    itemCountPercent=[],
    settingSize={},
    isUpdateByNoActual=false,
    isNoActual=false,
    onUpdate=()=> null
}) => {
    const generalProjectInfo = useAppSelector(projectGeneral);
    const language = useAppSelector(currentUserLang);
    const reduxEvents = useAppSelector(eventsData);
    const currency= generalProjectInfo && generalProjectInfo.currency || 'RUB';

    const [targetPrice, setTargetPrice] = useState<number>(getMaxTargetPrice(reduxEvents))

    useEffect(()=> {
        if(targetEvents && typeof targetEvents.event_target_price === 'number') {
            setTargetPrice(targetEvents.event_target_price)
        } else if(reduxEvents && reduxEvents.length > 0) {
            setTargetPrice(getMaxTargetPrice(reduxEvents));
        }
    }, [reduxEvents, targetEvents]);


    const getStylesItem = (key: number) => {
        return {
            minWidth: settingSize[key] ? settingSize[key] : undefined,
            width: settingSize[key] ? settingSize[key] : undefined,
            maxWidth: settingSize[key] ? settingSize[key] : undefined,
            left: activePin[key] && activePin[key].left ? `${activePin[key].left}px` : undefined
        }
    }

    const [stylesUpdate, setStylesUpdate] = useState<{minWidth: number}>({} as {minWidth: number})
    useEffect(() => {
        const width = Object.keys(settingSize).reduce(
          (accumulator, key) => accumulator + settingSize[key],
          0
        );
        const temp = {minWidth: width};
        setStylesUpdate(temp);
    }, [settingSize]);

    return (
        <div className='table-interactive-body'>
            {(isNoActual && isUpdateByNoActual && onUpdate) &&
                <div className='table-interactive-body-update' style={stylesUpdate}>
                    <div className="table-interactive-body-update__block">
                        <p>{trans('The data is not up to date', language)}</p>
                        <Btn
                            text={trans('Update', language)}
                            color={'light-blue'}
                            onClick={()=>onUpdate()}
                        />
                    </div>
                </div>
            }
            {columnPreferences.length > 0 && dataWrapper.length > 0 && dataWrapper.slice((currentPage - 1) * limitChannelCustom, currentPage * limitChannelCustom).map((item: any, index: number) => (
                <div className='table-interactive-body-row' key={index}>
                    {item.map((value: string, key: number) => (
                        value !== keyToSkip && ((metricChoice.length) ? (aggColIndexesDefault.includes(key) || metricColIndexes.includes(key)) : true) && typeof columnPreferences === 'object' && columnPreferences[key] &&
                        <div key={key}
                            className={`table-interactive-body-row__item ${columnPreferences[key].type === 'date' ? 'date-value' : ''} ${columnPreferences[key] && columnPreferences[key].type === 'string' ? 'string-value' : ''} ${/Стоимость целевого действия/i.test(columnPreferences[key].title) ? getColorValue(+value, targetPrice) : ''} ${index === columnPreferences.map((itemCol: any, indexCol: number) => dataWrapper[0] && dataWrapper[0][indexCol] !== keyToSkip && itemCol.type).lastIndexOf(item.type) ? 'last' : ''} ${activePin[key] ? 'sticky' : ''}`}
                            style={getStylesItem(key)}
                        >
                            <DashboardOutputValue
                                columnPreferences={columnPreferences}
                                itemIndex={key}
                                value={value}
                                rowIndex={index}
                                itemCountPercent={itemCountPercent}
                                currentPage={currentPage}
                                limitChannelCustom={limitChannelCustom}
                                currency={currency}
                                language={language}
                            />
                        </div>
                    ))}
                </div>
            ))
            }
        </div>
    )
}

export default TableInteractiveBody;