import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {IProject, IProjectUsers} from '../../interfaces/common.d'
import { RootState } from '..'
import {
  downloadProjectsData,
  creatProject,
  deleteProjectById,
  updateProjectById,
  changeProjectStatus,
  isProject,
  updateProjectBySetting,
  getProjectUsers,
  addProjectUser,
  deleteProjectUser,
  updateProjectUser
} from '../../actions/projects'

// interface StepDone {
//   step: false
// }

interface ProjectsState {
  data: IProject[]
  loaded: boolean
  isLoading: boolean
  error: string | null
  steps: boolean[],
  stepsData: any
  users: IProjectUsers[] | []
}

const initialState: ProjectsState = {
  data: [],
  loaded: false,
  isLoading: false,
  error: null,
  steps: [false, false, false, false],
  stepsData: {},
  users: []
}

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setProjects: (state, action) => {
      state.data = action.payload;
    },
    clearProjects: (state) => {
      state.data = [];
    },
    setProjectsLoaded: (state, action) => {
      state.loaded = action.payload
    },
    removeError: (state) => {
      state.error = null;
    },
    firstDone: (state, action) => {
      state.steps[0] = true;
      state.stepsData = action.payload;
    },
    secondDone: (state) => {
      state.steps[1] = true
    },
    thirdDone: (state) => {
      state.steps[2] = true
    },
    fourthDone: (state) => {
      state.steps = [false,false,false,false];
      state.stepsData = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      downloadProjectsData.fulfilled,
      (state, action: PayloadAction<IProject[]>) => {
      state.data = action.payload ? action.payload : [];
      state.loaded = true;
      state.isLoading = false;
    });
    builder.addCase(
      downloadProjectsData.pending,
      (state) => {
      state.isLoading = true;
      state.loaded = false;
    });
    builder.addCase(
      downloadProjectsData.rejected,
      (state,  action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload
    });
    builder.addCase(
      creatProject.fulfilled,
      (
        state,
        // action: PayloadAction<IProject>
      ) => {
      // state.data.push(action.payload);
      state.isLoading = false;
    });
    builder.addCase(
      creatProject.pending,
      (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      creatProject.rejected,
      (state,  action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload
    });

    builder.addCase(
      isProject.fulfilled,
      (
        state,
        // action: PayloadAction<any>
      ) => {
        // state.data = action.payload;
        // state.data = action.payload ? action.payload : 'hello dzhem';
        state.isLoading = false;
      });
    builder.addCase(
      isProject.pending,
      (state) => {
        state.isLoading = true;
      });
    builder.addCase(
      isProject.rejected,
      (state,  action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload
      });

    builder.addCase(
      deleteProjectById.fulfilled,
      (state) => {
      state.isLoading = false;
    });
    builder.addCase(
      deleteProjectById.pending,
      (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      deleteProjectById.rejected,
      (state,  action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload
    });
    builder.addCase(
      updateProjectById.fulfilled,
      (
        state,
        // action: PayloadAction<IProject>
      ) => {
        // const inData = state.data.find((item) => item.id === action.payload.id);
        // const idx = inData ? state.data.indexOf(inData) : -1;
        // state.data = [
        //   ...state.data.slice(0, idx),
        //   action.payload,
        //   ...state.data.slice(idx + 1)
        // ];
        state.isLoading = false;
      });
    builder.addCase(
      updateProjectById.pending,
      (state) => {
        state.isLoading = true;
      });
    builder.addCase(
      updateProjectById.rejected,
      (state,  action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload
      });
    builder.addCase(
      updateProjectBySetting.fulfilled,
      (
        state,
        // action: PayloadAction<IProject>
      ) => {
        // const inData = state.data.find((item) => item.id === action.payload.id);
        // const idx = inData ? state.data.indexOf(inData) : -1;
        // state.data = [
        //   ...state.data.slice(0, idx),
        //   action.payload,
        //   ...state.data.slice(idx + 1)
        // ];
        state.isLoading = false;
      });
    builder.addCase(
      updateProjectBySetting.pending,
      (state) => {
        state.isLoading = true;
      });
    builder.addCase(
      updateProjectBySetting.rejected,
      (state,  action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload
      });
    builder.addCase(
      getProjectUsers.fulfilled, (state, action: PayloadAction<any>) => {
      state.users = action.payload;
      state.isLoading = false;
    });
    builder.addCase(
      getProjectUsers.pending,
      (state) => {
        state.isLoading = true;
      });
    builder.addCase(
      getProjectUsers.rejected,
      (state,  action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload
      });
    builder.addCase(
      addProjectUser.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(
      addProjectUser.pending,
      (state) => {
        state.isLoading = true;
      });
    builder.addCase(
      addProjectUser.rejected,
      (state,  action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload
      });
    builder.addCase(
      deleteProjectUser.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(
      deleteProjectUser.pending,
      (state) => {
        state.isLoading = true;
      });
    builder.addCase(
      deleteProjectUser.rejected,
      (state,  action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload
      });
    builder.addCase(
      updateProjectUser.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(
      updateProjectUser.pending,
      (state) => {
        state.isLoading = true;
      });
    builder.addCase(
      updateProjectUser.rejected,
      (state,  action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload
      });

    builder.addCase(
      changeProjectStatus.fulfilled,
      (
        state,
        // action: PayloadAction<any>
      ) => {
        // const projectIndex = state.data.findIndex((item) => {
        //   return item.id === action.payload.projectId
        // })
        // if (action.payload.requestStatus === 'success') {
        //   state.data[projectIndex].status = action.payload.newStatus;
        // }
        state.isLoading = false;
      });
    builder.addCase(
      changeProjectStatus.pending,
      (state) => {
        state.isLoading = true;
      });
    builder.addCase(
      changeProjectStatus.rejected,
      (state,  action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload
      });
  }
})

export const { setProjects,
  removeError,
  clearProjects,
  setProjectsLoaded,
  firstDone,
  secondDone,
  thirdDone,
  fourthDone,
} = projectsSlice.actions

export const projectsData = createSelector(
  (state: RootState) => state.projects,
  (projects) => projects.data
)

export const projectsLoaded = createSelector(
  (state: RootState) => state.projects,
  (projects) => projects.loaded
)

export const getIsLoading = createSelector(
  (state: RootState) => state.projects,
  (projects) => projects.isLoading
)

export const errorValue = createSelector(
  (state: RootState) => state.projects,
  (projects) => projects.error
)

export const currentProjectUsers = createSelector(
  (state: RootState) => state.projects,
  (projects) => projects.users
)

export const stepsData = createSelector(
  (state: RootState) => state.projects,
  (projects) => projects.stepsData
)

export default projectsSlice.reducer

