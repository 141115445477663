import { FC, useEffect, useRef, useState } from 'react'
import cx from 'classnames';
import { useOnClickOutside } from '../../helpers';
import { ReactComponent as SelectArrowIcon } from '../../assets/icons/icon-select-arrow.svg'
import { ReactComponent as CheckMarkIcon } from '../../assets/icons/icon-check-mark.svg'
import { ReactComponent as PlusIcon } from '../../assets/icons/icon-plus.svg'
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg'
import { ReactComponent as RemoveIcon } from '../../assets/icons/trash.svg'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { ModalName, modalStack as modalStackSelector, openModal } from '../../redux/slices/modals'
import { ISelect } from './Select.d'
import './Select.scss'
import { Btn } from '../index'
import { parseValue } from '../../utils'
import {Context} from '../../components';
import Tooltip from "../../components/Tooltip";
import {trans} from "../../_locales";
import {currentUser, currentUserLang} from "../../redux/slices/user";
import {sources} from "../../redux/slices/sources";
import {removeSource} from "../../actions/source";

const Select: FC<ISelect> = ({
  options,
  name,
  value,
  label,
  tooltip,
  errorLabel,
  isOpenUp = false,
  hasAddButton = false,
  isInvalid = false,
  activeOption,
  onChange,
  required = false,
  beta = false,
  projectId
}) => {
  const fieldRef = useRef(null);
  const listRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useAppDispatch();
  const modalStack = useAppSelector(modalStackSelector);
  const language = useAppSelector(currentUserLang);
  const reduxSources = useAppSelector(sources);
  const reduxUser = useAppSelector(currentUser);
  const permissions = reduxUser && reduxUser.permission ? reduxUser.permission[`${projectId}`] : undefined;
  const isSourceCreate = permissions && permissions['can_create'] && permissions['can_create'].includes('sources');
  const isSourceEdit = permissions && permissions['can_edit'] && permissions['can_edit'].includes('sources');
  const isSourceDelete = permissions && permissions['can_delete'] && permissions['can_delete'].includes('sources');

  const selectClassName = cx(
    'custom-select',
    ' custom-select--placeholder',
    {
      ['is-open']: isOpen,
      ['open-up']: isOpenUp,
      ['is-invalid']: isInvalid
    }
  );

  const currentOption = options &&
    options.length > 0 &&
    options.find((option) =>
      String(option.value) === String(value));

  useOnClickOutside(() => {
    if (isOpen && modalStack.length < 2) setIsOpen(false);
  }, [fieldRef, listRef]);

  useEffect(() => {
    if (activeOption) onChange({ [name]: activeOption });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOption, name]);

  const handleSelectClick = () => {
    setIsOpen(!isOpen);
  }

  const handleOptionChange = (evt: any) => {
    const value = evt.target.value;
    const currentValue = parseValue(value);
    setIsOpen(false);
    onChange({ [name]: currentValue });
  }

  const handleAddBtnClick = (evt: MouseEvent | TouchEvent) => {
    evt.preventDefault();
    if(projectId) {
      dispatch(openModal({
        name: ModalName.NEW_SOURCE,
        data: {
          projectId: projectId
        }
      }))
    }
  }

  const handleEditSource = (index:number) => {
    if(reduxSources && reduxSources[index]) {
      dispatch(openModal({
        name:ModalName.EDIT_SOURCE,
        data: {
          initialValues: reduxSources[index],
          sourceId: reduxSources[index].id,
          projectId: reduxSources[index].project_id
        }
      }));
    }
  }

  const handleDeleteSource = (index:number) => {
    if(reduxSources && reduxSources[index]) {
      dispatch(removeSource({sourceId: reduxSources[index].id, projectId: reduxSources[index].project_id}))
    }
  }

  return (
    <>
      <div className={selectClassName}>
        {label && <span className="custom-select__label">{label}{required && <span className='red'>*</span>} {tooltip && <Tooltip text={tooltip}/>} {beta && <span className='beta'>beta</span>}</span>}
        <button
          ref={fieldRef}
          className="custom-select__button"
          type="button"
          aria-label="Выберите одну из опций"
          onClick={handleSelectClick}
        >
          {currentOption && <span className="custom-select__text">{currentOption.label}</span>}
          <span className="custom-select__icon">
            <SelectArrowIcon width={13} height={8} />
          </span>
        </button>
        {errorLabel && <span className="custom-select__error">{errorLabel}</span>}

        {
          isOpen &&
          <Context
            parentRef={fieldRef}
            onClose={() => {
              setIsOpen(false);
            }}
            isFullWidth
          >
            <div ref={listRef} className="custom-select__list">
              {
                options &&
                options.length > 0 &&
                options.map((option, i) =>
                  <label
                    key={`${option.label}-${i}`}
                    className={cx(
                      'custom-select__item',
                      {'is-selected': String(value) === String(option.value)}
                    )}
                  >
                    <span className="custom-select__item-label">{option.label}</span>
                    <input
                      className="visually-hidden"
                      type="radio"
                      name={name}
                      value={option.value}
                      data-label={option.label}
                      onChange={handleOptionChange}
                    />
                    <CheckMarkIcon width={12} height={8} />
                    {hasAddButton && (isSourceEdit || reduxSources[i].author_id == reduxUser.id) &&
                        <button className='custom-select__item-edit' onClick={()=>handleEditSource(i)}>
                          <EditIcon />
                        </button>
                    }
                    {hasAddButton && (isSourceDelete || reduxSources[i].author_id == reduxUser.id) &&
                        <button className='custom-select__item-edit custom-select__item-remove' onClick={()=>handleDeleteSource(i)}>
                          <RemoveIcon />
                        </button>
                    }
                  </label>
                )
              }

              {
                hasAddButton && isSourceCreate &&
                <Btn
                  className='custom-select__add-btn'
                  text={trans('Add source', language)}
                  icon={{
                    Component: PlusIcon,
                    width: 12,
                    height: 12,
                  }}
                  widthAuto={false}
                  color='light-blue'
                  size='sm'
                  onClick={handleAddBtnClick}
                />
              }
            </div>
          </Context>
        }
      </div>
    </>
  );
};

export default Select;
