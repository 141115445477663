import { createAsyncThunk } from '@reduxjs/toolkit'
import {DefaultObject, ItemStatus} from '../interfaces/common.d'
import { closeModal, ModalName } from '../redux/slices/modals'
import {showPopup, updateToken} from '../utils'
import {openPopup} from "../redux/slices/popup";
import {contentJson, PopupType} from "../data";
import {getUserInfo} from "./users";
import {getProjectGeneral} from "./project";
import {getApiTokens} from "./tokens";

interface StatusParams {
  status: ItemStatus
  userId: number
  projectId: number
}

export const downloadProjectsData = createAsyncThunk(
  'projects/download',
  async (
    // { userId, refresh }: {userId: number, refresh?: boolean},
    _,
    { rejectWithValue, extra: API}: any
) => {
    // const currentRefresh = typeof refresh !== 'undefined' ? `?refresh=${refresh}` : '';
    try {
      // const response = await API.get(`/api/user/${userId}/project${currentRefresh}`);
      const response = await API.get(`/api/projects/`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const creatProject = createAsyncThunk(
  'projects/creatProject',
  async (
    { data, userId }: { data: any, userId: number }, // was data: FormData | JSON inside any
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      // const response = await API.post(`/api/user/${userId}/project`, data);
      const response = await API.post(`/api/projects/`, {...data, timezone: 'Etc/GMT+3'});
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      dispatch(closeModal(ModalName.NEW_PROJECT));
      showPopup(dispatch, response.data.message as string);
      // dispatch(downloadProjectsData({ userId: userId, refresh: true }));
      dispatch(downloadProjectsData());
      dispatch(getUserInfo());
      return response.data;
    } catch (e: any) {
      if (typeof e.response.data.detail === 'string')
        dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail }));
      return rejectWithValue(e.detail);
    }
  }
);

export const isProject = createAsyncThunk(
  'projects/isProject',
  async ({ projectId }: { projectId: number }, { rejectWithValue, extra: API }: any) => {
    try {
      const response = await API.get(`/api/pixels/?project_id=${projectId}`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      return response;
    } catch (e: any) {
      return rejectWithValue(e.response.data.detail);
    }
  }
)

export const deleteProjectById = createAsyncThunk(
  'projects/deleteProjectById',
  async (
    { userId, projectId }: { userId: number, projectId: number },
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const response = await API.delete(`/api/projects/${projectId}/`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      dispatch(downloadProjectsData());
      showPopup(dispatch, response.data.message as string);
      return response.data;
    } catch (e: any) {
      if (typeof e.response.data.detail === 'string')
        dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail }));
      return rejectWithValue(e.response.data.detail);
    }
  }
);
// Todo непонятно теперь за что отвечает ручка ??
export const fetchProjectById = createAsyncThunk(
  'projects/fetchProjectById',
  async (
    { userId, projectId, refresh }: { userId: number, projectId: number, refresh?: boolean },
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    const currentRefresh = typeof refresh !== 'undefined' ? `?refresh=${refresh}` : '';
    try {
      const response = await API.get(`/api/user/${userId}/project/${projectId}${currentRefresh}/`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      showPopup(dispatch, response.data.message as string);
      return response.data.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data.message);
    }
  }
);

export const updateProjectById = createAsyncThunk(
  'projects/updateProjectById',
  async (
    { data, projectId }: { data: JSON | FormData | any, projectId: any },
    { dispatch, rejectWithValue, extra: API }: any

  ) => {
    try {
      // const response = await API.patch(`/api/user/${userId}/project/${projectId}`, data);
      const response = await API.put(`/api/projects/${projectId}/`, data);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      dispatch(closeModal(ModalName.PROJECT_EDITING));
      showPopup(dispatch, response.data.message as string);
      // dispatch(downloadProjectsData({ userId: userId, refresh: true }));
      dispatch(downloadProjectsData());
      return response.data.data;
    } catch (e: any) {
      if (typeof e.response.data.detail === 'string')
        dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail }));
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const changeProjectStatus = createAsyncThunk(
  'projects/changeProjectStatus',
  async (
    { status, userId, projectId }: StatusParams,
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const data = {'status':status};
      const response = await API.post(`/api/projects/${projectId}/status/`, data);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      // dispatch(downloadProjectsData({ userId: userId, refresh: true }));
      dispatch(downloadProjectsData());
      // return ({
      //   projectId,
      //   newStatus: status,
      //   requestStatus: response.data.status
      // })
      return response.data.status;
    } catch (e: any) {
      if (typeof e.response.data.detail === 'string')
        dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail }));
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const updateProjectBySetting = createAsyncThunk(
  'projects/updateProjectBySetting',
  async (
    { data, projectId, userId }: { data: JSON | FormData | any, projectId: any, userId:any },
    { dispatch, rejectWithValue, extra: API }: any

  ) => {
    try {
      const response = await API.post(`/api/projects/${projectId}/settings/general/`, data);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      showPopup(dispatch, response.data.message as string);
      // dispatch(downloadProjectsData({ userId: userId, refresh: true }));
      dispatch(downloadProjectsData());
      dispatch(getProjectGeneral({currentUserId: userId, projectId: projectId}))
      dispatch(openPopup({ type: PopupType.SUCCESS, title: 'Изменения сохранены', icon: 'setting' }))
      return response.data.data;
    } catch (e: any) {
      if(typeof e.response.data.detail === 'object' && e.response.data.detail.length > 0) {
        dispatch(openPopup({ type: PopupType.ERROR, description: e.response.statusText }))
        return rejectWithValue(e.response.statusText);
      }
      if (typeof e.response.data.detail === 'string')
        dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail }));
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const getProjectUsers = createAsyncThunk(
  'projects/getProjectUsers',
  async (
    { projectId }: { projectId: any },
    { dispatch, rejectWithValue, extra: API }: any

  ) => {
    try {
      const response = await API.get(`/api/project/${projectId}/users/`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      return response.data;
    } catch (e: any) {
      if (typeof e.response.data.detail === 'string')
        dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail }));
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const addProjectUser = createAsyncThunk(
  'projects/addProjectUser',
  async (
    { data, projectId }: { data: JSON | any, projectId: any },
    { dispatch, rejectWithValue, extra: API }: any

  ) => {
    try {
      const response = await API.post(`/api/project/${projectId}/users/`, data);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      dispatch(getProjectUsers({projectId: projectId}));
      dispatch(openPopup({ type: PopupType.SUCCESS, title: 'Пользователь добавлен успешно', icon: 'hello' }))
      return response.data;
    } catch (e: any) {
      if (typeof e.response.data.detail === 'string')
        dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail }));
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const deleteProjectUser = createAsyncThunk(
  'projects/deleteProjectUser',
  async (
    { email, projectId, userId }: { email: string, projectId: any, userId: any },
    { dispatch, rejectWithValue, extra: API }: any

  ) => {
    try {
      const response = await API.delete(`/api/project/${projectId}/users/?email=${email}`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      dispatch(getProjectUsers({projectId: projectId}));
      return response.data;
    } catch (e: any) {
      if (typeof e.response.data.detail === 'string')
        dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail }));
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const updateProjectUser = createAsyncThunk(
  'projects/updateProjectUser',
  async (
    { data, projectId, userId }: { data: JSON | any, projectId: any, userId: any },
    { dispatch, rejectWithValue, extra: API }: any

  ) => {
    try {
      const response = await API.put(`/api/project/${projectId}/users/`, data);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      dispatch(getProjectUsers({projectId: projectId}));
      return response.data;
    } catch (e: any) {
      if (typeof e.response.data.detail === 'string')
        dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail }));
      return rejectWithValue(e.response.data.detail);
    }
  }
);


export const getFilterUser = createAsyncThunk(
  'projects/getFilterUser',
  async (
      {email}: {email: string},
    { dispatch, rejectWithValue, extra: API }: any

  ) => {
    try {
      const response = await API.get(`/api/profile/filter/?email=${email}`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      return response.data;
    } catch (e: any) {
      if (typeof e.response.data.detail === 'string' && e.response.data.detail !== 'User not found')
        dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail }));
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const getRoles = createAsyncThunk(
  'projects/getRoles',
  async (
    { projectId, permissions=false }: { projectId: number, permissions?:boolean },
    {dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      let url = `/api/projects/${projectId}/roles/`;
      if(permissions) {
        url += `?pm=1`
      }
      const response = await API.get(url);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      return response.data;
    } catch (e: any) {
      // if (typeof e.response.data.detail === 'string')
      //   dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail }));
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const createProjectRole = createAsyncThunk(
  'projects/createProjectRole',
  async (
    { data, projectId, userId }: { data: DefaultObject<any>, projectId: number, userId: number },
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const response = await API.post(`/api/projects/${projectId}/roles/?user_id=${userId}`, data);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      dispatch(getRoles({projectId, permissions: true}));
      dispatch(openPopup({ type: PopupType.SUCCESS, description: 'The role was created' }))
      return response.data.data;
    } catch (e: any) {
      if (typeof e.response.data.detail === 'string')
        dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail }));
      return rejectWithValue(e.response.data.detail);
    }
  }
);


export const updateProjectRole = createAsyncThunk(
  'projects/updateProjectRole',
  async (
    { data, projectId, roleId, userId }:
      { data: DefaultObject<any>, projectId: number, roleId: number, userId: number },
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const response = await API.put(`/api/projects/${projectId}/roles/${roleId}/?user_id=${userId}`, data);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      dispatch(getRoles({projectId, permissions: true}));
      dispatch(openPopup({ type: PopupType.SUCCESS, description: 'The role was updated' }))
      return response.data.data;
    } catch (e: any) {
      if (typeof e.response.data.detail === 'string')
        dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail }));
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const deleteProjectRole = createAsyncThunk(
  'projects/deleteProjectRole',
  async (
    { projectId, roleId }: { projectId: number, roleId: number },
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const response = await API.delete(`/api/projects/${projectId}/roles/${roleId}/`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      dispatch(getRoles({projectId, permissions: true}));
      dispatch(openPopup({ type: PopupType.SUCCESS, description: 'The role was deleted' }))
      return response.data.data;
    } catch (e: any) {
      if (typeof e.response.data.detail === 'string')
        dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail }));
      return rejectWithValue(e.response.data.detail);
    }
  }
);