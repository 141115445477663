import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import { PopupType } from '../../data'

interface PopupData {
  type: PopupType,
  title?: string,
  description?: string,
  icon?:'setting' | 'hello' | 'error' | 'rocket'
}

export interface PopupState {
  data: PopupData
}

const initialState: PopupState = {
  data: {} as PopupData
}

export const popupSlice = createSlice({
  name: 'popup',
  initialState,
  reducers: {
    openPopup: (state, action: PayloadAction<PopupData>) => {
      state.data = action.payload;
    },
    closePopup: (state) => {
      state.data = {} as PopupData;
    },
  }
})

export const { openPopup, closePopup } = popupSlice.actions

export const popupData = createSelector(
  (state: RootState) => state.popup,
  (popup) => popup.data
)

export default popupSlice.reducer
