import cls from "./style.module.scss";
import React, {ChangeEvent, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {isProject} from "../../../../actions/projects";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";
import {getIsLoading, secondDone, stepsData} from "../../../../redux/slices/projects";
import {sendSdk, validateUrl} from "../../../../utils";
import DocsCounter from "../../../../components/Modals/DocsCounter";
import Btn from "../../../../ui/Btn";
import {trans} from "../../../../_locales";
import {currentUserLang, userId} from "../../../../redux/slices/user";

export const SecondStep = () => {
  const [checkUrlInp, setCheckUrlInp] = useState<string>('');
  const [tempHelper, setTempHelper] = useState<boolean>(false)
  const [checkCopy, setCheckCopy] = useState<boolean>(false)
  const [checkCounterPending, setCheckCounterPending] = useState<boolean>(false)
  const [styleUrl, setStyleUrl] = useState<boolean>(false)
  const [statusPixelCheck, setStatusPixelCheck] = useState<boolean>(false)
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const reduxStepsData = useAppSelector(stepsData);
  const reduxLoading = useAppSelector(getIsLoading);
  const language = useAppSelector(currentUserLang);
  const reduxUserId = useAppSelector(userId);
  if(Object.values(reduxStepsData).length === 0) {
    navigate('/projects/create/1/');
  }
  const projectId =  reduxStepsData && reduxStepsData.dataProject && Number(reduxStepsData.dataProject.id);
  useEffect(()=> {
    if (reduxUserId && projectId) {
      sendSdk('page_view', {
        'user': {
          'uid': reduxUserId
        },
        'event_params': {
          'project_id': projectId,
          'user_id': reduxUserId,
          'page': 'project_create_counter'
        }
      })
    }
  }, [projectId, reduxUserId])
  useEffect(()=> {
    if(reduxLoading) {
      setCheckCounterPending(true);
      setTempHelper(false);
    } else {
      setCheckCounterPending(false);
    }

  }, [reduxLoading])

  useEffect(() => {
    setTimeout(() => {
      setCheckCopy(false)
    }, 3000)
  }, [checkCopy])
  const [sdkFuncText, setSdkFuncText] = useState<string>(
    `<script src="https://cdn.targetads.io/websdk/index.js"></script>
<script>
   window.targetAdsDataLayer = window.targetAdsDataLayer || {}
   function _targetadsTag(){window.targetAdsDataLayer.data = arguments;}
   _targetadsTag('init', ${projectId})
   _targetadsTag('page_view');
</script>
`);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(sdkFuncText).then(r => setCheckCopy(true));
  }

  const checkUrl = async () => {

      const projectId = Number(reduxStepsData.dataProject.id);
      const resultAction = await dispatch(isProject({projectId}))
      if (isProject.fulfilled.match(resultAction)) {
        const user = resultAction.payload;
        setTempHelper(true);
        console.log('success', user);
        if(user.status === 204) {
          setStatusPixelCheck(true);
        }
        if(user.status === 404) {
          setStatusPixelCheck(false);
          console.log('not found')
        }
      } else {
        setTempHelper(true);
        if (resultAction.payload) {
          // Since we passed in `MyKnownError` to `rejectValue` in `updateUser`, the type information will be available here.
          // Note: this would also be a good place to do any handling that relies on the `rejectedWithValue` payload, such as setting field errors
          console.log('error', resultAction.payload);
          setStatusPixelCheck(false);
        } else {
          console.log('error', resultAction.error.message);
        }
      }
  }

  const onSubmit = () => {
    dispatch(secondDone())
    navigate('/projects/create/3/')
  }

  const [showDocs, setShowDocs] = useState(false);
  const onHandlerModal = ()=> {
    setShowDocs(true);
  }

  return (
    <div className={cls.wrapper}>
      <div className={cls.form}>
        <h3 className={cls.onboard_step}>{trans('Installing a counter', language)}</h3>
        <div className={cls.input_section}>
          <span className={cls.section_title}>1 {trans('Copy the pixel code', language)}</span>
          <textarea
            className={cls.sdk_code}
            value={sdkFuncText}
            disabled={true}
          />
          <button
            className={checkCopy ? cls.copied : cls.inter_btn}
            type='submit'
            onClick={copyToClipboard}
          >
            { checkCopy ? trans('Copied', language) : trans('Copy', language) }
          </button>
          <Btn
            text={trans('Counter documentation', language)}
            color='light-blue'
            onClick={onHandlerModal}
          />
        </div>
        <div className={cls.input_section}>
          <span className={cls.section_title}>2 {trans('Set the pixel on the site', language)}</span>
          <p className={cls.description}>{trans('Add the counter code to the HTML code of all the pages of the site. The code must be placed within the <head> </head> or <body> </body> tags as close to the top of the page as possible: this way it will load earlier and will be able to send browsing data to TargetAds, even if the visitor closes the page almost immediately.', language)}</p>
        </div>
        <div className={cls.input_section}>
          <span className={cls.section_title}>3 {trans('', language)}Проверьте установку счетчика</span>
          {/*<span>Введите адрес сайта</span>*/}
          {/*<input*/}
          {/*  className={styleUrl ? cls.input_error : cls.input}*/}
          {/*  type='text'*/}
          {/*  value={checkUrlInp}*/}
          {/*  onChange={(e:ChangeEvent<HTMLInputElement>) => setCheckUrlInp(e.target.value)}*/}
          {/*/>*/}
          <button
            className={tempHelper ? cls.inter_btn : cls.inter_btn}
            type='submit'
            onClick={checkUrl}
          >
            {trans('Check', language)}
          </button>
          {checkCounterPending && <div className={cls.check_counter_preloader}>{trans('Checking the counter', language)}</div>}
          { !tempHelper && !checkCounterPending &&
            <div className={cls.check_counter}>{trans('The result of the check will appear here', language)}</div>
          }
          { tempHelper &&
            <div className={statusPixelCheck ? cls.success : cls.denied}>
              {statusPixelCheck ? trans('The counter is successfully connected!', language) : trans('The counter is not installed', language)}
            </div>
          }
        </div>
      </div>
      <div className={cls.footer}>
        <button className={cls.btn} type='button' onClick={onSubmit}>{trans('Next step', language)}</button>
        <button className={cls.skipper} onClick={() => navigate('/projects/create/3/')}>{trans('Skip', language)}</button>
      </div>
      {showDocs && <div className={cls.modal}>
        <DocsCounter title={trans('Counter documentation', language)} onClose={()=>setShowDocs(false)}/>
      </div>}
    </div>
  );
};
