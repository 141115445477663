import {FC} from 'react'
import cx from 'classnames'
import { IOptions } from './Options.d'
import { Btn } from '../../ui/index'
import { Popup, Filter } from '../index'
import './Options.scss'
import '../Alt/style.scss';

const Options: FC<IOptions> = ({ className, items }) => {
  const handleMouseEnter = (e:any, index:number) => {
    if(items[index].tooltip) {
        const box = e.target.closest('.options__item');
        if (box) {
            const div = document.createElement('div');
            div.id = `tooltip-${index}`
            div.innerHTML = `<div class='alt active right dark'><span>${items[index].tooltip}</span></div>`;
            box.append(div)
        }
    }
  }

  const handleMouseLeave = (index:number) => {
      const div = document.getElementById(`tooltip-${index}`)
      if (div && div.parentNode) {
          div.parentNode.removeChild(div);
      }
  }

  return (
    <div className={cx('options', className)}>
      <ul className="options__list">
        {items && items.length > 0 && items.map((item, i) => {
          if (item.type === 'btn') {
            return (
              <li key={i}
                  className="options__item"
                  onMouseEnter={(e)=> {
                      handleMouseEnter(e, i);
                  }}
                  onMouseLeave={()=>handleMouseLeave(i)}
              >
                <Btn
                  {...item.data}
                  className={cx('options__btn', {'is-active': item.isActive})}
                  isIconBtn={true}
                  size='lg'
                />
              </li>
            );
          } else if (item.type === 'popup') {
            return (
              <li key={i} className="options__item"
                onMouseEnter={(e)=>handleMouseEnter(e, i)}
                onMouseLeave={()=>handleMouseLeave(i)}
              >
                <Popup {...item.data} />
              </li>
            );
          } else if (item.type === 'filter') {
            return (
              <li key={i} className="options__item"
                onMouseEnter={(e)=>handleMouseEnter(e, i)}
                onMouseLeave={()=>handleMouseLeave(i)}
              >
                <Filter {...item.data} />
              </li>
            );
          }
        })}
      </ul>
    </div>
  )
}

export default Options