import { FC } from 'react'
import cx from 'classnames'
import { useBodyScrollLock } from '../../helpers'
import { Portal } from '../index'
import {ILoader} from './Loader.d'
import './Loader.scss'

const Loader: FC<ILoader> = ({
  isNotTransparent = false,
  isNotTransparentLight = false,
  inBlock = false,
  size
}) => {
  useBodyScrollLock();

  const loaderClassName = cx(
    'loader',
    {
      ['is-not-transparent']: isNotTransparent,
      ['is-not-transparent-light']: isNotTransparentLight,
      ['in-block']: inBlock,
      ['loader--xs']: size ==='xs',
    }
  )

  return (
        !inBlock ?
        <Portal selector='#modal-root'>
          <div className={loaderClassName}>
            <div className="loader__spinner" />
          </div>
        </Portal> :
        <div className={loaderClassName}>
            <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>

  );
};

export default Loader;
