const numbersFormatWithLetters = (number: number) => {
  let string = ''
  if (number < 1000) {
    string = `${number}`
  } else if (number < 1e6) {
    string = `${(number / 1000).toFixed(1)} к`
  } else if (number < 1e9) {
    string = `${(number / 1e6).toFixed(1)} млн`
  } else {
    string = `${(number / 1e9).toFixed(1)} млрд`
  }

  return string.replace('.0', '')
}

export default numbersFormatWithLetters