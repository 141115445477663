import React, {FC, useCallback, useEffect, useState} from 'react'
import { Form, Loader } from '../../index'
import {AccessField, Btn} from '../../../ui'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import {
  AccessList,
  getAccessList,
  getIsLoading,
  errorValue,
  removeError
} from '../../../redux/slices/access'
import { addAccess, fetchAccessList, removeAccess } from '../../../actions/access'
import { AnyEvent } from '../../../interfaces/common.d'
import { useShowError } from '../../../helpers'
import { IDeleteAccessModal } from './interfaces.d'
import './style.scss';
import {deleteProjectUser} from "../../../actions/projects";
import {currentUserLang} from "../../../redux/slices/user";
import {trans} from "../../../_locales";

const DeleteAccessModal: FC<IDeleteAccessModal> = ({
  email,
  userId,
  projectId,
  onClose
}) => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(getIsLoading);
  const language = useAppSelector(currentUserLang);

  const onReset = ()=> {
    onClose('DELETE_USER')
  }
  const onSubmit = ()=> {
    dispatch(deleteProjectUser({ email, projectId, userId }));
    onClose('DELETE_USER')
  }
  return (
    <>
      <p className={'delete-access__title'}>{trans("Confirmation of the user's removal from the project", language)}</p>
      <div className="delete-access-action">
        <Btn
          type='reset'
          text={trans('Cancel', language)}
          size='lg'
          widthAuto={false}
          onClick={onReset}
        />
        <Btn
          type='submit'
          text={trans('Delete', language)}
          color='blue'
          widthAuto={false}
          size='lg'
          disabled={false}
          onClick={onSubmit}
        />
      </div>
      {isLoading && <Loader />}
    </>
  );
};

export default DeleteAccessModal;
