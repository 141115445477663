import { createAsyncThunk } from '@reduxjs/toolkit'
import { closeModal, ModalName } from '../redux/slices/modals'
import {showPopup, updateToken} from '../utils'
import {openPopup} from "../redux/slices/popup";
import {PopupType} from "../data";

export const getEvents = createAsyncThunk(
  'events/getEvents',
  async (
    { projectId, calcAttr=false }: { projectId: any, calcAttr?: boolean },
    { dispatch, rejectWithValue, extra: API }: any

  ) => {
    try {
      const response = await API.get(`/api/projects/${projectId}/settings/events/?ca=${calcAttr}`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      return response.data;
    } catch (e: any) {
      if (typeof e.response.data.detail === 'string' && e.response.data.detail !== 'Somehow settings were not found')
        dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail }));
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const updateEvent = createAsyncThunk(
  'events/updateEvent',
  async (
    { projectId, eventsId, data }: { projectId: any, eventsId:number, data: any },
    { dispatch, rejectWithValue, extra: API }: any

  ) => {
    try {
      const response = await API.put(`/api/projects/${projectId}/settings/events/${eventsId}/`, data);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      dispatch(getEvents({projectId}))
      dispatch(openPopup({ type: PopupType.SUCCESS, title: 'Целевое действие обновлено' }))
      return response.data;
    } catch (e: any) {
      if (typeof e.response.data.detail === 'string')
        dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail }));
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const deleteEvent = createAsyncThunk(
  'events/deleteEvent',
  async (
    { projectId, eventsId }: { projectId: any, eventsId:number },
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const response = await API.delete(`/api/projects/${projectId}/settings/events/${eventsId}/`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      dispatch(getEvents({projectId}))
      return response.data;
    } catch (e: any) {
      if (typeof e.response.data.detail === 'string')
        dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail }));
      return rejectWithValue(e.response.data.detail);
    }
  }
);