import {FC, useEffect, useState} from 'react'
import cx from 'classnames'
import {ISideMenu, ISideMenuBtns} from './interfaces.d'
import { Btn } from '../../ui'
import './style.scss'

const SideMenu: FC<ISideMenu> = ({
    className,
    btnsList,
    alt,
    hideFullVersion
}) => {
    const BtnItem = ({item, i}: {item:ISideMenuBtns, i:number}) => {
        const [isHovered, setIsHovered] = useState<any>(false);
        return (
            <li
              key={i}
              className={cx('side-menu__item', {'is-active': item.isActive})}
              title={item.btn.ariaLabel}
              onMouseEnter={()=>setIsHovered(true)}
              onMouseLeave={()=> setIsHovered(false)}
            >
                <Btn
                    className='side-menu__btn'
                    {...item.btn}
                    isIconBtn={true}
                    size='md'
                    text={item.btn.ariaLabel}
                    isfullWidth={true}
                />
                {alt && <div className="side-menu__alt">
                    <span>{item.btn.ariaLabel}</span>
                </div>}
                {item.subBtn && item.subBtn.length > 0 && isHovered && <div className='side-menu-sub'>
                    {
                        item.subBtn.map((item, index) => (
                            <Btn
                                key={index}
                                className='side-menu-sub__btn'
                                size='md'
                                text={item.title}
                                href={item.href}
                                isfullWidth={true}
                                onClick={()=>hideFullVersion()}
                            />
                        ))
                    }
                </div>}
            </li>
        )
    }

  return (
    <nav className={cx('side-menu', className)}>
      <ul className="side-menu__list">
        {btnsList.length > 0 && btnsList.map((item, i) =>
          <BtnItem key={i} item={item} i={i} />)
        }
      </ul>
    </nav>
  );
}

export default SideMenu
